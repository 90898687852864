import { useState, useRef, useEffect } from 'react';
import domtoimage from 'dom-to-image';
import { isMobile } from 'react-device-detect';

import { FaLightbulb } from 'react-icons/fa';

import {
  AgressiveCard,
  PassiveCard,
  AssertiveCard,
  numberedCards,
  ResultCard,
} from '../../assets';

import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  DataURL,
} from '@utils';

import {
  ModalAddOps,
  ModalAlert,
  TertiaryButton,
  PrimaryButton,
  ModalConfirm,
} from '@components';

import {
  Container,
  CardContainer,
  Card,
  Button,
  DeckContainer,
  GlobalStyle,
  SaveArea,
  ButtonContainer,
  AnswerBox,
  ResultBoxProfessional,
  DeckBox,
  ResultDeckBoxProfessional,
  ResultBoxPageOne,
  DeckColumn,
  NumberResult,
  DeckNumbers,
  ResultTable,
  CardBox,
} from './styles';

export default function PassiveAgressive(props) {
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  //Socket variables
  const [yourID, setYourID] = useState();
  const socketRef = useRef();

  //Modal variables
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCleanIsOpen, setModalCleanIsOpen] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);

  const answerArray = [
    { correctCategorie: 'assertivo', patientAnswer: '', index: 0 },
    { correctCategorie: 'passivo', patientAnswer: '', index: 1 },
    { correctCategorie: 'assertivo', patientAnswer: '', index: 2 },
    { correctCategorie: 'passivo', patientAnswer: '', index: 3 },
    { correctCategorie: 'passivo', patientAnswer: '', index: 4 },
    { correctCategorie: 'assertivo', patientAnswer: '', index: 5 },
    { correctCategorie: 'agressivo', patientAnswer: '', index: 6 },
    { correctCategorie: 'passivo', patientAnswer: '', index: 7 },
    { correctCategorie: 'agressivo', patientAnswer: '', index: 8 },
    { correctCategorie: 'assertivo', patientAnswer: '', index: 9 },
    { correctCategorie: 'assertivo', patientAnswer: '', index: 10 },
    { correctCategorie: 'agressivo', patientAnswer: '', index: 11 },
    { correctCategorie: 'assertivo', patientAnswer: '', index: 12 },
    { correctCategorie: 'passivo', patientAnswer: '', index: 13 },
    { correctCategorie: 'passivo', patientAnswer: '', index: 14 },
    { correctCategorie: 'passivo', patientAnswer: '', index: 15 },
    { correctCategorie: 'assertivo', patientAnswer: '', index: 16 },
    { correctCategorie: 'agressivo', patientAnswer: '', index: 17 },
    { correctCategorie: 'passivo', patientAnswer: '', index: 18 },
    { correctCategorie: 'agressivo', patientAnswer: '', index: 19 },
    { correctCategorie: 'agressivo', patientAnswer: '', index: 20 },
    { correctCategorie: 'assertivo', patientAnswer: '', index: 21 },
    { correctCategorie: 'assertivo', patientAnswer: '', index: 22 },
    { correctCategorie: 'assertivo', patientAnswer: '', index: 23 },
    { correctCategorie: 'agressivo', patientAnswer: '', index: 24 },
    { correctCategorie: 'passivo', patientAnswer: '', index: 25 },
    { correctCategorie: 'agressivo', patientAnswer: '', index: 26 },
  ];

  //card variables
  const [typeOfCardObject, setTypeOfCardObject] = useState({
    agressivo: 0,
    passivo: 0,
    assertivo: 0,
  });
  const [typeOfCard, setTypeOfCard] = useState([]);
  const [cardIndex, setCardIndex] = useState(0);
  const [cardsArray, setCardsArray] = useState(shuffle([...answerArray]));
  const [shouldShowResult, setShouldShowResult] = useState(false);
  const [resultObject, setResultObject] = useState({});
  const [shouldShowDetailedResult, setShouldShowDetailedResult] = useState(
    false
  );

  const showAnimationCard = useRef(null);
  const showAnimationDeckAgressive = useRef(null);
  const showAnimationDeckPassive = useRef(null);
  const showAnimationDeckAssertive = useRef(null);
  const [shouldDesableButton, setShouldDesableButton] = useState(false);

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  function showAnimation(typeOfCard) {
    showAnimationCard.current.classList.add('animation');

    if (typeOfCard === 'agressivo') {
      showAnimationDeckAgressive.current.style.filter =
        'drop-shadow(0px 0px 30px rgba(244, 67, 54, 0.75))';
    } else if (typeOfCard === 'passivo') {
      showAnimationDeckPassive.current.style.filter =
        'drop-shadow(0px 0px 30px rgba(39, 151, 186, 0.75))';
    } else {
      showAnimationDeckAssertive.current.style.filter =
        'drop-shadow(0px 0px 30px rgba(123, 97, 255, 0.75))';
    }

    setShouldDesableButton(true);

    setTimeout(() => {
      showAnimationCard.current.classList.remove('animation');
      if (typeOfCard === 'agressivo') {
        showAnimationDeckAgressive.current.style.filter =
          'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))';
      } else if (typeOfCard === 'passivo') {
        showAnimationDeckPassive.current.style.filter =
          'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))';
      } else {
        showAnimationDeckAssertive.current.style.filter =
          'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))';
      }

      setShouldDesableButton(false);
    }, 250);
  }

  function handleButtonOnClick(typeOfCardString) {
    const newArray = [...cardsArray];
    const newTypeOfCard = [...typeOfCard];
    const newCardIndex = cardIndex + 1;
    const newTypeOfCardObject = typeOfCardObject;

    newArray[cardIndex] = {
      ...newArray[cardIndex],
      patientAnswer: typeOfCardString,
    };

    newTypeOfCard.push(typeOfCardString);

    newTypeOfCardObject[typeOfCardString] =
      newTypeOfCardObject[typeOfCardString] + 1;

    setCardsArray(newArray);
    setTypeOfCard(newTypeOfCard);
    setTypeOfCardObject(newTypeOfCardObject);

    showAnimation(typeOfCardString);

    setTimeout(() => {
      setCardIndex(newCardIndex);
      sendCardsObject(
        newArray,
        newTypeOfCard,
        newTypeOfCardObject,
        newCardIndex
      );
    }, 250);
  }

  function handleUndo() {
    if (cardIndex > 0) {
      const newTypeOfCard = [...typeOfCard];
      const newCardIndex = cardIndex - 1;
      const newTypeOfCardObject = typeOfCardObject;
      const lastTypeOfCard = newTypeOfCard[newTypeOfCard.length - 1];

      newTypeOfCard.pop();
      newTypeOfCardObject[lastTypeOfCard] =
        newTypeOfCardObject[lastTypeOfCard] - 1;

      setTypeOfCardObject(newTypeOfCardObject);
      setCardIndex(newCardIndex);
      setTypeOfCard(newTypeOfCard);

      sendCardsObject(
        cardsArray,
        newTypeOfCard,
        newTypeOfCardObject,
        newCardIndex
      );
    }
  }

  const handleExport = () => {
    domtoimage
      .toPng(document.getElementById('divIdToPrint'))
      .then(function (dataUrl) {
        const nome_paciente = localStorage
          .getItem('@nome_paciente')
          .replaceAll(' ', '_');
        const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
        downloadURI(
          dataUrl,
          filename,
          sendArquivo,
          os,
          token,
          nome_recurso_cod
        );
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  };

  function handleClean() {
    const newCardsArray = shuffle([...answerArray]);
    const newTypeOfCardObject = {
      agressivo: 0,
      passivo: 0,
      assertivo: 0,
    };
    const newCardIndex = 0;
    const newTypeOfCard = [];

    setCardsArray(newCardsArray);
    setCardIndex(newCardIndex);
    setTypeOfCardObject(newTypeOfCardObject);
    setTypeOfCard(newTypeOfCard);
    setModalCleanIsOpen(false);
    setShouldShowResult(false);
    setShouldShowDetailedResult(false);

    sendCardsObject(
      newCardsArray,
      newTypeOfCard,
      newTypeOfCardObject,
      newCardIndex
    );
  }

  //socket functions

  function sendCardsObject(
    cardsArray,
    typeOfCard,
    typeOfCardObject,
    cardIndex
  ) {
    const messageObject = {
      body: {
        cardsArray,
        typeOfCard,
        typeOfCardObject,
        cardIndex,
      },
      id: yourID,
      typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  function receivedMessage(message) {
    if (message.body.requestCards) {
      sendCardsObject(cardsArray, typeOfCard, typeOfCardObject, cardIndex);
    } else {
      const newCardsArray = message.body.cardsArray;
      const newTypeOfCard = message.body.typeOfCard;
      const newTypeOfCardObject = message.body.typeOfCardObject;
      const newCardIndex = message.body.cardIndex;

      setCardsArray(newCardsArray);
      setTypeOfCard(newTypeOfCard);
      setTypeOfCardObject(newTypeOfCardObject);
      setCardIndex(newCardIndex);
      if (newCardIndex === 0) {
        setShouldShowResult(false);
        setShouldShowDetailedResult(false);
      }
    }
  }

  function requestCardsFromProfessional() {
    const messageObject = {
      body: { requestCards: true },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);
    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, tokenSocketIo);
      sendCardsObject(cardsArray, typeOfCard, typeOfCardObject, cardIndex);
    } else if (typeUser === 'paciente' && cardIndex === 0) {
      requestCardsFromProfessional();
    }
  }, []);

  //-------------result functions----------------//

  function handleShowResult() {
    const result = {
      resultNumbers: 0,
      agressivo: [],
      passivo: [],
      assertivo: [],
    };

    for (let i = 0; i < cardsArray.length; i++) {
      if (cardsArray[i].patientAnswer === cardsArray[i].correctCategorie) {
        result.resultNumbers += 1;
      }
      if (cardsArray[i].index.toString().length === 1) {
        const newNumber = `0${cardsArray[i].index + 1}`;
        if (cardsArray[i].index === 9) {
          result[cardsArray[i].patientAnswer].push(cardsArray[i].index + 1);
        } else {
          result[cardsArray[i].patientAnswer].push(newNumber);
        }
      } else {
        result[cardsArray[i].patientAnswer].push(cardsArray[i].index + 1);
      }
    }

    setResultObject(result);
    setShouldShowResult(true);
  }

  return (
    <>
      <Container>
        <GlobalStyle />
        <CardContainer viewHeight={cardIndex > 26 ? '100%' : '94vh'}>
          <SaveArea>
            {typeUser === 'profissional' && (
              <TertiaryButton
                style={{ fontSize: 16, width: 100 }}
                onClick={() => setModalCleanIsOpen(true)}
                mobile
              >
                Reiniciar
              </TertiaryButton>
            )}
            {typeUser === 'profissional' && (
              <PrimaryButton
                style={{ fontSize: 16, width: 100 }}
                onClick={() => setIsOpenAlert(true)}
                mobile
              >
                Instruções
              </PrimaryButton>
            )}
            {cardIndex > 26 && typeUser === 'profissional' && (
              <PrimaryButton
                style={{ fontSize: 16, width: 100 }}
                onClick={() => setIsOpen(true)}
                mobile
              >
                Salvar
              </PrimaryButton>
            )}
            {typeUser === 'profissional' && (
              <PrimaryButton
                style={{ fontSize: 16, width: 100 }}
                onClick={() => setModalCloseIsOpen(true)}
                mobile
              >
                Fechar
              </PrimaryButton>
            )}

            {cardIndex <= 26 && (
              <PrimaryButton
                style={{ fontSize: 16, width: 100 }}
                onClick={handleUndo}
                mobile
              >
                Desfazer
              </PrimaryButton>
            )}
          </SaveArea>
          {cardIndex <= 26 && (
            <DeckContainer>
              <DeckBox
                ref={showAnimationDeckAgressive}
                style={{ backgroundImage: `url(${AgressiveCard})` }}
              >
                <span>
                  <p>{typeOfCardObject.agressivo}</p>
                </span>
              </DeckBox>
              <DeckBox
                ref={showAnimationDeckPassive}
                style={{ backgroundImage: `url(${PassiveCard})` }}
              >
                <span>
                  <p>{typeOfCardObject.passivo}</p>
                </span>
              </DeckBox>
              <DeckBox
                ref={showAnimationDeckAssertive}
                style={{ backgroundImage: `url(${AssertiveCard})` }}
              >
                <span>
                  <p>{typeOfCardObject.assertivo}</p>
                </span>
              </DeckBox>
            </DeckContainer>
          )}
          {cardIndex <= 26 && (
            <>
              {typeUser === 'profissional' && (
                <AnswerBox>
                  <span>
                    <FaLightbulb />
                  </span>
                  <div>
                    <h2>Resposta Correta:</h2>
                    <strong>{cardsArray[cardIndex].correctCategorie}</strong>
                  </div>
                </AnswerBox>
              )}
              <CardBox>
                <Card
                  ref={showAnimationCard}
                  src={numberedCards[cardsArray[cardIndex].index]}
                  alt="carta de comportamento agressivo, passivo ou assertivo"
                />
              </CardBox>
              <ButtonContainer>
                <Button
                  buttonColor="#F44336"
                  shaddow="drop-shadow(0px 0px 24px rgba(244,67,54,0.4))"
                  shaddowSelected="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.7))"
                  onClick={
                    shouldDesableButton
                      ? null
                      : () => handleButtonOnClick('agressivo')
                  }
                >
                  Agressivo
                </Button>
                <Button
                  buttonColor="#2196F3"
                  shaddow="drop-shadow(0px 0px 24px rgba(39, 151, 186, 0.3))"
                  shaddowSelected="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.7))"
                  onClick={
                    shouldDesableButton
                      ? null
                      : () => handleButtonOnClick('passivo')
                  }
                >
                  Passivo
                </Button>
                <Button
                  buttonColor="#8E7AD6"
                  shaddow="drop-shadow(0px 0px 24px rgba(123, 97, 255, 0.3))"
                  shaddowSelected="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.7))"
                  onClick={
                    shouldDesableButton
                      ? null
                      : () => handleButtonOnClick('assertivo')
                  }
                >
                  Assertivo
                </Button>
              </ButtonContainer>
            </>
          )}
        </CardContainer>

        {cardIndex > 26 && !shouldShowResult && !shouldShowDetailedResult && (
          <ResultBoxPageOne>
            <div>
              <h3>Parabéns!!!</h3>
              <h3>Você concluiu o recurso Comunicação</h3>
              <h3>Assertiva, passiva e agressiva!</h3>
            </div>
            <img src={ResultCard} alt="Grupo segurando bandeira" />
            <button onClick={handleShowResult}>MEUS RESULTADOS</button>
          </ResultBoxPageOne>
        )}
        {cardIndex > 26 && shouldShowResult && (
          <ResultBoxProfessional>
            <NumberResult>
              <h3>Meus Resultados</h3>
              <h3>{resultObject.resultNumbers}/27</h3>
            </NumberResult>
            <ResultDeckBoxProfessional>
              <DeckColumn>
                <DeckBox
                  result
                  style={{ backgroundImage: `url(${AgressiveCard})` }}
                >
                  <span>
                    <p>{typeOfCardObject.agressivo}</p>
                  </span>
                </DeckBox>
                <DeckNumbers>
                  {resultObject.agressivo.map((item, index) => {
                    return (
                      <p>
                        {resultObject.agressivo.length - 1 === index
                          ? item
                          : `${item},`}
                      </p>
                    );
                  })}
                </DeckNumbers>
              </DeckColumn>
              <DeckColumn>
                <DeckBox
                  result
                  style={{ backgroundImage: `url(${PassiveCard})` }}
                >
                  <span>
                    <p>{typeOfCardObject.passivo}</p>
                  </span>
                </DeckBox>
                <DeckNumbers>
                  {resultObject.passivo.map((item, index) => {
                    return (
                      <p>
                        {resultObject.passivo.length - 1 === index
                          ? item
                          : `${item},`}
                      </p>
                    );
                  })}
                </DeckNumbers>
              </DeckColumn>
              <DeckColumn>
                <DeckBox
                  result
                  style={{ backgroundImage: `url(${AssertiveCard})` }}
                >
                  <span>
                    <p>{typeOfCardObject.assertivo}</p>
                  </span>
                </DeckBox>
                <DeckNumbers>
                  {resultObject.assertivo.map((item, index) => {
                    return (
                      <p>
                        {resultObject.assertivo.length - 1 === index
                          ? item
                          : `${item},`}
                      </p>
                    );
                  })}
                </DeckNumbers>
              </DeckColumn>
            </ResultDeckBoxProfessional>
            <button
              onClick={() => {
                setShouldShowDetailedResult(true);
                setShouldShowResult(false);
              }}
            >
              VER GABARITO DETALHADO
            </button>
          </ResultBoxProfessional>
        )}

        {cardIndex > 26 && shouldShowDetailedResult && (
          <div>
            <ResultTable>
              <h1>Gabarito</h1>
              <table>
                <tr>
                  <th>
                    <h3>nº</h3>
                  </th>
                  <th>
                    <h3>Agressivo</h3>
                  </th>
                  <th>
                    <h3>Passivo</h3>
                  </th>
                  <th>
                    <h3>Assertivo</h3>
                  </th>
                  <th>
                    <h3>Resposta escolhida</h3>
                  </th>
                </tr>
                {cardsArray.map((card, index) => {
                  return (
                    <tr>
                      <td>
                        <p>{index < 9 ? `0${index + 1}` : index + 1}</p>
                      </td>
                      <td
                        style={{
                          backgroundColor:
                            card.correctCategorie === 'agressivo'
                              ? '#CCD1D3'
                              : '#f5f5f5',
                          width: isMobile ? '70px' : '91px',
                        }}
                      ></td>
                      <td
                        style={{
                          backgroundColor:
                            card.correctCategorie === 'passivo'
                              ? '#CCD1D3'
                              : '#f5f5f5',
                          width: isMobile ? '70px' : '91px',
                        }}
                      ></td>
                      <td
                        style={{
                          backgroundColor:
                            card.correctCategorie === 'assertivo'
                              ? '#CCD1D3'
                              : '#f5f5f5',
                          width: isMobile ? '70px' : '91px',
                        }}
                      ></td>
                      <td
                        style={{
                          backgroundColor: '#28A745',
                          fontSize: '18px',
                          fontWeight: '700',
                          color: '#f5f5f5',
                          width: isMobile ? '70px' : '91px',
                          textAlign: 'center',
                        }}
                      >
                        {card.patientAnswer.charAt(0).toUpperCase() +
                          card.patientAnswer.slice(1)}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </ResultTable>
          </div>
        )}

        <ModalAddOps
          generatePdf={handleExport}
          modalIsOpen={modalIsOpen}
          setIsOpen={setIsOpen}
          token={token}
        />
        <ModalAlert
          modalIsOpen={modalIsOpenAlert}
          setIsOpen={setIsOpenAlert}
          token={token}
          props={props}
          title={'Passivo, agressivo ou assertivo'}
          recurso={nome_recurso_cod}
          bt01Txt={'Fechar'}
        />
        <ModalConfirm
          title="Reiniciar"
          description="Deseja realmente reiniciar o recurso?"
          modalIsOpen={modalCleanIsOpen}
          setIsOpen={setModalCleanIsOpen}
          confirmButtonText="Reiniciar"
          cancelButtonText="Cancelar"
          onCancel={() => setModalCleanIsOpen(false)}
          onConfirm={handleClean}
        />
        <ModalConfirm
          title="Fechar o recurso"
          description="Deseja realmente fechar o recurso?"
          modalIsOpen={modalCloseIsOpen}
          setIsOpen={setModalCloseIsOpen}
          confirmButtonText="Fechar"
          cancelButtonText="Cancelar"
          onCancel={() => setModalCloseIsOpen(false)}
          onConfirm={() =>
            handleBackHome(
              modalClose,
              nome_recurso_cod,
              typeUser,
              props,
              os,
              token,
              ambiente
            )
          }
        />
      </Container>
    </>
  );
}
