import React, { useState, useEffect, useRef } from 'react';

//Utilities import
import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  PacientName,
  DataURL,
} from '@utils';

//Styles Import
import { Container, SaveArea, BoardBox } from './styles';

//Images import
import {
  CheckerBoard,
  CheckerGraveyard,
  CheckersBox,
  YellowChecker,
  BlueChecker,
  YellowPawn,
  BluePawn,
} from '@assets';

//Drag'n Drop imports
import { Stage, Layer, Text } from 'react-konva';
import MountUrlImage from '@components/MountUrlImage';

//Components import
import {
  ModalAddOps,
  ModalAlert,
  SecondaryButton,
  TertiaryButton,
  ModalConfirm,
} from '@components';

const Checkers = (props) => {
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  //Socket variables
  const [yourID, setYourID] = useState();
  const socketRef = useRef();

  //Modal variables
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCleanIsOpen, setModalCleanIsOpen] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);

  //Control variables
  const innerWidthSize = window.innerWidth > 1000 ? 1000 : window.innerWidth;
  const proportionalStageHeight = (innerWidthSize * 753) / 1000;
  const scaleX = innerWidthSize / 1000;
  const scaleY = proportionalStageHeight / 753;

  //Drag'n Drop variables
  const stageRef = React.useRef();
  const [lastKnownPosition, setLastKnownPosition] = useState({
    x: 0,
    y: 900,
    src: null,
    width: 70,
    height: 70,
    offsetX: 35,
    offsetY: 35,
    drag: false,
  });
  const [selectedId, selectShape] = useState(null);
  const standartBoardState = [
    {
      x: 235,
      y: 235,
      src: CheckerBoard,
      width: 750,
      height: 750,
      drag: false,
      preventDefault: false,
    },
    {
      x: 830,
      y: 40,
      src: CheckersBox,
      width: 230,
      height: 110,
      drag: false,
      preventDefault: false,
    },
    {
      x: 830,
      y: 320,
      src: CheckerGraveyard,
      preventDefault: false,
      drag: false,
      width: 230,
      height: 620,
    },
    {
      x: typeUser === 'profissional' ? 820 : -75,
      y: typeUser === 'profissional' ? 55 : 690,
      rotation: typeUser === 'profissional' ? 0 : 180,
      src: YellowChecker,
      drag: true,
      width: 70,
      height: 70,
    },
    {
      x: typeUser === 'profissional' ? 820 : -75,
      y: typeUser === 'profissional' ? 55 : 690,
      rotation: typeUser === 'profissional' ? 0 : 180,
      src: YellowChecker,
      drag: true,
      width: 70,
      height: 70,
    },
    {
      x: typeUser === 'profissional' ? 820 : -75,
      y: typeUser === 'profissional' ? 55 : 690,
      rotation: typeUser === 'profissional' ? 0 : 180,
      src: YellowChecker,
      drag: true,
      width: 70,
      height: 70,
    },
    {
      x: typeUser === 'profissional' ? 820 : -75,
      y: typeUser === 'profissional' ? 55 : 690,
      rotation: typeUser === 'profissional' ? 0 : 180,
      src: YellowChecker,
      drag: true,
      width: 70,
      height: 70,
    },
    {
      x: typeUser === 'profissional' ? 820 : -75,
      y: typeUser === 'profissional' ? 55 : 690,
      rotation: typeUser === 'profissional' ? 0 : 180,
      src: YellowChecker,
      drag: true,
      width: 70,
      height: 70,
    },
    {
      x: typeUser === 'profissional' ? 820 : -75,
      y: typeUser === 'profissional' ? 55 : 690,
      rotation: typeUser === 'profissional' ? 0 : 180,
      src: YellowChecker,
      drag: true,
      width: 70,
      height: 70,
    },
    {
      x: typeUser === 'profissional' ? 820 : -75,
      y: typeUser === 'profissional' ? 55 : 690,
      rotation: typeUser === 'profissional' ? 0 : 180,
      src: YellowChecker,
      drag: true,
      width: 70,
      height: 70,
    },
    {
      x: typeUser === 'profissional' ? 820 : -75,
      y: typeUser === 'profissional' ? 55 : 690,
      rotation: typeUser === 'profissional' ? 0 : 180,
      src: YellowChecker,
      drag: true,
      width: 70,
      height: 70,
    },
    {
      x: typeUser === 'profissional' ? 920 : -165,
      y: typeUser === 'profissional' ? 55 : 690,
      rotation: typeUser === 'profissional' ? 0 : 180,
      src: BlueChecker,
      drag: true,
      width: 70,
      height: 70,
    },
    {
      x: typeUser === 'profissional' ? 920 : -165,
      y: typeUser === 'profissional' ? 55 : 690,
      rotation: typeUser === 'profissional' ? 0 : 180,
      src: BlueChecker,
      drag: true,
      width: 70,
      height: 70,
    },
    {
      x: typeUser === 'profissional' ? 920 : -165,
      y: typeUser === 'profissional' ? 55 : 690,
      rotation: typeUser === 'profissional' ? 0 : 180,
      src: BlueChecker,
      drag: true,
      width: 70,
      height: 70,
    },
    {
      x: typeUser === 'profissional' ? 920 : -165,
      y: typeUser === 'profissional' ? 55 : 690,
      rotation: typeUser === 'profissional' ? 0 : 180,
      src: BlueChecker,
      drag: true,
      width: 70,
      height: 70,
    },
    {
      x: typeUser === 'profissional' ? 920 : -165,
      y: typeUser === 'profissional' ? 55 : 690,
      rotation: typeUser === 'profissional' ? 0 : 180,
      src: BlueChecker,
      drag: true,
      width: 70,
      height: 70,
    },
    {
      x: typeUser === 'profissional' ? 920 : -165,
      y: typeUser === 'profissional' ? 55 : 690,
      rotation: typeUser === 'profissional' ? 0 : 180,
      src: BlueChecker,
      drag: true,
      width: 70,
      height: 70,
    },
    {
      x: typeUser === 'profissional' ? 920 : -165,
      y: typeUser === 'profissional' ? 55 : 690,
      rotation: typeUser === 'profissional' ? 0 : 180,
      src: BlueChecker,
      drag: true,
      width: 70,
      height: 70,
    },
    {
      x: typeUser === 'profissional' ? 920 : -165,
      y: typeUser === 'profissional' ? 55 : 690,
      rotation: typeUser === 'profissional' ? 0 : 180,
      src: BlueChecker,
      drag: true,
    },
    {
      x: 168,
      y: 84,
      src: BluePawn,
      drag: true,
    },
    {
      x: 334,
      y: 84,
      src: BluePawn,
      drag: true,
    },
    {
      x: 500,
      y: 84,
      src: BluePawn,
      drag: true,
    },
    {
      x: 665,
      y: 84,
      src: BluePawn,
      drag: true,
    },
    {
      x: 582,
      y: 167,
      src: BluePawn,
      drag: true,
    },
    {
      x: 416,
      y: 167,
      src: BluePawn,
      drag: true,
    },

    {
      x: 251,
      y: 167,
      src: BluePawn,
      drag: true,
    },
    {
      x: 85,
      y: 167,
      src: BluePawn,
      drag: true,
    },
    {
      x: 168,
      y: 250,
      src: BluePawn,
      drag: true,
    },
    {
      x: 334,
      y: 250,
      src: BluePawn,
      drag: true,
    },
    {
      x: 499,
      y: 250,
      src: BluePawn,
      drag: true,
    },
    {
      x: 665,
      y: 250,
      src: BluePawn,
      drag: true,
    },
    {
      x: 168,
      y: 582,
      src: YellowPawn,
      drag: true,
    },
    {
      x: 334,
      y: 582,
      src: YellowPawn,
      drag: true,
    },
    {
      x: 499,
      y: 582,
      src: YellowPawn,
      drag: true,
    },
    {
      x: 665,
      y: 582,
      src: YellowPawn,
      drag: true,
    },
    {
      x: 416,
      y: 500,
      src: YellowPawn,
      drag: true,
    },

    {
      x: 251,
      y: 500,
      src: YellowPawn,
      drag: true,
    },
    {
      x: 84,
      y: 500,
      src: YellowPawn,
      drag: true,
    },
    {
      x: 583,
      y: 500,
      src: YellowPawn,
      drag: true,
    },
    {
      x: 416,
      y: 668,
      src: YellowPawn,
      drag: true,
    },

    {
      x: 251,
      y: 668,
      src: YellowPawn,
      drag: true,
    },
    {
      x: 86,
      y: 668,
      src: YellowPawn,
      drag: true,
    },
    {
      x: 583,
      y: 668,
      src: YellowPawn,
      drag: true,
    },
  ];
  const [images, setImages] = useState(standartBoardState);

  //*************************************************************************************************************** */
  //Functions

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };
  const salvarFim = () => {
    setIsOpen(true);
  };

  const handleExport = () => {
    selectShape(null);
    setTimeout(() => {
      const uri = stageRef.current.toDataURL();
      const nome_paciente = localStorage
        .getItem('@nome_paciente')
        .replaceAll(' ', '_');
      const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
      downloadURI(uri, filename, sendArquivo, os, token, nome_recurso_cod);
    }, 500);
  };

  const handleClean = () => {
    setImages(standartBoardState);
    selectShape(-1);
    setLastKnownPosition({
      x: 0,
      y: 900,
      src: null,
      width: 70,
      height: 70,
      drag: false,
    });
    sendImage(
      standartBoardState,
      { x: 0, y: 900, src: null, width: 70, height: 70, drag: false },
      -1
    );

    setModalCleanIsOpen(false);
  };

  function sendImage(imagesData, lastKnownP, selectedId) {
    const messageObject = {
      body: {
        imagesData: imagesData,
        lastKnownPosition: lastKnownP,
        selectedId: selectedId,
      },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  function CheckChecker(originalImage, newImage, selectedId) {
    let auxImage = newImage;
    auxImage.rotation = typeUser === 'profissional' ? 0 : 180; //Rotação relativa ao usuário

    if (typeUser === 'profissional') {
      //Caso estejamos na tela do profissional
      if (newImage.y === 690) {
        //Se a imagem recebida for na altura de onde ficam as damas no lado do paciente(exata)
        auxImage.x = newImage.x === -75 ? 820 : 920; //O X vai ser o da esquerda para amarelo e da direita para azul
        auxImage.y = 55; //O Y é convertido para o normal para o profissional
        return auxImage;
      } else if (newImage.y <= 555 && newImage.x <= -50 && selectedId > 2) {
        //Se a imagem estava na área do cemitério do paciente e não é um dos backgrounds
        auxImage.x = 850; //O X recebe o meio do cemitério do profissional
        auxImage.y = 500; //o Y recebe uma coordenada fixa para evitar diferenças
        return auxImage;
      }
    } else {
      //Caso estejamos na tela do paciente
      if (newImage.y === 55) {
        //Se a imagem recebida for na altura de onde ficam as damas no lado do profissional(exata)
        auxImage.x = newImage.x === 820 ? -75 : -165; //O X vai ser o da esquerda para amarelo e da direita para azul
        auxImage.y = 690; //O Y é convertido para o normal para o paciente
        return auxImage;
      }
      if (newImage.x >= 780 && newImage.y >= 140 && selectedId > 2) {
        //Se a imagem estava na área do cemitério do profissional e não é um dos backgrounds
        auxImage.x = -120; //O X recebe o meio do cemitério do paciente
        auxImage.y = 400; //o Y recebe uma coordenada fixa para evitar diferenças
        return auxImage;
      }
    }
    return auxImage;
  }

  async function receivedMessage(message) {
    //xProfissionalDamas>780
    //yProfissionalDamas>100
    //xProfissionalCemiterio > 780
    //yProfissionalCemiterio > 140

    //xPacienteDamas < -36
    //yPacienteDamas > 635
    //xPacienteCemiterio < -50
    //yPacienteCemiterio < 555

    selectShape(message.body.selectedId);
    if (message.body.originalImage) {
      let LNP = {
        ...message.body.originalImage,
        src: null,
        width: 70,
        height: 70,
        offsetX: lastKnownPosition.offsetX,
        offsetY: lastKnownPosition.offsetY,
        drag: false,
      };
      setLastKnownPosition(LNP);

      setImages(
        images.map((image, index) => {
          if (index !== message.body.selectedId) {
            return image;
          } else {
            return CheckChecker(
              message.body.originalImage,
              message.body.newImage,
              message.body.selectedId
            );
          }
        })
      );
    } else {
      let LNP = message.body.lastKnownPosition;
      setLastKnownPosition({
        x: LNP.x,
        y: LNP.y,
        width: LNP.width,
        height: LNP.height,
        offsetX: LNP.offsetX,
        offsetY: LNP.offsetY,
        drag: LNP.drag,
      });
      setImages(
        message.body.imagesData.map((image, index) => {
          let auxImage = image;
          if (index > 2 && typeUser === 'profissional') auxImage.rotation = 0; //Rotação relativa ao usuário
          if (index > 2 && typeUser === 'paciente') auxImage.rotation = 180; //Rotação relativa ao usuário
          if (typeUser === 'profissional') {
            //Caso estejamos na tela do profissional
            if (image.y === 690) {
              //Se a imagem recebida for na altura de onde ficam as damas no lado do paciente(exata)
              auxImage.x = image.x === -75 ? 820 : 920; //O X vai ser o da esquerda para amarelo e da direita para azul
              auxImage.y = 55; //O Y é convertido para o normal para o profissional

              return auxImage;
            }
            if (image.y <= 555 && image.x <= -50 && index > 2) {
              //Se a imagem estava na área do cemitério do paciente e não é um dos backgrounds
              auxImage.x = 850; //O X recebe o meio do cemitério do profissional
              auxImage.y = 500; //o Y recebe uma coordenada fixa para evitar diferenças

              return auxImage;
            }
          } else {
            //Caso estejamos na tela do paciente
            if (image.y === 55) {
              //Se a imagem recebida for na altura de onde ficam as damas no lado do profissional(exata)
              auxImage.x = image.x === 820 ? -75 : -165; //O X vai ser o da esquerda para amarelo e da direita para azul
              auxImage.y = 690; //O Y é convertido para o normal para o paciente
              return auxImage;
            }
            if (image.x >= 780 && image.y >= 140 && index > 2) {
              //Se a imagem estava na área do cemitério do profissional e não é um dos backgrounds
              auxImage.x = -120; //O X recebe o meio do cemitério do paciente
              auxImage.y = 400; //o Y recebe uma coordenada fixa para evitar diferenças
              return auxImage;
            }
          }
          return auxImage;
        })
      );
    }
  }

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);
    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, tokenSocketIo);
    }
  }, []);

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <SaveArea>
          <TertiaryButton onClick={() => setModalCleanIsOpen(true)}>
            Limpar
          </TertiaryButton>
          {typeUser === 'profissional' && (
            <SecondaryButton onClick={() => setIsOpenAlert(true)}>
              Instruções
            </SecondaryButton>
          )}
          {typeUser === 'profissional' && (
            <SecondaryButton onClick={salvarFim}>Salvar</SecondaryButton>
          )}
          {typeUser === 'profissional' && (
            <SecondaryButton onClick={() => setModalCloseIsOpen(true)}>
              Fechar
            </SecondaryButton>
          )}
        </SaveArea>

        <BoardBox
          onDragOver={(e) => {
            e.preventDefault();
          }}
        >
          <Stage
            width={innerWidthSize}
            height={proportionalStageHeight}
            scaleX={scaleX}
            scaleY={scaleY}
            onMouseDown={checkDeselect}
            onTouchStart={checkDeselect}
            ref={stageRef}
          >
            <Layer>
              {images.map((image, i) => {
                return (
                  i === 2 && (
                    <MountUrlImage
                      image={image}
                      key={i}
                      shapeProps={image}
                      onSelect={() => {
                        if (image.drag) {
                          selectShape(image.alt);
                        }
                      }}
                      onChange={(newAttrs) => {
                        const aux = images.slice();
                        aux[i] = newAttrs;
                        setImages(aux);
                        sendImage(aux, lastKnownPosition, selectedId);
                      }}
                      resizable={false}
                      rotatable={false}
                    />
                  )
                );
              })}
            </Layer>
            <Layer>
              {typeUser === 'profissional' && (
                <Text x={45} y={15} text={PacientName()} fontSize={24} />
              )}
              {images.map((image, i) => {
                return (
                  i === 1 && (
                    <MountUrlImage
                      image={image}
                      key={i}
                      shapeProps={image}
                      // isSelected={image.alt === selectedId}
                      onSelect={() => {
                        if (image.drag) selectShape(image.alt);
                      }}
                      onChange={(newAttrs) => {
                        const aux = images.slice();
                        aux[i] = newAttrs;
                        setImages(aux);
                        sendImage(aux, lastKnownPosition, selectedId);
                      }}
                      resizable={false}
                      rotatable={false}
                    />
                  )
                );
              })}
            </Layer>
            <Layer
              rotation={typeUser === 'profissional' ? 0 : 180}
              offsetX={typeUser === 'profissional' ? 0 : 750}
              offsetY={typeUser === 'profissional' ? 0 : 750}
            >
              {images.map((image, i) => {
                return (
                  (i < 1 || i > 2) && (
                    <MountUrlImage
                      image={image}
                      key={i}
                      shapeProps={image}
                      isSelected={i === selectedId}
                      onSelect={() => {
                        if (image.drag) {
                          selectShape(i);
                        }
                      }}
                      onChange={(newAttrs) => {
                        setLastKnownPosition({
                          x: image.x,
                          y: image.y,
                          src: null,
                          width: 70,
                          offsetX: 35,
                          offsetY: 35,
                          height: 70,
                          drag: false,
                        });
                        if (image.drag) {
                          selectShape(i);
                        }
                        const aux = images.slice();
                        aux[i] = newAttrs;
                        setImages(aux);
                        sendImage(
                          aux,
                          {
                            x: image.x,
                            y: image.y,
                            src: null,
                            width: 70,
                            offsetX: 35,
                            offsetY: 35,
                            height: 70,
                            drag: false,
                          },
                          i
                        );
                        // sendUniqueImage(image, newAttrs, i);
                      }}
                      resizable={false}
                      rotatable={image.src === null ? false : true}
                    />
                  )
                );
              })}
              <MountUrlImage
                image={lastKnownPosition}
                key={99}
                shapeProps={lastKnownPosition}
                isSelected={true}
                onSelect={() => {}}
                onChange={() => {}}
                resizable={false}
                rotatable={false}
              />
            </Layer>
          </Stage>
        </BoardBox>
      </div>
      <ModalAddOps
        generatePdf={handleExport}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        token={token}
      />
      <ModalAlert
        modalIsOpen={modalIsOpenAlert}
        setIsOpen={setIsOpenAlert}
        token={token}
        props={props}
        title={'Damas'}
        recurso={nome_recurso_cod}
        bt01Txt={'Fechar'}
      />
      <ModalConfirm
        title="Limpar o jogo"
        description="Deseja realmente limpar o tabuleiro?"
        modalIsOpen={modalCleanIsOpen}
        setIsOpen={setModalCleanIsOpen}
        confirmButtonText="Limpar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCleanIsOpen(false)}
        onConfirm={() => handleClean()}
      />
      <ModalConfirm
        title="Fechar o jogo"
        description="Deseja realmente fechar o jogo?"
        modalIsOpen={modalCloseIsOpen}
        setIsOpen={setModalCloseIsOpen}
        confirmButtonText="Fechar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCloseIsOpen(false)}
        onConfirm={() =>
          handleBackHome(
            modalClose,
            nome_recurso_cod,
            typeUser,
            props,
            os,
            token,
            ambiente
          )
        }
      />
    </Container>
  );
};

export default Checkers;
