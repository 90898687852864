import React from 'react';

import { Container, Light } from './styles';

import { SelfEvaluationImages } from '../../../assets';

export default function TrafficLight({
  selectedColor,
  setSelectedColor,
  index,
}) {
  return (
    <Container>
      <img
        src={SelfEvaluationImages[selectedColor === 0 ? 4 : 1]}
        alt="Pare"
        onClick={(e) => {
          e.preventDefault();
          setSelectedColor(index, 0);
        }}
      />
      <img
        src={SelfEvaluationImages[selectedColor === 1 ? 5 : 2]}
        alt="Atenção"
        onClick={(e) => {
          e.preventDefault();
          setSelectedColor(index, 1);
        }}
      />
      <img
        src={SelfEvaluationImages[selectedColor === 2 ? 6 : 3]}
        alt="Continue"
        onClick={(e) => {
          e.preventDefault();
          setSelectedColor(index, 2);
        }}
      />
    </Container>
  );
}
