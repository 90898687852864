import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  background: white;

  position: absolute;
  left: 50%;
  top: 50vw;
  transform: translate(-50%, -35vw);
  max-height: 700px;
  width: 50vw;

  padding: 0 30px 50px 30px;

  h1 {
    font-family: 'Roboto', serif;
    font-weight: 500;
    font-size: 26px;
    color: #3d3d3d;
  }

  margin-right: 30px;
  margin-left: 30px;

  @media (max-width: 1000px) {
    width: 80vw;
  }

  scrollbar-width: thin;
`;

export const Modal = styled.div`
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;

  display: ${(props) => (props.isOpen ? `flex` : `none`)};

  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.3);

  z-index: 999;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 20px;
  margin-bottom: 20px;

  justify-content: space-between;
  align-items: center;
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;

  h2 {
    font-family: 'Roboto', serif;
    font-weight: 700;
    font-size: 18px;
    color: #333333;
    min-width: 110px;
    margin-top: 1px;
  }
  h3 {
    font-family: 'Roboto', serif;
    font-weight: 400;
    font-size: 18px;
    color: #4c5153;
    margin-top: 1px;
  }
`;

export const OwnerContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  h3 {
    font-family: 'Roboto', serif;
    font-weight: 400;
    font-size: 18px;
    color: #677176;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  margin-top: 30px;
`;

export const CancelButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  border: 2px solid #f5f5f5;

  padding: 10px 0px;

  border-radius: 2px;

  margin-right: 20px;

  width: 150px;

  cursor: pointer;

  h3 {
    font-family: 'Roboto', serif;
    color: #7d8c94;
    font-weight: 400;
    font-size: 18px;
    margin: 0;
  }
  transition: 0.5s;

  &:hover {
    border-color: #7d8c94;
    background-color: #e8e9e9;
  }
`;

export const UseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;

  border: 2px solid #8e7ad6;
  background: #8e7ad6;

  padding: 10px 0px;

  width: 150px;

  cursor: pointer;

  h3 {
    font-family: 'Roboto', serif;
    color: #e8e3fc;
    font-weight: 400;
    font-size: 18px;
    margin: 0;
  }

  transition: 0.5s;

  &:hover {
    border-color: #2b186c;
    background-color: #2b186c;
  }
`;
