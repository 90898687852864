import React, { useState } from 'react';

import { Container, TitleContainer, TextArea, InnerTitle } from './styles';

export default function AMAField(props) {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Container isFocused={isFocused} all={props}>
      {props.title && (
        <TitleContainer isFocused={isFocused} all={props}>
          <h1>{props.title}</h1>
        </TitleContainer>
      )}
      {props.innerTitle && <InnerTitle>{props.innerTitle}</InnerTitle>}
      <TextArea
        maxLength={props.size === 'sm' ? 150 : 250}
        spellCheck={false}
        all={props}
        onKeyPress={(e) => {
          if (e.key === 'Enter') e.preventDefault();
        }}
        onFocus={() => {
          setIsFocused(true);
          props.setFocusedField && props.setFocusedField(props.title);
        }}
        onKeyUp={() => {
          props.setEmitSocket();
        }}
        value={props.data}
        onChange={(t) => {
          props.setData(t.target.value);
        }}
      />
    </Container>
  );
}
