import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px;
  display: flex;
  border: 0;
  background: #fff;

  justify-content: center;
  align-items: center;

  position: relative;

  h2 {
    text-align: center;
    font-family: 'Roboto', sans-serif;
  }
`;

export const AreiaBox = styled.div`
  border: 0;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-top: 25px;
`;

export const SaveArea = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`;
