import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';

/** Imagens */
import { Behaviors } from '../../../assets';

import {
  Container,
  BackgroundCircle,
  Tab,
  TabPanel,
  MobileContainer,
  MobileTab,
  MobileTabContainer,
} from './styles';

export default function GoodVsBadTabs({
  dragUrl,
  usedIndexes,
  setUsedIndexes,
  mobileTouchHandler,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const setImageGrey = (index) => {
    let indexArr = usedIndexes;
    indexArr.push(index);
    setUsedIndexes(indexArr);
  };
  return (
    <>
      {!isMobile && (
        <Container>
          <Tab>
            <BackgroundCircle>
              <img src={Behaviors[0]} alt={Behaviors[0]} />
            </BackgroundCircle>
          </Tab>
          <TabPanel>
            {Behaviors &&
              Behaviors.map((item, index) => (
                <img
                  style={{
                    cursor: 'pointer',
                    filter: `grayscale(${
                      usedIndexes !== undefined && usedIndexes.includes(index)
                        ? '100%'
                        : '0%'
                    })`,
                    userSelect: 'none',
                  }}
                  alt={item}
                  src={item}
                  draggable={
                    usedIndexes !== undefined && usedIndexes.includes(index)
                      ? 'false'
                      : 'true'
                  }
                  onDragStart={(e) => {
                    if (
                      usedIndexes !== undefined &&
                      usedIndexes.includes(index)
                    ) {
                      dragUrl.current = null;
                    } else {
                      dragUrl.current = e.target.src;
                      setImageGrey(index);
                    }
                  }}
                />
              ))}
          </TabPanel>
        </Container>
      )}
      {isMobile && (
        <MobileContainer>
          <MobileTabContainer isOpen={isOpen}>
            {Behaviors &&
              Behaviors.map((item, index) => (
                <img
                  style={{
                    cursor: 'pointer',
                    filter: `grayscale(${
                      usedIndexes !== undefined && usedIndexes.includes(index)
                        ? '100%'
                        : '0%'
                    })`,
                    userSelect: 'none',
                  }}
                  alt={item}
                  src={item}
                  draggable={
                    usedIndexes !== undefined && usedIndexes.includes(index)
                      ? 'false'
                      : 'true'
                  }
                  onDragStart={(e) => {
                    if (
                      usedIndexes !== undefined &&
                      usedIndexes.includes(index)
                    ) {
                      dragUrl.current = null;
                    } else {
                      dragUrl.current = e.target.src;
                      setImageGrey(index);
                    }
                  }}
                  onTouchStart={(e) => {
                    dragUrl.current = e.target.src;
                    setImageGrey(index);
                  }}
                  onTouchEnd={(e) => mobileTouchHandler(e)}
                />
              ))}
          </MobileTabContainer>
          <MobileTab onClick={() => setIsOpen(!isOpen)}>
            <img src={Behaviors[0]} alt={Behaviors[0]} />
          </MobileTab>
        </MobileContainer>
      )}
    </>
  );
}
