import React from 'react';

import { Container, Background } from './styles';

const ColorableImage = ({ imageSource, position, clickFunction, color }) => {
  return (
    <Container position={position} onClick={() => clickFunction()}>
      <Background color={color} />
      <img src={imageSource} alt={imageSource} />
    </Container>
  );
};

export default ColorableImage;
