import styled from 'styled-components';

import Wave from '../../assets/geral/wave.png';

import LogoTerapiaInterativa from '../../assets/geral/logoTerapiaInterativa.svg';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: white;
`;

export const Waves = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 40vh;

  background-image: url(${Wave});
  background-size: 100% 40vh;
  background-repeat: no-repeat;

  h1 {
    font-family: 'Roboto', serif;
    font-weight: 400;
    margin: 0;

    font-size: 32px;

    color: #66aaad;
  }
`;

export const LogoContainer = styled.div`
  margin-top: 100px;
  width: 800px;
  height: 200px;

  background-image: url(${LogoTerapiaInterativa});
  background-size: 400px 80px;
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;

  animation: pulse infinite 4s;
  animation-timing-function: ease-in-out;

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
`;
