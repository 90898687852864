import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: #fff;

  @media (max-width: 768px) {
    justify-content: center;
    padding: 5px;
  }

  flex-wrap: wrap;
  flex-direction: row;
  min-height: 500px;

  @media (max-width: 550px) {
    background-color: ${(props) => props.backgroundColor};
  }
`;

export const SearchBox = styled.div`
  width: 100%;
  height: 50px;
  padding: 20px;
  flex-direction: row;

  display: flex;
  justify-content: flex-start;

  @media (max-width: 768px) {
    justify-content: center;
  }

  img {
    width: 150px;
  }
`;

export const Search = styled.input`
  background: #fafbfb;
  border: 1px solid #90a4ae;
  box-sizing: border-box;
  border-radius: 2px;

  width: 187px;
  height: 32px;

  padding: 8px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  display: flex;
  justify-content: flex-end;

  ::placeholder {
    padding: 8px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 20px;
  width: 100%;
`;

export const Box = styled.div`
  font-family: Roboto;
  font-style: normal;

  margin: 20px;

  @media (max-width: 786px) {
    margin: 20px 25px 20px 0px;
  }

  @media screen and (min-width: 448px) and (max-width: 792px) {
    margin: 20px;
  }

  .card {
    width: 195px;
    height: 260px;

    @media (max-width: 768px) {
      justify-content: center;
      width: 130px;
      height: 180px;
      max-width: 40vw;
      margin: 0;
    }
  }

  .content {
    width: 100%;
    height: 100%;

    transition: transform 1s;
    transform-style: preserve-3d;
  }

  .card:hover .content {
    transform: rotateY(180deg);
    transition: transform 0.5s;
  }

  .front {
    text-align: center;
    align-items: center;

    position: relative;
    img {
      width: 70px;
    }

    .title {
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      margin-top: 26px;

      width: 100%;
      height: 25px;
      text-align: center;
      align-items: center;

      color: ${(props) => (props.status === '1' ? '#8E7AD6' : '#677176')};

      @media (max-width: 786px) {
        font-size: 14px;
      }
    }

    .boxprice {
      display: flex;
      flex-direction: row;
      padding: 4px;

      background: #fffad5;
      border-radius: 4px;

      font-weight: normal;
      font-size: 14px;
      line-height: 20px;

      margin-top: 15px;

      display: flex;

      align-items: center;
      text-align: left;

      color: #ccb400;
    }
  }

  .front,
  .back {
    position: absolute;
    height: 100%;
    width: 100%;
    text-align: center;
    border-radius: 5px;
    transform: rotateX(0deg);
    backface-visibility: hidden;
    padding: 10px;

    background-color: ${(props) =>
      props.status === '1' ? '#f5f5f5' : '#cdd1d4'};
    border-radius: 10px;
    border: 0px;
    box-shadow: 0px 0px 32px 0px #cdd1d4;

    @media (max-width: 550px) {
      box-shadow: none;
    }
  }

  .back {
    transform: rotateY(180deg);
    margin-left: -20px;

    .title {
      font-weight: bold;
      font-size: 22px;
      line-height: 26px;

      margin-bottom: 5px;
      text-align: left;

      width: 100%;

      color: ${(props) => (props.status === '1' ? '#8E7AD6' : '#677176')};
      @media (max-width: 786px) {
        font-size: 14px;
        line-height: normal;
      }
    }

    .description {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      padding: 5px;
      margin-top: 5px;

      display: flex;

      text-align: left;

      color: #677176;

      @media (max-width: 786px) {
        font-size: 12px;
        margin: 0;
        padding: 0;
        line-height: normal;
      }
    }
  }

  .button {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    position: absolute;
    bottom: 10px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 786px) {
      font-size: 12px;
      line-height: normal;
    }

    .white,
    .blue {
      border: 1px solid #8e7ad6;
      border-radius: 5px;
      padding: 5px;
      cursor: pointer;
    }

    .white {
      color: #8e7ad6;
      margin-right: 15px;
      @media (max-width: 786px) {
        margin-right: 5px;
      }
    }

    .blue {
      border-radius: 5px;
      background-color: #8e7ad6;
      color: #f5f5f5;
      @media (max-width: 786px) {
        margin-right: 10px;
      }
    }

    .blue a {
      color: #f5f5f5;
      text-decoration: none;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  width: 200px;

  background: #fafbfb;

  border: 1px solid #90a4ae;
  border-radius: 2px;

  padding: 8px 8px;
`;

export const InputField = styled.input`
  margin-left: 10px;
  height: 100%;
  width: 100%;

  font-size: 20px;

  padding: 0;

  background: transparent;
  box-shadow: none;
  border: none;
  outline: none;

  font-family: 'Roboto', serif;
`;

export const ButtonSave = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 24px;
  position: absolute;
  right: 5px;
  top: 3px;
  z-index: 1;

  button, a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 112px;
    height: 32px;
    background-color: #f5f5f5;
    border: 1px solid #0062cc;
    border-radius: 2px;
    font-size: 16px;
    color: #0062cc;
    margin-left: 30px;
    cursor: pointer;
    transition: 0.25s;
    text-decoration: none;
    font-family: 'Roboto', serif;

    :hover {
      background-color: #0062cc;
      color: #f5f5f5;
    }

    @media (max-width: 558px) {
      display: none;
    }
  }

  img {
    display: none;
    width: 22px;
    cursor: pointer;
    @media screen and (min-width: 320px) and (max-width: 558px) {
      display: inline;
      color: white;
    }
  }
`;
