//Main imports
import React, { useState, useEffect, useRef } from 'react';
import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  PacientName,
  DataURL,
} from '../../utils';

//Drag'n Drop imports
import { Stage, Layer, Text } from 'react-konva';
import MountUrlImage from '../../components/MountUrlImage';

//Styles import
import {
  Container,
  CentralRow,
  SaveArea,
  ButtonClean,
  ButtonSave,
  BoardBox,
} from './styles';

//Images Imports
import { ArrowAnswers, ArrowBoard, ArrowInstructions } from '../../assets';

//Components import
import {
  ModalAddOps,
  ModalAlert,
  ModalConfirm,
  TertiaryButton,
  SecondaryButton,
} from '../../components';
import ArrowsTab from '../../components/Arrows/ArrowsTab';

export default function Arrows(props) {
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  //Socket variables
  const [yourID, setYourID] = useState();
  const socketRef = useRef();

  //Modal variables
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCleanIsOpen, setModalCleanIsOpen] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);
  const [print, setPrint] = useState(false);

  //Drag'n Drop variables
  const dragUrl = useRef();
  const stageRef = React.useRef();
  const [selectedId, selectShape] = useState(null);
  const [images, setImages] = React.useState([
    {
      x: 1650,
      y: 1210,
      src: ArrowBoard,
      height: 600,
      width: 800,
      drag: false,
    },
  ]);

  //*************************************************************************************************************** */
  //Functions

  const handleClean = () => {
    setImages([
      {
        x: 1650,
        y: 1210,
        src: ArrowBoard,
        height: 600,
        width: 800,
        drag: false,
      },
    ]);
    sendImage([
      {
        x: 1650,
        y: 1210,
        src: ArrowBoard,
        height: 600,
        width: 800,
        drag: false,
      },
    ]);
    setModalCleanIsOpen(false);
  };

  function sendImage(imagesData) {
    const messageObject = {
      body: { imagesData: imagesData },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  const salvarFim = () => {
    setIsOpen(true);
  };

  const handleExport = () => {
    selectShape(null);
    setTimeout(() => {
      const uri = stageRef.current.toDataURL();
      const nome_paciente = localStorage
        .getItem('@nome_paciente')
        .replaceAll(' ', '_');
      const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
      downloadURI(uri, filename, sendArquivo, os, token, nome_recurso_cod);
    }, 500);
  };

  function receivedMessage(message) {
    setImages(message.body.imagesData);
  }

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);
    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, tokenSocketIo);
    }
  }, []);

  return (
    <Container>
      <CentralRow>
        <div
          style={{
            marginRight: 5,
            marginTop: 50,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <ArrowsTab dragUrl={dragUrl} />
          <h4 style={{ fontFamily: 'Roboto', marginTop: 33 }}>Exemplo:</h4>
          <img
            src={ArrowInstructions}
            alt={ArrowInstructions}
            style={{ width: 200 }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <SaveArea>
            <TertiaryButton onClick={() => setModalCleanIsOpen(true)}>
              Limpar
            </TertiaryButton>
            {typeUser === 'profissional' && (
              <>
                <SecondaryButton onClick={() => setIsOpenAlert(true)}>
                  Instruções
                </SecondaryButton>
                <SecondaryButton onClick={salvarFim}>Salvar</SecondaryButton>
                <SecondaryButton onClick={() => setModalCloseIsOpen(true)}>
                  Fechar
                </SecondaryButton>
              </>
            )}
          </SaveArea>

          <BoardBox
            onDrop={(e) => {
              e.preventDefault();
              if (dragUrl.current !== null) {
                stageRef.current.setPointersPositions(e);
                const nameFile =
                  100000 + Math.floor((999999 - 100000) * Math.random());
                selectShape(nameFile);
                setImages(
                  images.concat([
                    {
                      ...stageRef.current.getPointerPosition(),
                      src: dragUrl.current,
                      drag: true,
                      alt: nameFile,
                    },
                  ])
                );
                sendImage(
                  images.concat([
                    {
                      ...stageRef.current.getPointerPosition(),
                      src: dragUrl.current,
                      drag: true,
                      alt: nameFile,
                    },
                  ])
                );
              }
            }}
            onDragOver={(e) => {
              e.preventDefault();
            }}
          >
            <Stage
              width={795}
              height={560}
              onMouseDown={checkDeselect}
              onTouchStart={checkDeselect}
              ref={stageRef}
            >
              <Layer>
                {images.map((image, i) => {
                  return (
                    <MountUrlImage
                      image={image}
                      key={i}
                      shapeProps={image}
                      isSelected={image.alt === selectedId}
                      onSelect={() => {
                        if (image.drag) selectShape(image.alt);
                      }}
                      onChange={(newAttrs) => {
                        const aux = images.slice();
                        aux[i] = newAttrs;
                        setImages(aux);
                        sendImage(aux);
                      }}
                      width={55}
                      height={55}
                      resizable={false}
                      rotatable={false}
                    />
                  );
                })}
                {typeUser === 'profissional' && (
                  <Text x={20} y={15} text={PacientName()} fontSize={24} />
                )}
              </Layer>
            </Stage>
          </BoardBox>
          {typeUser === 'profissional' && (
            <img
              src={ArrowAnswers}
              alt={ArrowAnswers}
              style={{ width: 798, marginTop: 30 }}
            />
          )}
        </div>
      </CentralRow>
      <ModalAddOps
        generatePdf={handleExport}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        token={token}
      />
      <ModalAlert
        modalIsOpen={modalIsOpenAlert}
        setIsOpen={setIsOpenAlert}
        token={token}
        props={props}
        title={'Setas'}
        recurso={nome_recurso_cod}
        bt01Txt={'Fechar'}
      />
      <ModalConfirm
        title="Limpar o jogo"
        description="Deseja realmente limpar o jogo?"
        modalIsOpen={modalCleanIsOpen}
        setIsOpen={setModalCleanIsOpen}
        confirmButtonText="Limpar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCleanIsOpen(false)}
        onConfirm={() => handleClean()}
      />
      <ModalConfirm
        title="Fechar o jogo"
        description="Deseja realmente fechar o jogo?"
        modalIsOpen={modalCloseIsOpen}
        setIsOpen={setModalCloseIsOpen}
        confirmButtonText="Fechar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCloseIsOpen(false)}
        onConfirm={() =>
          handleBackHome(
            modalClose,
            nome_recurso_cod,
            typeUser,
            props,
            os,
            token,
            ambiente
          )
        }
      />
    </Container>
  );
}
