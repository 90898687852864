import styled, { css } from 'styled-components';

import { shade } from 'polished';

export const Container = styled.div`
  width: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: white;
`;

export const CentralContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  padding-bottom: 70px;

  @media (max-width: 768px) {
    max-width: 100%;

    button {
      width: 30vw;
      max-width: 140px;
    }
  }

  h2 {
    margin-top: 50px;

    @media (max-width: 768px) {
      text-align: center;
    }
  }
`;

export const NegativeContainer = styled.div`
  margin-top: 50px;
  display: flex;
  width: 100%;

  animation: 0.3s
    ${(props) => {
      if (props.page === 1 || props.page === 3) {
        return 'fadeIn';
      } else {
        return 'fadeOut';
      }
    }}
    linear;

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateX(-120px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    100% {
      opacity: 0;
      transform: translateX(120px);
    }
  }
`;

export const PositiveContainer = styled.div`
  margin-top: 50px;
  display: flex;
  width: 100%;

  animation: 0.3s
    ${(props) => {
      if (props.page === 1 || props.page === 3) {
        return 'fadeIn';
      } else {
        return 'fadeOut';
      }
    }}
    linear;

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateX(120px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    100% {
      opacity: 0;
      transform: translateX(120px);
    }
  }
`;

export const FieldsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;

    span {
      width: 100%;
      height: 2px;
      background-color: lightgray;
      align-self: center;
      margin: 68px 0 -15px 0;
    }
  }
`;

export const TextArea = styled.textarea`
  border: none;
  resize: none;
  width: 100%;
  height: 100px;

  background: #dcdcdc;

  border: 2px solid #dcdcdc;

  border-radius: 10px;
  margin-top: 10px;

  box-sizing: border-box;
  outline: 0;
  overflow: hidden;
`;

export const Col = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 768px) {
    width: 80%;
  }
`;

export const InstructionsContainer = styled.div`
  display: flex;

  max-width: 800px;

  padding: 20px 0;

  flex-direction: column;

  align-items: flex-start;

  strong {
    font-size: 24px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    margin: 2px;
    margin-top: 10px;
  }
  h1 {
    margin: 2px;
    margin-top: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 500;
  }
`;

export const HeroImage = styled.img`
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 10px;
    width: 90%;
  }
`;

export const SaveAndPaginatedContainer = styled.div`
  display: flex;
  width: 400px;
  justify-content: center;
  margin-top: ${(props) => (props.closeAndSave ? '30px' : '0')};

  @media (max-width: 768px) {
    max-width: 300px;

    button {
      width: 30vw;
      max-width: 140px;
    }
  }
`;

export const CauseEffectImage = styled.img`
  ${(props) =>
    props.cause &&
    css`
      @media (max-width: 768px) {
        margin-top: 25px;
      }
    `}

  ${(props) =>
    props.effect &&
    css`
      height: 75px;
      width: 200px;

      @media (max-width: 768px) {
        width: 170px;
        margin-top: 25px;
      }
    `}
`;
