import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import Modal from 'react-modal';

import { getSessaoAtual, getPacients } from '../../services/apiService';

import { BoxSession, Button } from './styles.js';

import img003 from '../../assets/geral/logo.png';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 999999,
  },
};

function ModalForm({ modalIsOpen, setIsOpen, token, os }) {
  const [sessions, setSessions] = useState();
  const [pacients, setPacients] = useState([]);

  const listSessaoAtual = async () => {
    await getSessaoAtual(os, token)
      .then((resp) => {
        setSessions(resp.data.records);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const listPacient = async () => {
    await getPacients(os, token)
      .then((resp) => {
        setPacients(resp.data.records);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showPacients = () => {
    listPacient();
  };

  const setPacient = (data) => {
    localStorage.setItem('@token', token);
    localStorage.setItem('@cod_paciente', data.cod_paciente);
    localStorage.setItem('@cod_sessao', data.cod_sessao);
    localStorage.setItem('@data', data.data);
    localStorage.setItem('@hora', data.hora);
    localStorage.setItem('@nome_paciente', data.nome_paciente);
    setIsOpen(false);
  };

  useEffect(() => {
    listSessaoAtual();
  }, []);

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel="Assinar"
      >
        <div
          id="modal-dialog"
          style={isMobile ? {} : { width: 650 }}
          className="container mfp-with-anim popup-wrapper bg-contrast"
        >
          <div className="row">
            <div className="col-md-12">
              <div className="box-logo-modal">
                <img
                  src={img003}
                  style={{ width: 200 }}
                  className="img-responsive logo-modal"
                  alt="PsicoManager"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="BoxSession col-md-12">
              {sessions &&
                sessions.nome_paciente !== '' &&
                pacients.length === 0 && (
                  <BoxSession>
                    <p>{sessions.nome_paciente}</p>
                    <p>{sessions.data}</p>
                    <p>{sessions.hora}</p>
                    <Button
                      onClick={() => setPacient(sessions)}
                      color={'#dccbb5'}
                    >
                      Selecionar Paciente
                    </Button>
                  </BoxSession>
                )}

              {pacients.map((val, i) => {
                return (
                  <BoxSession>
                    <p>{val.nome_paciente}</p>
                    <Button onClick={() => setPacient(val)} color={'#dccbb5'}>
                      Selecionar Paciente
                    </Button>
                  </BoxSession>
                );
              })}

              <Button onClick={showPacients} color={'#dccbb5'}>
                Selecionar outro Paciente
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ModalForm;
