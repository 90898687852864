import { auto } from 'async';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';

import { IoClose } from 'react-icons/io5';

import Modal from 'react-modal';

import { Container, Button } from './styles.js';

// '20%'
const customStyles = {
  content: {
    top: `${window.innerWidth / 2}px`,
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '90vw',
    marginRight: '-50%',
    transform: `translate(-50%, -${window.innerWidth / 2.8}px)`,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 999999,
  },
};

const ModalAlert = ({
  modalIsOpen,
  setIsOpen,
  title,
  description,
  recurso,
  bt01Txt,
  bt02Txt,
  LinkTo,
}) => {
  const [obs, setObs] = useState('');

  const setObsData = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel="Assinar"
      >
        <Container>
          <div
            id="modal-dialog"
            style={{ width: 650, maxWidth: '90vw' }}
            className="container mfp-with-anim popup-wrapper bg-contrast"
          >
            <div className="row">
              <div className="BoxSession col-md-12">
                <IoClose
                  size={30}
                  color="#7D8C94"
                  onClick={() => setIsOpen(false)}
                  style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '15px',
                    right: '15px',
                  }}
                />
                <p>
                  <h2>{title}</h2>
                </p>
                {!recurso && (
                  <div>
                    <p>
                      Nesta tela você encontrará todas as informações dos
                      Recursos Terapêuticos da plataforma e poderá iniciar a
                      utilização.
                    </p>
                    <p>
                      Entretanto, a melhor forma de utilizar o jogo é através da
                      Sala Virtual com toda a segurança e privacidade na chamada
                      de vídeo.
                    </p>
                    <p>
                      Para utilizar os Recursos na Sala Virtual, basta clicar no
                      ícone da Câmera de Vídeo no canto superior direito da tela
                      e depois clicar em Acessar Sala Virtual.
                    </p>

                    <p>
                      Lembre-se que, para melhor utilização e integração do
                      sistema, cadastre seus clientes primeiro.
                    </p>
                  </div>
                )}
                {recurso === 'jogo-de-areia' && (
                  <div>
                    <p>
                      Instrua o seu cliente a colocar livremente objetos como,
                      animais pessoas ou instituições na areia. De acordo com a
                      teoria Junguiana, o cliente expressa aspectos do seu
                      inconsciente, tendo maior acesso ao interior do paciente.
                    </p>
                    <p>
                      O oriente apenas sobre os recursos de arrastar, girar e
                      redimencionar os objetos escolhidos. Instruções sobre o
                      que ele deve colocar ou o que é certo colocar não deve ser
                      dada.
                    </p>
                  </div>
                )}
                {recurso === 'animal-musica-amuleto' && (
                  <div>
                    <p>
                      <strong>Objetivo: </strong>conhecer o cliente através de
                      projeções simbólicas de maneira lúdica.{' '}
                    </p>
                    <p>
                      <strong>Antes do jogo: </strong> introduza o assunto
                      identidade e explique o que será pedido ao cliente para
                      que ele se aqueça.{' '}
                    </p>
                    <p>
                      <strong>Questões: </strong> Se você se transformasse em um
                      animal, qual seria? Defina a trilha sonora da sua vida com
                      até três músicas. Qual é o seu amuleto de força?
                    </p>
                    <strong>
                      Utilize os quadros para expressar suas reflexões sobre as
                      questões acima. Você pode escrever seus pensamentos.
                    </strong>
                  </div>
                )}
                {recurso === 'a-flor-do-cacto' && (
                  <div>
                    <strong>Instruções: </strong>
                    <p>
                      Um cacto tem muito a nos ensinar. Ele é símbolo de
                      persistência, força e superação. Ele precisa de muitas
                      horas de sol para florescer, do contrário, não conceberá
                      flores.
                    </p>
                    <p>
                      Estas flores mantêm sua leveza, beleza e força, mesmo
                      diante do ambiente aparentemente desfavorável: os
                      espinhos.Como temos nos sentido frente a esses espinhos?
                      Murchos? Sem vida? Desanimados... cansados... estressados,
                      ou, vibrantes, confiantes e otimistas, como uma flor de
                      cacto?
                    </p>
                    <p>
                      Esta flor nos ensina que, as possibilidades existem e,
                      estas, estão dentro de nós mesmos.
                    </p>

                    <strong>Objetivo: </strong>
                    <p>
                      Estimular o paciente a não desistir, a permanecer
                      perseverante e firme como uma flor de cacto.
                    </p>
                  </div>
                )}
                {recurso === 'ciranda-de-pessoas' && (
                  <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                    <p>
                      <strong>Objetivo:</strong> identificar os vínculos mais
                      próximos e significativos do cliente, bem como compreender
                      a dinâmica dessas relações.
                    </p>
                    <p>
                      <strong>Antes do jogo: </strong>conversar brevemente com o
                      cliente sobre seus círculos sociais e sua relação com as
                      pessoas desses círculos.
                    </p>
                    <p>
                      <strong>Instruções para aplicação: </strong>
                      Esse é um jogo de exploração da dinâmica das relações
                      sociais. Ao aplicá-lo, procure entrevistar seu cliente
                      sobre a qualidade de cada relação, buscando identificar
                      aspectos como:
                      <br />
                      <br />
                      1) Quais são os papéis e funções que seu cliente assume em
                      cada relação?
                      <br />
                      2) Quais são as expectativas que seu cliente tem em cada
                      relação?
                      <br />
                      3) Quais são as situações que geram desconforto ou
                      conforto em cada uma delas?
                      <br />
                      4) Que tipo de troca acontece em cada relação?
                      <br />
                      5) O que sustenta ou abala os vínculos sociais do seu
                      cliente?
                    </p>
                    <br />
                    <span style={{ fontSize: '18px', fontStyle: 'italic' }}>
                      Outros questionamentos podem ser feitos a medida que você
                      julgar necessário.
                    </span>
                  </div>
                )}
                {recurso === 'ciranda-das-tribos' && (
                  <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                    <p>
                      <strong>Objetivo:</strong> identificar os vínculos mais
                      próximos e significativos do cliente, bem como compreender
                      a dinâmica dessas relações.
                    </p>
                    <p>
                      <strong>Antes do jogo:</strong> Antes do jogo: conversar
                      brevemente com o cliente sobre seus círculos sociais e sua
                      relação com as pessoas desses círculos.
                    </p>
                    <p>
                      <strong>Instruções para aplicação:</strong> Esse é um jogo
                      de exploração da dinâmica das relações sociais. Ao
                      aplicá-lo,procure entrevistar seu cliente sobre a
                      qualidade de cada relação, buscando identificar aspectos
                      como:
                    </p>
                    <p>
                      1) Quais são os papéis e funções que seu cliente assume em
                      cada relação?
                    </p>
                    <p>
                      2) Quais são as expectativas que seu cliente tem em cada
                      relação?
                    </p>
                    <p>
                      3) Quais são as situações que geram desconforto ou
                      conforto em cada uma delas?
                    </p>
                    <p>4) Que tipo de troca acontece em cada relação?</p>
                    <p>
                      5) O que sustenta ou abala os vínculos sociais do seu
                      cliente? Outros questionamentos podem ser feitos a medida
                      que você julgar necessário.
                    </p>
                  </div>
                )}
                {recurso === 'o-tamanho-do-meu-problema' && (
                  <div>
                    <strong>Instruções: </strong>
                    <p>
                      Auxilie o seu cliente a responder todos os campos
                      previstos.
                    </p>
                    <p>
                      Oriente também a preencher a intensidade dos eventos
                      estressores.
                    </p>
                    <p>
                      As reflexões sobre como lidar com as emoções e resolução
                      de problemas, fica a caráter da condução do psicólogo.
                    </p>
                  </div>
                )}
                {recurso === 'julgamento-do-toc' && (
                  <div>
                    <strong>Instruções</strong>
                    <p>
                      No primeiro espaço, o paciente deverá escrever tudo o que
                      acha que pode acontecer, caso não realize os rituais. Ele
                      precisa anotar o máximo de informações possíveis.
                    </p>
                    <p>
                      No segundo espaço, ele irá escrever os motivos ou provas
                      pelas quais não acontecerá nada, caso não os realize. Ele
                      deverá defender fortemente esta ideia
                    </p>
                    <p>
                      No terceiro espaço, o espaço do juiz, ele deverá analisar
                      com cautela e precisão, se o que ele pensa acerca de seus
                      rituais está correto, ou, errado
                    </p>
                    <p>
                      Nas lupas, ele terá que anotar todas as provas ou suas
                      conclusões.
                    </p>
                    <p>
                      Explique ao paciente que, para lidarmos com nossos
                      pensamentos, devemos agir como o juiz. O juiz trabalha com
                      provas, portanto, ele deverá fazer o mesmo com seus
                      pensamentos, sempre buscando por provas que apoiam seus
                      pensamentos, e, as que não apoiam.
                    </p>
                  </div>
                )}
                {recurso === 'mostre-o-caminho' && (
                  <div>
                    <strong>Instruções</strong>
                    <p>
                      O cliente deverá realizar o caminho para que o animal
                      alcance o seu respectivo objeto, para tal, deverá utilizar
                      as setas à disposição para que o animal realize o trajeto
                      correto até o objeto. O terapeuta poderá dificultar o
                      caminho, colocando outros animais e objetos que não sejam
                      o foco como obstáculos, para que o cliente tenha que usar
                      setas criativas para driblar certos obstáculos.
                    </p>
                    <p>Gato - Bola de lã</p>
                    <p>Cachorro - Osso</p>
                    <p>Joaninha - Folha</p>
                  </div>
                )}
                {recurso === 'monte-a-emocao' && (
                  <div>
                    <strong>Instruções</strong>
                    <p>
                      Utilize o comando no canto inferior direito para sortear
                      uma expressão facial. O cliente deverá a partir das bocas
                      e olhos disponíveis representar a mesma expressão
                      sorteada. A partir deste momento, pode ser realizada uma
                      psicoeducação com o cliente baseada nas emoções. Pergunte
                      em qual situação este cliente já expressou essa emoção, se
                      esta emoção é maléfica ou benéfica e se ela também pode
                      ser importante para nós. Em seguida, sorteie outra emoção
                      e repita o mesmo processo.
                    </p>
                  </div>
                )}
                {recurso === 'causa-e-efeito' && (
                  <div>
                    <strong>Instruções</strong>
                    <p>
                      Auxilie o seu cliente a preencher as duas colunas, sendo a
                      primeira coluna preenchida por uma ação que a pessoa fez e
                      a segunda coluna às consequências deste ato. Na primeira
                      página, deve ser preenchida causas e consequências
                      maléficas, já na segunda página, causas e consequências
                      benéficas.
                    </p>
                  </div>
                )}
                {recurso === 'autoavaliacao' && (
                  <div>
                    <strong>Instruções</strong>
                    <p>
                      Instrua seu paciente a responder cada uma das situações,
                      sobre como ele tem agido. Ele deverá clicar em uma das 3
                      carinhas de cada pergunta, de acordo com o que melhor
                      representa a própria postura. Ao responder, a cor do item
                      selecionado será destacada, representando ali a escolha do
                      cliente.
                    </p>
                    <p>
                      Rosto triste -{' '}
                      <strong style={{ color: 'red' }}>vermelho</strong> -
                      preciso melhorar
                    </p>
                    <p>
                      Rosto neutro -{' '}
                      <strong style={{ color: 'orange' }}>laranja</strong> -
                      regular
                    </p>
                    <p>
                      Rosto sorridente -{' '}
                      <strong style={{ color: 'green' }}>verde</strong> - muito
                      bem!
                    </p>
                  </div>
                )}
                {recurso === 'estacionamento' && (
                  <div>
                    <strong>Instruções</strong>
                    <p>
                      Instrua o cliente a memorizar a figura do estacionamento,
                      ordem dos carros e suas respectivas cores em questão, para
                      em seguida ele tente replicar a sequência do
                      estacionamento sem revisitar a imagem.
                    </p>
                  </div>
                )}
                {recurso === 'bom-x-mau-comportamento' && (
                  <div>
                    <strong>Instruções</strong>
                    <p>
                      Trabalhe com a criança a educação emocional e o
                      comportamento-problema. A partir de uma atividade de
                      identificação de atitudes. A criança deverá arrastar todas
                      as figuras, que demonstram uma certa ação da criança que
                      poderá ser boa ou má de acordo com o respectivo campo.
                      Atividade voltada para criança.
                    </p>
                  </div>
                )}
                {recurso === 'vai-encarar' && (
                  <div>
                    <strong>Instruções</strong>
                    <p>
                      Passe as cartas para o seu cliente e se limite a ler
                      apenas a pergunta de cada desafio, mais instruções que
                      isso, pode interferir na qualidade das respostas. Fica a
                      critério do psicólogo determinar quanto tempo cada
                      resposta deve ser dada.
                    </p>
                  </div>
                )}
                {recurso === 'me-amo-porque' && (
                  <div>
                    <strong>Instruções</strong>
                    <p>
                      Nesta atividade, peça ao cliente que escreva dentro dos
                      corações os motivos pelos quais ele se ama. O objetivo é
                      que ele reconheça seus pontos fortes, suas vitórias, e sua
                      beleza tanto externa quanto interna.
                    </p>
                  </div>
                )}
                {recurso === 'excesso-de-telas' && (
                  <div>
                    <strong>Instruções</strong>
                    <p>
                      Instrua o cliente a responder todos os balões abaixo, a
                      investigação e avaliação dos dados deve ser feita pelo
                      próprio terapeuta, a partir das respostas obtidas e
                      discussões com o cliente.
                    </p>
                  </div>
                )}
                {recurso === 'relacione-as-cores' && (
                  <div>
                    <strong>Instruções</strong>
                    <p>
                      Instrua o cliente a observar as estruturas formadas por
                      quatro camadas, sendo cada uma destas camadas representada
                      por uma cor diferente, após isso, o cliente deverá
                      responder para o terapeuta qual é a sequência de cores a
                      partir de uma visualização superior (de cima para baixo)
                      da figura.
                    </p>
                  </div>
                )}
                {recurso === 'setas' && (
                  <div>
                    <strong>Instruções</strong>
                    <p>
                      Oriente o cliente sobre seguir o modelo para montar os
                      círculos na folha de montagem, respeitando a direção e a
                      cor dos círculos. Ao final, confira no gabarito os
                      resultados da atividade.
                    </p>
                  </div>
                )}
                {recurso === 'domino-hexagonal' && (
                  <div>
                    <strong>Instruções</strong>
                    <p>
                      No jogo de dominó, o objetivo é que você interligue as
                      faces de diferentes peças que tenham a mesma numeração. O
                      objetivo desse jogo continua sendo o mesmo, porém, com
                      peças hexagonais. Instrua o cliente a encaixar todas as
                      peças disponíveis.
                    </p>
                  </div>
                )}
                {recurso === 'quadro-de-desenhos' && (
                  <div>
                    <strong>Instruções</strong>
                    <p>
                      O Quadro de Desenhos, como o nome já diz, é um recurso que
                      possibilita desenhos durante as sessões. O desenho é uma
                      das formas de expressão de sentimentos e pensamentos.
                      Muito utilizado nos atendimentos com crianças, é um meio
                      que permite a organização de informações, processamento de
                      experiências vividas e pensadas, estimulando-as a
                      desenvolverem um estilo de representação do mundo. Ajuda
                      na concentração e estimula a criatividade. Esse recurso
                      também possibilita o uso de jogos como “Jogo da Forca” e
                      “Jogo da Velha”. Utilize sua criatividade!
                    </p>
                  </div>
                )}
                {recurso === 'quadro-de-desenhos-texto' && (
                  <div>
                    <strong>Instruções</strong>
                    <p>
                      Insira o texto que quiser na caixa de texto. Após isso
                      clique dentro do quadro de desenhos, colocando o texto na
                      posição de sua preferencia.
                    </p>
                  </div>
                )}
                {recurso === 'roda-da-vida' && (
                  <div>
                    <strong>Instruções</strong>
                    <p>
                      O recurso Roda da Vida é utilizado para auxiliar o cliente
                      a identificar como estão as diversas áreas de sua vida, se
                      estão em harmonia, suas prioridades e quais precisam de
                      maior atenção e cuidado. Instrua o cliente a escolher em
                      uma escala de 1 a 10 o nível de prioridade ou como ele
                      avalia cada setor de sua vida.
                    </p>
                  </div>
                )}
                {recurso === 'comunicacao-assertiva-passiva-e-agressiva' && (
                  <div>
                    <strong>Instruções</strong>
                    <p>
                      Nesse recurso o paciente deverá analisar se a afirmação
                      correspondente a uma pessoa Agressiva, Passiva ou
                      Assertiva.
                    </p>
                  </div>
                )}
              </div>
              {bt01Txt && (
                <Button onClick={() => setIsOpen(false)} color={'#8E7AD6'}>
                  {bt01Txt}
                </Button>
              )}
              {bt02Txt && (
                <Button onClick={() => LinkTo()} color={'#8E7AD6'}>
                  {bt02Txt}
                </Button>
              )}
            </div>
          </div>
        </Container>
      </Modal>
    </>
  );
};

export default ModalAlert;
