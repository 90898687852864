import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';

import { IoClose } from 'react-icons/io5';
import { RiErrorWarningLine } from 'react-icons/ri';

import Modal from 'react-modal';

import { Button, WarningContainer } from './styles.js';

const customStyles = {
  content: {
    top: `${window.innerWidth / 2}px`,
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '90vw',
    marginRight: '-50%',
    transform: `translate(-50%, -${window.innerWidth / 2.8}px)`,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 999999,
  },
};

const ModalAddObs = ({ modalIsOpen, setIsOpen, generatePdf }) => {
  const [obs, setObs] = useState('');

  const setObsData = (data) => {
    localStorage.setItem('@obs', obs);
    generatePdf();
    setIsOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel="Assinar"
      >
        <div
          id="modal-dialog"
          style={{ width: 650, maxWidth: '90vw' }}
          className="container mfp-with-anim popup-wrapper bg-contrast"
        >
          <div className="row">
            <div
              className="BoxSession col-md-12"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <IoClose
                size={30}
                color="#7D8C94"
                onClick={() => setIsOpen(false)}
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '15px',
                  right: '15px',
                }}
              />
              <p style={{ fontFamily: 'Roboto', fontWeight: '500' }}>
                Observações:
              </p>
              <WarningContainer>
                <RiErrorWarningLine size={20} color="#367392" />
                <h2>
                  As observações serão salvas no{' '}
                  <strong>Módulo de Recursos</strong> na ficha do paciente
                </h2>
              </WarningContainer>
              <textarea
                autoFocus
                onChange={(t) => setObs(t.target.value)}
                spellCheck={false}
                value={obs}
                rows={4}
                cols={10}
                style={{
                  width: '100%',
                  resize: 'none',
                  marginTop: 2,
                  outline: 'none',
                  border: '1px solid',
                }}
              ></textarea>
            </div>
            <Button
              onClick={setObsData}
              color={'#2797ba'}
              style={{ marginLeft: 5 }}
            >
              Salvar
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalAddObs;
