import React, { useState } from 'react';

import { Container, TitleContainer, TextArea, InnerTitle } from './styles';

export default function ProblemSizeField(props) {
  return (
    <Container all={props}>
      {props.title && <h1>{props.title}</h1>}
      <TextArea
        all={props}
        maxLength={props.size === 'lg' ? 500 : 150}
        onKeyPress={(e) => {
          if (e.key === 'Enter') e.preventDefault();
        }}
        onKeyUp={() => {
          props.setEmitSocket({
            barval: props.bar,
            pageval: props.page,
            sizeproblemval: props.sizeProblem,
            sizereactval: props.sizeReact,
            emotionalsizeval: props.emotionalSize,
          });
        }}
        value={props.data}
        onChange={(t) => {
          props.setData(t.target.value);
        }}
      />
    </Container>
  );
}
