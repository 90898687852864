import React, { useState, useEffect, useRef } from 'react';

import { isMobile } from 'react-device-detect';

import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  PacientName,
  DataURL,
} from '@utils';

import domtoimage from 'dom-to-image';

import {
  ModalAddOps,
  ModalAlert,
  PrimaryButton,
  ModalConfirm,
} from '@components';

import {
  Container,
  Logo,
  LeftDialogTextArea,
  RightDialogTextArea,
  FlexRowCenter,
  FlexRowStart,
  ProsecutorImg,
  DefenseLawyerImg,
  JudgeImg,
  MagnifierBackground,
  MagnifiersContainer,
  AcusationWrapper,
} from './styles';

import { JudgementLogo, Prosecutor, DefenseLawyer, Judge } from '../../assets';

export default function TOCJudgement(props) {
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  const [prosecutorField, setProsecutorField] = useState('');
  const [defenseField, setDefenseField] = useState('');
  const [judgeField, setJudgeField] = useState('');

  const [magnifier1, setMagnifier1] = useState('');
  const [magnifier2, setMagnifier2] = useState('');
  const [magnifier3, setMagnifier3] = useState('');

  const [yourID, setYourID] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);
  const [print, setPrint] = useState(false);

  const socketRef = useRef();

  function receivedMessage(message) {
    setProsecutorField(message.body.prosecutorField);
    setDefenseField(message.body.defenseField);
    setJudgeField(message.body.judgeField);
    setMagnifier1(message.body.magnifier1);
    setMagnifier2(message.body.magnifier2);
    setMagnifier3(message.body.magnifier3);
  }

  function setEmitSocket() {
    const messageObject = {
      body: {
        prosecutorField,
        defenseField,
        judgeField,
        magnifier1,
        magnifier2,
        magnifier3,
      },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  const generatePdf = () => {
    setPrint(true);
    domtoimage
      .toPng(document.getElementById('divIdToPrint'))
      .then(function (dataUrl) {
        const nome_paciente = localStorage
          .getItem('@nome_paciente')
          .replaceAll(' ', '_');
        const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
        downloadURI(
          dataUrl,
          filename,
          sendArquivo,
          os,
          token,
          nome_recurso_cod
        );

        setPrint(false);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  };

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);
    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, tokenSocketIo);
    }
  }, []);

  return (
    <Container id="divIdToPrint" className="containerr">
      <Logo src={JudgementLogo} alt="Julgamento do TOC" />
      {typeUser === 'profissional' && !print && (
        <PrimaryButton
          style={{ marginTop: 30, marginBottom: 30 }}
          onClick={() => setIsOpenAlert(true)}
          mobile
        >
          Instruções
        </PrimaryButton>
      )}
      {typeUser === 'profissional' && (
        <>
          <span
            style={{
              fontSize: '30px',
              fontStyle: 'italic',
              marginTop: '20px',
              marginBottom: '80px',
              marginLeft: '10px',
            }}
          >
            Este material trabalha o TOC.Pode ser utilizado com adolescentes e
            adultos.
          </span>
        </>
      )}
      <FlexRowCenter>
        <AcusationWrapper>
          <LeftDialogTextArea
            spellCheck={false}
            maxLength={isMobile ? 128 : 490}
            value={prosecutorField}
            onKeyPress={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
            onChange={(e) => setProsecutorField(e.target.value)}
            onKeyUp={() => setEmitSocket()}
          />
          <h2>
            Neste espaço o promotor deverá fazer toda a sua acusação, tentando
            provar a culpa do réu! Será preciso relatar as más consequências
            ocorridas, ou, que podem ocorrer caso o réu não realize os rituais.
          </h2>
        </AcusationWrapper>
      </FlexRowCenter>
      <FlexRowCenter>
        <ProsecutorImg src={Prosecutor} alt="Promotor" />
        <RightDialogTextArea
          spellCheck={false}
          maxLength={isMobile ? 128 : 450}
          value={defenseField}
          onKeyPress={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
          onChange={(e) => setDefenseField(e.target.value)}
          onKeyUp={() => setEmitSocket()}
        />
      </FlexRowCenter>
      <FlexRowCenter>
        <h2>
          Neste espaço, o advogado de defesa deverá esforçar-se para provar a
          inocência do réu. Ele irá elucidar todas as provas que defendem o réu,
          mostrando que ele não é um perigo na vida do próximo, bem como não é o
          responsável caso coisas ruins aconteçam.
        </h2>
        <DefenseLawyerImg src={DefenseLawyer} alt="Advogado de defesa" />
      </FlexRowCenter>
      <FlexRowCenter style={{ marginTop: 50 }}>
        <LeftDialogTextArea
          spellCheck={false}
          maxLength={isMobile ? 128 : 450}
          value={judgeField}
          onKeyPress={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
          onChange={(e) => setJudgeField(e.target.value)}
          onKeyUp={() => setEmitSocket()}
        />
        <h2>
          Agora o juiz deverá decretar se o réu é inocente ou culpado! Além do
          mais, ele precisa relatar os motivos pelos quais decidiu condená-lo,
          ou, inocentá-lo.
        </h2>
      </FlexRowCenter>
      <FlexRowStart>
        <JudgeImg src={Judge} alt="Juíz" />
      </FlexRowStart>
      <h3>Conclusão</h3>
      <h4>
        Aqui você deverá anotar as provas encontradas durante o julgamento ou
        suas conclusões.
      </h4>
      <MagnifiersContainer style={{ width: '90%', marginTop: '30px' }}>
        <MagnifierBackground rotation="left">
          <textarea
            spellCheck={false}
            maxLength={140}
            value={magnifier1}
            onKeyPress={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
            onChange={(e) => setMagnifier1(e.target.value)}
            onKeyUp={() => setEmitSocket()}
          />
        </MagnifierBackground>
        <MagnifierBackground rotation="none">
          <textarea
            spellCheck={false}
            maxLength={140}
            value={magnifier2}
            onKeyPress={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
            onChange={(e) => setMagnifier2(e.target.value)}
            onKeyUp={() => setEmitSocket()}
          />
        </MagnifierBackground>
        <MagnifierBackground rotation="right">
          <textarea
            spellCheck={false}
            maxLength={140}
            value={magnifier3}
            onKeyPress={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
            onChange={(e) => setMagnifier3(e.target.value)}
            onKeyUp={() => setEmitSocket()}
          />
        </MagnifierBackground>
      </MagnifiersContainer>
      {typeUser === 'profissional' && (
        <h2 style={{ marginTop: '50px', textAlign: 'center' }}>
          {PacientName()}
        </h2>
      )}
      {typeUser === 'profissional' && !print && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 50,
          }}
        >
          <PrimaryButton onClick={() => setIsOpen(true)} mobile>
            Salvar
          </PrimaryButton>

          <PrimaryButton onClick={() => setModalCloseIsOpen(true)} mobile>
            Fechar
          </PrimaryButton>
        </div>
      )}
      <ModalAddOps
        generatePdf={generatePdf}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        token={token}
      />
      <ModalAlert
        modalIsOpen={modalIsOpenAlert}
        setIsOpen={setIsOpenAlert}
        token={token}
        props={props}
        title={'Julgamento do TOC'}
        recurso={nome_recurso_cod}
        bt01Txt={'Fechar'}
      />
      <ModalConfirm
        title="Fechar o jogo"
        description="Deseja realmente fechar o jogo?"
        modalIsOpen={modalCloseIsOpen}
        setIsOpen={setModalCloseIsOpen}
        confirmButtonText="Fechar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCloseIsOpen(false)}
        onConfirm={() =>
          handleBackHome(
            modalClose,
            nome_recurso_cod,
            typeUser,
            props,
            os,
            token,
            ambiente
          )
        }
      />
    </Container>
  );
}
