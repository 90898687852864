import styled, { css } from 'styled-components';

const SecondaryButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background: #f5f5f5;
  color: #2797ba;

  width: 20vw;
  max-width: 122px;

  height: 40px;

  margin: 0px 5px;
  padding: 5px;

  box-shadow: inset 0px 0px 19px #e8e9e9;
  border: 2px solid #2797ba;
  border-radius: 24px;

  font-family: 'Roboto', serif;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;

  transition: 0.3s;

  &:hover {
    border: 2px solid #137594;
    color: #127594;
    box-shadow: inset 0px 0px 15px rgb(17 70 85 / 17%);
  }

  cursor: pointer;

  @media (max-width: 800px) {
    font-size: 14px;
    margin: 5px 2px;
  }

  ${(props) =>
    props.mobile &&
    css`
      @media (max-width: 768px) {
        width: 30vw;
      }
    `}
`;

export default SecondaryButton;
