import React from 'react';

import { Container, Waves, LogoContainer } from './styles';

const WaitingPacientScreen = ({ text }) => {
  return (
    <Container>
      <Waves>
        <h1>{text}</h1>
      </Waves>
      <LogoContainer></LogoContainer>
    </Container>
  );
};

export default WaitingPacientScreen;
