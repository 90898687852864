import React, { useState, useEffect, useRef } from 'react';

//Utilities imports
import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  PacientName,
  DataURL,
} from '@utils';

import domtoimage from 'dom-to-image';

//Components import
import {
  ModalAddOps,
  ModalAlert,
  SecondaryButton,
  TertiaryButton,
  ModalConfirm,
  PrimaryButton,
  ColorableImage,
} from '@components';

//Styles import
import {
  Container,
  FoodsContainer,
  OptionsContainer,
  TitleContainer,
  Image,
} from './styles';

//Images imports
import Title from '@assets/foodgroup/title.png';
import SubTitle from '@assets/foodgroup/subtitle.png';
import { FoodsOptions, FoodGroupFoods } from '@assets';

const FoodGroup = (props) => {
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  //Socket variables
  const [yourID, setYourID] = useState();
  const socketRef = useRef();

  //Modal variables
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCleanIsOpen, setModalCleanIsOpen] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);

  //Control variables
  /*
   67b31b - vegetais
   ea1889 - grãos
   007ac6 - frutas
   f22b0c - proteínas
   ffd000 - laticínios
   */
  const [print, setPrint] = useState(false);
  const [activeColor, setActiveColor] = useState('#67b31b');
  const [colorsArray, setColorsArray] = useState([
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#fff',
    '#fff',
  ]);

  const fillImage = async (position, actualArray) => {
    setColorsArray(
      colorsArray.map((color, index) =>
        index === position ? activeColor : color
      )
    );

    setEmitSocket(position, actualArray);
  };

  const generatePdf = () => {
    setPrint(true);
    domtoimage
      .toPng(document.getElementById('divIdToPrint'))
      .then(function (dataUrl) {
        const nome_paciente = localStorage
          .getItem('@nome_paciente')
          .replaceAll(' ', '_');
        const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
        downloadURI(
          dataUrl,
          filename,
          sendArquivo,
          os,
          token,
          nome_recurso_cod
        );
        setPrint(false);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  };

  async function receivedMessage(message) {
    setColorsArray(
      message.body.actualArray.map((color, index) =>
        index === message.body.position ? message.body.activeColor : color
      )
    );
  }

  function setEmitSocket(position, actualArray) {
    const messageObject = {
      body: {
        position: position,
        activeColor: activeColor,
        actualArray: actualArray,
      },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);
    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, tokenSocketIo);
    }
  }, []);

  return (
    <>
      <Container id="divIdToPrint">
        <TitleContainer>
          <img src={Title} alt={Title} />
          <img src={SubTitle} alt={SubTitle} />
        </TitleContainer>
        <OptionsContainer>
          <Image
            src={FoodsOptions[0]}
            alt={FoodsOptions[0]}
            isSelected={activeColor === '#007ac6'}
            onClick={() => setActiveColor('#007ac6')}
          />
          <Image
            src={FoodsOptions[1]}
            alt={FoodsOptions[1]}
            isSelected={activeColor === '#ea1889'}
            onClick={() => setActiveColor('#ea1889')}
          />
          <Image
            src={FoodsOptions[2]}
            alt={FoodsOptions[2]}
            isSelected={activeColor === '#ffd000'}
            onClick={() => setActiveColor('#ffd000')}
          />
          <Image
            src={FoodsOptions[3]}
            alt={FoodsOptions[3]}
            isSelected={activeColor === '#f22b0c'}
            onClick={() => setActiveColor('#f22b0c')}
          />
          <Image
            src={FoodsOptions[4]}
            alt={FoodsOptions[4]}
            isSelected={activeColor === '#67b31b'}
            onClick={() => setActiveColor('#67b31b')}
          />
        </OptionsContainer>
        <FoodsContainer>
          {FoodGroupFoods &&
            FoodGroupFoods.map((item, index) => (
              <ColorableImage
                imageSource={item}
                position={index}
                clickFunction={() => fillImage(index, colorsArray)}
                color={colorsArray[index]}
                activeColor={activeColor}
              />
            ))}
        </FoodsContainer>

        <ModalAddOps
          generatePdf={generatePdf}
          modalIsOpen={modalIsOpen}
          setIsOpen={setIsOpen}
          token={token}
        />
        <ModalAlert
          modalIsOpen={modalIsOpenAlert}
          setIsOpen={setIsOpenAlert}
          token={token}
          props={props}
          title={'Eu me amo porque...'}
          recurso={nome_recurso_cod}
          bt01Txt={'Fechar'}
        />
        <ModalConfirm
          title="Fechar o jogo"
          description="Deseja realmente fechar o jogo?"
          modalIsOpen={modalCloseIsOpen}
          setIsOpen={setModalCloseIsOpen}
          confirmButtonText="Fechar"
          cancelButtonText="Cancelar"
          onCancel={() => setModalCloseIsOpen(false)}
          onConfirm={() =>
            handleBackHome(
              modalClose,
              nome_recurso_cod,
              typeUser,
              props,
              os,
              token,
              ambiente
            )
          }
        />
        {typeUser === 'profissional' && !print && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 50,
            }}
          >
            <PrimaryButton onClick={() => setModalCloseIsOpen(true)}>
              Fechar
            </PrimaryButton>
            <PrimaryButton onClick={() => setIsOpen(true)}>
              Salvar
            </PrimaryButton>
          </div>
        )}
      </Container>
    </>
  );
};

export default FoodGroup;
