/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';

import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  PacientName,
  DataURL,
} from '@utils';

import domtoimage from 'dom-to-image';

// Images import
import { ProblemSizeImages } from '@assets';

import { Container, FormTitle } from './styles';

import { Row } from 'react-flexbox-grid';

import {
  ModalAddOps,
  ModalAlert,
  PrimaryButton,
  ModalConfirm,
} from '@components';

import Page01 from './Page01';
import Page02 from './Page02';
import Page03 from './Page03';

const ProblemSize = (props) => {
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  const [page, setPage] = useState(1);
  const [whatProblem, setWhatProblem] = useState('');
  const [whatActitude, setWhatActitude] = useState('');
  const [bar, setBar] = useState(100);
  const [problemDescription, setProblemDescription] = useState('');
  const [sizeProblem, setSizeProblem] = useState(1);
  const [problemReact, setProblemReact] = useState('');
  const [sizeReact, setSizeReact] = useState(1);
  const [emotionalSize, setEmotionalSize] = useState(1);
  const [justifyDescription, setJustifyDescription] = useState('');
  const [alternativeProblemResolve, setAlternativeProblemResolve] = useState(
    ''
  );

  const [yourID, setYourID] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);
  const [print, setPrint] = useState(false);

  const containerRef = useRef();
  const socketRef = useRef();

  const receivedMessage = (message) => {
    setWhatProblem(message.body.whatProblem);
    setWhatActitude(message.body.whatActitude);
    setBar(message.body.bar);
    setProblemDescription(message.body.problemDescription);
    setSizeProblem(message.body.sizeProblem);
    setProblemReact(message.body.problemReact);
    setSizeReact(message.body.sizeReact);
    setEmotionalSize(message.body.emotionalSize);
    setJustifyDescription(message.body.justifyDescription);
    setAlternativeProblemResolve(message.body.alternativeProblemResolve);
    setPage(message.page);
  };

  const handleClickBar = (e) => {
    setBar(e);
    setEmitSocket({
      barval: e,
      pageval: page,
      sizeproblemval: sizeProblem,
      sizereactval: sizeReact,
      emotionalsizeval: emotionalSize,
    });
  };

  const handleClickPage = (e) => {
    setPage(e);
    setEmitSocket({
      barval: bar,
      pageval: e,
      sizeproblemval: sizeProblem,
      sizereactval: sizeReact,
      emotionalsizeval: emotionalSize,
    });
  };

  const handleClickSizeProblem = (e) => {
    setSizeProblem(e);
    setEmitSocket({
      barval: bar,
      pageval: page,
      sizeproblemval: e,
      sizereactval: sizeReact,
      emotionalsizeval: encodeURI,
    });
  };

  const handleClickSizeReact = (e) => {
    setSizeReact(e);
    setEmitSocket({
      barval: bar,
      pageval: page,
      sizeproblemval: sizeProblem,
      sizereactval: e,
      emotionalsizeval: emotionalSize,
    });
  };

  const handleClickEmotionalSize = (e) => {
    setEmotionalSize(e);
    setEmitSocket({
      barval: bar,
      pageval: page,
      sizeproblemval: sizeProblem,
      sizereactval: sizeReact,
      emotionalsizeval: e,
    });
  };

  const setEmitSocket = ({
    barval,
    pageval,
    sizeproblemval,
    sizereactval,
    emotionalsizeval,
  }) => {
    const messageObject = {
      body: {
        whatProblem,
        whatActitude,
        bar: barval,
        problemDescription,
        sizeProblem: sizeproblemval,
        problemReact,
        sizeReact: sizereactval,
        emotionalSize: emotionalsizeval,
        justifyDescription,
        alternativeProblemResolve,
      },
      id: yourID,
      page: pageval,
      typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  };

  const generatePdf = () => {
    setPrint(true);
    domtoimage
      .toPng(document.getElementById('divIdToPrint'))
      .then(function (dataUrl) {
        const nome_paciente = localStorage
          .getItem('@nome_paciente')
          .replaceAll(' ', '_');
        const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
        downloadURI(
          dataUrl,
          filename,
          sendArquivo,
          os,
          token,
          nome_recurso_cod
        );

        setPrint(false);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  };

  const closeGame = () => setModalCloseIsOpen(true);

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);
    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, tokenSocketIo);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  return (
    <>
      <Container id="divIdToPrint" className="containerr" ref={containerRef}>
        <Row top="xs">
          <FormTitle
            onClick={() =>
              handleBackHome(
                modalClose,
                nome_recurso_cod,
                typeUser,
                props,
                os,
                token,
                ambiente
              )
            }
          >
            <img alt="logo" src={ProblemSizeImages[0]} />
          </FormTitle>
        </Row>
        {typeUser === 'profissional' && !print && (
          <PrimaryButton
            onClick={() => setIsOpenAlert(true)}
            style={{ marginBottom: 30, marginTop: -20 }}
            problemSize
          >
            Instruções
          </PrimaryButton>
        )}

        {(page === 1 || page === -1) && (
          <Page01
            page={page}
            setPage={handleClickPage}
            whatProblem={whatProblem}
            setWhatProblem={setWhatProblem}
            whatActitude={whatActitude}
            setWhatActitude={setWhatActitude}
            problemDescription={problemDescription}
            setProblemDescription={setProblemDescription}
            bar={bar}
            setBar={handleClickBar}
            setEmitSocket={setEmitSocket}
            modalClose={closeGame}
            typeUser={typeUser}
          />
        )}

        {(page === 2 || page === -1) && (
          <Page02
            page={page}
            setPage={handleClickPage}
            sizeProblem={sizeProblem}
            setSizeProblem={handleClickSizeProblem}
            problemReact={problemReact}
            setProblemReact={setProblemReact}
            setEmitSocket={setEmitSocket}
            modalClose={closeGame}
            typeUser={typeUser}
          />
        )}

        {(page === 3 || page === -1) && (
          <Page03
            page={page}
            setPage={handleClickPage}
            sizeReact={sizeReact}
            setSizeReact={handleClickSizeReact}
            problemReact={problemReact}
            setProblemReact={setProblemReact}
            emotionalSize={emotionalSize}
            setEmotionalSize={handleClickEmotionalSize}
            justifyDescription={justifyDescription}
            setJustifyDescription={setJustifyDescription}
            alternativeProblemResolve={alternativeProblemResolve}
            setAlternativeProblemResolve={setAlternativeProblemResolve}
            setEmitSocket={setEmitSocket}
            containerRef={containerRef}
            modalClose={closeGame}
            typeUser={typeUser}
            setIsOpen={setIsOpen}
          />
        )}

        {typeUser === 'profissional' && (
          <h2 style={{ marginTop: '50px' }}>{PacientName()}</h2>
        )}

        <ModalAddOps
          generatePdf={generatePdf}
          modalIsOpen={modalIsOpen}
          setIsOpen={setIsOpen}
          token={token}
        />
        <ModalAlert
          modalIsOpen={modalIsOpenAlert}
          setIsOpen={setIsOpenAlert}
          token={token}
          props={props}
          title={'O Tamanho do Problema'}
          recurso={nome_recurso_cod}
          bt01Txt={'Fechar'}
        />
        <ModalConfirm
          title="Fechar o jogo"
          description="Deseja realmente fechar o jogo?"
          modalIsOpen={modalCloseIsOpen}
          setIsOpen={setModalCloseIsOpen}
          confirmButtonText="Fechar"
          cancelButtonText="Cancelar"
          onCancel={() => setModalCloseIsOpen(false)}
          onConfirm={() =>
            handleBackHome(
              modalClose,
              nome_recurso_cod,
              typeUser,
              props,
              os,
              token,
              ambiente
            )
          }
        />
      </Container>
    </>
  );
};

export default ProblemSize;
