import React from 'react';

import { IoClose } from 'react-icons/io5';

import './styles.css';

import {
  Container,
  TitleContainer,
  DataContainer,
  OwnerContainer,
  ButtonsContainer,
  CancelButton,
  UseButton,
  Modal,
} from './styles';

export default function ModalReadMore({
  modalIsOpen,
  setIsOpen,
  content,
  os,
  token,
  ambiente,
  setStartResourceModal,
  setStartResourceContent,
}) {
  return (
    <Modal isOpen={modalIsOpen}>
      <Container>
        <TitleContainer>
          <h1>{content.nome_recurso}</h1>
          <IoClose
            size={30}
            color="#7D8C94"
            onClick={() => setIsOpen(false)}
            style={{ cursor: 'pointer' }}
          />
        </TitleContainer>
        <DataContainer>
          <h2>Descrição: </h2>
          <h3>{content.desc_recurso}</h3>
        </DataContainer>
        {content.approach && (
          <DataContainer>
            <h2>Abordagem: </h2>
            <h3>{content.approach}</h3>
          </DataContainer>
        )}
        <DataContainer>
          <h2>Público Alvo: </h2>
          <h3>{content.publico_alvo}</h3>
        </DataContainer>
        {content.owner && (
          <OwnerContainer>
            <h3>Criado por {content.owner}</h3>
          </OwnerContainer>
        )}
        <ButtonsContainer>
          <CancelButton onClick={() => setIsOpen(false)}>
            <h3>Cancelar</h3>
          </CancelButton>
          <UseButton
            onClick={() => {
              setIsOpen(false);
              setStartResourceContent(content);
              setStartResourceModal(true);
            }}
          >
            <h3>Usar recurso</h3>
          </UseButton>
        </ButtonsContainer>
      </Container>
    </Modal>
  );
}
