import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  strong {
    font-family: 'Bungee Inline', cursive !important;
    font-size: 26px !important;
    margin-bottom: 5px !important;
    max-width: 350px;
    text-align: center;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 5px 20px;

  width: 310px;
  height: 180px;

  border: 3px solid;
  img {
    max-width: 170px;
  }
`;
