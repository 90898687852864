import React, { useEffect, useRef, useState } from 'react';

import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  PacientName,
  DataURL,
} from '@utils';

import domtoimage from 'dom-to-image';

import {
  Container,
  CentralContainer,
  NegativeContainer,
  PositiveContainer,
  InstructionsContainer,
  FieldsRow,
  TextArea,
  Col,
  SaveAndPaginatedContainer,
  HeroImage,
  CauseEffectImage,
} from './styles';
import {
  ModalAddOps,
  ModalAlert,
  PrimaryButton,
  TertiaryButton,
  ModalConfirm,
} from '@components';
import { CauseEffectImages } from '@assets';

export default function CauseEffect(props) {
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  const [yourID, setYourID] = useState();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);

  let totalOfCauses = new Array(4);
  totalOfCauses.fill(4);

  const [page, setPage] = useState(1);
  const [print, setPrint] = useState(false);

  const socketRef = useRef();

  const [negativeCause0, setNegativeCause0] = useState('');
  const [negativeCause1, setNegativeCause1] = useState('');
  const [negativeCause2, setNegativeCause2] = useState('');
  const [negativeCause3, setNegativeCause3] = useState('');
  const [negativeEffect0, setNegativeEffect0] = useState('');
  const [negativeEffect1, setNegativeEffect1] = useState('');
  const [negativeEffect2, setNegativeEffect2] = useState('');
  const [negativeEffect3, setNegativeEffect3] = useState('');
  const [positiveCause0, setPositiveCause0] = useState('');
  const [positiveCause1, setPositiveCause1] = useState('');
  const [positiveCause2, setPositiveCause2] = useState('');
  const [positiveCause3, setPositiveCause3] = useState('');
  const [positiveEffect0, setPositiveEffect0] = useState('');
  const [positiveEffect1, setPositiveEffect1] = useState('');
  const [positiveEffect2, setPositiveEffect2] = useState('');
  const [positiveEffect3, setPositiveEffect3] = useState('');

  function receivedMessage(message) {
    if (message.body.page !== undefined) {
      setPage(message.body.page);
    } else {
      setNegativeCause0(message.body.negativeCause0);
      setNegativeCause1(message.body.negativeCause1);
      setNegativeCause2(message.body.negativeCause2);
      setNegativeCause3(message.body.negativeCause3);
      setNegativeEffect0(message.body.negativeEffect0);
      setNegativeEffect1(message.body.negativeEffect1);
      setNegativeEffect2(message.body.negativeEffect2);
      setNegativeEffect3(message.body.negativeEffect3);
      setPositiveCause0(message.body.positiveCause0);
      setPositiveCause1(message.body.positiveCause1);
      setPositiveCause2(message.body.positiveCause2);
      setPositiveCause3(message.body.positiveCause3);
      setPositiveEffect0(message.body.positiveEffect0);
      setPositiveEffect1(message.body.positiveEffect1);
      setPositiveEffect2(message.body.positiveEffect2);
      setPositiveEffect3(message.body.positiveEffect3);
    }
  }

  function setPageOnSocket(pg) {
    const messageObject = {
      body: {
        page: pg,
      },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  function setEmitSocket() {
    //console.log('ola');
    const messageObject = {
      body: {
        negativeCause0: negativeCause0,
        negativeCause1: negativeCause1,
        negativeCause2: negativeCause2,
        negativeCause3: negativeCause3,
        negativeEffect0: negativeEffect0,
        negativeEffect1: negativeEffect1,
        negativeEffect2: negativeEffect2,
        negativeEffect3: negativeEffect3,
        positiveCause0: positiveCause0,
        positiveCause1: positiveCause1,
        positiveCause2: positiveCause2,
        positiveCause3: positiveCause3,
        positiveEffect0: positiveEffect0,
        positiveEffect1: positiveEffect1,
        positiveEffect2: positiveEffect2,
        positiveEffect3: positiveEffect3,
      },
      id: yourID,
      typeUser: typeUser,
      page: page,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  const generatePdf = () => {
    setPrint(true);
    domtoimage
      .toPng(document.getElementById('divIdToPrint'))
      .then(function (dataUrl) {
        const nome_paciente = localStorage
          .getItem('@nome_paciente')
          .replaceAll(' ', '_');
        const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
        downloadURI(
          dataUrl,
          filename,
          sendArquivo,
          os,
          token,
          nome_recurso_cod
        );

        setPrint(false);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  };

  const chooseCauseTextAreaValue = (type, index) => {
    if (type === 'negative') {
      switch (index) {
        case 0:
          return negativeCause0;
        case 1:
          return negativeCause1;
        case 2:
          return negativeCause2;
        case 3:
          return negativeCause3;

        default:
          return;
      }
    } else {
      switch (index) {
        case 0:
          return positiveCause0;
        case 1:
          return positiveCause1;
        case 2:
          return positiveCause2;
        case 3:
          return positiveCause3;

        default:
          return;
      }
    }
  };

  const updateCauseTextValue = (type, value, index) => {
    if (type === 'negative') {
      switch (index) {
        case 0:
          setNegativeCause0(value);
          break;
        case 1:
          setNegativeCause1(value);
          break;
        case 2:
          setNegativeCause2(value);
          break;
        case 3:
          setNegativeCause3(value);
          break;

        default:
          return;
      }
    } else {
      switch (index) {
        case 0:
          setPositiveCause0(value);
          break;
        case 1:
          setPositiveCause1(value);
          break;
        case 2:
          setPositiveCause2(value);
          break;
        case 3:
          setPositiveCause3(value);
          break;

        default:
          return;
      }
    }
  };

  const chooseEffectTextAreaValue = (type, index) => {
    if (type === 'negative') {
      switch (index) {
        case 0:
          return negativeEffect0;
        case 1:
          return negativeEffect1;
        case 2:
          return negativeEffect2;
        case 3:
          return negativeEffect3;

        default:
          return;
      }
    } else {
      switch (index) {
        case 0:
          return positiveEffect0;
        case 1:
          return positiveEffect1;
        case 2:
          return positiveEffect2;
        case 3:
          return positiveEffect3;

        default:
          return;
      }
    }
  };

  const updateEffectTextValue = (type, value, index) => {
    if (type === 'negative') {
      switch (index) {
        case 0:
          setNegativeEffect0(value);
          break;
        case 1:
          setNegativeEffect1(value);
          break;
        case 2:
          setNegativeEffect2(value);
          break;
        case 3:
          setNegativeEffect3(value);
          break;

        default:
          return;
      }
    } else {
      switch (index) {
        case 0:
          setPositiveEffect0(value);
          break;
        case 1:
          setPositiveEffect1(value);
          break;
        case 2:
          setPositiveEffect2(value);
          break;
        case 3:
          setPositiveEffect3(value);
          break;

        default:
          return;
      }
    }
  };

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);
    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, tokenSocketIo);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  return (
    <Container id="divIdToPrint">
      <CentralContainer>
        <HeroImage src={CauseEffectImages[0]} alt="Titulo" />
        {typeUser === 'profissional' && !print && (
          <PrimaryButton
            onClick={() => setIsOpenAlert(true)}
            style={{ marginTop: 30 }}
          >
            Instruções
          </PrimaryButton>
        )}

        {typeUser === 'profissional' && !print && page === 3 && (
          <InstructionsContainer
            style={{ textAlign: 'center', alignItems: 'center' }}
          >
            <strong>
              Por favor confira se os campos foram preenchidos corretamente.
            </strong>
          </InstructionsContainer>
        )}
        {(page === 1 || page === 3) &&
          totalOfCauses.map((val, index) => {
            return (
              <>
                <NegativeContainer page={page}>
                  <FieldsRow>
                    <Col>
                      <CauseEffectImage
                        src={CauseEffectImages[1]}
                        alt="Causa negativa"
                        cause
                      />
                      <TextArea
                        spellCheck={false}
                        maxLength={185}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') e.preventDefault();
                        }}
                        value={chooseCauseTextAreaValue('negative', index)}
                        onChange={(e) => {
                          updateCauseTextValue(
                            'negative',
                            e.target.value,
                            index
                          );
                        }}
                        onKeyUp={() => setEmitSocket()}
                      />
                    </Col>
                    <Col>
                      <CauseEffectImage
                        src={CauseEffectImages[2]}
                        alt="Efeito negativo"
                        effect
                      />
                      <TextArea
                        spellCheck={false}
                        maxLength={185}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') e.preventDefault();
                        }}
                        value={chooseEffectTextAreaValue('negative', index)}
                        onChange={(e) =>
                          updateEffectTextValue(
                            'negative',
                            e.target.value,
                            index
                          )
                        }
                        onKeyUp={() => setEmitSocket()}
                      />
                      {index < totalOfCauses.length - 1 && <span></span>}
                    </Col>
                  </FieldsRow>
                </NegativeContainer>
              </>
            );
          })}
        {(page === 2 || page === 3) &&
          totalOfCauses.map((val, index) => {
            return (
              <PositiveContainer page={page}>
                <FieldsRow>
                  <Col>
                    <CauseEffectImage
                      src={CauseEffectImages[3]}
                      alt="Causa positiva"
                      cause
                    />
                    <TextArea
                      spellCheck={false}
                      maxLength={185}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') e.preventDefault();
                      }}
                      value={chooseCauseTextAreaValue('positive', index)}
                      onChange={(e) =>
                        updateCauseTextValue('positive', e.target.value, index)
                      }
                      onKeyUp={() => setEmitSocket()}
                    />
                  </Col>
                  <Col>
                    <CauseEffectImage
                      src={CauseEffectImages[4]}
                      alt="Efeito positivo"
                      effect
                    />
                    <TextArea
                      spellCheck={false}
                      maxLength={185}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') e.preventDefault();
                      }}
                      value={chooseEffectTextAreaValue('positive', index)}
                      onChange={(e) =>
                        updateEffectTextValue('positive', e.target.value, index)
                      }
                      onKeyUp={() => setEmitSocket()}
                    />
                    {index < totalOfCauses.length - 1 && <span></span>}
                  </Col>
                </FieldsRow>
              </PositiveContainer>
            );
          })}

        {typeUser === 'profissional' && <h2>{PacientName()}</h2>}
        <SaveAndPaginatedContainer>
          {typeUser === 'profissional' && !print && page > 1 && (
            <TertiaryButton
              onClick={() => {
                setPage(page - 1);
                setPageOnSocket(page - 1);
              }}
            >
              Anterior
            </TertiaryButton>
          )}
          {typeUser === 'profissional' && !print && page < 3 && (
            <TertiaryButton
              onClick={() => {
                setPage(page + 1);
                setPageOnSocket(page + 1);
                if (page === 2) {
                  window.scrollTo(0, 0);
                }
              }}
            >
              Próximo
            </TertiaryButton>
          )}
        </SaveAndPaginatedContainer>
        <SaveAndPaginatedContainer closeAndSave>
          {typeUser === 'profissional' && !print && page === 3 && (
            <PrimaryButton onClick={() => setIsOpen(true)}>
              Salvar
            </PrimaryButton>
          )}
          {typeUser === 'profissional' && !print && (
            <PrimaryButton onClick={() => setModalCloseIsOpen(true)}>
              Fechar
            </PrimaryButton>
          )}
        </SaveAndPaginatedContainer>
      </CentralContainer>
      <ModalAddOps
        generatePdf={generatePdf}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        token={token}
      />
      <ModalAlert
        modalIsOpen={modalIsOpenAlert}
        setIsOpen={setIsOpenAlert}
        token={token}
        props={props}
        title={'Causa e Efeito'}
        recurso={nome_recurso_cod}
        bt01Txt={'Fechar'}
      />
      <ModalConfirm
        title="Fechar o jogo"
        description="Deseja realmente fechar o jogo?"
        modalIsOpen={modalCloseIsOpen}
        setIsOpen={setModalCloseIsOpen}
        confirmButtonText="Fechar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCloseIsOpen(false)}
        onConfirm={() =>
          handleBackHome(
            modalClose,
            nome_recurso_cod,
            typeUser,
            props,
            os,
            token,
            ambiente
          )
        }
      />
    </Container>
  );
}
