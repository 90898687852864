import { API, API2 } from './api';

export const getSessaoAtual = (os, token) => {
  if (os === 'psicomanager') {
    return API2.get(`/get-sessao-atual/?q=(tkn:${token})`);
  } else {
    return API.get(`/get-sessao-atual/?q=(tkn:${token})`);
  }
};

export const getPacients = (os, token) => {
  if (os === 'psicomanager') {
    return API2.get(`/listar-pac-prof/?q=(tkn:${token})`);
  } else {
    return API.get(`/listar-pac-prof/?q=(tkn:${token})`);
  }
};

export const sendRecurso = (os, obj) => {
  if (os === 'psicomanager') {
    return API2.post(`/salvar-reg-recurso`, obj);
  } else {
    return API.post(`/salvar-reg-recurso`, obj);
  }
};

export const getListRecursos = (os, token) => {
  if (os === 'psicomanager') {
    return API2.get(`/listar-recurso/?q=(tkn:${token})`);
  } else {
    return API.get(`/listar-recurso/?q=(tkn:${token})`);
  }
};

// tkn:TOKEN_NEFT, cod_sessao, cod_paciente, arquivo, alguma anotação
