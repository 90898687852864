import styled from 'styled-components';

export const SubTitle = styled.h3`
  margin: 10px 0 20px 0;
  padding: 0;
  font-size: 30px;
  color: #363435;
  font-family: 'Open Sans Condensed', sans-serif;
  width: 100%;
  text-align: center;
`;

export const Value = styled.div`
  display: inline-block;
  font-size: 1.3em;
  padding: 5px 10px;
  border: 1px solid #ddd;
  cursor: pointer;

  :hover {
    background: #ecc;
  }
`;
