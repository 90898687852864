/* eslint-disable no-unused-expressions */
import styled, { css } from 'styled-components';

import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  overflow: hidden;

  span {
    margin-bottom: 100px;
    font-weight: 700;
    font-size: 2rem;
    text-align: center;
    color: #502d61;
  }

  .page-break {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  @media print {
    .page-break {
      display: flex;
      flex-direction: row;
      page-break-before: auto;
    }
    img {
      width: 50%;
    }
    div {
      font-size: 18px;
    }
    footer {
      page-break-after: always;
    }
  }

  @font-face {
    font-family: 'Bungee Inline', cursive;
    src: url('https://fonts.googleapis.com/css?family=Bungee+Inline');
  }

  .center-items {
    flex-direction: column;
    text-align: center;

    .items {
      margin-top: 25px 20px;
      flex-direction: column;
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export const ProblemSizeFieldWrapper = styled.div`
  ${(props) =>
    props.whatProblem &&
    css`
      width: 35%;
      float: left;

      @media (max-width: 962px) {
        width: 100%;
      }
    `}

  ${(props) =>
    props.thermometer &&
    css`
      width: 30%;
      float: left;
      @media (max-width: 962px) {
        width: 70%;
      }
    `}

  ${(props) =>
    props.whatAttitude &&
    css`
      width: 35%;
      float: left;

      @media (max-width: 768px) {
        width: 100%;
      }
    `}
`;

export const TermometroContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
  margin-top: 50px;
`;

export const TermometroView = styled.div`
  display: flex;
  text-align: center;
  width: 100%;
  height: 100%;
  left: 35%;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  img {
    width: 35%;
  }
`;

export const TermometroViewColor = styled.div`
  display: flex;
  text-align: center;
  width: 100%;
  height: 100%;
  left: 35%;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  img {
    width: 35%;
    height: 100%;
    clip-path: inset(${(props) => props.clip}% 0% 0% 0%);
    transition: clip-path 3s;
  }
  @media print {
    img {
      width: 35%;
      clip-path: inset(${(props) => props.clip}% 0% 0% 0%);
      transition: clip-path 3s;
    }
  }
`;

export const BoxText = styled.div`
  text-align: center;
  font-family: 'Bungee Inline', cursive;
  padding: 3px;
`;

export const Row = styled.div`
  width: 100%;
  margin-top: 70px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const EmotionsDefaultContainer = styled.div`
  width: 150px;

  margin: 0px 15px;

  background: white;

  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    text-align: center;
    font-family: 'Roboto', serif;
    font-weight: ${(props) => (props.isSelected ? 700 : 400)};
    margin-top: 5px;
  }

  cursor: pointer;
`;

export const ColorButton = styled.div`
  width: 100%;
  height: 50px;

  border-radius: 9px;

  border: 1px solid;

  background: ${(props) => props.color};

  transform: ${(props) => (props.isSelected ? 'scale(1.1)' : 'scale(1)')};
  transition: 0.3s;
`;
