import styled, { css } from 'styled-components';

const SecondaryButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background: #fff;
  color: #aeb9bf;

  width: 20vw;
  max-width: 122px;
  /* width: ${(props) => {
    switch (props.size) {
      case 'sm':
        return '130px';
      case 'md':
        return '170px';
      case 'lg':
        return '220px';
      default:
        return '130px';
    }
  }}; */

  height: 40px;

  margin: 5px;
  padding: 5px;

  box-shadow: inset 0px 0px 19px #e8e9e9;
  border: 2px solid #AEB9BF;
  border-radius: 24px;

  font-family: 'Roboto', serif;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;

  transition: 0.3s;

  &:hover {
    background: #fff;
    border: 2px solid #90a4ae;
    color: #90a4ae;
  }

  cursor: pointer;

  @media (max-width: 800px) {
    font-size: 14px;
    margin: 5px 2px;
  }

  ${(props) =>
    (props.problemSize || props.mobile) &&
    css`
      @media (max-width: 768px) {
        width: 30vw;
      }
    `}
`;

export default SecondaryButton;
