import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;

  width: 100%;
  min-height: 90vh;
`;

export const SaveArea = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`;
export const BoardBox = styled.div`
  background-repeat: no-repeat;
  background-position: 722px 130px;
  border: 0;
  justify-content: center;
  align-items: center;
`;
