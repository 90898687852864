import styled from 'styled-components';

export const Container = styled.div`
  width: 180px;
  display: flex;
  flex-direction: column;
`;

export const Tab = styled.div`
  height: 65px;
  width: 60px;
  background: white;
  border: ${(props) => (props.isSelected ? '1px solid #2797ba' : 'none')};
  border-bottom: none;
  margin-bottom: -1px;
  margin-right: 5px;

  display: flex;
  justify-content: center;

  border-radius: 50px 50px 0px 0px;

  img {
    width: 40px;
  }

  cursor: pointer;
  z-index: ${(props) => (props.isSelected ? 999 : 0)};
`;

export const TabPanel = styled.div`
  height: 420px;
  width: 180px;
  border: 1px solid #2797ba;
  border-radius: ${(props) =>
    props.selectedTab <= 0 || props.typeUser === 'paciente'
      ? '0px 10px 10px 10px'
      : '10px 10px 10px 10px'};

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: scroll;

  img {
    width: 70px;
    cursor: pointer;
    user-select: none;
  }
  z-index: 1;
`;

export const BackgroundCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 60px;
  width: 60px;

  background: ${(props) => (props.isSelected ? '#ddf2f8' : '#E8E9E9')};

  border-radius: 50%;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const MobileContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;

  z-index: 9999;
`;

export const MobileTabsCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MobileTab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 0px 50% 50% 0px;
  border: 1px solid #2797ba;
  border-left: none;
  margin-left: -1px;
  margin-bottom: 10px;

  cursor: pointer;
  user-select: none;

  img {
    width: 35px;
    user-select: none;
  }
`;

export const MobileTabContainer = styled.div`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  flex-wrap: wrap;

  justify-content: center;

  width: 50vw;
  height: 100vh;
  overflow-y: scroll;

  padding: 10px 0px;

  background-color: white;
  border-right: 1px solid #2797ba;
  border-top: 1px solid #2797ba;

  img {
    width: 70px;
    height: 70px;
    margin: 5px;

    cursor: pointer;
  }
`;
