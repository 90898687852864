import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 40px;
`;

export const TextArea = styled.textarea.attrs({
  rows: '4',
  cols: '4',
})`
  width: 85%;
  height: 65%;
  text-decoration: none;
  border: none;
  padding: 5px 10px;
  text-align: start;
  font-family: 'Marcellus SC', serif;
  resize: none;
  background: transparent;
  font-size: 20px;
  box-sizing: border-box;
  outline: 0;
  overflow: hidden;
`;

export const WingRow = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 365px;
  width: 20vw;

  justify-content: space-between;
  align-items: center;
  margin-top: ${(props) => (props.bottomWing ? '0' : '10px')};

  @media (max-width: 768px) {
    min-width: 210px;
  }

  @media screen and (min-device-width: 320px) and (max-device-width: 360px) {
    min-width: 190px;
  }
`;

export const MidRow = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 577px;
  width: 35vw;

  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    min-width: 335px;
  }

  @media screen and (min-device-width: 320px) and (max-device-width: 360px) {
    min-width: 305px;
  }
`;

export const Circle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: ${(props) => (props.central ? '5rem' : '10rem')};
  width: ${(props) => (props.central ? '5rem' : '10rem')};

  @media (max-width: 768px) {
    height: ${(props) => (props.central ? '4rem' : '5rem')};
    width: ${(props) => (props.central ? '4rem' : '5rem')};
  }

  @media screen and (min-device-width: 320px) and (max-device-width: 360px) {
    height: ${(props) => (props.central ? '3rem' : '4rem')};
    width: ${(props) => (props.central ? '3rem' : '4rem')};
  }

  border-radius: 50%;

  z-index: 30;
  padding: 10px;

  background: ${(props) => props.color};

  h1 {
    color: white;
    font-size: ${(props) => (props.central ? '2rem' : '3rem')};
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    top: -90%;
    margin-bottom: -10px;

    @media (max-width: 768px) {
      font-size: ${(props) => (props.central ? '1.25rem' : '3rem')};
      margin-bottom: -35px;
    }

    @media screen and (min-device-width: 320px) and (max-device-width: 360px) {
      font-size: ${(props) => (props.central ? '1rem' : '3rem')};
      margin-bottom: -24px;
    }
  }

  textarea {
    margin-bottom: 10px;
    font-family: 'Roboto', serif;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  img:nth-child(1) {
    position: relative;
    transform: rotate(50deg);
    top: 5px;
    left: -38px;
    height: 30px;
    width: 55px;

    @media (max-width: 768px) {
      top: -17px;
      left: -27px;
      width: 25px;
    }

    @media screen and (min-device-width: 320px) and (max-device-width: 360px) {
      top: -15px;
      left: -24px;
      width: 22px;
    }
  }

  img:nth-child(2) {
    position: relative;
    transform: rotate(130deg);
    top: -12px;
    right: -37px;
    height: 30px;
    width: 55px;

    @media (max-width: 768px) {
      top: -30px;
      right: -24px;
      width: 25px;
    }

    @media screen and (min-device-width: 320px) and (max-device-width: 360px) {
      top: -22px;
      right: -25px;
      width: 22px;
    }
  }

  img:nth-child(3) {
    position: relative;
    transform: rotate(180deg);
    top: 35px;
    right: -79px;
    height: 30px;
    width: 60px;

    @media (max-width: 768px) {
      right: -53px;
      top: 10px;
      width: 25px;
    }

    @media screen and (min-device-width: 320px) and (max-device-width: 360px) {
      right: -46px;
      top: 10px;
      width: 25px;
    }
  }

  img:nth-child(4) {
    position: relative;
    transform: rotate(230deg);
    bottom: -77px;
    right: -43px;
    height: 30px;
    width: 55px;

    @media (max-width: 768px) {
      bottom: -40px;
      right: -27px;
      width: 25px;
    }

    @media screen and (min-device-width: 320px) and (max-device-width: 360px) {
      bottom: -35px;
      right: -26px;
      width: 22px;
    }
  }

  img:nth-child(5) {
    position: relative;
    transform: rotate(310deg);
    bottom: -56px;
    left: -45px;
    height: 30px;
    width: 55px;

    @media (max-width: 768px) {
      bottom: -29px;
      left: -28px;
      width: 25px;
    }

    @media screen and (min-device-width: 320px) and (max-device-width: 360px) {
      bottom: -27px;
      left: -30px;
      width: 25px;
    }
  }

  img:nth-child(6) {
    position: relative;
    bottom: 29%;
    left: -75px;
    height: 30px;
    width: 55px;

    @media (max-width: 768px) {
      bottom: 33%;
      left: -54px;
      width: 25px;
    }

    @media screen and (min-device-width: 320px) and (max-device-width: 360px) {
      bottom: 35%;
      left: -46px;
      width: 25px;
    }
  }
`;
