import axios from 'axios';

const API = axios.create({
  baseURL: 'https://api.terapiainterativa.com.br/v1/1080/psico/pv-sinc',
  headers: {
    Accept: 'application/x-www-form-urlencoded; charset=UTF-8',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  },
});

API.interceptors.request.use(
  async (options) => {
    //console.log(options);

    return options;
  },
  (error) => {
    return console.log(error);
  }
);

const API2 = axios.create({
  baseURL: 'https://apip.psicomanager.com/v1/1080/psico/pv-sinc',
  headers: {
    Accept: 'application/x-www-form-urlencoded; charset=UTF-8',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  },
});

API2.interceptors.request.use(
  async (options) => {
    //console.log(options);

    return options;
  },
  (error) => {
    return console.log(error);
  }
);

export { API, API2 };
