import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;

  width: 100%;
  min-height: 90vh;
`;

export const CentralRow = styled.div`
  display: flex;
  align-items: flex-start;
  min-width: 60%;
`;

export const SaveArea = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export const BoardBox = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  border: 0;
  justify-content: center;
  align-items: center;

  border: 1px solid;
`;
