import React from 'react';

/** Imagens */
import { ArrowsBalls } from '../../assets';

//Estilos
import { Container, Tab, TabPanel, BackgroundCircle } from './styles';

export default function EasyTab({ dragUrl }) {
  return (
    <Container>
      <Tab>
        <BackgroundCircle>
          <img src={ArrowsBalls[0]} alt={ArrowsBalls[0]} />
        </BackgroundCircle>
      </Tab>
      <TabPanel>
        {ArrowsBalls &&
          ArrowsBalls.map((item) => (
            <img
              style={{
                cursor: 'pointer',
                userSelect: 'none',
              }}
              alt={item}
              src={item}
              draggable={'true'}
              onDragStart={(e) => {
                dragUrl.current = e.target.src;
              }}
            />
          ))}
      </TabPanel>
    </Container>
  );
}
