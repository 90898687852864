/* eslint-disable no-unused-expressions */
import styled from 'styled-components';

import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  overflow: hidden;

  span {
    margin-bottom: 100px;
    font-weight: 700;
    font-size: 2rem;
    text-align: center;
    color: #502d61;
  }

  .page-break {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  @media print {
    .page-break {
      display: flex;
      flex-direction: row;
      page-break-before: auto;
    }
    div {
      font-size: 18px;
    }
    footer {
      page-break-after: always;
    }
  }

  @font-face {
    font-family: 'Bungee Inline', cursive;
    src: url('https://fonts.googleapis.com/css?family=Bungee+Inline');
  }

  .center-items {
    text-align: center;
  }
`;

export const BoxNumber = styled.div`
  margin: 25px;
  flex-direction: row;
  float: left;
  p {
    font-size: 22px;
    font-family: 'Bungee Inline', cursive;
    float: left;
    margin-right: 15px;
  }
  input {
    width: 60px;
    height: 60px;
    font-size: 32px;
    font-family: 'Bungee Inline', cursive;
    padding: 5px;
  }

  @media (max-width: 768px) {
    margin-bottom: -25px;
  }
`;

export const BoxContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
  left: 10%;
  right: 90%;
`;

export const BoxViewGrey = styled.div`
  display: flex;
  text-align: center;
  width: 100%;
  height: 100%;
`;

export const BoxViewColor = styled.div`
  display: flex;
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
  img {
    width: ${(props) => props.width}%;
    height: 100%;
    clip-path: inset(${(props) => props.clip}% 0% 0% 0%);
    transition: clip-path 3s;
  }
  @media print {
    img {
      width: ${(props) => props.width}%;
      height: 100%;
      clip-path: inset(${(props) => props.clip}% 0% 0% 0%);
      transition: clip-path 3s;
    }
  }
`;

export const NumberButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 50px;
  margin-right: 10px;
  border-radius: 12px;

  height: 35px;
  width: 35px;

  float: left;

  background: ${(props) => props.color};

  border: ${(props) => (props.isSelected ? '5px solid red' : '0')};

  cursor: pointer;

  transform: ${(props) => (props.isSelected ? 'scale(1.2)' : 'scale(1)')};

  &:hover {
    transform: scale(1.1);
    transition: 0.2s;
  }

  h1 {
    font-size: 22px;
    font-family: 'Bungee Inline', cursive;
    /* Fazendo o texto do botão não ser selecionável pra quando a gente clicar e arrastar sem querer kkkkkk */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }
`;

export const NumberWrapper = styled.div`
  width: 100%;
  margin-top: 20;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const NumberContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;
