import React, { useState, useEffect, useRef } from 'react';

import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  PacientName,
  DataURL,
} from '@utils';

import domtoimage from 'dom-to-image';

import { Container, FlexCol, FlexRow, TextArea } from './styles';

import { FirstCactus, LogoCacto } from '@assets';

import {
  ModalAddOps,
  ModalAlert,
  PrimaryButton,
  ModalConfirm,
} from '@components';

export default function CactusFlower(props) {
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  const [yourID, setYourID] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);
  const [print, setPrint] = useState(false);

  const socketRef = useRef();

  const [quest1, setQuest1] = useState('');
  const [quest2, setQuest2] = useState('');
  const [quest3, setQuest3] = useState('');
  const [quest4, setQuest4] = useState('');
  const [quest5, setQuest5] = useState('');
  const [quest6, setQuest6] = useState('');

  function receivedMessage(message) {
    setQuest1(message.body.quest1);
    setQuest2(message.body.quest2);
    setQuest3(message.body.quest3);
    setQuest4(message.body.quest4);
    setQuest5(message.body.quest5);
    setQuest6(message.body.quest6);
  }

  function setEmitSocket() {
    const messageObject = {
      body: {
        quest1,
        quest2,
        quest3,
        quest4,
        quest5,
        quest6,
      },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  const generatePdf = () => {
    setPrint(true);
    domtoimage
      .toPng(document.getElementById('divIdToPrint'))
      .then(function (dataUrl) {
        const nome_paciente = localStorage
          .getItem('@nome_paciente')
          .replaceAll(' ', '_');
        const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
        downloadURI(
          dataUrl,
          filename,
          sendArquivo,
          os,
          token,
          nome_recurso_cod
        );

        setPrint(false);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  };

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);
    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, tokenSocketIo);
    }
  }, []);

  return (
    <Container id="divIdToPrint" className="containerr">
      <img src={LogoCacto} alt="logoCacto" />
      {typeUser === 'profissional' && !print && (
        <PrimaryButton
          style={{ marginTop: 40 }}
          onClick={() => setIsOpenAlert(true)}
          mobile
        >
          Instruções
        </PrimaryButton>
      )}
      {typeUser === 'profissional' && (
        <span
          style={{
            fontSize: '30px',
            fontStyle: 'italic',
            marginTop: '50px',
            textAlign: 'center',
          }}
        >
          Este material auxilia no processo de superação. Pode ser utilizado com
          adolescentes e adultos.
        </span>
      )}
      <FlexRow>
        <img src={FirstCactus} alt="Cactus" />
        <FlexCol>
          <h2>
            Quantas situações desfavoráveis você viveu até aqui? Quantos
            ambientes secos... áridos você já passou, ou, ainda está passando?
          </h2>
          <TextArea
            rows={5}
            spellCheck={false}
            maxLength={268}
            value={quest1}
            onKeyPress={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
            onChange={(e) => {
              setQuest1(e.target.value);
            }}
            onKeyUp={() => {
              setEmitSocket();
            }}
          />
          <h2>Como você tem se mantido frente a essas situações?</h2>
          <TextArea
            rows={5}
            spellCheck={false}
            maxLength={268}
            value={quest2}
            onKeyPress={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
            onChange={(e) => {
              setQuest2(e.target.value);
            }}
            onKeyUp={() => {
              setEmitSocket();
            }}
          />
        </FlexCol>
      </FlexRow>
      <FlexRow small reverse>
        <TextArea
          small
          rows={5}
          spellCheck={false}
          maxLength={355}
          value={quest3}
          onKeyPress={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
          onChange={(e) => {
            setQuest3(e.target.value);
          }}
          onKeyUp={() => {
            setEmitSocket();
          }}
          right
        />
        <h2>
          Quem você é? O que quer ser? Como conduzirá o seu crescimento e
          evolução enquanto pessoa?
        </h2>
        <img src={FirstCactus} alt="Cactus" />
      </FlexRow>
      <FlexRow small>
        <img src={FirstCactus} alt="Cactus" />
        <h2>Onde você está? Como está? Onde deseja chegar?</h2>
        <TextArea
          small
          rows={5}
          spellCheck={false}
          maxLength={355}
          value={quest4}
          onKeyPress={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
          onChange={(e) => {
            setQuest4(e.target.value);
          }}
          onKeyUp={() => {
            setEmitSocket();
          }}
          left
        />
      </FlexRow>
      <FlexRow small reverse>
        <TextArea
          small
          rows={5}
          spellCheck={false}
          maxLength={355}
          value={quest5}
          onKeyPress={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
          onChange={(e) => {
            setQuest5(e.target.value);
          }}
          onKeyUp={() => {
            setEmitSocket();
          }}
          right
        />
        <h2>
          Como se manter firme como uma flor de cacto, vencendo os extremos do
          clima, do tempo, da aridez e se mantendo linda(o) e vibrante?
        </h2>
        <img src={FirstCactus} alt="Cactus" />
      </FlexRow>
      <FlexRow small>
        <img src={FirstCactus} alt="Cactus" />
        <h2>
          Há um tesouro em meio aos espinhos e, ele nasce no tempo certo. Mas a
          pergunta é: quando florescer? O que você irá fazer com todos esses
          espinhos?
        </h2>
        <TextArea
          small
          rows={5}
          spellCheck={false}
          maxLength={355}
          value={quest6}
          onKeyPress={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
          onChange={(e) => {
            setQuest6(e.target.value);
          }}
          onKeyUp={() => {
            setEmitSocket();
          }}
          left
        />
      </FlexRow>
      {typeUser === 'profissional' && (
        <h2 style={{ marginTop: '50px', textAlign: 'center' }}>
          {PacientName()}
        </h2>
      )}
      {typeUser === 'profissional' && !print && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 50,
          }}
        >
          <PrimaryButton onClick={() => setIsOpen(true)} mobile>
            Salvar
          </PrimaryButton>

          <PrimaryButton onClick={() => setModalCloseIsOpen(true)} mobile>
            Fechar
          </PrimaryButton>
        </div>
      )}
      <ModalAddOps
        generatePdf={generatePdf}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        token={token}
      />
      <ModalAlert
        modalIsOpen={modalIsOpenAlert}
        setIsOpen={setIsOpenAlert}
        token={token}
        props={props}
        title={'A Flor do Cacto'}
        recurso={nome_recurso_cod}
        bt01Txt={'Fechar'}
      />
      <ModalConfirm
        title="Fechar o jogo"
        description="Deseja realmente fechar o jogo?"
        modalIsOpen={modalCloseIsOpen}
        setIsOpen={setModalCloseIsOpen}
        confirmButtonText="Fechar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCloseIsOpen(false)}
        onConfirm={() =>
          handleBackHome(
            modalClose,
            nome_recurso_cod,
            typeUser,
            props,
            os,
            token,
            ambiente
          )
        }
      />
    </Container>
  );
}
