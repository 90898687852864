import React, { useState } from 'react';

import { isMobile } from 'react-device-detect';

/** Imagens */
import { ParkingCars } from '../../../assets';

import {
  Container,
  Tab,
  TabPanel,
  BackgroundCircle,
  MobileContainer,
  MobileTab,
  MobileTabContainer,
} from './styles';

export default function ShowTheWayTabs({ dragUrl, mobileTouchHandler }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {!isMobile && (
        <Container>
          <Tab>
            <BackgroundCircle>
              <img src={ParkingCars[0]} alt={ParkingCars[0]} />
            </BackgroundCircle>
          </Tab>
          <TabPanel>
            {ParkingCars &&
              ParkingCars.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                />
              ))}
          </TabPanel>
        </Container>
      )}

      {isMobile && (
        <MobileContainer>
          <MobileTabContainer isOpen={isOpen}>
            {ParkingCars &&
              ParkingCars.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchEnd={(e) => mobileTouchHandler(e)}
                />
              ))}
          </MobileTabContainer>
          <MobileTab onClick={() => setIsOpen(!isOpen)}>
              <img src={ParkingCars[0]} alt={ParkingCars[0]} />
          </MobileTab>
        </MobileContainer>
      )}
    </>
  );
}
