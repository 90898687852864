import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;

  width: 100%;
  min-height: 90vh;
`;

export const CentralRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-width: 70%;

  h2 {
    font-family: 'Roboto', sans-serif;
    font-size: 56px;
    text-align: center;
    transform: scale(1.05);
    animation: shake infinite 4.5s;

    @keyframes shake {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.05);
      }
      100% {
        transform: scale(1);
      }
    }
  }
`;

export const SaveArea = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export const ButtonClean = styled.button`
  align-items: center;
  outline: none;
  text-align: center;
  background: #333;
  border-radius: 8px;
  color: white;
  padding: 10px;
  margin: 10px;
  border: 0;
  cursor: pointer;
`;

export const ButtonSave = styled.button`
  align-items: center;
  text-align: center;
  background: ${(props) => (props.color ? props.color : '#009900')};
  border-radius: 8px;
  color: white;
  padding: 10px;
  margin: 10px;
  border: 0;
  cursor: pointer;
  outline: none;
`;

export const BoardBox = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  border: 0;
  justify-content: center;
  align-items: center;

  border: 1px solid;
`;

export const DifficultSelection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  h1 {
    font-family: 'Roboto', serif;
    font-size: 40px;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  width: 100%;
  max-width: 1060px;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    max-width: 300px;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }

  cursor: pointer;
`;
