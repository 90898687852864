import React, { useState } from 'react';

import { isMobile } from 'react-device-detect';

/** Imagens */
import {
  PurpleStraights,
  BlueStraights,
  LighBlueStraights,
  YellowStraights,
  GreenStraights,
  RedStraights,
  OrangeStraights,
  PinkStraights,
} from '@assets';

import {
  Container,
  Tab,
  TabPanel,
  BackgroundCircle,
  MobileContainer,
  MobileTab,
  MobileTabContainer,
} from './styles';

export default function LinkThePlanesTabs({ dragUrl, mobileTouchHandler }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {!isMobile && (
        <Container>
          <Tab>
            <BackgroundCircle>
              <img src={PurpleStraights[0]} alt={PurpleStraights[0]} />
            </BackgroundCircle>
          </Tab>
          <TabPanel>
            {BlueStraights &&
              BlueStraights.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                />
              ))}
            {LighBlueStraights &&
              LighBlueStraights.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                />
              ))}
            {GreenStraights &&
              GreenStraights.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                />
              ))}
            {RedStraights &&
              RedStraights.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                />
              ))}
            {YellowStraights &&
              YellowStraights.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                />
              ))}
            {PinkStraights &&
              PinkStraights.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                />
              ))}
            {PurpleStraights &&
              PurpleStraights.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                />
              ))}
            {OrangeStraights &&
              OrangeStraights.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                />
              ))}
          </TabPanel>
        </Container>
      )}
      {isMobile && (
        <MobileContainer>
          <MobileTabContainer isOpen={isOpen}>
            {BlueStraights &&
              BlueStraights.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchEnd={(e) => mobileTouchHandler(e)}
                />
              ))}
            {LighBlueStraights &&
              LighBlueStraights.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchEnd={(e) => mobileTouchHandler(e)}
                />
              ))}
            {GreenStraights &&
              GreenStraights.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchEnd={(e) => mobileTouchHandler(e)}
                />
              ))}
            {RedStraights &&
              RedStraights.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchEnd={(e) => mobileTouchHandler(e)}
                />
              ))}
            {YellowStraights &&
              YellowStraights.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchEnd={(e) => mobileTouchHandler(e)}
                />
              ))}
            {PinkStraights &&
              PinkStraights.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchEnd={(e) => mobileTouchHandler(e)}
                />
              ))}
            {PurpleStraights &&
              PurpleStraights.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchEnd={(e) => mobileTouchHandler(e)}
                />
              ))}
            {OrangeStraights &&
              OrangeStraights.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchEnd={(e) => mobileTouchHandler(e)}
                />
              ))}
          </MobileTabContainer>
          <MobileTab onClick={() => setIsOpen(!isOpen)}>
            <BackgroundCircle>
              <img src={PurpleStraights[0]} alt={PurpleStraights[0]} />
            </BackgroundCircle>
          </MobileTab>
        </MobileContainer>
      )}
    </>
  );
}
