import React, { useState, useRef, useEffect } from 'react';

import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  PacientName,
  DataURL,
} from '@utils';

import { Container, CentralRow, BoardBox, SaveArea } from './styles';

import { ShowTheWayBoard } from '@assets';
import { Stage, Layer, Text } from 'react-konva';
import MountUrlImage from '@components/MountUrlImage';

import ShowTheWayTabs from '@components/ShowTheWay/ShowTheWayTabs';
import {
  ModalAddOps,
  ModalAlert,
  PrimaryButton,
  TertiaryButton,
  ModalConfirm,
} from '@components';

export default function ShowTheWay(props) {
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  const dragUrl = useRef();
  const stageRef = React.useRef();
  const [yourID, setYourID] = useState();
  const [selectedId, selectShape] = useState(null);

  const socketRef = useRef();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCleanIsOpen, setModalCleanIsOpen] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);

  const innerWidthSize = window.innerWidth > 610 ? 610 : window.innerWidth;
  const proportionalStageHeight = (innerWidthSize * 585) / 610;

  const [images, setImages] = React.useState([
    {
      x: 420,
      y: 410,
      src: ShowTheWayBoard,
      height: 555,
      width: 600,
      drag: false,
      preventDefault: false,
    },
  ]);

  const possiblePoints = [
    //Primeira linha
    {
      x: 68.5,
      y: 56,
    },
    {
      x: 163.5,
      y: 56,
    },
    {
      x: 258.5,
      y: 56,
    },
    {
      x: 353.5,
      y: 56,
    },
    {
      x: 448.5,
      y: 56,
    },
    {
      x: 543.5,
      y: 56,
    },
    //Segunda linha
    {
      x: 68.5,
      y: 145,
    },
    {
      x: 163.5,
      y: 145,
    },
    {
      x: 258.5,
      y: 145,
    },
    {
      x: 353.5,
      y: 145,
    },
    {
      x: 448.5,
      y: 145,
    },
    {
      x: 543.5,
      y: 145,
    },
    //Terceira linha
    {
      x: 68.5,
      y: 233,
    },
    {
      x: 163.5,
      y: 233,
    },
    {
      x: 258.5,
      y: 233,
    },
    {
      x: 353.5,
      y: 233,
    },
    {
      x: 448.5,
      y: 233,
    },
    {
      x: 543.5,
      y: 233,
    },
    //Quarta linha
    {
      x: 68.5,
      y: 321,
    },
    {
      x: 163.5,
      y: 321,
    },
    {
      x: 258.5,
      y: 321,
    },
    {
      x: 353.5,
      y: 321,
    },
    {
      x: 448.5,
      y: 321,
    },
    {
      x: 543.5,
      y: 321,
    },
    //Quinta linha
    {
      x: 68.5,
      y: 409,
    },
    {
      x: 163.5,
      y: 409,
    },
    {
      x: 258.5,
      y: 409,
    },
    {
      x: 353.5,
      y: 409,
    },
    {
      x: 448.5,
      y: 409,
    },
    {
      x: 543.5,
      y: 409,
    },
    //Sexta linha
    {
      x: 68.5,
      y: 497,
    },
    {
      x: 163.5,
      y: 497,
    },
    {
      x: 258.5,
      y: 497,
    },
    {
      x: 353.5,
      y: 497,
    },
    {
      x: 448.5,
      y: 497,
    },
    {
      x: 543.5,
      y: 497,
    },
  ];

  const handleExport = () => {
    selectShape(null);
    setTimeout(() => {
      const uri = stageRef.current.toDataURL();
      const nome_paciente = localStorage
        .getItem('@nome_paciente')
        .replaceAll(' ', '_');
      const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
      downloadURI(uri, filename, sendArquivo, os, token, nome_recurso_cod);
    }, 500);
  };

  function sendImage(imagesData) {
    const messageObject = {
      body: { imagesData: imagesData },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  const handleClean = () => {
    setImages([
      {
        x: 420,
        y: 410,
        src: ShowTheWayBoard,
        height: 555,
        width: 600,
        drag: false,
      },
    ]);
    sendImage([
      {
        x: 420,
        y: 410,
        src: ShowTheWayBoard,
        height: 555,
        width: 600,
        drag: false,
      },
    ]);
    setModalCleanIsOpen(false);
  };

  const salvarFim = () => {
    setIsOpen(true);
  };

  function receivedMessage(message) {
    setImages(message.body.imagesData);
  }

  function onDropMobileHandler(e) {
    e.preventDefault();
    if (dragUrl.current !== null) {
      stageRef.current.setPointersPositions(e);
      let Xcoordinate =
        stageRef.current.getPointerPosition().x / getStageXScale();
      let Ycoordinate =
        stageRef.current.getPointerPosition().y / getStageYScale();
      const nameFile = 100000 + Math.floor((999999 - 100000) * Math.random());
      selectShape(nameFile);
      setImages(
        images.concat([
          {
            x: Xcoordinate,
            y: Ycoordinate,
            src: dragUrl.current,
            drag: true,
            alt: nameFile,
          },
        ])
      );
      sendImage(
        images.concat([
          {
            x: Xcoordinate,
            y: Ycoordinate,
            src: dragUrl.current,
            drag: true,
            alt: nameFile,
          },
        ])
      );
    }
  }

  const getStageXScale = () => {
    if (window.innerWidth < 800) {
      return innerWidthSize / 670;
    }
    return innerWidthSize / 610;
  };

  const getStageYScale = () => {
    if (window.innerWidth < 800) {
      return proportionalStageHeight / 655;
    }
    return proportionalStageHeight / 585;
  };

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);

    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, tokenSocketIo);
    }
  }, []);

  return (
    <Container>
      <CentralRow>
        <ShowTheWayTabs
          dragUrl={dragUrl}
          typeUser={typeUser}
          mobileTouchHandler={onDropMobileHandler}
        />

        <div className="alignment">
          <SaveArea>
            <TertiaryButton onClick={() => setModalCleanIsOpen(true)}>
              Limpar
            </TertiaryButton>
            {typeUser === 'profissional' && (
              <PrimaryButton onClick={salvarFim}>Salvar</PrimaryButton>
            )}
            {typeUser === 'profissional' && (
              <PrimaryButton onClick={() => setModalCloseIsOpen(true)}>
                Fechar
              </PrimaryButton>
            )}
            {typeUser === 'profissional' && (
              <PrimaryButton onClick={() => setIsOpenAlert(true)}>
                Instruções
              </PrimaryButton>
            )}
          </SaveArea>
          <BoardBox
            onDrop={(e) => onDropMobileHandler(e)}
            onDragOver={(e) => e.preventDefault()}
          >
            <Stage
              width={innerWidthSize}
              height={proportionalStageHeight}
              scaleX={getStageXScale()}
              scaleY={getStageYScale()}
              onMouseDown={checkDeselect}
              onTouchStart={checkDeselect}
              ref={stageRef}
            >
              <Layer>
                {images.map((image, i) => {
                  return (
                    <MountUrlImage
                      image={image}
                      key={i}
                      shapeProps={image}
                      isSelected={image.alt === selectedId}
                      onSelect={() => {
                        if (image.drag) selectShape(image.alt);
                      }}
                      onChange={(newAttrs) => {
                        const aux = images.slice();
                        aux[i] = newAttrs;
                        setImages(aux);
                        sendImage(aux);
                      }}
                      resizable={false}
                      rotatable={false}
                      height={i === 0 ? null : 76}
                      width={i === 0 ? null : 76}
                      autoFixPoints={possiblePoints}
                    />
                  );
                })}
                {typeUser === 'profissional' && (
                  <Text x={10} y={555} text={PacientName()} fontSize={24} />
                )}
              </Layer>
            </Stage>
          </BoardBox>
        </div>
      </CentralRow>
      <ModalAddOps
        generatePdf={handleExport}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        token={token}
      />
      <ModalAlert
        modalIsOpen={modalIsOpenAlert}
        setIsOpen={setIsOpenAlert}
        token={token}
        props={props}
        title={'Mostre o caminho'}
        recurso={nome_recurso_cod}
        bt01Txt={'Fechar'}
      />
      <ModalConfirm
        title="Limpar o jogo"
        description="Deseja realmente limpar o jogo?"
        modalIsOpen={modalCleanIsOpen}
        setIsOpen={setModalCleanIsOpen}
        confirmButtonText="Limpar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCleanIsOpen(false)}
        onConfirm={() => handleClean()}
      />
      <ModalConfirm
        title="Fechar o jogo"
        description="Deseja realmente fechar o jogo?"
        modalIsOpen={modalCloseIsOpen}
        setIsOpen={setModalCloseIsOpen}
        confirmButtonText="Fechar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCloseIsOpen(false)}
        onConfirm={() =>
          handleBackHome(
            modalClose,
            nome_recurso_cod,
            typeUser,
            props,
            os,
            token,
            ambiente
          )
        }
      />
    </Container>
  );
}
