/* eslint-disable no-unused-expressions */
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background: #fff;
  flex-direction: column;

  /* @fontface {
    font-family: 'Bungee Inline', cursive;
    src: url('https://fonts.googleapis.com/css?family=Bungee+Inline');
  } */

  h1 {
    font-family: 'Bungee Inline', cursive;
    color: #333;
    font-size: 22px;
    text-align: left;
    
    @media(max-width: 768px) {
      margin-top: 40px;
    }
  }
`;

export const TextArea = styled.textarea`
  display: flex;
  text-decoration: none;
  border: 1px solid #333;
  text-align: left;
  resize: none;
  background: transparent;
  font-size: 20px;
  height: 200px;
  overflow: hidden;
  margin-bottom: 40px;
`;

export const TitleContainer = styled.div`
  width: 100%;
  justify-content: left;
  align-items: left;
`;
