/* eslint-disable no-unused-vars */
import React from 'react';

// Images import
import { ProblemSizeImages } from '@assets';

import {
  Container,
  Wrapper,
  TermometroContainer,
  TermometroView,
  TermometroViewColor,
  EmotionsDefaultContainer,
  ColorButton,
  Row,
  ProblemSizeFieldWrapper,
} from './styles';

import { ProblemSizeField, TertiaryButton, PrimaryButton } from '@components';

const Page01 = ({
  page,
  setPage,
  whatProblem,
  setWhatProblem,
  whatActitude,
  setWhatActitude,
  problemDescription,
  setProblemDescription,
  bar,
  setBar,
  setEmitSocket,
  modalClose,
  typeUser,
  sizeProblem,
  sizeReact,
  emotionalSize,
}) => {
  return (
    <>
      <Container>
        <Wrapper>
          <ProblemSizeFieldWrapper whatProblem>
            <ProblemSizeField
              title="Qual é o seu problema?"
              rotation="none"
              size="md"
              data={whatProblem}
              setData={setWhatProblem}
              setEmitSocket={setEmitSocket}
              bar={bar}
              page={page}
              sizeProblem={sizeProblem}
              sizeReact={sizeReact}
              emotionalSize={emotionalSize}
            />
          </ProblemSizeFieldWrapper>

          <ProblemSizeFieldWrapper thermometer>
            <TermometroContainer>
              <TermometroView>
                <img alt={'1'} src={ProblemSizeImages[1]} />
              </TermometroView>
              <TermometroViewColor clip={bar}>
                <img alt={'2'} src={ProblemSizeImages[2]} />
              </TermometroViewColor>
            </TermometroContainer>
          </ProblemSizeFieldWrapper>

          <ProblemSizeFieldWrapper whatAttitude>
            <ProblemSizeField
              title="Qual foi a sua atitude?"
              rotation="none"
              size="md"
              data={whatActitude}
              setData={setWhatActitude}
              setEmitSocket={setEmitSocket}
              bar={bar}
              page={page}
              sizeProblem={sizeProblem}
              sizeReact={sizeReact}
              emotionalSize={emotionalSize}
            />
          </ProblemSizeFieldWrapper>
        </Wrapper>

        <Row>
          <EmotionsDefaultContainer
            isSelected={bar === 61 || false}
            onClick={() => setBar(61)}
          >
            <ColorButton color="#32ddff" isSelected={bar === 61 || false} />
            <p>Calmo / Feliz</p>
          </EmotionsDefaultContainer>

          <EmotionsDefaultContainer
            isSelected={bar === 46 || false}
            onClick={() => setBar(46)}
          >
            <ColorButton color="#24d55f" isSelected={bar === 46 || false} />
            <p>Tranquilo</p>
          </EmotionsDefaultContainer>

          <EmotionsDefaultContainer
            isSelected={bar === 31 || false}
            onClick={() => setBar(31)}
          >
            <ColorButton color="#ffcf03" isSelected={bar === 31 || false} />
            <p>Nervoso / Irritado / Preocupado</p>
          </EmotionsDefaultContainer>

          <EmotionsDefaultContainer
            isSelected={bar === 16 || false}
            onClick={() => setBar(16)}
          >
            <ColorButton color="#ffa500" isSelected={bar === 16 || false} />
            <p>Chateado / Frustrado / Fora do Controle</p>
          </EmotionsDefaultContainer>

          <EmotionsDefaultContainer
            isSelected={bar === 0 || false}
            onClick={() => setBar(0)}
          >
            <ColorButton color="#f12b09" isSelected={bar === 0 || false} />
            <p>Zangado / Chorando / Gritando</p>
          </EmotionsDefaultContainer>
        </Row>

        <div>
          <ProblemSizeField
            title="Descreva o Problema:"
            rotation="none"
            titleBlock={true}
            size="lg"
            data={problemDescription}
            setData={setProblemDescription}
            setEmitSocket={setEmitSocket}
            bar={bar}
            page={page}
            sizeProblem={sizeProblem}
            sizeReact={sizeReact}
            emotionalSize={emotionalSize}
          />
        </div>

        {typeUser === 'profissional' && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {page > 0 && (
              <TertiaryButton
                style={{ marginTop: 30 }}
                onClick={() => setPage(2)}
                problemSize
              >
                Continuar
              </TertiaryButton>
            )}

            {page > 0 && typeUser === 'profissional' && (
              <PrimaryButton
                style={{ marginTop: 30 }}
                onClick={() => modalClose()}
                problemSize
              >
                Fechar
              </PrimaryButton>
            )}
          </div>
        )}
      </Container>
    </>
  );
};

export default Page01;
