import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { x_icon } from '../../assets';

import {
  ModalReadMore,
  ModalStartResource,
  ModalAlert,
} from '../../components';

import { getListRecursos } from '../../services/apiService';

/** CSS */
import {
  Container,
  Box,
  SearchBox,
  InputContainer,
  InputField,
  ButtonSave,
  CardContainer,
} from './styles';

/** Imagens */
import { GoSearch } from 'react-icons/go';
import { LapisGrey } from '../../assets';

function Resources(props) {
  // url related variables
  const params = useParams();
  const url = props.location.pathname.split('/');
  const urldebug =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjZlODM0ODQ4LWRlY2ItNDQ5MS1hNjRkLTllNDgyNjAxOTliZCIsImlhdCI6MTYxMjc5MTUwMiwiZXhwIjoxOTI4MzY3NTAyfQ.mcS6GKpGWw7YWjzEBxvEQYlPNq9gunRKe1Jswe__5vw';
  const os = params.os || 'terapiaplay';
  const token = params.token || urldebug;
  const ambiente = url[6] || '';

  // when to turn card logic
  const [selectedId, setSelectedId] = useState(-1);

  // rand socket for ModalStartResource
  const rand = () => Math.random(0).toString(36).substr(2);
  const tokenU = (length) =>
    (rand() + rand() + rand() + rand()).substr(0, length);
  const tokenSocket = tokenU(10);

  // modal related variables
  const [modalIsOpenAlert, setIsOpenAlert] = useState(
    ambiente === 'salavirtual' ? false : true
  );
  const [readMoreModal, setReadMoreModal] = useState(false);
  const [readMoreContent, setReadMoreContent] = useState({});
  const [startResourceContent, setStartResourceContent] = useState({});
  const [startResourceModal, setStartResourceModal] = useState(false);

  const [jogos, setJogos] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const listRecursos = async () => {
    await getListRecursos(os, token)
      .then((resp) => {
        setJogos(resp.data.records);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const searchData = () => {
    const results = jogos.filter((j) =>
      j.nome_recurso.toLowerCase().includes(searchTerm)
    );
    setSearchResults(results);
  };

  const modalClose = () => {
    if (window && window.parent) {
      window.parent.postMessage(
        {
          action: 'fechar',
        },
        '*'
      );
    }
  };

  const handleBackHome = () => {
    const r = window.confirm('Gostaria realmente de encerrar os recursos?');
    if (r === true) {
      modalClose();
    }
  };

  useEffect(() => {
    listRecursos();
    document.title = 'Recursos';
  }, []);

  useEffect(() => {
    searchData();
  }, [jogos, searchTerm]);

  return (
    <>
      <Container
        backgroundColor={ambiente === 'salavirtual' ? '#3D3D3D' : '#ffffff'}
      >
        <SearchBox>
          <InputContainer>
            <GoSearch size={26} color={'#AEB9BF'} />
            <InputField
              value={searchTerm}
              spellCheck={false}
              onChange={handleChange}
              placeholder="Buscar Recurso"
            />
          </InputContainer>
          <ButtonSave color={'#ff0000'} onClick={handleBackHome}>
            {ambiente === 'salavirtual' && (
              <>
                <button>Fechar</button>
                <img src={x_icon} alt="x icon" />
              </>
            )}
          </ButtonSave>
          <ButtonSave color={'#ff0000'}>
            {ambiente !== 'salavirtual' && (
              <>
                <a href={`/${os}/${token}`}>Voltar</a>
                <img src={x_icon} alt="x icon" />
              </>
            )}
          </ButtonSave>
        </SearchBox>

        <CardContainer>
          {searchResults.length > 0 &&
            searchResults.map((val, index) => {
              return (
                <React.Fragment key={index}>
                  {val.jogo_recurso === '1' && (
                    <Box status={val.status}>
                      <div
                        className="card"
                        onClick={() => setSelectedId(index)}
                      >
                        <div
                          className="content"
                          style={
                            selectedId === index
                              ? {
                                  transform: 'rotateY(180deg)',
                                  transition: ' transform 0.5s',
                                }
                              : {}
                          }
                        >
                          <div status={val.status} className="front">
                            <img
                              style={
                                val.status === '1'
                                  ? { filter: 'none', marginTop: 20 }
                                  : { filter: 'grayscale(100%)', marginTop: 20 }
                              }
                              alt="Icon"
                              width="88px"
                              src={val.arquivo ? val.arquivo : LapisGrey}
                            />
                            <div status={val.status} className={'title'}>
                              {val.nome_recurso}
                            </div>
                            {val.status === '0' && (
                              <div className={'boxprice'}>
                                Disponível a partir do plano Intermediário
                              </div>
                            )}
                          </div>
                          <div status={val.status} className="back">
                            <p className={'title'}>{val.nome_recurso}</p>
                            <p className={'description'}>
                              {val.desc_recurso_menor}
                            </p>
                            <div className={'button'}>
                              <div
                                className={'white'}
                                onClick={() => {
                                  setReadMoreContent(val);
                                  setReadMoreModal(true);
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                Leia mais
                              </div>
                              {val.status && (
                                <div
                                  className={'blue'}
                                  onClick={() => {
                                    setStartResourceContent(val);
                                    setStartResourceModal(true);
                                  }}
                                >
                                  Iniciar recurso
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Box>
                  )}
                </React.Fragment>
              );
            })}
        </CardContainer>
        <ModalAlert
          modalIsOpen={modalIsOpenAlert}
          setIsOpen={setIsOpenAlert}
          token={token}
          props={props}
          title={'Saiba mais'}
          type={'os'}
          bt01Txt={'Continuar apenas nos recursos'}
        />
        <ModalReadMore
          modalIsOpen={readMoreModal}
          setIsOpen={setReadMoreModal}
          content={readMoreContent}
          os={os}
          token={token}
          ambiente={ambiente}
          setStartResourceContent={setStartResourceContent}
          setStartResourceModal={setStartResourceModal}
        />
      </Container>
      <ModalStartResource
        isOpen={startResourceModal}
        setIsOpen={setStartResourceModal}
        content={startResourceContent}
        os={os}
        token={token}
        ambiente={ambiente}
        tokenSocket={tokenSocket}
      />
    </>
  );
}

export default Resources;
