import { Value, SubTitle } from './styles';

function WheelValues({ valueName, index, setValue }) {
  return (
    <div className="area">
      <SubTitle>{valueName}</SubTitle>
      <div className="values">
        <Value onClick={() => setValue(index, 1)}>1</Value>
        <Value onClick={() => setValue(index, 2)}>2</Value>
        <Value onClick={() => setValue(index, 3)}>3</Value>
        <Value onClick={() => setValue(index, 4)}>4</Value>
        <Value onClick={() => setValue(index, 5)}>5</Value>
        <Value onClick={() => setValue(index, 6)}>6</Value>
        <Value onClick={() => setValue(index, 7)}>7</Value>
        <Value onClick={() => setValue(index, 8)}>8</Value>
        <Value onClick={() => setValue(index, 9)}>9</Value>
        <Value onClick={() => setValue(index, 10)}>10</Value>
      </div>
    </div>
  );
}

export default WheelValues;
