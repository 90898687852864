import styled from 'styled-components';

export const Title = styled.h1`
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 76px;
  color: #363435;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
`;

export const Areas = styled.div`
  text-align: center;
  margin-bottom: 15px;
  min-height: 95px;

  h3 {
    margin-top: 0;
  }

  @media (max-width: 786px) {
    margin-bottom: 45px;
  }
`;

export const SaveArea = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: -20px;

  @media (max-width: 786px) {
    flex-wrap: wrap;
  }
`;
