import React, { useState, useRef, useEffect } from 'react';

import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  PacientName,
  DataURL,
} from '@utils';

import domtoimage from 'dom-to-image';

import { MusicNotes } from '@assets';

import { Container, CentralContainer, FormTitle, Row } from './styles';

import {
  AMAField,
  ModalAddOps,
  ModalAlert,
  PrimaryButton,
  ModalConfirm,
} from '@components';

export default function ProfessionalForm(props) {
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  const [animals, setAnimals] = useState('');
  const [musics, setMusics] = useState('');
  const [amulets, setAmulets] = useState('');
  const [experience, setExperience] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);
  const [print, setPrint] = useState(false);

  const [yourID, setYourID] = useState();

  const socketRef = useRef();

  function receivedMessage(message) {
    setAnimals(message.body.animals);
    setMusics(message.body.musics);
    setAmulets(message.body.amulets);
    setExperience(message.body.experience);
  }

  function setEmitSocket() {
    const messageObject = {
      body: {
        animals,
        musics,
        amulets,
        experience,
      },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  const generatePdf = () => {
    setPrint(true);
    domtoimage
      .toPng(document.getElementById('divIdToPrint'))
      .then(function (dataUrl) {
        const nome_paciente = localStorage
          .getItem('@nome_paciente')
          .replaceAll(' ', '_');
        const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
        downloadURI(
          dataUrl,
          filename,
          sendArquivo,
          os,
          token,
          nome_recurso_cod
        );

        setPrint(false);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  };

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);
    modalOpen(nome_recurso_cod, tokenSocketIo);
  }, []);

  return (
    <Container id="divIdToPrint" className="containerr">
      <CentralContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FormTitle>
            <span>A.M.A</span>
            <h1>Animal, Música e Amuleto</h1>
            {typeUser === 'profissional' && (
              <Row professional>
                <h3>{PacientName()}</h3>
                {!print && (
                  <Row instructions>
                    <PrimaryButton onClick={() => setIsOpenAlert(true)}>
                      Instruções
                    </PrimaryButton>
                    <PrimaryButton onClick={() => setModalCloseIsOpen(true)}>
                      Fechar
                    </PrimaryButton>
                    <PrimaryButton onClick={() => setIsOpen(true)}>
                      Salvar
                    </PrimaryButton>
                  </Row>
                )}
              </Row>
            )}
          </FormTitle>
        </div>
        <Row>
          <AMAField
            title="Animais"
            rotation="right"
            size="sm"
            data={animals}
            setData={setAnimals}
            setEmitSocket={setEmitSocket}
            setFocusedField={() => {}}
          />
          <AMAField
            title="Músicas"
            rotation="left"
            size="sm"
            data={musics}
            setData={setMusics}
            setEmitSocket={setEmitSocket}
            setFocusedField={() => {}}
          />
        </Row>
        <Row>
          <AMAField
            title="Amuletos"
            rotation="none"
            size="sm"
            data={amulets}
            setData={setAmulets}
            setEmitSocket={setEmitSocket}
            setFocusedField={() => {}}
          />
          <img src={MusicNotes} alt="Notas musicais" />
        </Row>
        <Row>
          <AMAField
            innerTitle="Como foi sua experiência?"
            rotation="none"
            size="md"
            data={experience}
            setData={setExperience}
            setEmitSocket={setEmitSocket}
            setFocusedField={() => {}}
          />
        </Row>
      </CentralContainer>
      <ModalAddOps
        generatePdf={generatePdf}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        token={token}
      />
      <ModalAlert
        modalIsOpen={modalIsOpenAlert}
        setIsOpen={setIsOpenAlert}
        token={token}
        props={props}
        title={'Animal, Música e Amuleto'}
        recurso={nome_recurso_cod}
        bt01Txt={'Fechar'}
      />
      <ModalConfirm
        title="Fechar o jogo"
        description="Deseja realmente fechar o jogo?"
        modalIsOpen={modalCloseIsOpen}
        setIsOpen={setModalCloseIsOpen}
        confirmButtonText="Fechar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCloseIsOpen(false)}
        onConfirm={() =>
          handleBackHome(
            modalClose,
            nome_recurso_cod,
            typeUser,
            props,
            os,
            token,
            ambiente
          )
        }
      />
    </Container>
  );
}
