/* eslint-disable no-unused-vars */
import React from 'react';

// Images import
import { ProblemSizeImages } from '@assets';

import {
  Container,
  BoxNumber,
  BoxContainer,
  BoxViewGrey,
  BoxViewColor,
  NumberButton,
  NumberContainer,
  NumberWrapper,
} from './styles';

import { Row, Col } from 'react-flexbox-grid';

import { ProblemSizeField, PrimaryButton, TertiaryButton } from '@components';

const Page02 = ({
  page,
  setPage,
  sizeProblem,
  setSizeProblem,
  problemReact,
  setProblemReact,
  setEmitSocket,
  modalClose,
  typeUser,
  bar,
  sizeReact,
  emotionalSize,
}) => {
  return (
    <>
      <Container className={'center-items'}>
        <Row
          bottom="xs"
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            marginTop: '15px',
          }}
        >
          <Col xs={3} sm={3} md={3} lg={3}>
            <BoxContainer>
              <BoxViewGrey>
                <img alt={'6'} width={'100%'} src={ProblemSizeImages[6]} />
              </BoxViewGrey>

              <BoxViewColor clip={sizeProblem <= 4 ? 0 : 100}>
                <img alt={'3'} width={'100%'} src={ProblemSizeImages[3]} />
              </BoxViewColor>
            </BoxContainer>
          </Col>

          <Col xs={3} sm={3} md={3} lg={3} style={{ marginLeft: 30 }}>
            <BoxContainer>
              <BoxViewGrey>
                <img alt={'7'} width={'80%'} src={ProblemSizeImages[7]} />
              </BoxViewGrey>
              <BoxViewColor
                clip={sizeProblem > 4 && sizeProblem <= 7 ? 0 : 100}
                width={80}
              >
                <img alt={'4'} width={'80%'} src={ProblemSizeImages[4]} />
              </BoxViewColor>
            </BoxContainer>
          </Col>

          <Col xs={3} sm={3} md={3} lg={3} style={{ marginLeft: -25 }}>
            <BoxContainer>
              <BoxViewGrey>
                <img alt={'8'} width={'83%'} src={ProblemSizeImages[8]} />
              </BoxViewGrey>
              <BoxViewColor clip={sizeProblem > 7 ? 0 : 100} width={83}>
                <img alt={'5'} width={'83%'} src={ProblemSizeImages[5]} />
              </BoxViewColor>
            </BoxContainer>
          </Col>
        </Row>

        <NumberWrapper>
          <BoxNumber>
            <p style={{ marginTop: 0, fontWeight: '700' }}>
              Quão grande é o seu problema?
            </p>
          </BoxNumber>
          <NumberContainer page>
            <NumberButton
              color="#85ecff"
              onClick={() => {
                setSizeProblem(1);
              }}
              isSelected={sizeProblem === 1}
            >
              <h1>1</h1>
            </NumberButton>
            <NumberButton
              color="#01afd2"
              onClick={() => {
                setSizeProblem(2);
              }}
              isSelected={sizeProblem === 2}
            >
              <h1>2</h1>
            </NumberButton>
            <NumberButton
              color="#83f5a8"
              onClick={() => {
                setSizeProblem(3);
              }}
              isSelected={sizeProblem === 3}
            >
              <h1>3</h1>
            </NumberButton>
            <NumberButton
              color="#24d560"
              onClick={() => {
                setSizeProblem(4);
              }}
              isSelected={sizeProblem === 4}
            >
              <h1>4</h1>
            </NumberButton>
            <NumberButton
              color="#ffe46f"
              onClick={() => {
                setSizeProblem(5);
              }}
              isSelected={sizeProblem === 5}
            >
              <h1>5</h1>
            </NumberButton>
            <NumberButton
              color="#fec55a"
              onClick={() => {
                setSizeProblem(6);
              }}
              isSelected={sizeProblem === 6}
            >
              <h1>6</h1>
            </NumberButton>
            <NumberButton
              color="#feab5b"
              onClick={() => {
                setSizeProblem(7);
              }}
              isSelected={sizeProblem === 7}
            >
              <h1>7</h1>
            </NumberButton>
            <NumberButton
              color="#ff8371"
              onClick={() => {
                setSizeProblem(8);
              }}
              isSelected={sizeProblem === 8}
            >
              <h1>8</h1>
            </NumberButton>
            <NumberButton
              color="#f22a0c"
              onClick={() => {
                setSizeProblem(9);
              }}
              isSelected={sizeProblem === 9}
            >
              <h1>9</h1>
            </NumberButton>
            <NumberButton
              color="#a31800"
              onClick={() => {
                setSizeProblem(10);
              }}
              isSelected={sizeProblem === 10}
            >
              <h1>10</h1>
            </NumberButton>
          </NumberContainer>
        </NumberWrapper>

        <div>
          <ProblemSizeField
            title="Descreva sua reação física e emocional:"
            rotation="none"
            titleBlock
            size="lg"
            data={problemReact}
            setData={setProblemReact}
            setEmitSocket={setEmitSocket}
            bar={bar}
            page={page}
            sizeProblem={sizeProblem}
            sizeReact={sizeReact}
            emotionalSize={emotionalSize}
          />
        </div>

        {page > 0 && typeUser === 'profissional' && (
          <div
            style={{
              width: '100%',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'row',
              marginTop: 40,
            }}
          >
            <TertiaryButton onClick={() => setPage(1)} problemSize>
              Voltar
            </TertiaryButton>

            <TertiaryButton onClick={() => setPage(3)} problemSize>
              Continuar
            </TertiaryButton>
          </div>
        )}
        {page > 0 && typeUser === 'profissional' && (
          <div
            style={{
              width: '100%',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'row',
              marginTop: 25,
            }}
          >
            <PrimaryButton onClick={() => modalClose()} problemSize>
              Fechar
            </PrimaryButton>
          </div>
        )}
      </Container>
    </>
  );
};

export default Page02;
