import io from 'socket.io-client';
import info from '../../package.json';
import { sendRecurso, getListRecursos } from '../services/apiService';

/* ABRE O JOGO PARA O PACIENTE */
export const modalOpen = (nome_recurso_cod, tokenSocketIo) => {
  // console.log(nome_recurso_cod, tokenSocketIo);
  if (window && window.parent) {
    window.parent.postMessage(
      {
        action: 'abrir',
        recurso: `${nome_recurso_cod}`,
        ambiente: 'paciente',
        token: `${tokenSocketIo}`,
      },
      '*'
    );
  }
};

/* FECHA O JOGO PARA O PACIENTE */
export const modalClose = (
  nome_recurso_cod,
  typeUser,
  props,
  os,
  token,
  ambiente
) => {
  //console.log(nome_recurso_cod, typeUser, props, os, token, ambiente);
  if (window && window.parent) {
    window.parent.postMessage(
      {
        action: 'fechar',
        recurso: `${nome_recurso_cod}`,
        ambiente: `${typeUser}`,
      },
      '*'
    );
    if (typeUser === 'profissional') {
      props.history.push(`/${os}/${token}/${ambiente}`);
    }
  }
};

/* FECHAMENTO DO JOGO */
export const handleBackHome = (
  modalClose,
  nome_recurso_cod,
  typeUser,
  props,
  os,
  token,
  ambiente
) => {
  if (typeUser === 'profissional') {
    modalClose(nome_recurso_cod, typeUser, props, os, token, ambiente);
  }
};

/* ENVIA OS DADOS DO JOGO PARA O SERVIDOR */
export const sendArquivo = async (uri, os, token, nome_recurso_cod) => {
  const tokenUser = localStorage.getItem('@token');
  const cod_sessao = localStorage.getItem('@cod_sessao');
  const cod_paciente = localStorage.getItem('@cod_paciente');
  const obs = localStorage.getItem('@obs');

  const cod_recurso = await getListRecursos(os, token)
    .then((resp) => {
      const jogo = resp.data.records.find(
        (rec) => rec.link === nome_recurso_cod
      );
      return jogo.cod_recurso;
    })
    .catch((err) => {
      console.log(err);
    });

  const obj = {
    tkn: tokenUser,
    cod_sessao,
    cod_paciente,
    cod_recurso,
    obs,
    arquivo: uri,
  };

  await sendRecurso(os, obj)
    .then((resp) => {
      if (resp?.data?.records?.cod_sessao)
        localStorage.setItem('@cod_sessao', resp.data.records.cod_sessao);

      alert(resp.data.records.msg);
    })
    .catch((err) => {
      alert(err.data.records.msg);
    });
};

export const startSocket = (
  socketRef,
  setYourID,
  receivedMessage,
  tokenSocketIo,
  typeUser
) => {
  socketRef.current = io(
    info.statusAPP === 'debug' ? info.ENDPOINTDEBUG : info.ENDPOINTRELEASE
  );

  socketRef.current.on('your id', async (id) => {
    await setYourID(id);
    console.log(id);
  });

  socketRef.current.emit('create', tokenSocketIo);

  socketRef.current.on('message', (message) => {
    if (message.typeUser !== typeUser) {
      setTimeout(() => {
        receivedMessage(message);
      }, 500);
    }
  });
};

export const downloadURI = (
  uri,
  filename,
  sendArquivo,
  os,
  token,
  nome_recurso_cod
) => {
  const link = document.createElement('a');
  link.download = filename;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  sendArquivo(uri, os, token, nome_recurso_cod);
};

export const PacientName = () => {
  const nome_paciente_local =
    localStorage.getItem('@nome_paciente') !== 'undefined'
      ? localStorage.getItem('@nome_paciente')
      : 'sem paciente selecionado';
  return `Paciente: ${nome_paciente_local} - ${new Date().toLocaleDateString()}`;
};

export const DataURL = (props) => {
  const url = props.location.pathname.split('/');

  console.log('url em DataURL', url)

  const aux = {
    nome_recurso_cod: url[1],
    typeUser: url[2],
    os: url[3],
    token: url[4],
    tokenSocketIo: url[2] === 'profissional' ? url[5] : url[4],
    ambiente: url[6] || '',
  };

  return aux;
};
