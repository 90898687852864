import React, { useState, useRef, useEffect } from 'react';

import { isMobile } from 'react-device-detect';

import {
  Container,
  SaveArea,
  RostoBox,
  DiceDiv,
  Wrapper,
  DiceContainer,
} from './styles';

import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  PacientName,
  DataURL,
} from '@utils';

import { Rosto, Rostos } from '@assets';

import MountTheEmotionTabs from '@components/MountTheEmotionTabs';
import MountUrlImage from '@components/MountUrlImage';

import {
  ModalAddOps,
  ModalAlert,
  PrimaryButton,
  TertiaryButton,
  ModalConfirm,
} from '@components';

import { Stage, Layer, Text } from 'react-konva';

export default function MountTheEmotion(props) {
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  //Drag'n Drop variables
  const innerWidthSize = window.innerWidth > 630 ? 630 : window.innerWidth;
  const proportionalStageHeight = (innerWidthSize * 555) / 630;
  const scaleX = innerWidthSize / 630;
  const scaleY = proportionalStageHeight / 555;
  const dragUrl = useRef();
  const stageRef = React.useRef();
  const [activeEmotion, setActiveEmotion] = useState(0);
  const [yourID, setYourID] = useState();
  const [selectedId, selectShape] = useState(null);

  const [images, setImages] = React.useState([
    {
      x: 320,
      y: 270,
      src: Rosto,
      drag: false,
      preventDefault: false,
    },
  ]);
  const socketRef = useRef();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCleanIsOpen, setModalCleanIsOpen] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);

  const handleExport = () => {
    selectShape(null);
    setTimeout(() => {
      const uri = stageRef.current.toDataURL();
      const nome_paciente = localStorage
        .getItem('@nome_paciente')
        .replaceAll(' ', '_');
      const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
      downloadURI(uri, filename, sendArquivo, os, token, nome_recurso_cod);
    }, 500);
  };

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  function receivedMessage(message) {
    !!message.body.imagesData // se houver o array de imagens
      ? setImages(message.body.imagesData) //seta as imagens
      : setActiveEmotion(message.body.diceState); //caso contrario, só pode ser o estado do dado que deve ser setado
  }

  function sendImage(imagesData) {
    const messageObject = {
      body: { imagesData: imagesData },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  function sendDiceState(diceState) {
    const messageObject = {
      body: { diceState: diceState },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  const handleClean = () => {
    setImages([
      {
        x: 320,
        y: 270,
        src: Rosto,
        drag: false,
      },
    ]);
    sendImage([
      {
        x: 320,
        y: 270,
        src: Rosto,
        drag: false,
      },
    ]);
    setModalCleanIsOpen(false);
  };

  const salvarFim = () => {
    setIsOpen(true);
  };

  function onDropMobileHandler(e) {
    e.preventDefault();
    if (dragUrl.current !== null) {
      stageRef.current.setPointersPositions(e);
      let Xcoordinate = stageRef.current.getPointerPosition().x / scaleX;
      let Ycoordinate = stageRef.current.getPointerPosition().y / scaleY;
      const nameFile = 100000 + Math.floor((999999 - 100000) * Math.random());
      selectShape(nameFile);
      setImages(
        images.concat([
          {
            x: Xcoordinate,
            y: Ycoordinate,
            src: dragUrl.current,
            drag: true,
            alt: nameFile,
          },
        ])
      );
      sendImage(
        images.concat([
          {
            x: Xcoordinate,
            y: Ycoordinate,
            src: dragUrl.current,
            drag: true,
            alt: nameFile,
          },
        ])
      );
    }
  }

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);

    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, tokenSocketIo);
    }
  }, []);

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  const diceSortAnimation = (loopIndex) => {
    //função recursiva para sortear o dado
    if (typeUser !== 'profissional') return;

    setTimeout(() => {
      let rand = getRandomInt(0, 5);
      setActiveEmotion(rand);
      sendDiceState(rand);
      if (loopIndex <= 6) {
        diceSortAnimation(loopIndex + 1);
      }
    }, 70);
  };

  return (
    <Container>
      <Wrapper>
        <MountTheEmotionTabs
          dragUrl={dragUrl}
          mobileTouchHandler={onDropMobileHandler}
        />
        <div
          style={{
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <SaveArea>
            <TertiaryButton onClick={() => setModalCleanIsOpen(true)}>
              Limpar
            </TertiaryButton>
            {typeUser === 'profissional' && (
              <PrimaryButton onClick={() => setIsOpenAlert(true)}>
                Instruções
              </PrimaryButton>
            )}
            {typeUser === 'profissional' && (
              <PrimaryButton onClick={salvarFim}>Salvar</PrimaryButton>
            )}
            {typeUser === 'profissional' && (
              <PrimaryButton onClick={() => setModalCloseIsOpen(true)}>
                Fechar
              </PrimaryButton>
            )}
          </SaveArea>
          <RostoBox
            className={'containerr'}
            onDrop={(e) => onDropMobileHandler(e)}
            onDragOver={(e) => e.preventDefault()}
          >
            <Stage
              width={innerWidthSize}
              height={proportionalStageHeight}
              scaleX={scaleX}
              scaleY={scaleY}
              onMouseDown={checkDeselect}
              onTouchStart={checkDeselect}
              ref={stageRef}
            >
              <Layer>
                {images.map((image, i) => {
                  return (
                    <MountUrlImage
                      image={image}
                      key={i}
                      shapeProps={image}
                      isSelected={image.alt === selectedId}
                      onSelect={() => {
                        if (image.drag) selectShape(image.alt);
                      }}
                      onChange={(newAttrs) => {
                        const aux = images.slice();
                        aux[i] = newAttrs;
                        setImages(aux);
                        sendImage(aux);
                      }}
                      width={i !== 0 ? 250 : undefined}
                      height={i !== 0 ? 140 : undefined}
                      resizable={false}
                      rotatable={false}
                    />
                  );
                })}
                {typeUser === 'profissional' && !isMobile && (
                  <Text x={10} y={15} text={PacientName()} fontSize={24} />
                )}
              </Layer>
            </Stage>
          </RostoBox>
        </div>

        <DiceContainer>
          {typeUser === 'profissional' && (
            <h3
              style={{
                width: '150px',
                textAlign: 'center',
                fontFamily: 'Roboto',
              }}
            >
              Clique no quadrado abaixo para sortear uma nova emoção.
            </h3>
          )}
          <DiceDiv
            background={Rostos[activeEmotion]}
            onClick={() => diceSortAnimation(0)}
            onTouchStart={() => diceSortAnimation(0)}
          />
        </DiceContainer>
        {typeUser === 'profissional' && isMobile && (
          <h2 fontSize="24px">{PacientName()}</h2>
        )}
      </Wrapper>
      <ModalAddOps
        generatePdf={handleExport}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        token={token}
      />
      <ModalAlert
        modalIsOpen={modalIsOpenAlert}
        setIsOpen={setIsOpenAlert}
        token={token}
        props={props}
        title={'Monte a emoção'}
        recurso={nome_recurso_cod}
        bt01Txt={'Fechar'}
      />
      <ModalConfirm
        title="Limpar o jogo"
        description="Deseja realmente limpar o jogo?"
        modalIsOpen={modalCleanIsOpen}
        setIsOpen={setModalCleanIsOpen}
        confirmButtonText="Limpar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCleanIsOpen(false)}
        onConfirm={() => handleClean()}
      />
      <ModalConfirm
        title="Fechar o jogo"
        description="Deseja realmente fechar o jogo?"
        modalIsOpen={modalCloseIsOpen}
        setIsOpen={setModalCloseIsOpen}
        confirmButtonText="Fechar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCloseIsOpen(false)}
        onConfirm={() =>
          handleBackHome(
            modalClose,
            nome_recurso_cod,
            typeUser,
            props,
            os,
            token,
            ambiente
          )
        }
      />
    </Container>
  );
}
