import { ColorPickerWrapper, Color } from './styles';

function ColorPicker({ setColor }) {
  return (
    <ColorPickerWrapper>
      <Color
        style={{ backgroundColor: '#FFFFFF' }}
        onClick={(e) => setColor('#FFFFFF')}
      />
      <Color
        style={{ backgroundColor: '#000000' }}
        onClick={(e) => setColor('#000000')}
      />
      <Color
        style={{ backgroundColor: '#CCCCCC' }}
        onClick={(e) => setColor('#CCCCCC')}
      />
      <Color
        style={{ backgroundColor: '#666666' }}
        onClick={(e) => setColor('#666666')}
      />
      <Color
        style={{ backgroundColor: '#FF0000' }}
        onClick={(e) => setColor('#FF0000')}
      />
      <Color
        style={{ backgroundColor: '#8B0000' }}
        onClick={(e) => setColor('#8B0000')}
      />
      <Color
        style={{ backgroundColor: '#00FF00' }}
        onClick={(e) => setColor('#00FF00')}
      />
      <Color
        style={{ backgroundColor: '#008B00' }}
        onClick={(e) => setColor('#008B00')}
      />
      <Color
        style={{ backgroundColor: '#0000FF' }}
        onClick={(e) => setColor('#0000FF')}
      />
      <Color
        style={{ backgroundColor: '#00008B' }}
        onClick={(e) => setColor('#00008B')}
      />
      <Color
        style={{ backgroundColor: '#00FFFF' }}
        onClick={(e) => setColor('#00FFFF')}
      />
      <Color
        style={{ backgroundColor: '#008B8B' }}
        onClick={(e) => setColor('#008B8B')}
      />
      <Color
        style={{ backgroundColor: '#FFFF00' }}
        onClick={(e) => setColor('#FFFF00')}
      />
      <Color
        style={{ backgroundColor: '#8B8B00' }}
        onClick={(e) => setColor('#8B8B00')}
      />
      <Color
        style={{ backgroundColor: '#FF7F00' }}
        onClick={(e) => setColor('#FF7F00')}
      />
      <Color
        style={{ backgroundColor: '#8B4500' }}
        onClick={(e) => setColor('#8B4500')}
      />
      <Color
        style={{ backgroundColor: '#FF1493' }}
        onClick={(e) => setColor('#FF1493')}
      />
      <Color
        style={{ backgroundColor: '#8B0A50' }}
        onClick={(e) => setColor('#8B0A50')}
      />
      <Color
        style={{ backgroundColor: '#9B30FF' }}
        onClick={(e) => setColor('#9B30FF')}
      />
      <Color
        style={{ backgroundColor: '#551A8B' }}
        onClick={(e) => setColor('#551A8B')}
      />
      <Color
        style={{ backgroundColor: '#FFD8C1' }}
        onClick={(e) => setColor('#FFD8C1')}
      />
      <Color
        style={{ backgroundColor: '#8B6969' }}
        onClick={(e) => setColor('#8B6969')}
      />
    </ColorPickerWrapper>
  );
}

export default ColorPicker;
