import React, { useState, useEffect, useRef } from 'react';

import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  PacientName,
  DataURL,
} from '@utils';

/** CSS */
import { Container, AreiaBox, SaveArea } from './styles';

import {
  AreiaUrlImage,
  DragNDropTabs,
  ModalAddOps,
  ModalAlert,
  PrimaryButton,
  TertiaryButton,
  ModalConfirm,
} from '@components';

/** Imagens */
import { Areia } from '@assets';

/** KONVA */
import { Stage, Layer, Text, Image } from 'react-konva';
import useImage from 'use-image';

const AreiaView = (props) => {
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  const dragUrl = React.useRef();
  const stageRef = React.useRef();
  const [images, setImages] = useState([]);
  const [imgAreia] = useImage(Areia);
  const [selectedId, selectShape] = useState(null);
  const [yourID, setYourID] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCleanIsOpen, setModalCleanIsOpen] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);
  const innerWidthSize = window.innerWidth > 710 ? 710 : window.innerWidth;
  const proportionalStageHeight = (innerWidthSize * 535) / 710;
  const scaleX = innerWidthSize / 710;
  const scaleY = proportionalStageHeight / 535;

  const socketRef = useRef();

  function receivedMessage(message) {
    setImages(message.body);
  }

  function sendImage(imagesData) {
    const messageObject = {
      body: imagesData,
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  const handleExport = () => {
    selectShape(null);
    setTimeout(() => {
      const uri = stageRef.current.toDataURL();
      const nome_paciente = localStorage
        .getItem('@nome_paciente')
        .replaceAll(' ', '_');
      const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
      downloadURI(uri, filename, sendArquivo, os, token, nome_recurso_cod);
    }, 500);
  };

  function onDropMobileHandler(e) {
    e.preventDefault();
    if (dragUrl.current !== null) {
      stageRef.current.setPointersPositions(e);
      let Xcoordinate = stageRef.current.getPointerPosition().x / scaleX;
      let Ycoordinate = stageRef.current.getPointerPosition().y / scaleY;
      const nameFile = 100000 + Math.floor((999999 - 100000) * Math.random());
      selectShape(nameFile);
      setImages(
        images.concat([
          {
            x: Xcoordinate,
            y: Ycoordinate,
            src: dragUrl.current,
            drag: true,
            alt: nameFile,
          },
        ])
      );
      sendImage(
        images.concat([
          {
            x: Xcoordinate,
            y: Ycoordinate,
            src: dragUrl.current,
            drag: true,
            alt: nameFile,
          },
        ])
      );
    }
  }

  const salvarFim = () => {
    setIsOpen(true);
  };

  const handleClean = () => {
    setImages([]);
    sendImage([]);
    setModalCleanIsOpen(false);
  };

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);
    // console.log('dataUrl', DataURL(props));
    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, tokenSocketIo);
    }
  }, []);

  return (
    <Container id="container">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DragNDropTabs
          dragUrl={dragUrl}
          mobileTouchHandler={onDropMobileHandler}
        />
        <div
          style={{
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <SaveArea>
            <TertiaryButton onClick={() => setModalCleanIsOpen(true)}>
              Limpar
            </TertiaryButton>
            {typeUser === 'profissional' && (
              <>
                <PrimaryButton onClick={() => setIsOpenAlert(true)}>
                  Instruções
                </PrimaryButton>
                <PrimaryButton onClick={salvarFim}>Salvar</PrimaryButton>
                <PrimaryButton onClick={() => setModalCloseIsOpen(true)}>
                  Fechar
                </PrimaryButton>
              </>
            )}
          </SaveArea>
          <AreiaBox
            className={'containerr'}
            onDrop={(e) => onDropMobileHandler(e)}
            onDragOver={(e) => e.preventDefault()}
          >
            <Stage
              width={innerWidthSize}
              height={proportionalStageHeight}
              scaleX={scaleX}
              scaleY={scaleY}
              onMouseDown={checkDeselect}
              onTouchStart={checkDeselect}
              ref={stageRef}
            >
              <Layer>
                <Image x={0} y={-450} image={imgAreia} preventDefault={false} />
                {images.map((image, i) => {
                  return (
                    <AreiaUrlImage
                      image={image}
                      key={i}
                      shapeProps={image}
                      isSelected={image.alt === selectedId}
                      onSelect={() => {
                        if (image.drag) selectShape(image.alt);
                      }}
                      onChange={(newAttrs) => {
                        const aux = images.slice();
                        aux[i] = newAttrs;
                        setImages(aux);
                        sendImage(aux);
                      }}
                    />
                  );
                })}
                {typeUser === 'profissional' && (
                  <Text x={10} y={15} text={PacientName()} fontSize={24} />
                )}
              </Layer>
            </Stage>
          </AreiaBox>
        </div>
      </div>
      <ModalAddOps
        generatePdf={handleExport}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        token={token}
      />
      <ModalAlert
        modalIsOpen={modalIsOpenAlert}
        setIsOpen={setIsOpenAlert}
        token={token}
        props={props}
        title={'Jogo da Areia'}
        recurso={nome_recurso_cod}
        bt01Txt={'Fechar'}
      />
      <ModalConfirm
        title="Limpar o jogo"
        description="Deseja realmente limpar o jogo?"
        modalIsOpen={modalCleanIsOpen}
        setIsOpen={setModalCleanIsOpen}
        confirmButtonText="Limpar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCleanIsOpen(false)}
        onConfirm={() => handleClean()}
      />
      <ModalConfirm
        title="Fechar o jogo"
        description="Deseja realmente fechar o jogo?"
        modalIsOpen={modalCloseIsOpen}
        setIsOpen={setModalCloseIsOpen}
        confirmButtonText="Fechar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCloseIsOpen(false)}
        onConfirm={() =>
          handleBackHome(
            modalClose,
            nome_recurso_cod,
            typeUser,
            props,
            os,
            token,
            ambiente
          )
        }
      />
    </Container>
  );
};

export default AreiaView;
