import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: #fefefe;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${(props) => props.viewHeight};
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.img`
  margin-top: 16px;
  border-radius: 8px;
  width: 270px;
  filter: drop-shadow(0px 0px 24px rgba(125, 140, 148, 0.5));
  transition: 0.25s;

  @media only screen and (min-width: 500px) and (max-width: 786px) {
    width: 240px;
  }

  @media (max-width: 500px) {
    width: 200px;
    margin-top: 0;
  }
`;

export const Button = styled.button`
  width: 150px;
  height: 36px;
  border-radius: 5px;
  border: none;
  background: ${(props) => props.buttonColor};
  box-shadow: inset 4px 4px 4px rgb(245 245 245 / 46%);
  color: #f5f5f5;
  font-size: 18px;
  margin: 15px;

  @media (max-width: 500px) {
    width: 90px;
  }

  @media (min-width: 786px) {
    :hover {
      filter: ${(props) => props.shaddow};
    }
  }

  :focus {
    filter: ${(props) => props.shaddowSelected};
  }
`;

export const DeckContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 300px;
  transition: 0.25s;

  @media (min-width: 500px) {
    position: absolute;
    right: 0px;
    top: 40px;
  }

  @media only screen and (min-width: 500px) and (max-width: 786px) {
    width: 180px;
    top: 100px;
  }

  @media (max-width: 500px) {
    flex-direction: row;
    width: 100%;
    margin-bottom: 40px;
  }
`;

export const DeckBox = styled.div`
  display: flex;
  align-items: center;
  width: 175px;
  height: 140px;
  margin: ${(props) => (props.result ? '20px 24px' : '8px')};
  background-size: cover;
  background-position: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  transition: 0.25s;

  @media only screen and (min-width: 500px) and (max-width: 786px) {
    width: 140px;
    height: 110px;
  }

  @media (max-width: 500px) {
    width: 105px;
    height: 80px;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 22px;
    font-weight: 600;
    margin-left: 8px;

    @media (max-width: 500px) {
      font-size: 16px;
      margin-left: 0;
    }
  }
`;

export const SaveArea = styled.span`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 16px;

  @media (max-width: 786px) {
    flex-wrap: wrap;
  }
`;

export const AnswerBox = styled.div`
  width: 168px;
  height: 56px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0px 0px 16px rgba(125, 140, 148, 0.5);
  display: flex;
  position: absolute;
  top: 114px;
  left: 170px;

  @media only screen and (min-width: 500px) and (max-width: 1048px) {
    width: 148px;
    left: 16px;
  }

  @media (max-width: 500px) {
    top: 250px;
    left: 20px;
    width: 148px;
    height: 52px;
  }

  @media screen and (min-width: 320px) and (max-width: 380px) {
    top: 220px;
    left: 20px;
  }

  span {
    display: flex;
    width: 40px;
    align-items: center;
    justify-content: center;
    color: #2797ba;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    h2 {
      font-size: 14px;
      margin-bottom: 0;
      font-weight: 500;

      @media screen and (min-width: 320px) and (max-width: 380px) {
        font-size: 12px;
      }
    }
  }
`;

export const ResultBoxPageOne = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    font-weight: 600;
    color: #2b186c;
  }

  img {
    width: 374px;
  }

  button {
    width: 225px;
    height: 40px;
    border-radius: 24px;
    background-color: #f5f5f5;
    color: #2797ba;
    border: 1px solid #2797ba;
    font-size: 17px;
    font-weight: 600;
    cursor: pointer;
  }
`;

export const ResultBoxProfessional = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  button {
    width: 263px;
    height: 40px;
    border-radius: 24px;
    background-color: #f5f5f5;
    color: #2797ba;
    border: 1px solid #2797ba;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;

    @media (max-width: 500px) {
      margin-bottom: 20px;
    }
  }
`;

export const NumberResult = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 60px 0 14px;

  h3 {
    font-size: 18px;
    line-height: 10px;
    text-align: center;
    font-weight: 600;
    color: #2b186c;
    margin: 8px;
  }
`;

export const ResultDeckBoxProfessional = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 0 0 60px;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const DeckColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const DeckNumbers = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 146px;
  height: 70px;

  p {
    margin: 4px;
    font-size: 16px;
    font-weight: 700;
    color: #1c7330;
  }
`;

export const ResultTable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  h1 {
    font-size: 48px;
    margin-bottom: -20px;
    font-family: cursive;
  }

  h3 {
    font-weight: 700;
    font-size: 18px;
    text-align: Center;
    margin-bottom: 4px;

    @media (max-width: 500px) {
      font-size: 14px;
    }
  }

  table {
    border-collapse: collapse;
    margin: 8px;
  }

  td {
    border: 1px solid black;

    p {
      font-size: 14px;
      font-weight: 700;
      margin: 1px 8px;
    }
  }
`;

export const CardBox = styled.div`
  .animation {
    transition: 0.25s;
    width: 250px;
  }
`;
