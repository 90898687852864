import styled, { css } from 'styled-components';

const PrimaryButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background: #2797ba;
  color: #e8e3fc;

  width: 20vw;
  max-width: 122px;

  height: 40px;

  margin: 5px;
  padding: 5px;

  box-shadow: inset 0px 0px 19px #63d6fa;
  border: none;
  border-radius: 24px;

  font-family: 'Roboto', serif;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;

  transition: 0.3s;

  &:hover {
    background: #238bab;
    box-shadow: inset 0px 0px 19px #11465563;
  }

  cursor: pointer;

  @media (max-width: 800px) {
    font-size: 14px;
    margin: 5px 2px;
  }

  ${(props) =>
    (props.problemSize || props.mobile) &&
    css`
      @media (max-width: 768px) {
        width: 30vw;
      }
    `}
`;

export default PrimaryButton;
