import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: white;

  overflow-x: hidden;

  width: 100%;
  min-height: 90vh;
`;

export const CentralContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  max-width: 1300px;

  background: white;
`;

export const Title = styled.img`
  width: 1000px;

  @media(max-width: 786px) {
    width: 300px;
    margin-top: 10px;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media(max-width: 786px) {
    flex-direction: column;
  }

  img {
    margin-top: ${(props) => props.lastRow ? '20px' : 0};
    margin-bottom: ${(props) => props.lastRow ? '20px' : 0};
  }
`;

export const FirstDialog = styled.div`
  display: flex;
  flex-direction: column;

  padding-left: 25px;

  width: 400px;
  height: 400px;
  margin-right: -40;

  background-image: url(${(props) => props.src});
  background-size: 400px 400px;
  background-repeat: no-repeat;

  h1 {
    font-family: 'Niconne', cursive;
    font-size: 28px;
  }

  textarea {
    border: none;
    width: 350px;
    height: 200px;
    resize: none;
    outline: none;
  }

  @media(max-width: 786px) {
    padding-left: 0;
    background-size: 300px 300px;
    margin-right: -100px;
    height: 325px;

    h1 {
      margin-left: 20px;
    }

    textarea {
      width: 280px;
      height: 150px;
      margin-left: 10px;
    }
  }

  ${(props) => props.typeOfPost && css`
    margin-top: -70px;

    @media(max-width: 786px) {

      textarea {
        margin-top: -20px;
        width: 270px;
        height: 130px;
      }
      
    }
  `}

  ${(props) => props.harmfulDialog && css`
    margin-top: -100px;
    margin-left: 70px; 

    h1 {
      width: 90%;
    }

    textarea {
      height: 140px;
    }

    @media(max-width: 786px) {
      margin-top: 50px;
      margin-left: 0;
      background-size: 300px 370px;
      height: 450px;

      h1 {
        width: 70%
      }

      textarea {
        width: 272px;
        height: 120px;
      }
    }
  `}
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const FirstColoredDialogs = styled.div`
  width: 250px;
  height: 200px;
  background-image: url(${(props) => props.src});
  background-size: 250px 200px;
  background-repeat: no-repeat;

  textarea {
    margin-top: 20px;
    margin-left: 55px;
    height: 100px;
    width: 150px;
    outline: none;
    resize: none;
    border: none;
  }

  
  @media(max-width: 786px) {
    margin-top: 60px;
    margin-left: 30px;
    
  }
  ${(props) => props.second && css`
    margin-left: 280px;
    margin-top: -100px;
    transform: rotate(-5deg);

    @media(max-width: 786px) {
      margin-top: 60px;
      margin-left: 30px;
    }
  `}

  ${(props) => props.third && css`
    margin-top: -48px;
    margin-bottom: 80px; 

    @media(max-width: 786px) {
      margin-top: 60px;
      margin-left: 30px;
    }
  `}
`;

export const FirstColoredDialogsRow = styled.div`
  display: flex;
  flex-direction: column-reverse;
`

export const CursiveH1 = styled.h1`
  font-family: 'Niconne', cursive;
  width: 300px;
  font-size: 28px;
  margin-top: 20px;
  margin-bottom: -100px;
  margin-left: 280px;

  @media(max-width: 786px) {
    margin: 40px 0 0 10px;
  }
`;

export const SemiBordedDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 600px;
  height: 100px;
  margin-left: 100px;

  border: 2px solid;

  span {
    font-family: 'Niconne', cursive;
    font-size: 32px;
    font-weight: 700;
    margin-top: -25px;
    background: white;
    padding: 0 10px;
  }

  textarea {
    margin-top: 5px;
    width: 80%;
    height: 70px;
    resize: none;
    outline: none;
    font-size: 30px;
    text-align: center;
    border: none;
  }

  @media(max-width: 786px) {
    margin-left: 400px;
    width: 300px;
    height: 150px;
  }
`;

export const ShadowedRectangle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 350px;
  height: 200px;

  border: 2px solid;
  padding: 5px 10px;

  span {
    font-family: 'Niconne', cursive;
    font-size: 32px;
    font-weight: 700;
    text-align: center;
  }
  textarea {
    margin-top: 5px;
    width: 100%;
    height: 120px;
    resize: none;
    outline: none;
    font-size: 26px;
    text-align: center;
    border: none;
  }

  @media(max-width: 786px) {
    width: 280px;
    margin-left: ${(props) => props.first ? '52px' : 0};
  }

  ${(props) => props.second && css`
    @media(max-width: 786px) {
      height: 260px;

      span {
        font-size: 24px;
      }

      textarea {
        height: 120px;
      }
    }
  `}
`;

export const SecondDialog = styled.div`
  margin-top: 20px;
  margin-right: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 300px;
  height: 300px;

  padding: 8px 0px;

  background-image: url(${(props) => props.src});
  background-size: 300px 300px;
  background-repeat: no-repeat;

  span {
    font-family: 'Niconne', cursive;
    width: 90%;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
  }

  textarea {
    margin-top: 5px;
    width: 80%;
    height: 100px;
    resize: none;
    outline: none;
    font-size: 20px;
    text-align: center;
    border: none;
  }

  @media(max-width: 786px) {
    margin-left: 54px;
  }
`;

export const FirstSmartphone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 480px;
  height: 600px;

  background-image: url(${(props) => props.src});
  background-size: 480px 600px;
  background-repeat: no-repeat;

  margin-left: -80px;

  span {
    font-family: 'Niconne', cursive;
    width: 50%;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    transform: rotate(-10deg);
    margin-left: 85px;
    margin-top: 70px;
  }

  textarea {
    font-family: 'Roboto', serif;
    width: 180px;
    height: 200px;
    transform: rotate(-10deg);
    margin-left: 145px;
    margin-top: 10px;
    resize: none;
    outline: none;
    border: none;
  }

  @media(max-width: 786px) {
    background-size: 420px 520px;
    margin-left: -40px;

    span {
      width: 39%;
      font-size: 22px;
      margin-left: 31px;
      margin-top: 70px;
    }

    textarea {
      width: 170px;
      height: 178px;
      margin-left: 80px;
      margin-top: 5px;
    }
  }
`;

export const SecondSmartphone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 380px;
  height: 600px;
  margin-top: -500px;
  margin-left: -80px;
  transform: rotate(-10deg);

  background-image: url(${(props) => props.src});
  background-size: 480px 600px;
  background-repeat: no-repeat;

  span {
    font-family: 'Niconne', cursive;
    width: 65%;
    font-size: 23px;
    font-weight: 700;
    text-align: center;
    transform: rotate(10deg);
    margin-left: 10px;
    margin-top: 70px;
  }

  textarea {
    font-family: 'Roboto', serif;
    width: 180px;
    height: 200px;
    transform: rotate(11deg);
    margin-right: 40px;
    margin-top: 10px;
    resize: none;
    outline: none;
    border: none;
  }

  @media(max-width: 786px) {
    margin-top: 0;
    background-size: 420px 520px;
    margin-left: 100px;

    span {
      width: 60%;
      font-size: 23px;
      transform: rotate(10deg);
      margin-left: -36px;
      margin-top: 63px;
    }

    textarea {
      width: 170px;
      height: 178px;
      transform: rotate(10deg);
      margin-left: -61px;
      margin-top: 5px;
    }
  }
`;

export const ThirdSmartphone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 480px;
  height: 600px;

  background-image: url(${(props) => props.src});
  background-size: 480px 600px;
  background-repeat: no-repeat;

  span {
    font-family: 'Niconne', cursive;
    width: 45%;
    font-size: 23px;
    font-weight: 700;
    text-align: center;
    transform: rotate(10deg);
    margin-right: 90px;
    margin-top: 70px;
  }

  textarea {
    font-family: 'Roboto', serif;
    width: 180px;
    height: 200px;
    transform: rotate(10deg);
    margin-right: 150px;
    margin-top: 10px;
    resize: none;
    outline: none;
    border: none;
  }

  @media(max-width: 786px) {
    margin-top: 0;
    background-size: 420px 520px;
    margin-left: 160px;

    span {
      width: 50%;
      font-size: 23px;
      transform: rotate(10deg);
      margin-left: -46px;
      margin-top: 63px;
    }

    textarea {
      width: 170px;
      height: 178px;
      transform: rotate(10deg);
      margin-left: -61px;
      margin-top: 5px;
    }
  }
`;

export const DialogTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-family: 'Niconne', cursive;
    font-size: 26px;
    max-width: 250px;
    text-align: center;
  }

  ${(props) => props.first && css`
    transform: rotate(-5deg);

    @media(max-width: 786px) {
      transform: rotate(0deg);
      margin-right: 100px;
    }
  `}

  ${(props) => props.second && css`
    margin-left: 300px;
    margin-top: -200px;
    transform: rotate(5deg);

    @media(max-width: 786px) {
      transform: rotate(0deg);
      margin-top: 0;
      margin-left: 0;
    }
  `}

  ${(props) => props.third && css`
    margin-top: -150px;
    transform: rotate(-2deg);
    
    @media(max-width: 786px) {
      margin-top: 0;
      transform: rotate(0deg);
    }
  `}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  margin-top: 50px;
  margin-bottom: 50px;
`;
