/* eslint-disable no-unused-expressions */
import styled from 'styled-components';

export const Container = styled.div``;

export const Button = styled.button`
  color: #fff;
  width: 150px;
  height: 50px;
  background-color: ${(props) => props.color};
  font-size: 18px;
  border: 1px solid #fff;
  border-radius: 10px;
  margin: 15px;

  cursor: pointer;
`;

export const BoxSession = styled.div`
  border: 1px solid #333;
  border-radius: 5px;
  padding: 15px;
  margin: auto;
  width: 250px;
  margin-left: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  float: left;
`;

export const WarningContainer = styled.div`
  display: flex;
  width: 98%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  margin-bottom: 10px;
  margin-left: -2px;
  border-radius: 10px;

  background-color: #e3f8f5;

  h2 {
    margin: 1px 5px;
    font-family: 'Roboto', serif;
    font-weight: 400;
    font-size: 16px;
    color: #3c7996;
  }
  strong {
    font-family: 'Roboto', serif;
    font-size: 16px;
    color: #367392;
  }
`;
