import React, { useEffect } from 'react';

/** KONVA */
import { Image, Transformer } from 'react-konva';
import useImage from 'use-image';

export default function URLImage({
  shapeProps,
  isSelected,
  onSelect,
  onChange,
  image,
  key,
  width,
  height,
  resizable = true,
  rotatable = true,
  autoFixPoints = [],
  rotationSnaps,
  rotationTolerance,
}) {
  const shapeRef = React.useRef();
  const trRef = React.useRef();
  const [img] = useImage(image.src);

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <React.Fragment>
      <Image
        key={key}
        image={img}
        x={image.x}
        y={image.y}
        width={!!width ? width : undefined}
        height={!!height ? height : undefined}
        // I will use offset to set origin to the center of the image sizes
        offsetX={width ? width / 2 : img ? img.width / 2 : 0}
        offsetY={height ? height / 2 : img ? img.height / 2 : 0}
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        draggable={image.drag}
        onDragEnd={(e) => {
          const imgX = e.target.x();
          const imgY = e.target.y();
          let flag = false;

          if (autoFixPoints.length !== 0) {
            autoFixPoints.forEach((p) => {
              if (
                imgX > p.x - 20 &&
                imgX < p.x + 20 &&
                imgY > p.y - 20 &&
                imgY < p.y + 20
              ) {
                flag = true;
                onChange({
                  ...shapeProps,
                  x: p.x,
                  y: p.y,
                });
              }
            });
          }

          if (flag !== true) {
            onChange({
              ...shapeProps,
              x: e.target.x(),
              y: e.target.y(),
            });
          }
        }}
        onTransformEnd={(e) => {
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          node.scaleX(1);
          node.scaleY(1);

          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            rotation: !(resizable || rotatable)
              ? node.rotation(0)
              : node.rotation(),
            width: !(resizable || rotatable)
              ? Math.max(node.width())
              : Math.max(5, node.width() * scaleX),
            height: !(resizable || rotatable)
              ? Math.max(node.height())
              : Math.max(node.height() * scaleY),
          });
        }}
        transformsEnabled={!(resizable || rotatable) ? 'position' : 'all'}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
          rotateAnchorOffset={20}
          rotationSnaps={rotationSnaps ? rotationSnaps : []}
          rotationSnapTolerance={rotationTolerance ? rotationTolerance : 5}
          rotateEnabled={rotatable}
          resizeEnabled={resizable}
        />
      )}
    </React.Fragment>
  );
}
