import styled from 'styled-components';

import { shade } from 'polished';

export const Modal = styled.div`
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;

  display: ${(props) => (props.isOpen ? `flex` : `none`)};

  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.3);

  z-index: 999;
`;

export const Container = styled.div`
  width: 550px;
  height: 470px;
  max-width: 90vw;
  z-index: 1000;

  position: absolute;
  left: 50%;
  top: 10vw;
  transform: translate(-50%, -5vw);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;

  padding: 0px 20px;

  background: #f5f5f5;

  h2 {
    font-family: 'Roboto', serif;
    font-weight: 400;
    color: #4c5153;
    font-size: 18px;
  }
`;

export const TitleContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    color: #3d3d3d;
    font-family: 'Roboto', serif;
    font-weight: 500;
    font-size: 24px;
  }
`;

export const CentralContainer = styled.div`
  width: 100%;
  height: 85%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  flex-wrap: wrap;

  overflow: hidden;

  background: white;
  h1 {
    color: #2797ba;
    font-family: 'Roboto', serif;
    font-weight: 500;
    font-size: 30px;
  }

  h2 {
    font-family: 'Roboto', serif;
    font-weight: 400;
    color: #4c5153;
    font-size: 18px;
  }
`;

export const ColCenter = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;

  justify-content: flex-end;
  align-items: center;

  margin-top: 10px;
  margin-bottom: 20px;

  z-index: 99;
`;

export const CancelButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  border: 2px solid #ccd1d3;

  padding: 5px 0px;

  border-radius: 4px;

  margin-right: 20px;

  width: 100px;

  cursor: pointer;

  h3 {
    font-family: 'Roboto', serif;
    color: #7d8c94;
    font-weight: 400;
    font-size: 18px;
    margin: 0;
  }

  transition: 0.5s;

  &:hover {
    border-color: #7d8c94;
    background-color: #e8e9e9;
  }
`;

export const YesButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  border: 2px solid ${(props) => (props.disabled ? '#CCD1D3' : '#8E7AD6')};
  background: ${(props) => (props.disabled ? '#CCD1D3' : '#8E7AD6')};

  padding: 5px 0px;

  width: 100px;

  cursor: pointer;

  user-select: none;

  h3 {
    font-family: 'Roboto', serif;
    color: #e8e3fc;
    font-weight: 400;
    font-size: 18px;
    margin: 0;
  }

  transition: 0.5s;

  &:hover {
    border-color: ${(props) => (props.disabled ? '#CCD1D3' : '#2B186C')};
    background-color: ${(props) => (props.disabled ? '#CCD1D3' : '#2B186C')};
  }
`;

export const LinkContainer = styled.div`
  width: 95%;
  display: flex;
  flex-wrap: wrap;

  margin-top: 20px;

  padding: 0px 10px;

  border: 1px solid #2b186c;
  border-radius: 4px;

  h2 {
    color: #2b186c;
    font-size: 16px;
    font-family: 'Roboto', serif;
    font-weight: 400;
    width: 100%;
    overflow-wrap: break-word;
  }
`;

export const CopyButton = styled.div`
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
  padding: 0;

  margin-top: 40px;

  border: 1px solid #8e7ad6;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: #e8e3fc;
  }

  h2 {
    color: #8e7ad6;
    font-size: 16px;
    font-family: 'Roboto', serif;
    font-weight: 400;
    margin: 8px 0px;
    user-select: none;
    overflow: wrap;
    word-wrap: break-word;
  }
`;

export const CopiedSnack = styled.div`
  width: 120px;
  background: #4c5153;

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;

  position: relative;
  top: 100%;

  padding: 0;

  h2 {
    color: #fff;
    font-size: 16px;
    font-family: 'Roboto', serif;
    font-weight: 400;
    margin: 8px 0px;
  }

  animation: ${(props) => {
    if (props.animate === undefined) {
      return `none`;
    }
    if (props.animate === true) {
      return `popt 1.2s linear`;
    }
    if (props.animate === false) {
      return `popt2 1.2s linear`;
    }
  }};

  @keyframes popt {
    0% {
      top: 50%;
      opacity: 0;
    }
    25% {
      top: 10%;
      opacity: 1;
    }
    75% {
      top: 10%;
      opacity: 1;
    }
    100% {
      top: 50%;
      opacity: 0;
    }
  }
  @keyframes popt2 {
    0% {
      top: 50%;
      opacity: 0;
    }
    25% {
      top: 10%;
      opacity: 1;
    }
    75% {
      top: 10%;
      opacity: 1;
    }
    100% {
      top: 50%;
      opacity: 0;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  width: 95%;
  max-width: 80vw;

  background: #fafbfb;

  border: 1px solid #90a4ae;
  border-radius: 2px;

  padding: 8px 8px;

  margin-bottom: 20px;
`;

export const InputField = styled.input`
  margin-left: 10px;
  height: 100%;
  width: 100%;

  font-size: 20px;

  padding: 0;

  background: transparent;
  box-shadow: none;
  border: none;
  outline: none;

  font-family: 'Roboto', serif;
`;

export const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  flex-wrap: nowrap;

  max-height: 200px;

  overflow-y: scroll;
`;

export const ListItem = styled.div`
  width: 100%;

  z-index: 1000;

  background: ${(props) => {
    if (props.isSelected) {
      return shade(0.2, 'rgba(232, 233, 233, 0.72)');
    }
    if (props.pair === true) {
      return '#e8e9e9';
    } else {
      return 'rgba(232, 233, 233, 0.72)';
    }
  }};

  &:hover {
    background: ${(props) => {
      if (props.isSelected) {
        return shade(0.2, 'rgba(232, 233, 233, 0.72)');
      } else {
        props.pair
          ? shade(0.05, '#e8e9e9')
          : shade(0.05, 'rgba(232, 233, 233, 0.72)');
      }
    }};
  }

  h2 {
    color: #677176;
    font-family: 'Roboto';
    font-weight: 400;
    margin-left: 10px;

    font-size: 16px;
  }
`;
