import React, { useState, useEffect, useRef } from 'react';

import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  PacientName,
  DataURL,
} from '../../utils';

import {
  CentralRow,
  Container,
  SaveArea,
  ButtonClean,
  ButtonSave,
  BoardBox,
  DifficultSelection,
  Row,
  Col,
} from './styles';

import { RelateTheColorsBoards } from '../../assets';

//Drag'n Drop imports
import { Stage, Layer, Text } from 'react-konva';
import MountUrlImage from '../../components/MountUrlImage';

//Components import
import {
  ModalAddOps,
  ModalAlert,
  SecondaryButton,
  TertiaryButton,
  ModalConfirm,
  PrimaryButton,
} from '../../components';
import EasyTab from '../../components/RelateTheColors/EasyTab';
import MediumTab from '../../components/RelateTheColors/MediumTab';
import HardTab from '../../components/RelateTheColors/HardTab';

const RelateTheColors = (props) => {
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  //Socket variables
  const [yourID, setYourID] = useState();
  const socketRef = useRef();

  //Modal variables
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCleanIsOpen, setModalCleanIsOpen] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);
  const [print, setPrint] = useState(false);

  //Drag'n Drop variables
  const dragUrl = useRef();
  const stageRef = React.useRef();
  const [images, setImages] = React.useState([]);
  const easyBoardImageProps = {
    x: 1150,
    y: 860,
    src: RelateTheColorsBoards[0],
    height: 600,
    width: 800,
    drag: false,
  };
  const mediumBoardImageProps = {
    x: 1170,
    y: 1170,
    src: RelateTheColorsBoards[1],
    height: 800,
    width: 800,
    drag: false,
  };
  const hardBoardImageProps = {
    x: 1042,
    y: 1550,
    src: RelateTheColorsBoards[2],
    height: 1000,
    width: 750,
    drag: false,
  };

  const [selectedId, selectShape] = useState(null);

  //Control variables
  const [usedIndexes, setUsedIndexes] = useState([]);
  const [usedEasyIndexes, setUsedEasyIndexes] = useState([]);
  const [usedMediumIndexes, setUsedMediumIndexes] = useState([]);
  const [usedHardIndexes, setUsedHardIndexes] = useState([]);
  const [difficult, setDifficult] = useState('none');
  const windowIsSmall = window.innerWidth < 1620;

  //*************************************************************************************************************** */
  //Functions

  //Board props for BoardBox
  //Easy - W:800 H:610
  //Medium - W:800 H:805
  //Hard - W:750 H:990

  const getBoardHeight = () => {
    switch (difficult) {
      case 'easy':
        return 610;
      case 'medium':
        return 805;
      case 'hard':
        return 990;
      default:
        return 610;
    }
  };

  const getImageSize = () => {
    switch (difficult) {
      case 'easy':
        return 130;
      case 'medium':
        return 130;
      case 'hard':
        return 130;

      default:
        return 100;
    }
  };

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  const salvarFim = () => {
    setIsOpen(true);
  };

  const handleExport = () => {
    selectShape(null);
    setTimeout(() => {
      const uri = stageRef.current.toDataURL();
      const nome_paciente = localStorage
        .getItem('@nome_paciente')
        .replaceAll(' ', '_');
      const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
      downloadURI(uri, filename, sendArquivo, os, token, nome_recurso_cod);
    }, 500);
  };

  const handleChangeDifficult = () => {
    setDifficult('none');
    setDifficultOnSocket('none');
    handleClean();
  };

  const handleSelectDifficult = (type) => {
    switch (type) {
      case 'easy':
        setImages([easyBoardImageProps]);
        sendImage([easyBoardImageProps], []);
        break;
      case 'medium':
        setImages([mediumBoardImageProps]);
        sendImage([mediumBoardImageProps], []);
        break;
      case 'hard':
        setImages([hardBoardImageProps]);
        sendImage([hardBoardImageProps], []);
        break;

      default:
        return;
    }
    setDifficult(type);
    setDifficultOnSocket(type);
  };

  const handleClean = () => {
    switch (difficult) {
      case 'easy':
        setImages([easyBoardImageProps]);
        sendImage([easyBoardImageProps], []);
        break;
      case 'medium':
        setImages([mediumBoardImageProps]);
        sendImage([mediumBoardImageProps], []);
        break;
      case 'hard':
        setImages([hardBoardImageProps]);
        sendImage([hardBoardImageProps], []);
        break;

      default:
        return;
    }
    setUsedIndexes([]);
    setModalCleanIsOpen(false);
  };

  function sendImage(imagesData, usdIndexes) {
    const messageObject = {
      body: { imagesData: imagesData, usedIndexes: usdIndexes },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  function setDifficultOnSocket(diff) {
    const messageObject = {
      body: { difficult: diff },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  function receivedMessage(message) {
    console.log(message);
    if (message.body.imagesData) {
      setImages(message.body.imagesData);
      setUsedIndexes(message.body.usedIndexes);
    }
    if (message.body.difficult) setDifficult(message.body.difficult);
  }

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);
    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, tokenSocketIo);
    }
  }, []);

  return (
    <Container>
      {typeUser === 'profissional' && difficult === 'none' && (
        <DifficultSelection>
          <h1>Selecione uma dificuldade</h1>
          <Row>
            <Col onClick={() => handleSelectDifficult('easy')}>
              <h1>Fácil</h1>
              <img
                src={RelateTheColorsBoards[0]}
                alt={RelateTheColorsBoards[0]}
              />
            </Col>
            <Col onClick={() => handleSelectDifficult('medium')}>
              <h1>Médio</h1>
              <img
                src={RelateTheColorsBoards[1]}
                alt={RelateTheColorsBoards[1]}
              />
            </Col>
            <Col onClick={() => handleSelectDifficult('hard')}>
              <h1>Difícil</h1>
              <img
                src={RelateTheColorsBoards[2]}
                alt={RelateTheColorsBoards[2]}
              />
            </Col>
          </Row>
        </DifficultSelection>
      )}

      <CentralRow>
        {typeUser !== 'profissional' && difficult === 'none' && (
          <h2>Aguarde enquanto o profissional escolhe o tabuleiro.</h2>
        )}

        {difficult !== 'none' && (
          <div>
            {difficult === 'easy' && (
              <EasyTab
                dragUrl={dragUrl}
                usedIndexes={usedIndexes}
                setUsedIndexes={setUsedIndexes}
              />
            )}
            {difficult === 'medium' && (
              <MediumTab
                dragUrl={dragUrl}
                usedIndexes={usedIndexes}
                setUsedIndexes={setUsedIndexes}
              />
            )}
            {difficult === 'hard' && (
              <HardTab
                dragUrl={dragUrl}
                usedIndexes={usedIndexes}
                setUsedIndexes={setUsedIndexes}
              />
            )}
          </div>
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <SaveArea>
            {difficult !== 'none' && (
              <TertiaryButton onClick={() => setModalCleanIsOpen(true)}>
                Limpar
              </TertiaryButton>
            )}
            {typeUser === 'profissional' && difficult !== 'none' && (
              <SecondaryButton onClick={() => setIsOpenAlert(true)}>
                Instruções
              </SecondaryButton>
            )}
            {typeUser === 'profissional' && difficult !== 'none' && (
              <SecondaryButton onClick={salvarFim}>Salvar</SecondaryButton>
            )}
            {typeUser === 'profissional' && difficult !== 'none' && (
              <SecondaryButton onClick={() => setModalCloseIsOpen(true)}>
                Fechar
              </SecondaryButton>
            )}
            {typeUser === 'profissional' && difficult !== 'none' && (
              <PrimaryButton
                size="lg"
                onClick={() => {
                  handleClean();
                  handleChangeDifficult();
                }}
              >
                Escolher outra carta
              </PrimaryButton>
            )}
          </SaveArea>
          {typeUser === 'profissional' && difficult !== 'none' && (
            <h1 style={{ alignSelf: 'flex-start', margin: 0 }}>
              {PacientName()}
            </h1>
          )}
          {difficult !== 'none' && (
            <BoardBox
              onDrop={(e) => {
                e.preventDefault();
                if (dragUrl.current !== null) {
                  stageRef.current.setPointersPositions(e);
                  const nameFile =
                    100000 + Math.floor((999999 - 100000) * Math.random());
                  selectShape(nameFile);
                  setImages(
                    images.concat([
                      {
                        ...stageRef.current.getPointerPosition(),
                        src: dragUrl.current,
                        drag: true,
                        alt: nameFile,
                      },
                    ])
                  );
                  sendImage(
                    images.concat([
                      {
                        ...stageRef.current.getPointerPosition(),
                        src: dragUrl.current,
                        drag: true,
                        alt: nameFile,
                      },
                    ]),
                    usedIndexes
                  );
                }
              }}
              onDragOver={(e) => {
                e.preventDefault();
              }}
            >
              <Stage
                //Board props for BoardBox
                //Easy - W:800 H:610
                //Medium - W:800 H:805
                //Hard - W:750 H:990
                width={difficult === 'hard' ? 750 : 800}
                height={getBoardHeight()}
                onMouseDown={checkDeselect}
                onTouchStart={checkDeselect}
                ref={stageRef}
              >
                <Layer>
                  {images.map((image, i) => {
                    return (
                      <MountUrlImage
                        image={image}
                        key={i}
                        shapeProps={image}
                        isSelected={image.alt === selectedId}
                        onSelect={() => {
                          if (image.drag) selectShape(image.alt);
                        }}
                        onChange={(newAttrs) => {
                          const aux = images.slice();
                          aux[i] = newAttrs;
                          setImages(aux);
                          sendImage(aux, usedIndexes);
                        }}
                        width={getImageSize()}
                        height={getImageSize()}
                        resizable={false}
                        rotatable={false}
                      />
                    );
                  })}
                </Layer>
              </Stage>
            </BoardBox>
          )}
        </div>
      </CentralRow>
      <ModalAddOps
        generatePdf={handleExport}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        token={token}
      />
      <ModalAlert
        modalIsOpen={modalIsOpenAlert}
        setIsOpen={setIsOpenAlert}
        token={token}
        props={props}
        title={'Relacione as cores'}
        recurso={nome_recurso_cod}
        bt01Txt={'Fechar'}
      />
      <ModalConfirm
        title="Limpar o jogo"
        description="Deseja realmente limpar o jogo?"
        modalIsOpen={modalCleanIsOpen}
        setIsOpen={setModalCleanIsOpen}
        confirmButtonText="Limpar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCleanIsOpen(false)}
        onConfirm={() => handleClean()}
      />
      <ModalConfirm
        title="Fechar o jogo"
        description="Deseja realmente fechar o jogo?"
        modalIsOpen={modalCloseIsOpen}
        setIsOpen={setModalCloseIsOpen}
        confirmButtonText="Fechar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCloseIsOpen(false)}
        onConfirm={() =>
          handleBackHome(
            modalClose,
            nome_recurso_cod,
            typeUser,
            props,
            os,
            token,
            ambiente
          )
        }
      />
    </Container>
  );
};

export default RelateTheColors;
