//React imports
import React, { useState, useEffect, useRef } from 'react';

//Socket & utilities
import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  PacientName,
  DataURL,
} from '@utils';

//Styles imports
import { Container, SaveArea, BoardBox } from './styles';

//Images import
import { Dominos } from '@assets';

//Drag'n Drop imports
import { Stage, Layer, Text } from 'react-konva';
import MountUrlImage from '@components/MountUrlImage';

//Components import
import {
  ModalAddOps,
  ModalAlert,
  SecondaryButton,
  TertiaryButton,
  ModalConfirm,
} from '@components';
import HexagonalDominoTabs from '@components/HexagonalDominoTabs';

const HexagonalDomino = (props) => {
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  //Socket variables

  const [yourID, setYourID] = useState();
  const socketRef = useRef();

  //Modal variables
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCleanIsOpen, setModalCleanIsOpen] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);

  //Drag'n Drop variables
  const innerWidthSize = window.innerWidth > 795 ? 795 : window.innerWidth;
  const proportionalStageHeight = (innerWidthSize * 560) / 795;
  const scaleX = innerWidthSize / 795;
  const scaleY = proportionalStageHeight / 560;
  const dragUrl = useRef();
  const [selectedId, selectShape] = useState(null);
  const stageRef = React.useRef();
  const [images, setImages] = React.useState([]);

  //Images variables
  const [allDominos, setAllDominos] = useState(Dominos);
  const [profissionalDominos, setProfissionalDominos] = useState([]);
  const [pacientDominos, setPacientDominos] = useState([]);

  //Control variables
  const [usedPacientIndexes, setUsedPacientIndexes] = useState([]);
  const [usedProfissionalIndexes, setUsedProfissionalIndexes] = useState([]);

  //*************************************************************************************************************** */
  //Functions

  const handleClean = () => {
    setImages([]);
    sendImage([]);
    setModalCleanIsOpen(false);
    clearUsedIndexes();
  };

  const clearUsedIndexes = () => {
    setUsedPacientIndexes([]);
    setUsedProfissionalIndexes([]);
    clearUsedIndexesOnSocket();
  };

  const shuffleDominos = (dominosArr) => {
    dominosArr.sort(() => (Math.random() > 0.5 ? 1 : -1));
    setPacientDominos(dominosArr.slice(0, 11));
    setProfissionalDominos(dominosArr.slice(11, 22));
    let arr1 = dominosArr.slice(0, 11);
    let arr2 = dominosArr.slice(11, 22);
    sendDominosArrays(arr1, arr2);
  };

  const sendDominosArrays = (arr1, arr2) => {
    const messageObject = {
      body: {
        pacientDominos: arr1,
        profissionalDominos: arr2,
      },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  };

  const clearUsedIndexesOnSocket = () => {
    const messageObject = {
      body: { usedPacientIndexes: [], usedProfissionalIndexes: [] },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  };

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  const salvarFim = () => {
    setIsOpen(true);
  };

  function onDropMobileHandler(e) {
    e.preventDefault();
    if (dragUrl.current !== null) {
      stageRef.current.setPointersPositions(e);
      let Xcoordinate = stageRef.current.getPointerPosition().x / scaleX;
      let Ycoordinate = stageRef.current.getPointerPosition().y / scaleY;
      const nameFile = 100000 + Math.floor((999999 - 100000) * Math.random());
      selectShape(nameFile);
      setImages(
        images.concat([
          {
            x: Xcoordinate,
            y: Ycoordinate,
            src: dragUrl.current,
            drag: true,
            alt: nameFile,
          },
        ])
      );
      sendImage(
        images.concat([
          {
            x: Xcoordinate,
            y: Ycoordinate,
            src: dragUrl.current,
            drag: true,
            alt: nameFile,
          },
        ])
      );
    }
  }

  const handleExport = () => {
    selectShape(null);
    setTimeout(() => {
      const uri = stageRef.current.toDataURL();
      const nome_paciente = localStorage
        .getItem('@nome_paciente')
        .replaceAll(' ', '_');
      const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
      downloadURI(uri, filename, sendArquivo, os, token, nome_recurso_cod);
    }, 500);
  };

  function sendImage(imagesData) {
    const messageObject = {
      body: { imagesData: imagesData },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  function requestDominosFromProfissional() {
    const messageObject = {
      body: { requestDominos: true },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  function receivedMessage(message) {
    if (message.body.imagesData) {
      setImages(message.body.imagesData);
    }
    if (message.body.usedPacientIndexes !== undefined) {
      setUsedPacientIndexes(message.body.usedPacientIndexes);
      setUsedProfissionalIndexes(message.body.usedProfissionalIndexes);
    }
    if (message.body.pacientDominos !== undefined) {
      setPacientDominos(message.body.pacientDominos);
      setProfissionalDominos(message.body.profissionalDominos);
    }
    if (message.body.requestDominos) {
      shuffleDominos(allDominos);
    }
  }

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);
    if (typeUser === 'profissional') {
      shuffleDominos(allDominos);
      modalOpen(nome_recurso_cod, tokenSocketIo);
    } else {
      requestDominosFromProfissional();
    }
  }, []);

  return (
    <Container id="container">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <HexagonalDominoTabs
          dragUrl={dragUrl}
          dominos={
            typeUser === 'profissional' ? profissionalDominos : pacientDominos
          }
          usedIndexes={
            typeUser === 'profissional'
              ? usedProfissionalIndexes
              : usedPacientIndexes
          }
          setUsedIndexes={
            typeUser === 'profissional'
              ? setUsedProfissionalIndexes
              : setUsedPacientIndexes
          }
          mobileTouchHandler={onDropMobileHandler}
        />
        <div
          style={{
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <SaveArea>
            {typeUser === 'profissional' && (
              <TertiaryButton onClick={() => setModalCleanIsOpen(true)}>
                Limpar
              </TertiaryButton>
            )}
            {typeUser === 'profissional' && (
              <SecondaryButton onClick={() => setIsOpenAlert(true)}>
                Instruções
              </SecondaryButton>
            )}
            {typeUser === 'profissional' && (
              <SecondaryButton onClick={salvarFim}>Salvar</SecondaryButton>
            )}
            {typeUser === 'profissional' && (
              <SecondaryButton onClick={() => setModalCloseIsOpen(true)}>
                Fechar
              </SecondaryButton>
            )}
          </SaveArea>
          <BoardBox
            style={{
              marginTop: typeUser === 'paciente' ? 50 : 0,
            }}
            onDrop={(e) => onDropMobileHandler(e)}
            onDragOver={(e) => {
              e.preventDefault();
            }}
          >
            <Stage
              width={innerWidthSize}
              height={proportionalStageHeight}
              scaleX={scaleX}
              scaleY={scaleY}
              onMouseDown={checkDeselect}
              onTouchStart={checkDeselect}
              ref={stageRef}
            >
              <Layer>
                {images.map((image, i) => {
                  return (
                    <MountUrlImage
                      image={image}
                      key={i}
                      shapeProps={image}
                      isSelected={image.alt === selectedId}
                      onSelect={() => {
                        if (image.drag) selectShape(image.alt);
                      }}
                      onChange={(newAttrs) => {
                        const aux = images.slice();
                        aux[i] = newAttrs;
                        setImages(aux);
                        sendImage(aux);
                      }}
                      resizable={false}
                      rotatable={true}
                      rotationSnaps={[0, 60, 120, 180, 240, 300]}
                      rotationTolerance={30}
                    />
                  );
                })}
                {typeUser === 'profissional' && (
                  <Text x={20} y={15} text={PacientName()} fontSize={24} />
                )}
              </Layer>
            </Stage>
          </BoardBox>
        </div>
      </div>
      <ModalAddOps
        generatePdf={handleExport}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        token={token}
      />
      <ModalAlert
        modalIsOpen={modalIsOpenAlert}
        setIsOpen={setIsOpenAlert}
        token={token}
        props={props}
        title={'Dominó Hexagonal'}
        recurso={nome_recurso_cod}
        bt01Txt={'Fechar'}
      />
      <ModalConfirm
        title="Limpar o jogo"
        description="Deseja realmente limpar o jogo?"
        modalIsOpen={modalCleanIsOpen}
        setIsOpen={setModalCleanIsOpen}
        confirmButtonText="Limpar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCleanIsOpen(false)}
        onConfirm={() => handleClean()}
      />
      <ModalConfirm
        title="Fechar o jogo"
        description="Deseja realmente fechar o jogo?"
        modalIsOpen={modalCloseIsOpen}
        setIsOpen={setModalCloseIsOpen}
        confirmButtonText="Fechar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCloseIsOpen(false)}
        onConfirm={() =>
          handleBackHome(
            modalClose,
            nome_recurso_cod,
            typeUser,
            props,
            os,
            token,
            ambiente
          )
        }
      />
    </Container>
  );
};
export default HexagonalDomino;
