import React, { useState, useRef, useEffect } from 'react';

import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  PacientName,
  DataURL,
} from '@utils';

//Styles import
import { Container, CentralRow, SaveArea, BoardBox } from './styles';

//Image imports
import { GoodVsBadBackground } from '@assets';

//Components import
import {
  ModalAddOps,
  ModalAlert,
  PrimaryButton,
  TertiaryButton,
  ModalConfirm,
} from '@components';

import GoodVsBadTabs from '@components/GoodVsBad/GoodVsBadTabs';

//Drag'n Drop imports
import { Stage, Layer, Text } from 'react-konva';
import MountUrlImage from '@components/MountUrlImage';

export default function GoodVsBad(props) {
  //URL variables
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  //Socket variables
  const [yourID, setYourID] = useState();
  const socketRef = useRef();

  //Modal variables
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCleanIsOpen, setModalCleanIsOpen] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);

  //Control variables
  const [usedIndexes, setUsedIndexes] = useState([]);
  const [print, setPrint] = useState(false);

  //Drag'n Drop variables
  const dragUrl = useRef();
  const stageWidth = 1050;
  const stageHeight = 1300;
  const innerWidthSize =
    window.innerWidth > stageWidth ? stageWidth : window.innerWidth;
  const proportionalStageHeight = (innerWidthSize * stageHeight) / stageWidth;
  const scaleX = innerWidthSize / stageWidth;
  const scaleY = proportionalStageHeight / stageHeight;
  const stageRef = React.useRef();
  const [images, setImages] = React.useState([
    {
      x: 85,
      y: 70,
      src: GoodVsBadBackground,
      height: 1300,
      width: 1050,
      drag: false,
    },
  ]);
  const [selectedId, selectShape] = useState(null);

  //*************************************************************************************************************** */
  //Functions

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  const handleExport = () => {
    selectShape(null);
    setPrint(true);
    setTimeout(() => {
      const uri = stageRef.current.toDataURL();
      const nome_paciente = localStorage
        .getItem('@nome_paciente')
        .replaceAll(' ', '_');
      const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
      downloadURI(uri, filename, sendArquivo, os, token, nome_recurso_cod);
      setPrint(false);
      setImages(images);
    }, 500);
  };

  const salvarFim = () => {
    setIsOpen(true);
  };

  function sendImage(imagesData, usdIndexes) {
    const messageObject = {
      body: { imagesData: imagesData, usedIndexes: usdIndexes },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  const handleClean = () => {
    setImages([
      {
        x: 1240,
        y: 1735,
        src: GoodVsBadBackground,
        height: 1300,
        width: 1050,
        drag: false,
        preventDefault: false,
      },
    ]);
    sendImage(
      [
        {
          x: 1240,
          y: 1735,
          src: GoodVsBadBackground,
          height: 1300,
          width: 1050,
          drag: false,
        },
      ],
      [] //Aqui ele envia o array vazio para que esvazie o array de indexes usados na outra tela
    );

    setUsedIndexes([]);
    setModalCleanIsOpen(false);
  };

  function receivedMessage(message) {
    setImages(message.body.imagesData);
    setUsedIndexes(message.body.usedIndexes);
  }

  function onDropMobileHandler(e) {
    e.preventDefault();
    if (dragUrl.current !== null) {
      stageRef.current.setPointersPositions(e);
      let Xcoordinate = stageRef.current.getPointerPosition().x / scaleX;
      let Ycoordinate = stageRef.current.getPointerPosition().y / scaleY;
      const nameFile = 100000 + Math.floor((999999 - 100000) * Math.random());
      selectShape(nameFile);
      setImages(
        images.concat([
          {
            x: Xcoordinate,
            y: Ycoordinate,
            src: dragUrl.current,
            drag: true,
            alt: nameFile,
          },
        ])
      );
      sendImage(
        images.concat([
          {
            x: Xcoordinate,
            y: Ycoordinate,
            src: dragUrl.current,
            drag: true,
            alt: nameFile,
          },
        ])
      );
    }
  }

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);
    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, tokenSocketIo);
    }
  }, [nome_recurso_cod, tokenSocketIo, typeUser]);

  return (
    <Container>
      <CentralRow>
        <GoodVsBadTabs
          dragUrl={dragUrl}
          usedIndexes={usedIndexes}
          setUsedIndexes={setUsedIndexes}
          mobileTouchHandler={onDropMobileHandler}
        />

        <div
          style={{
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <SaveArea>
            <TertiaryButton onClick={() => setModalCleanIsOpen(true)}>
              Limpar
            </TertiaryButton>
            {typeUser === 'profissional' && (
              <PrimaryButton onClick={() => setIsOpenAlert(true)}>
                Instruções
              </PrimaryButton>
            )}
            {typeUser === 'profissional' && (
              <PrimaryButton onClick={salvarFim}>Salvar</PrimaryButton>
            )}
            {typeUser === 'profissional' && (
              <PrimaryButton onClick={() => setModalCloseIsOpen(true)}>
                Fechar
              </PrimaryButton>
            )}
          </SaveArea>
          <BoardBox
            onDrop={(e) => onDropMobileHandler(e)}
            onDragOver={(e) => {
              e.preventDefault();
            }}
          >
            <Stage
              width={print ? stageWidth : innerWidthSize / 1.3}
              height={print ? stageHeight : proportionalStageHeight / 1.3}
              scaleX={print ? 1 : scaleX / 1.3}
              scaleY={print ? 1 : scaleY / 1.3}
              onMouseDown={checkDeselect}
              onTouchStart={checkDeselect}
              ref={stageRef}
            >
              <Layer>
                {images.map((image, i) => {
                  return (
                    <MountUrlImage
                      image={image}
                      key={i}
                      shapeProps={image}
                      isSelected={image.alt === selectedId}
                      onSelect={() => {
                        if (image.drag) selectShape(image.alt);
                      }}
                      onChange={(newAttrs) => {
                        const aux = images.slice();
                        aux[i] = newAttrs;
                        setImages(aux);
                        sendImage(aux, usedIndexes);
                      }}
                      width={170}
                      height={170}
                      resizable={false}
                      rotatable={false}
                    />
                  );
                })}
                {typeUser === 'profissional' && (
                  <Text x={10} y={1255} text={PacientName()} fontSize={24} />
                )}
              </Layer>
            </Stage>
          </BoardBox>
        </div>
      </CentralRow>
      <ModalAddOps
        generatePdf={handleExport}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        token={token}
      />
      <ModalAlert
        modalIsOpen={modalIsOpenAlert}
        setIsOpen={setIsOpenAlert}
        token={token}
        props={props}
        title={'Bom vs Mau Comportamento'}
        recurso={nome_recurso_cod}
        bt01Txt={'Fechar'}
      />
      <ModalConfirm
        title="Limpar o jogo"
        description="Deseja realmente limpar o jogo?"
        modalIsOpen={modalCleanIsOpen}
        setIsOpen={setModalCleanIsOpen}
        confirmButtonText="Limpar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCleanIsOpen(false)}
        onConfirm={() => handleClean()}
      />
      <ModalConfirm
        title="Fechar o jogo"
        description="Deseja realmente fechar o jogo?"
        modalIsOpen={modalCloseIsOpen}
        setIsOpen={setModalCloseIsOpen}
        confirmButtonText="Fechar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCloseIsOpen(false)}
        onConfirm={() =>
          handleBackHome(
            modalClose,
            nome_recurso_cod,
            typeUser,
            props,
            os,
            token,
            ambiente
          )
        }
      />
    </Container>
  );
}
