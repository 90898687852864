import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;

  width: 100%;
  height: 100%;

  overflow-x: hidden;
`;

export const CentralRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    padding: 0;
    margin: 0;
    margin-left: 10px;
    font-size: 26px;
  }

  .alignment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 800px) {
    margin-left: 60px;

    .alignment {
      align-items: center;
    }
  }
`;

export const SaveArea = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  @media (max-width: 800px) {
    align-items: flex-start;
  }
`;

export const BoardBox = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  border: 0;
  justify-content: center;
  align-items: center;
`;
