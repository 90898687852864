import React, { useState } from 'react';

/** Imagens */
import { ShowTheWayArrows, ShowTheWayObjects } from '../../../assets';

//Mobile detection import
import { isMobile } from 'react-device-detect';

import {
  Container,
  Tab,
  Row,
  TabPanel,
  BackgroundCircle,
  MobileContainer,
  MobileTab,
  MobileTabContainer,
  MobileTabsCol,
} from './styles';

export default function ShowTheWayTabs({
  dragUrl,
  typeUser,
  mobileTouchHandler,
}) {
  const [selectedTab, setSelectedTab] = useState(
    typeUser === 'profissional' ? -1 : 1
  );
  return (
    <>
      {!isMobile && (
        <Container>
          <Row>
            {typeUser === 'profissional' && (
              <Tab
                isSelected={selectedTab <= 0}
                onClick={() => setSelectedTab(0)}
              >
                <BackgroundCircle isSelected={selectedTab === 0}>
                  <img src={ShowTheWayObjects[0]} alt={ShowTheWayObjects[0]} />
                </BackgroundCircle>
              </Tab>
            )}
            <Tab
              isSelected={selectedTab === 1}
              onClick={() => setSelectedTab(1)}
            >
              <BackgroundCircle isSelected={selectedTab === 1}>
                <img src={ShowTheWayArrows[0]} alt={ShowTheWayArrows[0]} />
              </BackgroundCircle>
            </Tab>
          </Row>
          <TabPanel typeUser={typeUser} selectedTab={selectedTab}>
            {ShowTheWayObjects &&
              typeUser === 'profissional' &&
              selectedTab <= 0 &&
              ShowTheWayObjects.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                />
              ))}
            {ShowTheWayArrows &&
              selectedTab === 1 &&
              ShowTheWayArrows.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                />
              ))}
          </TabPanel>
        </Container>
      )}

      {isMobile && (
        <MobileContainer>
          <MobileTabContainer isOpen={selectedTab !== -1}>
            {ShowTheWayObjects &&
              typeUser === 'profissional' &&
              selectedTab === 0 &&
              ShowTheWayObjects.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchEnd={(e) => mobileTouchHandler(e)}
                />
              ))}
            {ShowTheWayArrows &&
              selectedTab === 1 &&
              ShowTheWayArrows.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchEnd={(e) => mobileTouchHandler(e)}
                />
              ))}
          </MobileTabContainer>
          <MobileTabsCol>
            {(selectedTab === -1 || selectedTab === 0) &&
              typeUser === 'profissional' && (
                <MobileTab
                  onClick={() => setSelectedTab(selectedTab === 0 ? -1 : 0)}
                >
                  <img src={ShowTheWayObjects[0]} alt={ShowTheWayObjects[0]} />
                </MobileTab>
              )}
            {(selectedTab === -1 || selectedTab === 1) && (
              <MobileTab
                onClick={() => setSelectedTab(selectedTab === 1 ? -1 : 1)}
              >
                <img src={ShowTheWayArrows[0]} alt={ShowTheWayArrows[0]} />
              </MobileTab>
            )}
          </MobileTabsCol>
        </MobileContainer>
      )}
    </>
  );
}
