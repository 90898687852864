import React from 'react';

import { Container, Tab, TabPanel, BackgroundCircle } from './styles';
/** Imagens */
import { RelateTheColorsEasyColors } from '../../../assets';

export default function EasyTab({ dragUrl, usedIndexes, setUsedIndexes }) {
  const setImageGrey = (index) => {
    let indexArr = usedIndexes;
    indexArr.push(index);
    setUsedIndexes(indexArr);
  };
  return (
    <Container>
      <Tab>
        <BackgroundCircle>
          <img
            src={RelateTheColorsEasyColors[0]}
            alt={RelateTheColorsEasyColors[0]}
          />
        </BackgroundCircle>
      </Tab>
      <TabPanel>
        {RelateTheColorsEasyColors &&
          RelateTheColorsEasyColors.map((item, index) => (
            <img
              width={'90px'}
              style={{
                marginLeft: ' 20px',
                cursor: 'pointer',
                filter: `grayscale(${
                  usedIndexes !== undefined && usedIndexes.includes(index)
                    ? '100%'
                    : '0%'
                })`,
                userSelect: 'none',
              }}
              alt={item}
              src={item}
              draggable={
                usedIndexes !== undefined && usedIndexes.includes(index)
                  ? 'false'
                  : 'true'
              }
              onDragStart={(e) => {
                if (usedIndexes !== undefined && usedIndexes.includes(index)) {
                  dragUrl.current = null;
                } else {
                  dragUrl.current = e.target.src;
                  setImageGrey(index);
                }
              }}
            />
          ))}
      </TabPanel>
    </Container>
    // <div>
    //   <div>
    //     <Tabs>
    //       <TabList>
    //         <Tab>Cores</Tab>
    //       </TabList>

    //       <TabPanel
    //         style={{
    //           height: '530px',
    //           width: '250px',
    //           overflow: 'auto',
    //           border: '1px solid #999',
    //           borderRadius: '0 0 0 5px',
    //           marginTop: '-11px',
    //           paddingTop: '10px',
    //           justifyContent: 'center',
    //           alignItems: 'center',
    //         }}
    //       >
    //         {RelateTheColorsEasyColors &&
    //           RelateTheColorsEasyColors.map((item, index) => (
    //             <img
    //               width={'90px'}
    //               style={{
    //                 marginLeft: ' 20px',
    //                 cursor: 'pointer',
    //                 filter: `grayscale(${
    //                   usedIndexes !== undefined && usedIndexes.includes(index)
    //                     ? '100%'
    //                     : '0%'
    //                 })`,
    //                 userSelect: 'none',
    //               }}
    //               alt={item}
    //               src={item}
    //               draggable={
    //                 usedIndexes !== undefined && usedIndexes.includes(index)
    //                   ? 'false'
    //                   : 'true'
    //               }
    //               onDragStart={(e) => {
    //                 if (
    //                   usedIndexes !== undefined &&
    //                   usedIndexes.includes(index)
    //                 ) {
    //                   dragUrl.current = null;
    //                 } else {
    //                   dragUrl.current = e.target.src;
    //                   setImageGrey(index);
    //                 }
    //               }}
    //             />
    //           ))}
    //       </TabPanel>
    //     </Tabs>
    //   </div>
    // </div>
  );
}
