/* eslint-disable no-unused-expressions */
import styled from 'styled-components';

import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  max-width: 100vw;
  min-height: 200px;

  padding: 0 10vw 200px;

  background: #fff;

  h2 {
    text-align: center;
    font-family: 'Roboto', sans-serif;
  }

  overflow: hidden;

  span {
    margin-bottom: 100px;
    font-weight: 700;
    font-size: 2rem;
    text-align: center;
    color: #502d61;
  }

  @media (max-width: 700px) {
    padding: 50px 0px;
  }

  @media print {
    .page-break {
      margin-top: 15rem;
      display: flex;
      flex-direction: row;
      page-break-before: auto;
    }
  }
`;

export const FormTitle = styled.div`
  display: flex;

  flex-direction: column;

  align-items: center;
  padding: 10px 0 0 0;
  h1 {
    color: #502d61;
    margin: 0;
    font-family: 'Marcellus SC', serif;
    text-align: center;
  }
  span {
    margin: 0;
    font-family: 'Marcellus SC', serif;
    background: #95b389;
    text-align: center;

    padding: 10px 150px;

    clip-path: polygon(
      2% 97%,
      1% 94%,
      3% 90%,
      1% 84%,
      2% 78%,
      4% 74%,
      2% 70%,
      1% 62%,
      3% 60%,
      4% 55%,
      2% 52%,
      1% 44%,
      3% 43%,
      5% 37%,
      2% 32%,
      1% 24%,
      2% 19%,
      1% 14%,
      1% 7%,
      3% 3%,
      8% 3%,
      14% 5%,
      21% 4%,
      30% 3%,
      37% 4%,
      44% 5%,
      50% 4%,
      55% 3%,
      62% 3%,
      68% 4%,
      73% 4%,
      80% 5%,
      85% 4%,
      93% 5%,
      98% 4%,
      96% 10%,
      98% 16%,
      98% 24%,
      96% 31%,
      99% 36%,
      97% 44%,
      99% 50%,
      99% 59%,
      97% 65%,
      99% 71%,
      99% 79%,
      97% 84%,
      99% 91%,
      98% 97%,
      92% 96%,
      86% 96%,
      76% 95%,
      63% 96%,
      53% 97%,
      42% 97%,
      33% 98%,
      26% 95%,
      17% 96%,
      11% 98%
    );
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  flex-grow: 0;

  @media (max-width: 786px) {
    flex-direction: column;
  }
`;

export const MusicContainer = styled.div`
  img:nth-child(1) {
    margin-top: 100px;
    margin-left: 100px;

    height: 280px;
    width: 280px;

    animation: ${(props) => {
      if (props.focusedField === 'Músicas') {
        return `jingleRight 2s infinite`;
      } else {
        return `none`;
      }
    }};
  }

  @media (max-width: 800px) {
    display: none;
  }

  @keyframes jingleRight {
    0% {
      transform: rotate(20deg);
    }
    50% {
      transform: rotate(-20deg);
    }
    100% {
      transform: rotate(20deg);
    }
  }
  @keyframes jingleLeft {
    0% {
      transform: rotate(-20deg);
    }
    50% {
      transform: rotate(20deg);
    }
    100% {
      transform: rotate(-20deg);
    }
  }
`;

export const PawContainer = styled.div`
  display: grid;
  align-items: center;

  width: 20rem;
  height: 18rem;

  margin-top: 100px;

  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    'empty leftstep'
    'leftback empty2';

  img {
    height: 60px;
    width: 60px;

    margin-left: 30px;

    transform: rotate(30deg);

    grid-area: leftback;

    animation: ${(props) => {
      if (props.focusedField === 'Animais') {
        return `Lstep 6s infinite`;
      } else {
        return `none`;
      }
    }};

    & + img {
      height: 60px;
      width: 60px;
      grid-area: empty2;
      transition-delay: 20s;
    }

    @keyframes Lstep {
      0% {
        transform: translate(0px, 0px) rotate(30deg) scale(1);
      }

      25% {
        transform: translate(50px, -50px) rotate(30deg) scale(1.2);
      }

      50% {
        transform: translate(100px, -100px) rotate(30deg) scale(1);
      }
      75% {
        transform: translate(50px, -50px) rotate(30deg) scale(1.2);
      }

      100% {
        transform: translate(0px, 0px) rotate(30deg) scale(1);
      }
    }
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

export const CloverContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-top: 100px;
    width: 300px;
    height: 350px;
    animation: ${(props) => {
      if (props.focusedField === 'Amuletos') {
        return `round 4s infinite`;
      } else {
        return `none`;
      }
    }};

    @keyframes round {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  @media (max-width: 800px) {
    display: none;
  }
`;
