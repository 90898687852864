import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

  html {
    @media(max-width: 786px) {
      overflow: hidden;
      width: 100%;
    }
  }

  body {
    @media(max-width: 786px) {
      height: 100%;
      position: fixed;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 786px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const StageBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 24px;
`;

export const ToolsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px 0;
  width: 350px;

  @media (max-width: 786px) {
    flex-direction: column-reverse;
    padding-bottom: 0;
  }
`;

export const ToolsButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 90px;
  align-items: center;
  justify-content: center;

  @media (max-width: 786px) {
    width: 84%;
    height: 80px;
  }
`;

export const ToolsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 786px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ToolButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.large ? '72px' : '34px')};
  height: 34px;
  border: none;
  border-radius: 3px;
  color: #2797ba;
  margin: 2px;
  padding: 4px;
  background-color: #f5f5f5;
  border: 1px solid #2797ba;
  cursor: pointer;
  transition: 0.3s;
  position: relative;

  svg {
    font-size: 17px;
  }

  @media (max-width: 786px) {
    width: ${(props) => (props.large ? '88px' : '42px')};
    height: 36px;

    :focus {
      background-color: #2797ba;
      color: #f5f5f5;
    }
  }

  @media (min-width: 786px) {
    :hover {
      background-color: #2797ba;
      color: #f5f5f5;
    }
  }
`;

export const Slider = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  font-size: 20px;
  color: #767676;
  position: relative;
  margin-left: 12px;

  @media (max-width: 786px) {
    padding: 0;
    padding-top: 14px;
  }

  input[type='range'] {
    -webkit-appearance: none;
    width: 140px;
    height: 7px;
    background: #fefefe;
    border-radius: 5px;
    border: 1px solid #2797ba;
    background-repeat: no-repeat;

    @media (max-width: 786px) {
      width: 240px;
    }
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #2797ba;
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
    transition: background 0.3s ease-in-out;
  }

  input[type='range']::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  :hover span {
    visibility: visible;
    top: -30px;
    left: 60px;
    z-index: 999;
  }
`;

export const SaveArea = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
`;

export const ColorPickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;

  @media (max-width: 786px) {
    flex-direction: row;
    width: 100%;
    margin-top: 10px;
  }
`;

export const Display = styled.div`
  width: 35px;
  height: 35px;
  border: 1px solid #90a4ae;
  border-radius: 2px;
  margin-bottom: 8px;

  @media (max-width: 786px) {
    flex-direction: row;
    margin-bottom: 0;
  }
`;

export const TextInputBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 786px) {
    position: absolute;
    top: 490px;
  }

  h3 {
    font-size: 14px;
    font-weight: 700;
    line-height: 10px;
    margin-bottom: 8px;
    color: #2797ba;
  }

  textarea {
    border: 1px solid #2797ba;
    height: 60px;
    width: 124px;
    font-size: 12px;
    resize: none;
  }

  div {
    display: flex;
    width: 130px;
    align-items: center;
    justify-content: space-around;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 20px;
    border: 1px solid #2797ba;
    border-radius: 4px;
    color: #2797ba;
    background-color: #f5f5f5;
    margin-top: 10px;
    font-size: 11px;
    font-weight: 700;
    cursor: pointer;
    margin-right: 2px;
    margin-left: 2px;
  }
`;
