/* eslint-disable no-unused-expressions */
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    position: relative;
    top: -5px;

    font-family: 'Roboto', serif;
    font-weight: 500;
    font-size: 24px;
    color: #3d3d3d;

    margin: 0;
  }
  h2 {
    font-family: 'Roboto', serif;
    font-weight: 400;
    font-size: 18px;
    color: #4c5153;

    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

export const BoxSession = styled.div`
  border: 1px solid #333;
  border-radius: 5px;
  padding: 15px;
  margin: auto;
  width: 250px;
  margin-left: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  float: left;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
`;
