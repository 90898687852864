//Main imports
import React, { useState, useEffect, useRef } from 'react';

//Socket & API imports
import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  PacientName,
  DataURL,
} from '@utils';

//Styles import
import {
  CentralContainer,
  Container,
  DragDropRow,
  SaveArea,
  ParkingBox,
  ColCentered,
  DragDropContainer,
} from './styles';

//Image imports
import { ParkingCards, EmptyParking } from '@assets';

//Components import
import ParkingTabs from '@components/Parking/ParkingTabs';

import { Stage, Layer, Text, Image } from 'react-konva';
import useImage from 'use-image';
import WaitingPacientScreen from '@components/WaitingPacientScreen';

import {
  ModalAddOps,
  ModalAlert,
  TertiaryButton,
  PrimaryButton,
  ModalConfirm,
} from '@components';
import MountUrlImage from '@components/MountUrlImage';

const Parking = (props) => {
  //URL variables
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  //Drag'n Drop variables
  const innerWidthSize = window.innerWidth > 630 ? 630 : window.innerWidth;
  const proportionalStageHeight = (innerWidthSize * 639) / 630;
  const scaleX = innerWidthSize / 630;
  const scaleY = proportionalStageHeight / 639;
  const dragUrl = useRef();
  const stageRef = React.useRef();
  const [selectedId, selectShape] = useState(null);
  const [images, setImages] = useState([]);
  const [imgEmptyParking] = useImage(EmptyParking);

  //Socket variables
  const [yourID, setYourID] = useState();
  const socketRef = useRef();

  //Modal variables
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCleanIsOpen, setModalCleanIsOpen] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);

  //Control variables
  const [page, setPage] = useState(0);
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);

  //******************************************************************************************* */
  //Functions

  function receivedMessage(message) {
    if (message.body.imagesData) setImages(message.body.imagesData);
    if (message.body.page) setPage(message.body.page);
    if (message.body.index) setSelectedCardIndex(message.body.index);
  }

  function changePageOnSocket(pg) {
    const messageObject = {
      body: { page: pg },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  function changeCardIndexOnSocket(ind) {
    const messageObject = {
      body: { index: ind },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  function sendImage(imagesData) {
    const messageObject = {
      body: { imagesData: imagesData },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  const salvarFim = () => {
    setIsOpen(true);
  };

  const handleClean = () => {
    setImages([]);
    sendImage([]);
    setModalCleanIsOpen(false);
  };

  const handleExport = () => {
    selectShape(null);
    setTimeout(() => {
      const uri = stageRef.current.toDataURL();
      const nome_paciente = localStorage
        .getItem('@nome_paciente')
        .replaceAll(' ', '_');
      const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
      downloadURI(uri, filename, sendArquivo, os, token, nome_recurso_cod);
    }, 500);
  };

  function onDropMobileHandler(e) {
    e.preventDefault();
    if (dragUrl.current !== null) {
      stageRef.current.setPointersPositions(e);
      let Xcoordinate = stageRef.current.getPointerPosition().x / scaleX;
      let Ycoordinate = stageRef.current.getPointerPosition().y / scaleY;
      const nameFile = 100000 + Math.floor((999999 - 100000) * Math.random());
      selectShape(nameFile);
      setImages(
        images.concat([
          {
            x: Xcoordinate,
            y: Ycoordinate,
            src: dragUrl.current,
            drag: true,
            alt: nameFile,
          },
        ])
      );
      sendImage(
        images.concat([
          {
            x: Xcoordinate,
            y: Ycoordinate,
            src: dragUrl.current,
            drag: true,
            alt: nameFile,
          },
        ])
      );
    }
  }

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);

    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, tokenSocketIo);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  return (
    <Container>
      {typeUser === 'profissional' && page === 0 && (
        <h2>Por favor selecione uma das opções de memória</h2>
      )}
      {typeUser === 'profissional' && page !== 2 && (
        <PrimaryButton
          style={{ marginBottom: 30, marginTop: 10 }}
          onClick={() => setIsOpenAlert(true)}
          mobile
        >
          Instruções
        </PrimaryButton>
      )}
      {typeUser === 'paciente' && page === 0 && (
        <WaitingPacientScreen text="Aguarde enquanto o profissional escolhe uma carta" />
      )}
      {page === 0 && typeUser === 'profissional' && (
        <CentralContainer>
          {ParkingCards &&
            page === 0 &&
            typeUser === 'profissional' &&
            ParkingCards.map((image, index) => (
              <>
                <img
                  src={image}
                  alt="a"
                  onClick={() => {
                    setSelectedCardIndex(index);
                    setPage(1);
                    changePageOnSocket(1);
                    changeCardIndexOnSocket(index);
                  }}
                />
              </>
            ))}
        </CentralContainer>
      )}

      {page === 1 && (
        <ColCentered>
          {typeUser === 'paciente' && (
            <h2>Memorize as posições dos carros abaixo</h2>
          )}
          {typeUser === 'profissional' && (
            <h2>
              Após o tempo de memorização do paciente, clique no botão abaixo da
              imagem
            </h2>
          )}
          <img src={ParkingCards[selectedCardIndex]} alt="Carta" width={300} />
          {typeUser === 'profissional' && (
            <PrimaryButton
              size="lg"
              style={{ marginTop: 20, maxWidth: 180 }}
              onClick={() => {
                setPage(2);
                changePageOnSocket(2);
              }}
              mobile
            >
              Iniciar montagem
            </PrimaryButton>
          )}
        </ColCentered>
      )}

      {page === 2 && (
        <DragDropRow>
          <ParkingTabs
            dragUrl={dragUrl}
            mobileTouchHandler={onDropMobileHandler}
          />
          <DragDropContainer>
            <SaveArea>
              <TertiaryButton
                style={{ fontSize: 16, width: 100 }}
                onClick={() => setModalCleanIsOpen(true)}
                mobile
              >
                Limpar
              </TertiaryButton>
              {typeUser === 'profissional' && (
                <PrimaryButton
                  style={{ fontSize: 16, width: 100 }}
                  onClick={() => setIsOpenAlert(true)}
                  mobile
                >
                  Instruções
                </PrimaryButton>
              )}
              {typeUser === 'profissional' && (
                <PrimaryButton
                  style={{ fontSize: 16, width: 100 }}
                  onClick={salvarFim}
                  mobile
                >
                  Salvar
                </PrimaryButton>
              )}
              {typeUser === 'profissional' && (
                <PrimaryButton
                  style={{ fontSize: 16, width: 100 }}
                  onClick={() => setModalCloseIsOpen(true)}
                  mobile
                >
                  Fechar
                </PrimaryButton>
              )}
              {typeUser === 'profissional' && (
                <TertiaryButton
                  onClick={() => {
                    handleClean();
                    setPage(0);
                    changePageOnSocket(0);
                  }}
                  style={{ fontSize: 16 }}
                  mobile
                >
                  Escolher outra carta
                </TertiaryButton>
              )}
            </SaveArea>
            <ParkingBox
              className={'containerr'}
              onDrop={(e) => onDropMobileHandler(e)}
              onDragOver={(e) => e.preventDefault()}
            >
              <Stage
                width={innerWidthSize}
                height={proportionalStageHeight}
                scaleX={scaleX}
                scaleY={scaleY}
                onMouseDown={checkDeselect}
                onTouchStart={checkDeselect}
                ref={stageRef}
              >
                <Layer>
                  <Image
                    x={0}
                    y={0}
                    width={630}
                    height={639}
                    image={imgEmptyParking}
                    preventDefault={false}
                  />
                  {images?.map((image, i) => {
                    return (
                      <MountUrlImage
                        image={image}
                        key={i}
                        shapeProps={image}
                        isSelected={image.alt === selectedId}
                        onSelect={() => {
                          if (image.drag) selectShape(image.alt);
                        }}
                        onChange={(newAttrs) => {
                          const aux = images.slice();
                          aux[i] = newAttrs;
                          setImages(aux);
                          sendImage(aux);
                        }}
                        resizable={false}
                        rotatable={false}
                      />
                    );
                  })}
                  {typeUser === 'profissional' && (
                    <Text x={10} y={15} text={PacientName()} fontSize={24} />
                  )}
                </Layer>
              </Stage>
            </ParkingBox>
          </DragDropContainer>
          {typeUser === 'profissional' && (
            <div style={{ textAlign: 'center', marginLeft: 10 }}>
              <h1>Resposta:</h1>
              <img
                src={ParkingCards[selectedCardIndex]}
                alt="Carta"
                style={{ width: '200px' }}
              />
            </div>
          )}
        </DragDropRow>
      )}

      <ModalAddOps
        generatePdf={handleExport}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        token={token}
      />
      <ModalAlert
        modalIsOpen={modalIsOpenAlert}
        setIsOpen={setIsOpenAlert}
        token={token}
        props={props}
        title={'Mostre o caminho'}
        recurso={nome_recurso_cod}
        bt01Txt={'Fechar'}
      />
      <ModalConfirm
        title="Limpar o jogo"
        description="Deseja realmente limpar o jogo?"
        modalIsOpen={modalCleanIsOpen}
        setIsOpen={setModalCleanIsOpen}
        confirmButtonText="Limpar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCleanIsOpen(false)}
        onConfirm={() => handleClean()}
      />
      <ModalConfirm
        title="Fechar o jogo"
        description="Deseja realmente fechar o jogo?"
        modalIsOpen={modalCloseIsOpen}
        setIsOpen={setModalCloseIsOpen}
        confirmButtonText="Fechar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCloseIsOpen(false)}
        onConfirm={() =>
          handleBackHome(
            modalClose,
            nome_recurso_cod,
            typeUser,
            props,
            os,
            token,
            ambiente
          )
        }
      />
    </Container>
  );
};

export default Parking;
