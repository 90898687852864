import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: #fff;

  flex-wrap: wrap;
  flex-direction: row;
  min-height: 500px;

  @media (max-width: 550px) {
    background-color: ${(props) => props.backgroundColor};
  }
`;

export const TopBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  align-items: center;

  div {
    width: 100%;
    max-width: 1100px;

    @media screen and (min-width: 550px) and (max-width: 986px) {
      max-width: 85%;
    }

    @media screen and (min-width: 986px) and (max-width: 1126px) {
      max-width: 95%;
    }
  }

  h2 {
    color: #333333;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    font-family: roboto;
    @media screen and (min-width: 380px) and (max-width: 450px) {
      width: ${(props) => props.HeaderWidth};
    }

    @media (min-width: 450px) {
      margin-top: 60px;
    }
  }

  p {
    color: #4c5153;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    font-family: roboto;
  }

  @media (max-width: 550px) {
    justify-content: flex-start;
    margin-left: 5%;
    margin-right: 5%;

    h2 {
      color: ${(props) => props.HeaderColor};
    }

    p {
      color: ${(props) => props.ParagraphColor};
    }
  }
`;

export const ButtonSave = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 24px;
  position: absolute;
  right: 5px;
  z-index: 1;

  button {
    width: 112px;
    height: 32px;
    background-color: #f5f5f5;
    border: 1px solid #0062cc;
    border-radius: 2px;
    font-size: 16px;
    color: #0062cc;
    margin-left: 30px;
    cursor: pointer;
    transition: 0.25s;

    :hover {
      background-color: #0062cc;
      color: #f5f5f5;
    }

    @media (max-width: 558px) {
      display: none;
    }
  }

  img {
    display: none;
    width: 22px;
    cursor: pointer;
    @media screen and (min-width: 320px) and (max-width: 558px) {
      display: inline;
      color: white;
    }
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  font-style: normal;

  @media (max-width: 986px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Cardcontainer = styled.div`
  width: 500px;
  height: 220px;
  margin: 40px;
  margin-right: ${(props) => (props.recursos ? '11px' : '24px')};
  margin-left: ${(props) => (props.recursos ? '24px' : '11px')};
  padding: 0px 16px 44px 20px;
  background: #f5f5f5;
  border-radius: 8px;

  @media (max-width: 986px) {
    width: 80%;
    height: 234px;
    margin: 10px 0;
  }
`;

export const Title = styled.h2`
  color: #677176;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  font-family: roboto;

  @media (max-width: 786px) {
    font-size: 18px;
  }
`;

export const CardBody = styled.div`
  display: flex;
  /* padding-top: 20px; */
`;

export const ImageBox = styled.div`
  height: 184px;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 152px;
  padding-right: 16px;

  img {
    height: ${(props) => (props.cactus ? '180px' : '128px')};
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #677176;
    font-family: roboto;
  }

  @media (max-width: 786px) {
    height: 200px;
    p {
      font-size: 14px;
    }
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;

  button {
    width: 268px;
    height: 32px;
    background-color: #8e7ad6;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    color: #f5f5f5;
    cursor: pointer;
    align-self: flex-end;
    transition: 0.25s;
    font-family: roboto;

    :hover {
      background-color: #ac96fb;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  width: 200px;

  background: #fafbfb;

  border: 1px solid #90a4ae;
  border-radius: 2px;

  padding: 8px 8px;
`;

export const InputField = styled.input`
  margin-left: 10px;
  height: 100%;
  width: 100%;

  font-size: 20px;

  padding: 0;

  background: transparent;
  box-shadow: none;
  border: none;
  outline: none;

  font-family: 'Roboto', serif;
`;
