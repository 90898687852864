/* eslint-disable no-unused-expressions */
import styled from 'styled-components';

import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  background: #fff;

  padding: 0 20px;

  h2 {
    text-align: center;
    font-family: 'Roboto', sans-serif;
  }

  span {
    margin-bottom: 100px;
    font-weight: 700;
    font-size: 2rem;
    text-align: center;
    color: #502d61;
  }

  @media print {
    .page-break {
      display: flex;
      flex-direction: row;
      page-break-before: auto;
    }
    div {
      font-size: 18px !important;
    }
    img {
      width: 40%;
    }
  }

  @font-face {
    font-family: 'Bungee Inline', cursive;
    src: url('https://fonts.googleapis.com/css?family=Bungee+Inline');
  }

  .center-items {
    text-align: center;
  }

  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;

export const FormTitle = styled.button`
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 0;
  cursor: pointer;
  background: #fff;
  align-items: center;
  padding: 10px 0 0 0;
  margin-bottom: 100px;

  img {
    width: 30%;

    @media (max-width: 768px) {
      width: 90%;
    }
  }

  @media print {
    img {
      width: 40%;
    }
  }
`;
