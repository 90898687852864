import React from 'react';

import { Container, WingRow, MidRow, Circle, TextArea } from './styles';

import Arrow from '../../assets/ciranda/arrow.png';

const limit = 43;

export default function Ciranda({
  g1,
  g2,
  y1,
  y2,
  r1,
  r2,
  setG1,
  setG2,
  setY1,
  setY2,
  setR1,
  setR2,
  setEmitSocket,
}) {
  return (
    <Container>
      <WingRow>
        <Circle color="#a1dec5">
          <TextArea
            onKeyPress={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
            onChange={(t) => setG1(t.target.value.slice(0, limit))}
            onKeyUp={() => setEmitSocket()}
            spellCheck={false}
            value={g1}
            rows={4}
            cols={10}
          />
        </Circle>
        <Circle color="#ebd88e">
          <TextArea
            onKeyPress={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
            onChange={(t) => setY1(t.target.value.slice(0, limit))}
            onKeyUp={() => setEmitSocket()}
            spellCheck={false}
            value={y1}
            rows={4}
            cols={10}
          />
        </Circle>
      </WingRow>
      <MidRow>
        <Circle color="#ebd88e">
          <TextArea
            onKeyPress={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
            onChange={(t) => setY2(t.target.value.slice(0, limit))}
            onKeyUp={() => setEmitSocket()}
            spellCheck={false}
            value={y2}
            rows={4}
            cols={10}
          />
        </Circle>

        <Circle color="#a0b2e1" central>
          {/* <Circle color="#ff8787"> */}
          <img src={Arrow} alt="flecha" />
          <img src={Arrow} alt="flecha" />
          <img src={Arrow} alt="flecha" />
          <img src={Arrow} alt="flecha" />
          <img src={Arrow} alt="flecha" />
          <img src={Arrow} alt="flecha" />
          <h1>Você</h1>
        </Circle>
        <Circle color="#ff8787">
          <TextArea
            onKeyPress={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
            onChange={(t) => setR1(t.target.value.slice(0, limit))}
            onKeyUp={() => setEmitSocket()}
            spellCheck={false}
            value={r1}
            rows={4}
            cols={10}
          />
        </Circle>
      </MidRow>
      <WingRow bottomWing>
        <Circle color="#ff8787">
          <TextArea
            onKeyPress={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
            onChange={(t) => setR2(t.target.value.slice(0, limit))}
            onKeyUp={() => setEmitSocket()}
            spellCheck={false}
            value={r2}
            rows={4}
            cols={10}
          />
        </Circle>
        <Circle color="#a1dec5">
          <TextArea
            onKeyPress={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
            onChange={(t) => setG2(t.target.value.slice(0, limit))}
            onKeyUp={() => setEmitSocket()}
            spellCheck={false}
            value={g2}
            rows={4}
            cols={10}
          />
        </Circle>
      </WingRow>
    </Container>
  );
}
