import styled from 'styled-components';

import { shade } from 'polished';

export const Container = styled.div`
  display: flex;

  flex-direction: column;

  padding-bottom: 150px;

  background-color: #fff;

  align-items: center;
  justify-content: flex-start;

  min-height: 100vh;
  width: 100%;

  img:nth-child(1) {
    height: 200px;

    @media (max-width: 786px) {
      height: 70px;
      margin-top: 10px;
    }
  }

  @media (max-width: 786px) {
    padding-bottom: 30px;
  }
`;

export const InstructionsContainer = styled.div`
  display: flex;

  width: 60%;

  padding: 20px 0;

  flex-direction: column;

  align-items: flex-start;

  strong {
    font-size: 24px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    margin: 2px;
    margin-top: 10px;
  }
  h1 {
    margin: 2px;
    margin-top: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 500;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  padding: 0;

  margin-top: 100px;

  max-width: 1000px;

  img:nth-child(1) {
    height: ${(props) => (props.small ? '300px' : '800px')};
  }
  img:nth-child(3) {
    height: ${(props) => (props.small ? '300px' : '800px')};
  }

  h2 {
    text-align: center;
    font-family: 'Rochester', cursive;
    font-size: 35px;
    width: ${(props) => (props.small ? '30%' : ' 70%')};

    @media (max-width: 786px) {
      width: 80%;
    }
  }

  @media (max-width: 786px) {
    flex-direction: ${(props) => (props.reverse ? 'column-reverse' : 'column')};
    margin-top: 0;

    img:nth-child(1) {
      height: 300px;
    }
  }
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
`;

export const TextArea = styled.textarea`
  text-decoration: none;
  resize: none;
  border: 1px solid;
  box-sizing: border-box;
  outline: 0;

  margin-right: ${(props) => (props.right ? '60px' : 0)};
  margin-left: ${(props) => (props.left ? '60px' : 0)};

  height: 250px;
  width: ${(props) => (props.small ? '700px' : '400px')};

  font-size: 22px;

  @media (max-width: 786px) {
    display: flex;
    align-items: center;
    width: 80%;
    margin: 0;
  }
`;
