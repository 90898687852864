import React, { useState } from 'react';

import { isMobile } from 'react-device-detect';

/** Imagens */
import { Buildings, Creatures, Foods, Healths, People } from '../../assets';

import {
  Container,
  BackgroundCircle,
  Tab,
  TabPanel,
  Col,
  MobileContainer,
  MobileTab,
  MobileTabContainer,
  MobileTabsCol,
} from './styles';

export default function DragNDroptabs({ dragUrl, mobileTouchHandler }) {
  const [selectedTab, setSelectedTab] = useState(-1);

  return (
    <>
      {!isMobile && (
        <Container>
          <Col>
            <Tab
              isSelected={selectedTab <= 0}
              onClick={() => setSelectedTab(0)}
            >
              <BackgroundCircle isSelected={selectedTab === 0}>
                <img src={Buildings[0]} alt={Buildings[0]} />
              </BackgroundCircle>
            </Tab>
            <Tab
              isSelected={selectedTab === 1}
              onClick={() => setSelectedTab(1)}
            >
              <BackgroundCircle isSelected={selectedTab === 1}>
                <img src={Creatures[0]} alt={Creatures[0]} />
              </BackgroundCircle>
            </Tab>

            <Tab
              isSelected={selectedTab === 2}
              onClick={() => setSelectedTab(2)}
            >
              <BackgroundCircle isSelected={selectedTab === 2}>
                <img src={Foods[0]} alt={Foods[0]} />
              </BackgroundCircle>
            </Tab>
            <Tab
              isSelected={selectedTab === 3}
              onClick={() => setSelectedTab(3)}
            >
              <BackgroundCircle isSelected={selectedTab === 3}>
                <img src={Healths[0]} alt={Healths[0]} />
              </BackgroundCircle>
            </Tab>
            <Tab
              isSelected={selectedTab === 4}
              onClick={() => setSelectedTab(4)}
            >
              <BackgroundCircle isSelected={selectedTab === 4}>
                <img src={People[0]} alt={People[0]} />
              </BackgroundCircle>
            </Tab>
          </Col>
          <TabPanel selectedTab={selectedTab}>
            {Buildings &&
              selectedTab <= 0 &&
              Buildings.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                />
              ))}

            {Creatures &&
              selectedTab === 1 &&
              Creatures.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                />
              ))}

            {Foods &&
              selectedTab === 2 &&
              Foods.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                />
              ))}

            {Healths &&
              selectedTab === 3 &&
              Healths.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                />
              ))}

            {People &&
              selectedTab === 4 &&
              People.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                />
              ))}
          </TabPanel>
        </Container>
      )}
      {isMobile && (
        <MobileContainer>
          <MobileTabContainer isOpen={selectedTab !== -1}>
            {Buildings &&
              selectedTab === 0 &&
              Buildings.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchEnd={(e) => mobileTouchHandler(e)}
                />
              ))}

            {Creatures &&
              selectedTab === 1 &&
              Creatures.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchEnd={(e) => mobileTouchHandler(e)}
                />
              ))}

            {Foods &&
              selectedTab === 2 &&
              Foods.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchEnd={(e) => mobileTouchHandler(e)}
                />
              ))}

            {Healths &&
              selectedTab === 3 &&
              Healths.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchEnd={(e) => mobileTouchHandler(e)}
                />
              ))}

            {People &&
              selectedTab === 4 &&
              People.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchEnd={(e) => mobileTouchHandler(e)}
                />
              ))}
          </MobileTabContainer>
          <MobileTabsCol>
            {(selectedTab === -1 || selectedTab === 0) && (
              <MobileTab
                onClick={() => setSelectedTab(selectedTab === 0 ? -1 : 0)}
              >
                <img src={Buildings[0]} alt={Buildings[0]} />
              </MobileTab>
            )}
            {(selectedTab === -1 || selectedTab === 1) && (
              <MobileTab
                onClick={() => setSelectedTab(selectedTab === 1 ? -1 : 1)}
              >
                <img src={Creatures[0]} alt={Creatures[0]} />
              </MobileTab>
            )}
            {(selectedTab === -1 || selectedTab === 2) && (
              <MobileTab
                onClick={() => setSelectedTab(selectedTab === 2 ? -1 : 2)}
              >
                <img src={Foods[0]} alt={Foods[0]} />
              </MobileTab>
            )}
            {(selectedTab === -1 || selectedTab === 3) && (
              <MobileTab
                onClick={() => setSelectedTab(selectedTab === 3 ? -1 : 3)}
              >
                <img src={Healths[0]} alt={Healths[0]} />
              </MobileTab>
            )}
            {(selectedTab === -1 || selectedTab === 4) && (
              <MobileTab
                onClick={() => setSelectedTab(selectedTab === 4 ? -1 : 4)}
              >
                <img src={People[0]} alt={People[0]} />
              </MobileTab>
            )}
          </MobileTabsCol>
        </MobileContainer>
      )}
    </>
  );
}
