import React from 'react';
import ReactDOM from 'react-dom';

import Routes from './routes';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyles from './styles/GlobalStyles';

ReactDOM.render(
  <BrowserRouter>
    <Routes />
    <GlobalStyles />
  </BrowserRouter>,
  document.getElementById('root')
);

reportWebVitals();
