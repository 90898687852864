import styled from 'styled-components';

export const ColorPickerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media(max-width: 786px) {
    width: 230px;
  }
`

export const Color = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border: 1px solid #90A4AE;
  margin: 2px;
  cursor: pointer;
`