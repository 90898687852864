import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 90vh;

  background: white;

  display: flex;
  justify-content: center;
  padding-top: 40px;

  overflow-x: hidden;
`;

export const BoardBox = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  border: 0;
  justify-content: center;
  align-items: center;

  border: 1px solid;
`;

export const SaveArea = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`;
