import styled from 'styled-components';

import { shade } from 'polished';

export const Container = styled.div`
  width: 100%;
  min-height: 100%;

  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CentralContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 1200px;

  padding-bottom: 50px;

  strong {
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 76px;
    color: #363435;
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: center;
  }
  h1 {
    margin: 0;
    padding: 0;
    font-size: 30px;
    color: #363435;
    font-family: 'Open Sans Condensed', sans-serif;
    width: 100%;
    text-align: center;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  strong {
    margin-top: 80px;
  }

  @media (min-width: 1024px) {
    width: 1000px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;

    strong {
      margin-top: 50px;
    }
  }
`;
