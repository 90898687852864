//Main imports
import React, { useState, useEffect, useRef } from 'react';

//Device detect import
import { isMobile } from 'react-device-detect';

//Socket & API imports
import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  PacientName,
  DataURL,
} from '@utils';

//Styles import
import {
  Container,
  CentralContainer,
  TreeContainer,
  HeartDiv1,
  HeartDiv2,
  HeartDiv3,
  HeartDiv4,
  HeartDiv5,
  HeartDiv6,
  HeartDiv7,
  HeartDiv8,
  HeartDiv9,
  HeartDiv10,
  MobileHeart1,
  MobileHeart2,
  MobileHeart3,
  MobileHeart4,
  MobileHeart5,
  MobileHeart6,
  MobileHeart7,
  MobileHeart8,
  FirstHeartsRow,
  SecondHeartsRow,
  ThirdHeartsRow,
  ButtonsContainer,
  MobileButtonsContainer,
  MobileTree,
} from './styles';

//Image imports
import { LoveMyselfTitle, LoveMyselfTree, LoveMyselfMobileTree } from '@assets';

//Components import
import {
  ModalAddOps,
  ModalAlert,
  PrimaryButton,
  ModalConfirm,
} from '@components';

import domtoimage from 'dom-to-image';

export default function LoveMyself(props) {
  //URL variables
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  //Socket variables
  const [yourID, setYourID] = useState();
  const socketRef = useRef();

  //Modal variables
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);
  const [print, setPrint] = useState(false);

  //Control variables
  const [heartTextArea1, setHeartTextArea1] = useState('');
  const [heartTextArea2, setHeartTextArea2] = useState('');
  const [heartTextArea3, setHeartTextArea3] = useState('');
  const [heartTextArea4, setHeartTextArea4] = useState('');
  const [heartTextArea5, setHeartTextArea5] = useState('');
  const [heartTextArea6, setHeartTextArea6] = useState('');
  const [heartTextArea7, setHeartTextArea7] = useState('');
  const [heartTextArea8, setHeartTextArea8] = useState('');
  const [heartTextArea9, setHeartTextArea9] = useState('');
  const [heartTextArea10, setHeartTextArea10] = useState('');

  //********************************************************************************************************** */

  //Funções
  const generatePdf = () => {
    setPrint(true);
    domtoimage
      .toPng(document.getElementById('divIdToPrint'))
      .then(function (dataUrl) {
        const nome_paciente = localStorage
          .getItem('@nome_paciente')
          .replaceAll(' ', '_');
        const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
        downloadURI(
          dataUrl,
          filename,
          sendArquivo,
          os,
          token,
          nome_recurso_cod
        );

        setPrint(false);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  };

  function receivedMessage(message) {
    setHeartTextArea1(message.body.heartTextArea1);
    setHeartTextArea2(message.body.heartTextArea2);
    setHeartTextArea3(message.body.heartTextArea3);
    setHeartTextArea4(message.body.heartTextArea4);
    setHeartTextArea5(message.body.heartTextArea5);
    setHeartTextArea6(message.body.heartTextArea6);
    setHeartTextArea7(message.body.heartTextArea7);
    setHeartTextArea8(message.body.heartTextArea8);
    setHeartTextArea9(message.body.heartTextArea9);
    setHeartTextArea10(message.body.heartTextArea10);
  }

  function setEmitSocket() {
    const messageObject = {
      body: {
        heartTextArea1,
        heartTextArea2,
        heartTextArea3,
        heartTextArea4,
        heartTextArea5,
        heartTextArea6,
        heartTextArea7,
        heartTextArea8,
        heartTextArea9,
        heartTextArea10,
      },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);
    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, tokenSocketIo);
    }
  }, []);

  return (
    <Container id="divIdToPrint">
      {!isMobile && (
        <CentralContainer>
          <img src={LoveMyselfTitle} alt={LoveMyselfTitle} />
          {typeUser === 'profissional' && !print && (
            <PrimaryButton onClick={() => setIsOpenAlert(true)}>
              Instruções
            </PrimaryButton>
          )}
          {typeUser === 'profissional' && <h1>{PacientName()}</h1>}
          <TreeContainer>
            <FirstHeartsRow>
              <HeartDiv1>
                <textarea
                  spellCheck={false}
                  maxLength={140}
                  value={heartTextArea1}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') e.preventDefault();
                  }}
                  onChange={(e) => setHeartTextArea1(e.target.value)}
                  onKeyUp={() => setEmitSocket()}
                  style={{ fontSize: 14 }}
                />
              </HeartDiv1>
              <HeartDiv2>
                <textarea
                  spellCheck={false}
                  maxLength={140}
                  value={heartTextArea2}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') e.preventDefault();
                  }}
                  onChange={(e) => setHeartTextArea2(e.target.value)}
                  onKeyUp={() => setEmitSocket()}
                />
              </HeartDiv2>
              <HeartDiv3>
                <textarea
                  spellCheck={false}
                  maxLength={140}
                  value={heartTextArea3}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') e.preventDefault();
                  }}
                  onChange={(e) => setHeartTextArea3(e.target.value)}
                  onKeyUp={() => setEmitSocket()}
                />
              </HeartDiv3>
              <HeartDiv4>
                <textarea
                  spellCheck={false}
                  maxLength={140}
                  value={heartTextArea4}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') e.preventDefault();
                  }}
                  onChange={(e) => setHeartTextArea4(e.target.value)}
                  onKeyUp={() => setEmitSocket()}
                />
              </HeartDiv4>
            </FirstHeartsRow>
            <SecondHeartsRow>
              <HeartDiv5>
                <textarea
                  spellCheck={false}
                  maxLength={140}
                  value={heartTextArea5}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') e.preventDefault();
                  }}
                  onChange={(e) => setHeartTextArea5(e.target.value)}
                  onKeyUp={() => setEmitSocket()}
                />
              </HeartDiv5>
              <HeartDiv6>
                <textarea
                  spellCheck={false}
                  maxLength={140}
                  value={heartTextArea6}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') e.preventDefault();
                  }}
                  onChange={(e) => setHeartTextArea6(e.target.value)}
                  onKeyUp={() => setEmitSocket()}
                />
              </HeartDiv6>
              <HeartDiv7>
                <textarea
                  spellCheck={false}
                  maxLength={140}
                  value={heartTextArea7}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') e.preventDefault();
                  }}
                  onChange={(e) => setHeartTextArea7(e.target.value)}
                  onKeyUp={() => setEmitSocket()}
                />
              </HeartDiv7>
            </SecondHeartsRow>
            <ThirdHeartsRow>
              <HeartDiv8>
                <textarea
                  spellCheck={false}
                  maxLength={140}
                  value={heartTextArea8}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') e.preventDefault();
                  }}
                  onChange={(e) => setHeartTextArea8(e.target.value)}
                  onKeyUp={() => setEmitSocket()}
                />
              </HeartDiv8>
              <HeartDiv9>
                <textarea
                  spellCheck={false}
                  maxLength={140}
                  value={heartTextArea9}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') e.preventDefault();
                  }}
                  onChange={(e) => setHeartTextArea9(e.target.value)}
                  onKeyUp={() => setEmitSocket()}
                />
              </HeartDiv9>
              <HeartDiv10>
                <textarea
                  spellCheck={false}
                  maxLength={140}
                  value={heartTextArea10}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') e.preventDefault();
                  }}
                  onChange={(e) => setHeartTextArea10(e.target.value)}
                  onKeyUp={() => setEmitSocket()}
                />
              </HeartDiv10>
            </ThirdHeartsRow>
            <img
              src={LoveMyselfTree}
              alt={LoveMyselfTree}
              style={{ width: '600px' }}
            />
          </TreeContainer>
          {typeUser === 'profissional' && !print && (
            <ButtonsContainer>
              <PrimaryButton onClick={() => setModalCloseIsOpen(true)}>
                Fechar
              </PrimaryButton>
              <PrimaryButton onClick={() => setIsOpen(true)}>
                Salvar
              </PrimaryButton>
            </ButtonsContainer>
          )}
        </CentralContainer>
      )}
      {isMobile && (
        <>
          <MobileTree src={LoveMyselfMobileTree} alt={LoveMyselfMobileTree} />
          <MobileHeart1>
            <textarea
              spellCheck={false}
              maxLength={66}
              value={heartTextArea1}
              onKeyPress={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
              onChange={(e) => setHeartTextArea1(e.target.value)}
              onKeyUp={() => setEmitSocket()}
            />
          </MobileHeart1>
          <MobileHeart2>
            <textarea
              spellCheck={false}
              maxLength={66}
              value={heartTextArea2}
              onKeyPress={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
              onChange={(e) => setHeartTextArea2(e.target.value)}
              onKeyUp={() => setEmitSocket()}
            />
          </MobileHeart2>
          <MobileHeart3>
            <textarea
              spellCheck={false}
              maxLength={66}
              value={heartTextArea3}
              onKeyPress={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
              onChange={(e) => setHeartTextArea3(e.target.value)}
              onKeyUp={() => setEmitSocket()}
            />
          </MobileHeart3>
          <MobileHeart4>
            <textarea
              spellCheck={false}
              maxLength={66}
              value={heartTextArea4}
              onKeyPress={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
              onChange={(e) => setHeartTextArea4(e.target.value)}
              onKeyUp={() => setEmitSocket()}
            />
          </MobileHeart4>
          <MobileHeart5>
            <textarea
              spellCheck={false}
              maxLength={66}
              value={heartTextArea5}
              onKeyPress={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
              onChange={(e) => setHeartTextArea5(e.target.value)}
              onKeyUp={() => setEmitSocket()}
            />
          </MobileHeart5>
          <MobileHeart6>
            <textarea
              spellCheck={false}
              maxLength={66}
              value={heartTextArea6}
              onKeyPress={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
              onChange={(e) => setHeartTextArea6(e.target.value)}
              onKeyUp={() => setEmitSocket()}
            />
          </MobileHeart6>
          <MobileHeart7>
            <textarea
              spellCheck={false}
              maxLength={66}
              value={heartTextArea7}
              onKeyPress={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
              onChange={(e) => setHeartTextArea7(e.target.value)}
              onKeyUp={() => setEmitSocket()}
            />
          </MobileHeart7>
          <MobileHeart8>
            <textarea
              spellCheck={false}
              maxLength={66}
              value={heartTextArea8}
              onKeyPress={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
              onChange={(e) => setHeartTextArea8(e.target.value)}
              onKeyUp={() => setEmitSocket()}
            />
          </MobileHeart8>
          {typeUser === 'profissional' && !print && (
            <MobileButtonsContainer>
              <PrimaryButton onClick={() => setModalCloseIsOpen(true)}>
                Fechar
              </PrimaryButton>
              <PrimaryButton onClick={() => setIsOpen(true)}>
                Salvar
              </PrimaryButton>
            </MobileButtonsContainer>
          )}
        </>
      )}
      <ModalAddOps
        generatePdf={generatePdf}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        token={token}
      />
      <ModalAlert
        modalIsOpen={modalIsOpenAlert}
        setIsOpen={setIsOpenAlert}
        token={token}
        props={props}
        title={'Eu me amo porque...'}
        recurso={nome_recurso_cod}
        bt01Txt={'Fechar'}
      />
      <ModalConfirm
        title="Fechar o jogo"
        description="Deseja realmente fechar o jogo?"
        modalIsOpen={modalCloseIsOpen}
        setIsOpen={setModalCloseIsOpen}
        confirmButtonText="Fechar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCloseIsOpen(false)}
        onConfirm={() =>
          handleBackHome(
            modalClose,
            nome_recurso_cod,
            typeUser,
            props,
            os,
            token,
            ambiente
          )
        }
      />
    </Container>
  );
}
