//Main imports
import React, { useState, useEffect, useRef } from 'react';

import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  DataURL,
} from '@utils';

//Styles import
import {
  Container,
  CentralContainer,
  Title,
  Row,
  FirstDialog,
  Col,
  FirstColoredDialogs,
  CursiveH1,
  ShadowedRectangle,
  SemiBordedDiv,
  SecondDialog,
  FirstSmartphone,
  SecondSmartphone,
  ThirdSmartphone,
  DialogTextContainer,
  ButtonsContainer,
  FirstColoredDialogsRow,
} from './styles';

//Image imports
import {
  XSTitle,
  XSDialog,
  XSBlueDialog,
  XSOrangeDialog,
  XSGreenDialog,
  XSBoy,
  XSLeftHandWithSmartphone,
  XSHandWithSmartphone,
  XSGirl,
  XSGirl2,
} from '@assets';

//Components import
import {
  ModalAddOps,
  ModalAlert,
  PrimaryButton,
  ModalConfirm,
} from '@components';

import domtoimage from 'dom-to-image';

export default function ExcessiveScreens(props) {
  //URL variables
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  //Socket variables
  const [yourID, setYourID] = useState();
  const socketRef = useRef();

  //Modal variables
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);
  const [print, setPrint] = useState(false);

  //Control variables
  const [T1, setT1] = useState('');
  const [T2, setT2] = useState('');
  const [T3, setT3] = useState('');
  const [T4, setT4] = useState('');
  const [T5, setT5] = useState('');
  const [T6, setT6] = useState('');
  const [T7, setT7] = useState('');
  const [T8, setT8] = useState('');
  const [T9, setT9] = useState('');
  const [T10, setT10] = useState('');
  const [T11, setT11] = useState('');
  const [T12, setT12] = useState('');
  const [T13, setT13] = useState('');
  const [T14, setT14] = useState('');
  const [T15, setT15] = useState('');
  const [T16, setT16] = useState('');
  const [T17, setT17] = useState('');

  //********************************************************************************************************** */

  //Funções
  const generatePdf = () => {
    setPrint(true);
    domtoimage
      .toPng(document.getElementById('divIdToPrint'))
      .then(function (dataUrl) {
        const nome_paciente = localStorage
          .getItem('@nome_paciente')
          .replaceAll(' ', '_');
        const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
        downloadURI(
          dataUrl,
          filename,
          sendArquivo,
          os,
          token,
          nome_recurso_cod
        );

        setPrint(false);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  };

  function receivedMessage(message) {
    setT1(message.body.T1);
    setT2(message.body.T2);
    setT3(message.body.T3);
    setT4(message.body.T4);
    setT5(message.body.T5);
    setT6(message.body.T6);
    setT7(message.body.T7);
    setT8(message.body.T8);
    setT9(message.body.T9);
    setT10(message.body.T10);
    setT11(message.body.T11);
    setT12(message.body.T12);
    setT13(message.body.T13);
    setT14(message.body.T14);
    setT15(message.body.T15);
    setT16(message.body.T16);
    setT17(message.body.T17);
  }

  function setEmitSocket() {
    const messageObject = {
      body: {
        T1,
        T2,
        T3,
        T4,
        T5,
        T6,
        T7,
        T8,
        T9,
        T10,
        T11,
        T12,
        T13,
        T14,
        T15,
        T16,
        T17,
      },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);
    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, tokenSocketIo);
    }
  }, []);

  //********************************************************************************************************** */

  return (
    <Container>
      <CentralContainer id="divIdToPrint">
        <Title src={XSTitle} alt="title" />
        {typeUser === 'profissional' && !print && (
          <PrimaryButton
            style={{ marginBottom: 60 }}
            onClick={() => setIsOpenAlert(true)}
            mobile
          >
            Instruções
          </PrimaryButton>
        )}
        <Row>
          <FirstDialog src={XSDialog}>
            <h1>Redes sociais são...</h1>
            <textarea
              value={T1}
              spellCheck={false}
              maxLength={380}
              onKeyPress={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
              onChange={(e) => setT1(e.target.value)}
              onKeyUp={() => setEmitSocket()}
              style={{ zIndex: 1000, background: 'transparent' }}
            />
          </FirstDialog>
          <img
            src={XSBoy}
            alt={XSBoy}
            width={140}
            style={{ marginLeft: '-65px' }}
          />
          <Col>
            <FirstColoredDialogsRow>
              <FirstColoredDialogs
                src={XSBlueDialog}
                style={{ transform: 'rotate(5deg)' }}
              >
                <textarea
                  value={T2}
                  maxLength={105}
                  spellCheck={false}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') e.preventDefault();
                  }}
                  onChange={(e) => setT2(e.target.value)}
                  onKeyUp={() => setEmitSocket()}
                />
              </FirstColoredDialogs>
              <CursiveH1>
                Escreva 3 palavras para descrever as redes sociais e o papel
                delas nas vidas das pessoas
              </CursiveH1>
            </FirstColoredDialogsRow>
            <FirstColoredDialogs src={XSGreenDialog} second>
              <textarea
                value={T3}
                maxLength={105}
                spellCheck={false}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') e.preventDefault();
                }}
                onChange={(e) => setT3(e.target.value)}
                onKeyUp={() => setEmitSocket()}
              />
            </FirstColoredDialogs>
            <FirstColoredDialogs src={XSOrangeDialog} third>
              <textarea
                value={T4}
                maxLength={105}
                spellCheck={false}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') e.preventDefault();
                }}
                onChange={(e) => setT4(e.target.value)}
                onKeyUp={() => setEmitSocket()}
              />
            </FirstColoredDialogs>
          </Col>
        </Row>
        <div
          style={{
            width: '100%',
            height: '200px',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <SemiBordedDiv>
            <span>Sua rede social favorita é...</span>
            <textarea
              value={T5}
              maxLength={14}
              spellCheck={false}
              onKeyPress={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
              onKeyUp={() => setEmitSocket()}
              onChange={(e) => setT5(e.target.value)}
            />
          </SemiBordedDiv>
        </div>
        <Row>
          <Col>
            <ShadowedRectangle first>
              <span>Com que frequência você publica nas redes sociais?</span>
              <textarea
                value={T6}
                maxLength={51}
                spellCheck={false}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') e.preventDefault();
                }}
                onChange={(e) => setT6(e.target.value)}
                onKeyUp={() => setEmitSocket()}
              />
            </ShadowedRectangle>
            <SecondDialog src={XSDialog}>
              <span>
                Com que frequência voce verifica suas redes sociais todos os
                dias?
              </span>
              <textarea
                value={T7}
                maxLength={67}
                spellCheck={false}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') e.preventDefault();
                }}
                onChange={(e) => setT7(e.target.value)}
                onKeyUp={() => setEmitSocket()}
              />
            </SecondDialog>
          </Col>
          <FirstSmartphone src={XSLeftHandWithSmartphone}>
            <span>Liste todos os aplicativos que voce usa</span>
            <textarea
              value={T8}
              maxLength={200}
              spellCheck={false}
              onKeyPress={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
              onChange={(e) => setT8(e.target.value)}
              onKeyUp={() => setEmitSocket()}
            />
          </FirstSmartphone>
          <SecondSmartphone src={XSHandWithSmartphone} listSocial>
            <span>Liste todos os aspectos negativos das redes sociais</span>
            <textarea
              value={T9}
              maxLength={200}
              spellCheck={false}
              onKeyPress={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
              onChange={(e) => setT9(e.target.value)}
              onKeyUp={() => setEmitSocket()}
            />
          </SecondSmartphone>
        </Row>
        <Row>
          <img
            src={XSGirl}
            alt={XSGirl}
            style={{
              width: '300px',
              marginLeft: '800px',
              marginTop: '-450px',
              zIndex: '99',
            }}
          />
        </Row>
        <Row>
          <FirstDialog src={XSDialog} style={{ marginTop: '-70px' }} typeOfPost>
            <h1 style={{ width: '70%' }}>
              Que tipo de coisas voce publica nas redes sociais?
            </h1>
            <textarea
              value={T10}
              spellCheck={false}
              maxLength={256}
              onKeyPress={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
              onChange={(e) => setT10(e.target.value)}
              onKeyUp={() => setEmitSocket()}
            />
          </FirstDialog>
          <img
            src={XSGirl2}
            alt={XSGirl2}
            width={200}
            style={{ marginLeft: '-35px' }}
          />
          <ThirdSmartphone src={XSHandWithSmartphone}>
            <span>Liste todos os aspectos negativos das redes sociais</span>
            <textarea
              value={T11}
              maxLength={200}
              spellCheck={false}
              onKeyPress={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
              onChange={(e) => setT11(e.target.value)}
              onKeyUp={() => setEmitSocket()}
            />
          </ThirdSmartphone>
        </Row>
        <Row>
          <Col style={{ marginTop: '-120px', marginLeft: 80 }}>
            <DialogTextContainer first>
              <h1>
                Checar o celular é a primeira coisa que você faz ao acordar?
              </h1>
              <FirstColoredDialogs src={XSGreenDialog}>
                <textarea
                  value={T12}
                  maxLength={105}
                  spellCheck={false}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') e.preventDefault();
                  }}
                  onChange={(e) => setT12(e.target.value)}
                  onKeyUp={() => setEmitSocket()}
                />
              </FirstColoredDialogs>
            </DialogTextContainer>
            <DialogTextContainer second>
              <h1>
                Checar o celular é a primeira coisa que você faz ao chegar em
                casa?
              </h1>
              <FirstColoredDialogs src={XSOrangeDialog}>
                <textarea
                  value={T13}
                  maxLength={105}
                  spellCheck={false}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') e.preventDefault();
                  }}
                  onChange={(e) => setT13(e.target.value)}
                  onKeyUp={() => setEmitSocket()}
                />
              </FirstColoredDialogs>
            </DialogTextContainer>
            <DialogTextContainer third>
              <h1>
                Checar o celular é a ultima coisa que você faz antes de ir
                dormir?
              </h1>
              <FirstColoredDialogs src={XSBlueDialog}>
                <textarea
                  value={T14}
                  maxLength={105}
                  spellCheck={false}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') e.preventDefault();
                  }}
                  onChange={(e) => setT14(e.target.value)}
                  onKeyUp={() => setEmitSocket()}
                />
              </FirstColoredDialogs>
            </DialogTextContainer>
          </Col>
          <FirstDialog src={XSDialog} harmfulDialog>
            <h1>
              Você acredita que excesso de redes sociais pode ser prejudicial?
              Por quê?
            </h1>
            <textarea
              value={T15}
              spellCheck={false}
              maxLength={226}
              onKeyPress={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
              onChange={(e) => setT15(e.target.value)}
              onKeyUp={() => setEmitSocket()}
            />
          </FirstDialog>
        </Row>
        <Row style={{ marginTop: '-50px' }} lastRow>
          <ShadowedRectangle second>
            <span>
              Você consegue se lembrar da época que não possuía redes sociais?
              Como era?
            </span>
            <textarea
              style={{ fontSize: '16px' }}
              value={T16}
              maxLength={177}
              spellCheck={false}
              onKeyPress={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
              onChange={(e) => setT16(e.target.value)}
              onKeyUp={() => setEmitSocket()}
            />
          </ShadowedRectangle>
          <img
            src={XSBoy}
            alt={XSBoy}
            width={140}
            style={{ marginLeft: '-25px' }}
          />
          <ShadowedRectangle second>
            <span style={{ fontSize: '26px' }}>
              Você estaria disposto(a) a reduzir o tempo que passa nas redes
              sociais por uma semana? Por quê?
            </span>
            <textarea
              style={{ fontSize: '16px' }}
              value={T17}
              maxLength={180}
              spellCheck={false}
              onKeyPress={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
              onChange={(e) => setT17(e.target.value)}
              onKeyUp={() => setEmitSocket()}
            />
          </ShadowedRectangle>
        </Row>
        {typeUser === 'profissional' && (
          <ButtonsContainer>
            <PrimaryButton onClick={() => setIsOpen(true)} mobile>
              Salvar
            </PrimaryButton>
            <PrimaryButton onClick={() => setModalCloseIsOpen(true)} mobile>
              Fechar
            </PrimaryButton>
          </ButtonsContainer>
        )}
      </CentralContainer>
      <ModalAddOps
        generatePdf={generatePdf}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        token={token}
      />
      <ModalAlert
        modalIsOpen={modalIsOpenAlert}
        setIsOpen={setIsOpenAlert}
        token={token}
        props={props}
        title={'Excesso de telas'}
        recurso={nome_recurso_cod}
        bt01Txt={'Fechar'}
      />
      <ModalConfirm
        title="Fechar o jogo"
        description="Deseja realmente fechar o jogo?"
        modalIsOpen={modalCloseIsOpen}
        setIsOpen={setModalCloseIsOpen}
        confirmButtonText="Fechar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCloseIsOpen(false)}
        onConfirm={() =>
          handleBackHome(
            modalClose,
            nome_recurso_cod,
            typeUser,
            props,
            os,
            token,
            ambiente
          )
        }
      />
    </Container>
  );
}
