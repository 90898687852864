/* eslint-disable no-unused-expressions */
import styled from 'styled-components';

export const Container = styled.div``;

export const Button = styled.button`
  color: #333;
  width: 150px;
  height: 50px;
  background-color: ${(props) => props.color};
  font-size: 18px;
  border: 1px solid #333;
  border-radius: 10px;
  margin: 15px;
`;

export const BoxSession = styled.div`
  border: 1px solid #333;
  border-radius: 5px;
  padding: 15px;
  margin: auto;
  width: 250px;
  margin-left: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  float: left;
`;
