import styled from 'styled-components';

export const Container = styled.div`
  width: 200px;
  img {
    width: 200px;
    position: absolute;
    z-index: 99;
    border: none;
  }
  border: none;
  cursor: pointer;

  @media (max-width: 800px) {
    width: 130px;
    height: 120px;
    img {
      width: 130px;
      height: 120px;
    }
    margin-bottom: 10px;
  }
`;

export const Background = styled.div`
  position: absolute;
  height: 172px;
  width: 200px;
  max-width: 50vw;
  z-index: 1;
  background: ${(props) => props.color};
  border: none;
  @media (max-width: 800px) {
    width: 130px;
    height: 120px;
  }
`;
