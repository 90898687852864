import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: white;

  width: 100%;
  min-height: 90vh;
`;

export const CentralRow = styled.div`
  display: flex;

  max-width: 1500px;
  background-color: #dde1f4;

  border: 1px solid;

  margin-left: 20%;

  h1 {
    padding: 0;
    margin: 0;
    margin-left: 10px;
    font-size: 26px;
  }
`;

export const SaveArea = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export const BoardBox = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  border: 0;
  justify-content: center;
  align-items: center;

  z-index: 1;
`;
