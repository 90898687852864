import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: white;

  @media (max-width: 800px) {
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
    max-width: 1000px;
  }
  img:nth-child(2) {
    margin-top: 50px;
    width: 100vw;
    max-width: 600px;
  }
`;

export const OptionsContainer = styled.div`
  max-width: 1000px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  margin-top: 50px;

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const Image = styled.img`
  width: 100vw;
  max-width: 300px;
  margin-right: 30px;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    transition: 0.4s;
  }

  transform: ${(props) => (props.isSelected ? 'scale(1.05)' : 'scale(1)')};
`;

export const FoodsContainer = styled.div`
  width: 100vw;
  max-width: 1000px;
  min-height: 800px;
  margin-bottom: 50px;
  margin-top: 40px;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;
