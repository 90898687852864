/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';

import { startSocket } from '@utils';

// Images import
import { PawImage, MusicNotes, CloverImage } from '@assets';

import {
  Container,
  FormTitle,
  Row,
  PawContainer,
  MusicContainer,
  CloverContainer,
} from './styles';

import { AMAField } from '@components';

const AMAForm = (props) => {
  const url = props.location.pathname.split('/');
  const typeUser = url[2];
  const token = url[4];
  const tokenSocketIo = token;

  const [animals, setAnimals] = useState('');
  const [musics, setMusics] = useState('');
  const [amulets, setAmulets] = useState('');
  const [experience, setExperience] = useState('');
  const [focusedField, setFocusedField] = useState('');
  const [yourID, setYourID] = useState();

  const socketRef = useRef();

  function receivedMessage(message) {
    setAnimals(message.body.animals);
    setMusics(message.body.musics);
    setAmulets(message.body.amulets);
    setExperience(message.body.experience);
  }

  function setEmitSocket() {
    const messageObject = {
      body: {
        animals,
        musics,
        amulets,
        experience,
      },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);
  }, []);

  return (
    <>
      <Container>
        <FormTitle>
          <span>A.M.A</span>
          <h1>Animal, Música e Amuleto</h1>
        </FormTitle>
        <Row>
          <AMAField
            title="Animais"
            rotation="none"
            size="lg"
            data={animals}
            setData={setAnimals}
            setEmitSocket={setEmitSocket}
            setFocusedField={setFocusedField}
          />
          <PawContainer focusedField={focusedField}>
            <img src={PawImage} alt="pata" />
            <img src={PawImage} alt="pata" />
          </PawContainer>
        </Row>
        <Row>
          <MusicContainer focusedField={focusedField}>
            <img src={MusicNotes} alt="notas" />
          </MusicContainer>
          <AMAField
            title="Músicas"
            rotation="none"
            size="lg"
            data={musics}
            setData={setMusics}
            setEmitSocket={setEmitSocket}
            setFocusedField={setFocusedField}
          />
        </Row>
        <Row className="page-break">
          <AMAField
            title="Amuletos"
            rotation="none"
            size="lg"
            data={amulets}
            setData={setAmulets}
            setEmitSocket={setEmitSocket}
            setFocusedField={setFocusedField}
          />
          <CloverContainer focusedField={focusedField}>
            <img src={CloverImage} alt="Trevo" />
          </CloverContainer>
        </Row>
        <Row>
          <AMAField
            innerTitle="Como foi sua experiência?"
            rotation="none"
            size="md"
            data={experience}
            setData={setExperience}
            setEmitSocket={setEmitSocket}
            setFocusedField={() => {}}
          />
        </Row>
      </Container>
    </>
  );
};

export default AMAForm;
