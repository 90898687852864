import styled from 'styled-components';

import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  vertical-align: top;

  justify-content: center;
  align-items: center;
  background: #fff;

  min-height: 100vh;
  width: 100%;
  h2 {
    text-align: center;
    font-family: 'Roboto', sans-serif;
  }
`;

export const CentralContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 60%;
  min-height: 95vh;

  padding: 10px 0 50px;

  span {
    margin: 2px;
    margin-top: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    font-weight: 700;
    text-align: center;
  }
`;

export const FormTitle = styled.div`
  display: flex;

  flex-direction: column;

  align-items: center;
  padding: 10px 0 0 0;
  h1 {
    color: #502d61;
    margin: 0;
    font-family: 'Marcellus SC', serif;
  }
  span {
    font-family: 'Marcellus SC', serif;
    background: #95b389;

    font-weight: 700;
    font-size: 2rem;
    text-align: center;
    color: #502d61;

    padding: 10px 150px;

    @media (max-width: 768px) {
      padding: 44px 27px;
    }

    @media screen and (min-device-width: 320px) and (max-device-width: 360px) {
      padding: 44px 14px;
    }

    clip-path: polygon(
      2% 97%,
      1% 94%,
      3% 90%,
      1% 84%,
      2% 78%,
      4% 74%,
      2% 70%,
      1% 62%,
      3% 60%,
      4% 55%,
      2% 52%,
      1% 44%,
      3% 43%,
      5% 37%,
      2% 32%,
      1% 24%,
      2% 19%,
      1% 14%,
      1% 7%,
      3% 3%,
      8% 3%,
      14% 5%,
      21% 4%,
      30% 3%,
      37% 4%,
      44% 5%,
      50% 4%,
      55% 3%,
      62% 3%,
      68% 4%,
      73% 4%,
      80% 5%,
      85% 4%,
      93% 5%,
      98% 4%,
      96% 10%,
      98% 16%,
      98% 24%,
      96% 31%,
      99% 36%,
      97% 44%,
      99% 50%,
      99% 59%,
      97% 65%,
      99% 71%,
      99% 79%,
      97% 84%,
      99% 91%,
      98% 97%,
      92% 96%,
      86% 96%,
      76% 95%,
      63% 96%,
      53% 97%,
      42% 97%,
      33% 98%,
      26% 95%,
      17% 96%,
      11% 98%
    );
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Header = styled.h1`
  margin: 2px;
  margin-top: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 500;
`;
