import styled from 'styled-components';

import { HeartBg } from '../../assets';

export const Container = styled.div`
  width: 100%;
  min-height: 90vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  overflow-x: hidden;

  background: white;
`;

export const CentralContainer = styled.div`
  max-width: 1300px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding-bottom: 150px;
`;

export const TreeContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 1300px;
  height: 1240px;

  img:nth-last-child(1) {
    position: relative;
    top: -8%;
    left: 50%;
    transform: translate(-50%, -20%);
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  margin-top: 50px;
`;

export const FirstHeartsRow = styled.div`
  width: 100%;
  display: flex;
`;

export const SecondHeartsRow = styled.div`
  width: 100%;
  display: flex;
`;

export const ThirdHeartsRow = styled.div`
  width: 100%;
  display: flex;
`;

export const HeartDiv1 = styled.div`
  height: 250px;
  width: 250px;

  background-image: url(${HeartBg});
  background-size: 250px 250px;
  background-repeat: no-repeat;

  position: relative;
  top: 100px;
  left: 100px;

  transform: rotate(-20deg);

  textarea {
    background: transparent;
    box-sizing: border-box;
    text-decoration: none;
    overflow: hidden;
    resize: none;
    border: none;
    outline: none;

    height: 140px;
    width: 140px;
    position: relative;
    left: 55px;
    top: 40px;
  }
`;

export const HeartDiv2 = styled.div`
  height: 250px;
  width: 250px;

  background-image: url(${HeartBg});
  background-size: 250px 250px;
  background-repeat: no-repeat;

  position: relative;
  top: 130px;
  left: 100px;

  transform: rotate(-10deg);

  textarea {
    background: transparent;
    box-sizing: border-box;
    text-decoration: none;
    overflow: hidden;
    resize: none;
    border: none;
    outline: none;

    height: 140px;
    width: 140px;
    position: relative;
    left: 55px;
    top: 40px;
  }
`;

export const HeartDiv3 = styled.div`
  height: 250px;
  width: 250px;

  background-image: url(${HeartBg});
  background-size: 250px 250px;
  background-repeat: no-repeat;

  position: relative;
  top: 20px;
  left: 100px;

  transform: rotate(0deg);

  textarea {
    background: transparent;
    box-sizing: border-box;
    text-decoration: none;
    overflow: hidden;
    resize: none;
    border: none;
    outline: none;

    height: 140px;
    width: 140px;
    position: relative;
    left: 55px;
    top: 40px;
  }
`;

export const HeartDiv4 = styled.div`
  height: 250px;
  width: 250px;

  background-image: url(${HeartBg});
  background-size: 250px 250px;
  background-repeat: no-repeat;

  position: relative;
  top: 120px;
  left: 100px;

  transform: rotate(20deg);

  textarea {
    background: transparent;
    box-sizing: border-box;
    text-decoration: none;
    overflow: hidden;
    resize: none;
    border: none;
    outline: none;

    height: 140px;
    width: 140px;
    position: relative;
    left: 55px;
    top: 40px;
  }
`;

export const HeartDiv5 = styled.div`
  height: 250px;
  width: 250px;

  background-image: url(${HeartBg});
  background-size: 250px 250px;
  background-repeat: no-repeat;

  position: relative;
  top: 130px;
  left: 210px;

  transform: rotate(-35deg);

  textarea {
    background: transparent;
    box-sizing: border-box;
    text-decoration: none;
    overflow: hidden;
    resize: none;
    border: none;
    outline: none;

    height: 140px;
    width: 140px;
    position: relative;
    left: 55px;
    top: 40px;
  }
`;

export const HeartDiv6 = styled.div`
  height: 250px;
  width: 250px;

  background-image: url(${HeartBg});
  background-size: 250px 250px;
  background-repeat: no-repeat;

  position: relative;
  top: 30px;
  right: -320px;

  transform: rotate(-10deg);

  textarea {
    background: transparent;
    box-sizing: border-box;
    text-decoration: none;
    overflow: hidden;
    resize: none;
    border: none;
    outline: none;

    height: 140px;
    width: 140px;
    position: relative;
    left: 55px;
    top: 40px;
  }
`;
export const HeartDiv7 = styled.div`
  height: 250px;
  width: 250px;

  background-image: url(${HeartBg});
  background-size: 250px 250px;
  background-repeat: no-repeat;

  position: relative;
  top: 120px;
  right: -380px;

  transform: rotate(50deg);

  textarea {
    background: transparent;
    box-sizing: border-box;
    text-decoration: none;
    overflow: hidden;
    resize: none;
    border: none;
    outline: none;

    height: 140px;
    width: 140px;
    position: relative;
    left: 55px;
    top: 40px;
  }
`;

export const HeartDiv8 = styled.div`
  height: 250px;
  width: 250px;

  background-image: url(${HeartBg});
  background-size: 250px 250px;
  background-repeat: no-repeat;

  position: relative;
  top: 120px;
  left: 140px;

  transform: rotate(-50deg);

  textarea {
    background: transparent;
    box-sizing: border-box;
    text-decoration: none;
    overflow: hidden;
    resize: none;
    border: none;
    outline: none;

    height: 140px;
    width: 140px;
    position: relative;
    left: 55px;
    top: 40px;
  }
`;
export const HeartDiv9 = styled.div`
  height: 250px;
  width: 250px;

  background-image: url(${HeartBg});
  background-size: 250px 250px;
  background-repeat: no-repeat;

  position: relative;
  top: 120px;
  right: -500px;

  transform: rotate(-10deg);

  z-index: 1000;

  textarea {
    background: transparent;
    box-sizing: border-box;
    text-decoration: none;
    overflow: hidden;
    resize: none;
    border: none;
    outline: none;

    height: 140px;
    width: 140px;
    position: relative;
    left: 55px;
    top: 40px;
  }
`;

export const HeartDiv10 = styled.div`
  height: 250px;
  width: 250px;

  background-image: url(${HeartBg});
  background-size: 250px 250px;
  background-repeat: no-repeat;

  position: relative;
  top: 220px;
  right: -440px;

  transform: rotate(60deg);

  textarea {
    background: transparent;
    box-sizing: border-box;
    text-decoration: none;
    overflow: hidden;
    resize: none;
    border: none;
    outline: none;

    height: 140px;
    width: 140px;
    position: relative;
    left: 55px;
    top: 40px;
  }
`;

export const MobileTree = styled.img`
  width: 320px;
  height: auto;
  position: absolute;
  top: 0;
`;

export const MobileHeart1 = styled.div`
  width: 150px;
  height: 150px;
  background-image: url(${HeartBg});
  background-size: 150px 150px;
  background-repeat: no-repeat;

  position: relative;
  top: 80px;
  left: -63px;

  z-index: 99;

  textarea {
    background: white;
    box-sizing: border-box;
    text-decoration: none;
    overflow: hidden;
    font-size: 12px;

    resize: none;
    border: none;
    outline: none;

    height: 80px;
    width: 80px;
    position: relative;
    left: 35px;
    top: 28px;
  }
`;

export const MobileHeart2 = styled.div`
  width: 150px;
  height: 150px;
  background-image: url(${HeartBg});
  background-size: 150px 150px;
  background-repeat: no-repeat;

  position: relative;
  top: 90px;
  left: 100px;

  z-index: 99;

  textarea {
    background: white;
    box-sizing: border-box;
    text-decoration: none;
    overflow: hidden;
    font-size: 12px;

    resize: none;
    border: none;
    outline: none;

    height: 80px;
    width: 80px;
    position: relative;
    left: 35px;
    top: 28px;
  }
`;

export const MobileHeart3 = styled.div`
  width: 150px;
  height: 150px;
  background-image: url(${HeartBg});
  background-size: 150px 150px;
  background-repeat: no-repeat;

  position: relative;
  top: 70px;
  left: -20px;

  z-index: 99;

  transform: rotate(10deg);

  textarea {
    background: white;
    box-sizing: border-box;
    text-decoration: none;
    overflow: hidden;
    font-size: 12px;

    resize: none;
    border: none;
    outline: none;

    height: 80px;
    width: 80px;
    position: relative;
    left: 35px;
    top: 28px;
  }
`;

export const MobileHeart4 = styled.div`
  width: 150px;
  height: 150px;
  background-image: url(${HeartBg});
  background-size: 150px 150px;
  background-repeat: no-repeat;

  position: relative;
  top: 30px;
  left: 100px;

  z-index: 99;

  transform: rotate(-10deg);

  textarea {
    background: white;
    box-sizing: border-box;
    text-decoration: none;
    overflow: hidden;
    font-size: 12px;

    resize: none;
    border: none;
    outline: none;

    height: 80px;
    width: 80px;
    position: relative;
    left: 35px;
    top: 28px;
  }
`;

export const MobileHeart5 = styled.div`
  width: 150px;
  height: 150px;
  background-image: url(${HeartBg});
  background-size: 150px 150px;
  background-repeat: no-repeat;

  position: relative;
  top: 0px;
  left: -70px;

  z-index: 99;

  textarea {
    background: white;
    box-sizing: border-box;
    text-decoration: none;
    overflow: hidden;
    font-size: 12px;

    resize: none;
    border: none;
    outline: none;

    height: 80px;
    width: 80px;
    position: relative;
    left: 35px;
    top: 28px;
  }
`;

export const MobileHeart6 = styled.div`
  width: 150px;
  height: 150px;
  background-image: url(${HeartBg});
  background-size: 150px 150px;
  background-repeat: no-repeat;

  position: relative;
  top: 0px;
  left: 60px;

  z-index: 99;

  transform: rotate(-10deg);

  textarea {
    background: white;
    box-sizing: border-box;
    text-decoration: none;
    overflow: hidden;
    font-size: 12px;

    resize: none;
    border: none;
    outline: none;

    height: 80px;
    width: 80px;
    position: relative;
    left: 35px;
    top: 28px;
  }
`;

export const MobileHeart7 = styled.div`
  width: 150px;
  height: 150px;
  background-image: url(${HeartBg});
  background-size: 150px 150px;
  background-repeat: no-repeat;

  position: relative;
  top: -30px;
  left: -70px;

  z-index: 99;

  textarea {
    background: white;
    box-sizing: border-box;
    text-decoration: none;
    overflow: hidden;
    font-size: 12px;

    resize: none;
    border: none;
    outline: none;

    height: 80px;
    width: 80px;
    position: relative;
    left: 35px;
    top: 28px;
  }
`;

export const MobileHeart8 = styled.div`
  width: 150px;
  height: 150px;
  background-image: url(${HeartBg});
  background-size: 150px 150px;
  background-repeat: no-repeat;

  position: relative;
  top: -25px;
  left: 78px;

  z-index: 99;

  transform: rotate(10deg);

  textarea {
    background: white;
    box-sizing: border-box;
    text-decoration: none;
    overflow: hidden;
    font-size: 12px;

    resize: none;
    border: none;
    outline: none;

    height: 80px;
    width: 80px;
    position: relative;
    left: 35px;
    top: 28px;
  }
`;

export const MobileButtonsContainer = styled.div`
  width: 320px;
  height: 200px;
  position: relative;
  top: -40px;

  display: flex;
  justify-content: center;
  align-items: center;
`;
