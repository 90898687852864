import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 90vh;
  background-color: white;

  h2 {
    font-family: 'Roboto', sans-serif;
    font-size: 36px;
    text-align: center;
  }
`;

export const DragDropRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    padding: 0;
    margin: 0;
    margin-left: 10px;
    font-size: 26px;
  }
`;

export const CentralContainer = styled.div`
  width: 1200px;
  max-width: 100vw;
  min-height: 600px;

  display: flex;
  justify-content: center;

  flex-wrap: wrap;

  h1 {
    font-family: 'Roboto', sans-serif;
    font-size: 56px;
    text-align: center;
    transform: scale(1.05);
    animation: shake infinite 4.5s;

    @keyframes shake {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.05);
      }
      100% {
        transform: scale(1);
      }
    }
  }

  img {
    width: 300px;
    margin: 15px 10px;
    cursor: pointer;
  }

  img:hover {
    transform: scale(1.05);
    transition: 0.2s;
  }
`;

export const SaveArea = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  @media (max-width: 786px) {
    flex-wrap: wrap;
  }
`;
export const PlanesBox = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  border: 0;
  justify-content: center;
  align-items: center;
  background-size: 75%;
  height: 535px;
  width: 710px;

  @media(max-width: 786px) {
    width: 100vw;
    height: 50%;
  }
`;

export const DifficultSelection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100vh;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: 'Bungee Inline', cursive;
    font-size: 34px;
    text-align: center;
    margin: 10px 0;
    width: 250px;
    border: 1px solid;
    border-radius: 5px;
    padding: 5px 15px;

    &:hover {
      color: white;
    }

    cursor: pointer;
  }

  h1 {
    color: #51a0ff;
    border-color: #51a0ff;

    &:hover {
      background-color: #51a0ff;
    }
  }
  h2 {
    color: #053cd7;
    border-color: #053cd7;

    &:hover {
      background-color: #053cd7;
    }
  }
  h3 {
    color: #ff5aee;
    border-color: #ff5aee;

    &:hover {
      background-color: #ff5aee;
    }
  }
  h4 {
    color: #ff8400;
    border-color: #ff8400;

    &:hover {
      background-color: #ff8400;
    }
  }
  h5 {
    color: #ec2d32;
    border-color: #ec2d32;

    &:hover {
      background-color: #ec2d32;
    }
  }
  h6 {
    font-family: 'Bungee Inline', cursive;
    text-align: center;
    font-size: 40px;
    margin-top: 50px;
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-family: 'Bungee Inline', cursive;
    text-align: center;
    font-size: 40px;
  }
`;
