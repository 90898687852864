import styled from 'styled-components';

import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 90vh;
  background-color: white;

  h2 {
    font-family: 'Roboto', sans-serif;
    font-size: 36px;
    text-align: center;
  }
`;

export const DragDropRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    padding: 0;
    margin: 0;
    margin-left: 10px;
    font-size: 26px;
  }

  @media (max-width: 968px) {
    flex-direction: column;
  }
`;

export const DragDropContainer = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CentralContainer = styled.div`
  width: 1200px;
  min-height: 600px;

  display: flex;
  justify-content: center;

  flex-wrap: wrap;

  @media (max-width: 786px) {
    width: auto;
    flex-direction: column;
  }

  h1 {
    font-family: 'Roboto', sans-serif;
    font-size: 56px;
    text-align: center;
    transform: scale(1.05);
    animation: shake infinite 4.5s;

    @keyframes shake {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.05);
      }
      100% {
        transform: scale(1);
      }
    }
  }

  img {
    width: 300px;
    margin: 15px 10px;
    cursor: pointer;
  }

  img:hover {
    transform: scale(1.05);
    transition: 0.2s;
  }
`;

export const SaveArea = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  @media (max-width: 786px) {
    flex-wrap: wrap;
  }
`;

export const ParkingBox = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  border: 0;
  justify-content: center;
  align-items: center;

  @media (max-width: 786px) {
    margin-top: 50px;
  }
`;

export const ColCentered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-family: 'Roboto', sans-serif;
    font-size: 36px;
    text-align: center;
  }

  @media (max-width: 786px) {
    width: 100%;
  }
`;
