import React from 'react';

import { Container, Row } from './styles';

import TrafficLight from '../TrafficLight';

export default function Board({
  question,
  bgimage,
  semaphoreStatus,
  setSemaphoreStatus,
  index,
}) {
  return (
    <Container>
      <strong>{question}</strong>
      <Row>
        <img src={bgimage} alt="background" />
        <TrafficLight
          index={index}
          selectedColor={semaphoreStatus}
          setSelectedColor={setSemaphoreStatus}
        />
      </Row>
    </Container>
  );
}
