import { useRef, useEffect, useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { isMobile } from 'react-device-detect';
import domtoimage from 'dom-to-image';

import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  DataURL,
} from '@utils';

import {
  ModalAddOps,
  ModalAlert,
  TertiaryButton,
  PrimaryButton,
  ModalConfirm,
} from '@components';

import { WheelValues } from '@components';

import { Areas, Title, SaveArea } from './styles';

am4core.useTheme(am4themes_animated);

const CHART_ID = 'population_chart';

function WheelOfLife(props) {
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  const socketRef = useRef();
  const chartRef = useRef(null);
  const [yourID, setYourID] = useState();

  //Modal variables
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCleanIsOpen, setModalCleanIsOpen] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);

  const [index, setIndex] = useState(0);

  const [data, setData] = useState([
    {
      category: 'Saúde',
      value: 0,
      color: '#67b7dc',
    },
    {
      category: 'Carreira',
      value: 0,
      color: '#6794dc',
    },
    {
      category: 'Amor',
      value: 0,
      color: '#6771dc',
    },
    {
      category: 'Espiritualidade',
      value: 0,
      color: '#8067dc',
    },
    {
      category: 'Família',
      value: 0,
      color: '#a367dc',
    },
    {
      category: 'Dinheiro',
      value: 0,
      color: '#c767dc',
    },
    {
      category: 'Diversão',
      value: 0,
      color: '#dc67ce',
    },
    {
      category: 'Amizade',
      value: 0,
      color: '#dc67ab',
    },
  ]);

  //---------chart functions ----------//
  function changeDataRefOnUseEffect(ref, dataArray) {
    ref.current = am4core.create(CHART_ID, am4charts.RadarChart);

    ref.current.data = dataArray;
    ref.current.padding(20, 20, 20, 20);

    let categoryAxis = ref.current.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    categoryAxis.renderer.labels.template.location = 0.5;
    categoryAxis.renderer.tooltipLocation = 0.5;

    let valueAxis = ref.current.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.min = 0;
    valueAxis.max = 10;
    valueAxis.renderer.minGridDistance = 10;

    let series = ref.current.series.push(new am4charts.RadarColumnSeries());
    series.columns.template.tooltipText = '{categoryX}: {valueY.value}';
    series.columns.template.width = am4core.percent(100);
    series.columns.template.strokeWidth = 0;
    series.columns.template.column.propertyFields.fill = 'color';
    series.dataFields.categoryX = 'category';
    series.dataFields.valueY = 'value';
  }

  useEffect(() => {
    changeDataRefOnUseEffect(chartRef, data);
  }, []);

  function setValueOnClick(index, value, shouldSendSocketMessage = true) {
    chartRef.current.data[index].value = value;
    chartRef.current.invalidateRawData();

    let newData = [...data];
    newData[index].value = value;

    setData(newData);

    if (index !== 7) {
      setIndex((prevIndex) => prevIndex + 1);
    }

    if (shouldSendSocketMessage) {
      sendDataObject(newData[index], index, 'add');
    }
  }

  function handleClean(shouldSendSocketMessage = true) {
    const newData = [...data];
    const newIndex = 0;

    for (let i = 0; i < data.length; i++) {
      chartRef.current.data[i].value = 0;
      chartRef.current.invalidateRawData();
      newData[i].value = 0;
    }
    setData(newData);

    if (shouldSendSocketMessage) {
      sendDataObject(newData, newIndex, 'clean');
    }
    setIndex(0);
    setModalCleanIsOpen(false);
  }

  const salvarFim = () => {
    setIsOpen(true);
  };

  const generatePdf = () => {
    domtoimage
      .toPng(document.getElementById(CHART_ID))
      .then(function (dataUrl) {
        const nome_paciente = localStorage
          .getItem('@nome_paciente')
          .replaceAll(' ', '_');
        const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
        downloadURI(
          dataUrl,
          filename,
          sendArquivo,
          os,
          token,
          nome_recurso_cod
        );
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  };

  useEffect(() => {
    document.title = 'TerapiaPlay - Roda da vida';
  }, []);

  function sendDataObject(obj, index, typeOfMessage) {
    const messageObject = {
      body: { data: obj, index, typeOfMessage },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  function receivedMessage(message) {
    const data = message.body.data;
    const recievedIndex = message.body.index;
    const isTypeOfMessageAdd = message.body.typeOfMessage === 'add';

    if (isTypeOfMessageAdd) {
      setIndex(recievedIndex);
      setValueOnClick(recievedIndex, data.value, false);
    } else {
      handleClean(false);
    }
  }

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);

    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, tokenSocketIo);
    }
  }, []);

  return (
    <>
      <Title>Roda da vida</Title>
      <div>
        <Areas className="areas">
          {index === 0 && (
            <WheelValues
              valueName="Saúde"
              index={index}
              setValue={setValueOnClick}
            />
          )}
          {index === 1 && (
            <WheelValues
              valueName="Carreira"
              index={index}
              setValue={setValueOnClick}
            />
          )}
          {index === 2 && (
            <WheelValues
              valueName="Amor"
              index={index}
              setValue={setValueOnClick}
            />
          )}
          {index === 3 && (
            <WheelValues
              valueName="Espiritualidade"
              index={index}
              setValue={setValueOnClick}
            />
          )}
          {index === 4 && (
            <WheelValues
              valueName="Família"
              index={index}
              setValue={setValueOnClick}
            />
          )}
          {index === 5 && (
            <WheelValues
              valueName="Dinheiro"
              index={index}
              setValue={setValueOnClick}
            />
          )}
          {index === 6 && (
            <WheelValues
              valueName="Diversão"
              index={index}
              setValue={setValueOnClick}
            />
          )}
          {index === 7 && (
            <WheelValues
              valueName="Amizade"
              index={index}
              setValue={setValueOnClick}
            />
          )}
        </Areas>
      </div>
      <div
        id={CHART_ID}
        style={{
          width: isMobile ? '100vw' : '100%',
          height: isMobile ? '275px' : '500px',
          marginTop: isMobile ? '0px' : '-20px',
        }}
      />
      <SaveArea>
        {typeUser === 'profissional' && (
          <TertiaryButton
            style={{ fontSize: 16, width: 100 }}
            onClick={() => setModalCleanIsOpen(true)}
            mobile
          >
            Limpar
          </TertiaryButton>
        )}
        {typeUser === 'profissional' && (
          <PrimaryButton
            style={{ fontSize: 16, width: 100 }}
            onClick={() => setIsOpenAlert(true)}
            mobile
          >
            Instruções
          </PrimaryButton>
        )}
        {typeUser === 'profissional' && (
          <PrimaryButton
            style={{ fontSize: 16, width: 100 }}
            onClick={salvarFim}
            mobile
          >
            Salvar
          </PrimaryButton>
        )}
        {typeUser === 'profissional' && (
          <PrimaryButton
            style={{ fontSize: 16, width: 100 }}
            onClick={() => setModalCloseIsOpen(true)}
            mobile
          >
            Fechar
          </PrimaryButton>
        )}
      </SaveArea>
      <ModalAddOps
        generatePdf={generatePdf}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        token={token}
      />
      <ModalAlert
        modalIsOpen={modalIsOpenAlert}
        setIsOpen={setIsOpenAlert}
        token={token}
        props={props}
        title={'Roda da vida'}
        recurso={nome_recurso_cod}
        bt01Txt={'Fechar'}
      />
      <ModalConfirm
        title="Reiniciar"
        description="Deseja realmente limpar o jogo?"
        modalIsOpen={modalCleanIsOpen}
        setIsOpen={setModalCleanIsOpen}
        confirmButtonText="Limpar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCleanIsOpen(false)}
        onConfirm={handleClean}
      />
      <ModalConfirm
        title="Fechar o jogo"
        description="Deseja realmente fechar o jogo?"
        modalIsOpen={modalCloseIsOpen}
        setIsOpen={setModalCloseIsOpen}
        confirmButtonText="Fechar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCloseIsOpen(false)}
        onConfirm={() =>
          handleBackHome(
            modalClose,
            nome_recurso_cod,
            typeUser,
            props,
            os,
            token,
            ambiente
          )
        }
      />
    </>
  );
}
export default WheelOfLife;
