import styled from 'styled-components';

const GenericTextArea = styled.textarea`
  width: ${(props) => (props.width ? props.width + 'px' : '200px')};
  height: ${(props) => (props.height ? props.height + 'px' : '200px')};

  margin: ${(props) => (props.margin ? props.margin + 'px' : '5px')};

  background: #e8e9e9;
  border-radius: 6px;
  padding: 10px;

  box-sizing: border-box;
  resize: none;
  border: 1px solid #90a4ae;
  outline: none;
  transition: 0.3s;
  &:focus {
    box-shadow: 0px 0px 12px 0px #aeb9bf;
  }

  font-family: 'Roboto', serif;

  &::-webkit-input-placeholder {
    color: #90a4ae;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: #90a4ae;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: #90a4ae;
  }

  &:-ms-input-placeholder {
    color: #90a4ae;
  }

  &::placeholder {
    color: #90a4ae;
  }
`;

export default GenericTextArea;
