//Main imports
import { useState, useEffect, useRef, Fragment } from 'react';

//Socket & API imports
import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  PacientName,
  DataURL,
} from '@utils';

//Styles import
import {
  CentralContainer,
  Container,
  DragDropRow,
  SaveArea,
  PlanesBox,
  DifficultSelection,
  Col,
} from './styles';

//Image imports
import {
  VeryEasyPlanes,
  EasyPlanes,
  NormalPlanes,
  HardPlanes,
  VeryHardPlanes,
} from '@assets';

//Components import
import { Stage, Layer, Text } from 'react-konva';
import PlanesTabs from '@components/LinkThePlanes';
import WaitingPacientScreen from '@components/WaitingPacientScreen';

import {
  ModalAddOps,
  ModalAlert,
  SecondaryButton,
  TertiaryButton,
  PrimaryButton,
  ModalConfirm,
} from '@components';

import MountUrlImage from '@components/MountUrlImage';

const LinkThePlanes = (props) => {
  //URL variables
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  //Socket variables
  const [yourID, setYourID] = useState();
  const socketRef = useRef();

  //Modal variables
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCleanIsOpen, setModalCleanIsOpen] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);

  //Drag'n Drop variables
  const dragUrl = useRef();
  const stageRef = useRef();
  const [images, setImages] = useState([]);
  const [selectedId, selectShape] = useState(null);
  const [selectedBgImage, setSelectedBgImage] = useState(EasyPlanes[0]);

  //Control variables
  const [page, setPage] = useState(0);
  const [difficult, setDifficult] = useState();
  const innerWidthSize = window.innerWidth > 710 ? 710 : window.innerWidth;
  const proportionalStageHeight = (innerWidthSize * 535) / 710;
  const scaleX = innerWidthSize / 710;
  const scaleY = proportionalStageHeight / 535;

  //******************************************************************************************* */
  //Functions

  function ondDropHandler(e) {
    e.preventDefault();
    stageRef.current.setPointersPositions(e);
    const nameFile = 100000 + Math.floor((999999 - 100000) * Math.random());
    selectShape(nameFile);
    setImages(
      images.concat([
        {
          ...stageRef.current.getPointerPosition(),
          src: dragUrl.current,
          drag: true,
          alt: nameFile,
        },
      ])
    );
    sendImage(
      images.concat([
        {
          ...stageRef.current.getPointerPosition(),
          src: dragUrl.current,
          drag: true,
          alt: nameFile,
        },
      ])
    );
  }

  function onDropMobileHandler(e) {
    e.preventDefault();
    if (dragUrl.current !== null) {
      stageRef.current.setPointersPositions(e);
      let Xcoordinate = stageRef.current.getPointerPosition().x / scaleX;
      let Ycoordinate = stageRef.current.getPointerPosition().y / scaleY;
      const nameFile = 100000 + Math.floor((999999 - 100000) * Math.random());
      selectShape(nameFile);
      setImages(
        images.concat([
          {
            x: Xcoordinate,
            y: Ycoordinate,
            src: dragUrl.current,
            drag: true,
            alt: nameFile,
          },
        ])
      );
      sendImage(
        images.concat([
          {
            x: Xcoordinate,
            y: Ycoordinate,
            src: dragUrl.current,
            drag: true,
            alt: nameFile,
          },
        ])
      );
    }
  }
  function receivedMessage(message) {
    if (message.body.imagesData) setImages(message.body.imagesData);
    if (message.body.page) setPage(message.body.page);
    if (message.body.bgImage) setSelectedBgImage(message.body.bgImage);
    if (message.body.difficult) setDifficult(message.body.difficult);
  }

  function changePageOnSocket(pg) {
    const messageObject = {
      body: { page: pg },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  function changeBackgroundImageOnSocket(imag) {
    const messageObject = {
      body: { bgImage: imag },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  function changeDifficultOnSocket(diff) {
    const messageObject = {
      body: { difficult: diff },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  function sendImage(imagesData) {
    const messageObject = {
      body: { imagesData: imagesData },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  const salvarFim = () => {
    setIsOpen(true);
  };

  const handleClean = () => {
    setImages([
      {
        x: 557,
        y: 558,
        src: selectedBgImage,
        height: 639,
        width: 630,
        drag: false,
      },
    ]);
    sendImage([
      {
        x: 557,
        y: 558,
        src: selectedBgImage,
        height: 639,
        width: 630,
        drag: false,
      },
    ]);
    setModalCleanIsOpen(false);
  };

  const handleExport = () => {
    selectShape(null);
    setTimeout(() => {
      const uri = stageRef.current.toDataURL();
      const nome_paciente = localStorage
        .getItem('@nome_paciente')
        .replaceAll(' ', '_');
      const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
      downloadURI(uri, filename, sendArquivo, os, token, nome_recurso_cod);
    }, 500);
  };

  const chooseCards = () => {
    switch (difficult) {
      case 'veryeasy':
        return VeryEasyPlanes;
      case 'easy':
        return EasyPlanes;
      case 'normal':
        return NormalPlanes;
      case 'hard':
        return HardPlanes;
      case 'veryhard':
        return VeryHardPlanes;

      default:
        return EasyPlanes;
    }
  };

  const chooseSize = () => {
    switch (difficult) {
      case 'easy':
        return 85;
      case 'normal':
        return 73;
      case 'hard':
        return 62;
      case 'veryhard':
        return 55;
      default:
        return 98;
    }
  };

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);

    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, tokenSocketIo);
    }
  }, []);

  return (
    <Container>
      {typeUser === 'paciente' && page !== 2 && (
        <WaitingPacientScreen text="Aguarde enquanto o profissional escolhe um jogo" />
      )}
      {typeUser === 'profissional' && page === 0 && (
        <DifficultSelection>
          <h6>Selecione uma dificuldade</h6>
          <h1
            onClick={() => {
              setDifficult('veryeasy');
              changeDifficultOnSocket('veryeasy');
              setPage(1);
              changePageOnSocket(1);
            }}
          >
            Muito fácil
          </h1>
          <h2
            onClick={() => {
              setDifficult('easy');
              changeDifficultOnSocket('easy');
              setPage(1);
              changePageOnSocket(1);
            }}
          >
            Fácil
          </h2>
          <h3
            onClick={() => {
              setDifficult('normal');
              changeDifficultOnSocket('normal');
              setPage(1);
              changePageOnSocket(1);
            }}
          >
            Normal
          </h3>
          <h4
            onClick={() => {
              setDifficult('hard');
              changeDifficultOnSocket('hard');
              setPage(1);
              changePageOnSocket(1);
            }}
          >
            Difícil
          </h4>
          <h5
            onClick={() => {
              setDifficult('veryhard');
              changeDifficultOnSocket('veryhard');
              setPage(1);
              changePageOnSocket(1);
            }}
          >
            Muito difícil
          </h5>
        </DifficultSelection>
      )}

      {typeUser === 'profissional' && page === 1 && (
        <Col>
          <h1>Selecione um dos tabuleiros</h1>
          <CentralContainer>
            {difficult &&
              typeUser === 'profissional' &&
              chooseCards().map((image, index) => (
                <Fragment key={index}>
                  <img
                    src={image}
                    alt="a"
                    onClick={() => {
                      setSelectedBgImage(image);
                      changeBackgroundImageOnSocket(image);
                      setPage(2);
                      changePageOnSocket(2);
                      setImages([
                        {
                          x: 557,
                          y: 558,
                          src: image,
                          height: 639,
                          width: 630,
                          drag: false,
                        },
                      ]);
                      sendImage([
                        {
                          x: 557,
                          y: 558,
                          src: image,
                          height: 639,
                          width: 630,
                          drag: false,
                        },
                      ]);
                    }}
                  />
                </Fragment>
              ))}
          </CentralContainer>
        </Col>
      )}

      {page === 2 && (
        <DragDropRow>
          <PlanesTabs
            dragUrl={dragUrl}
            mobileTouchHandler={onDropMobileHandler}
          />
          <div
            style={{
              flexDirection: 'column',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <PlanesBox
              className={'containerr'}
              onDrop={(e) => ondDropHandler(e)}
              onDragOver={(e) => e.preventDefault()}
              style={{backgroundImage: `url(${images[0].src})`}}
            >
              <Stage
                width={innerWidthSize}
                height={proportionalStageHeight}
                scaleX={scaleX}
                scaleY={scaleY}
                onMouseDown={checkDeselect}
                onTouchStart={checkDeselect}
                ref={stageRef}
              >
                <Layer>
                  {images.map(
                    (image, index) =>
                      index !== 0 && (
                        <MountUrlImage
                          image={image}
                          key={index}
                          shapeProps={image}
                          isSelected={image.alt === selectedId}
                          onSelect={() => {
                            if (image.drag) selectShape(image.alt);
                          }}
                          onChange={(newAttrs) => {
                            const aux = images.slice();
                            aux[index] = newAttrs;
                            setImages(aux);
                            sendImage(aux);
                          }}
                          width={chooseSize()}
                          height={chooseSize()}
                          resizable={false}
                          rotatable={false}
                        />
                      )
                  )}

                  {typeUser === 'profissional' && (
                    <Text x={10} y={15} text={PacientName()} fontSize={24} />
                  )}
                </Layer>
              </Stage>
            </PlanesBox>
            <SaveArea>
              <TertiaryButton
                style={{ fontSize: 16, width: 100 }}
                onClick={() => setModalCleanIsOpen(true)}
                mobile
              >
                Limpar
              </TertiaryButton>
              {typeUser === 'profissional' && (
                <PrimaryButton
                  style={{ fontSize: 16, width: 100 }}
                  onClick={() => setIsOpenAlert(true)}
                  mobile
                >
                  Instruções
                </PrimaryButton>
              )}
              {typeUser === 'profissional' && (
                <PrimaryButton
                  style={{ fontSize: 16, width: 100 }}
                  onClick={salvarFim}
                  mobile
                >
                  Salvar
                </PrimaryButton>
              )}
              {typeUser === 'profissional' && (
                <PrimaryButton
                  style={{ fontSize: 16, width: 100 }}
                  onClick={() => setModalCloseIsOpen(true)}
                  mobile
                >
                  Fechar
                </PrimaryButton>
              )}
              {typeUser === 'profissional' && (
                <TertiaryButton
                  onClick={() => {
                    handleClean();
                    setPage(0);
                    changePageOnSocket(0);
                  }}
                  style={{ fontSize: 15 }}
                  mobile
                >
                  Escolher outra carta
                </TertiaryButton>
              )}
            </SaveArea>
          </div>
        </DragDropRow>
      )}
      <ModalAddOps
        generatePdf={handleExport}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        token={token}
      />
      <ModalAlert
        modalIsOpen={modalIsOpenAlert}
        setIsOpen={setIsOpenAlert}
        token={token}
        props={props}
        title={'Mostre o caminho'}
        recurso={nome_recurso_cod}
        bt01Txt={'Fechar'}
      />
      <ModalConfirm
        title="Limpar o jogo"
        description="Deseja realmente limpar o jogo?"
        modalIsOpen={modalCleanIsOpen}
        setIsOpen={setModalCleanIsOpen}
        confirmButtonText="Limpar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCleanIsOpen(false)}
        onConfirm={() => handleClean()}
      />
      <ModalConfirm
        title="Fechar o jogo"
        description="Deseja realmente fechar o jogo?"
        modalIsOpen={modalCloseIsOpen}
        setIsOpen={setModalCloseIsOpen}
        confirmButtonText="Fechar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCloseIsOpen(false)}
        onConfirm={() =>
          handleBackHome(
            modalClose,
            nome_recurso_cod,
            typeUser,
            props,
            os,
            token,
            ambiente
          )
        }
      />
    </Container>
  );
};

export default LinkThePlanes;
