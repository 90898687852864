import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';

import { IoClose } from 'react-icons/io5';
import { RiErrorWarningLine } from 'react-icons/ri';

import Modal from 'react-modal';

import { ButtonsContainer, Container } from './styles.js';

import { PrimaryButton, SecondaryButton } from '../../components';

const customStyles = {
  content: {
    top: `${window.innerWidth / 2}px`,
    left: '50%',
    right: 'auto',
    bottom: 'auto',

    maxWidth: '90vw',
    marginRight: '-50%',
    transform: `translate(-50%, -${window.innerWidth / 2.8}px)`,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 999999,
  },
};

const ModalConfirm = ({
  modalIsOpen,
  setIsOpen,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  onCancel,
  title,
  description,
}) => {
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel="Confirmar"
      >
        <div
          id="modal-dialog"
          style={{ width: 650, maxWidth: '85vw' }}
          className="container mfp-with-anim popup-wrapper bg-contrast"
        >
          <div className="row">
            <Container
              className="BoxSession col-md-12"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <IoClose
                size={30}
                color="#7D8C94"
                onClick={() => setIsOpen(false)}
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '15px',
                  right: '15px',
                  zIndex: 99,
                }}
              />
              <h1>{title}</h1>

              <h2>{description}</h2>
            </Container>
            <ButtonsContainer>
              <SecondaryButton onClick={() => onCancel()} mobile>
                {cancelButtonText}
              </SecondaryButton>
              <PrimaryButton onClick={() => onConfirm()} mobile>
                {confirmButtonText}
              </PrimaryButton>
            </ButtonsContainer>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalConfirm;
