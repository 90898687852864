import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px;
  display: flex;
  border: 0;
  background: #fff;

  justify-content: center;
  align-items: center;

  position: relative;

  h2 {
    text-align: center;
    font-family: 'Roboto', sans-serif;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const SaveArea = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    margin-left: 50px;
  }
`;

export const RostoBox = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  border: 0;
  justify-content: center;
  align-items: center;
`;

export const DiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
  margin-left: 30px;

  @media (max-width: 768px) {
    margin-left: 0;
    align-self: center;
  }
`;

export const DiceDiv = styled.div`
  display: flex;
  border: 1px solid;
  height: 150px;
  width: 150px;

  align-self: flex-end;

  /* background-image: ${(props) => props.background}; */
  background-image: url(${(props) => props.background});

  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
`;
