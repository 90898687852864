/* eslint-disable no-unused-expressions */
import styled from 'styled-components';

export const Container = styled.div`
  height: ${(props) => {
    if (props.all.size === 'lg') {
      return '17vw';
    } else if (props.all.size === 'md') {
      return '15rem';
    } else {
      return '20rem';
    }
  }};
  min-height: 15rem;
  width: ${(props) => {
    if (props.all.size === 'lg') {
      return '40vw';
    } else if (props.all.size === 'md') {
      return '65vw';
    } else {
      return '20rem';
    }
  }};
  min-width: 18rem;

  margin-top: 100px;

  background: #a3ddef;

  transition: 1s;
  transform: ${(props) =>
    props.isFocused && props.all.rotation !== 'none'
      ? props.all.rotation === 'right'
        ? 'rotate(3deg)'
        : 'rotate(-3deg)'
      : 'rotate(0deg)'};

  box-shadow: 5px 7px 20px grey;

  @media (max-width: 786px) {
    height: 20rem;
    width: 80%;
    min-width: 15rem;
    padding: 0;
    text-align: center;
  }
`;

export const InnerTitle = styled.span`
  font-family: 'Marcellus SC', serif;
  font-weight: 700;
  color: #502d61;
  font-size: 24px;
  margin-left: 10px;
  margin-top: 20px;
  text-align: center;

  @media (max-width: 800px) {
    margin: 0;
  }
`;

export const TextArea = styled.textarea`
  width: 95%;
  height: 80%;
  text-decoration: none;
  border: none;
  padding: 5px 10px;
  text-align: start;
  font-family: 'Marcellus SC', serif;
  resize: none;
  background: transparent;
  font-size: 20px;
  box-sizing: border-box;
  outline: 0;
  overflow: hidden;

  @media(max-width: 786px) {
    font-size: ${(props) => props.all.size === 'md' ? '16px' : '20px'};
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 3rem;
  width: 16rem;

  position: relative;
  top: -2rem;
  left: 2rem;

  h1 {
    font-family: 'Marcellus SC', serif;
    color: #502d61;
    font-size: 26px;
    text-align: center;
  }

  background: #95b389;

  transition: 1s;
  transform: ${(props) =>
    props.isFocused ? 'scale(1.1,1.1) rotate(-3deg)' : 'scale(1,1)'};

  @media (max-width: 800px) {
    left: -0.5rem;
  }

  clip-path: polygon(
    2% 97%,
    1% 94%,
    3% 90%,
    1% 84%,
    2% 78%,
    4% 74%,
    2% 70%,
    1% 62%,
    3% 60%,
    4% 55%,
    2% 52%,
    1% 44%,
    3% 43%,
    5% 37%,
    2% 32%,
    1% 24%,
    2% 19%,
    1% 14%,
    1% 7%,
    3% 3%,
    8% 3%,
    14% 5%,
    21% 4%,
    30% 3%,
    37% 4%,
    44% 5%,
    50% 4%,
    55% 3%,
    62% 3%,
    68% 4%,
    73% 4%,
    80% 5%,
    85% 4%,
    93% 5%,
    98% 4%,
    96% 10%,
    98% 16%,
    98% 24%,
    96% 31%,
    99% 36%,
    97% 44%,
    99% 50%,
    99% 59%,
    97% 65%,
    99% 71%,
    99% 79%,
    97% 84%,
    99% 91%,
    98% 97%,
    92% 96%,
    86% 96%,
    76% 95%,
    63% 96%,
    53% 97%,
    42% 97%,
    33% 98%,
    26% 95%,
    17% 96%,
    11% 98%
  );
`;
