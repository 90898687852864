import React, { useState, useEffect, useRef } from 'react';

import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  PacientName,
  DataURL,
} from '@utils';

import { Container, FormTitle, CentralContainer, Row, Header } from './styles';

import domtoimage from 'dom-to-image';

import {
  Ciranda,
  ModalAddOps,
  ModalAlert,
  PrimaryButton,
  ModalConfirm,
} from '@components';

export default function Pessoas(props) {
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  const [g1, setG1] = useState('');
  const [g2, setG2] = useState('');
  const [y1, setY1] = useState('');
  const [y2, setY2] = useState('');
  const [r1, setR1] = useState('');
  const [r2, setR2] = useState('');

  const [print, setPrint] = useState(false);

  const [yourID, setYourID] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);

  const socketRef = useRef();

  const generatePdf = () => {
    setPrint(true);
    domtoimage
      .toPng(document.getElementById('divIdToPrint'))
      .then(function (dataUrl) {
        const nome_paciente = localStorage
          .getItem('@nome_paciente')
          .replaceAll(' ', '_');
        const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
        downloadURI(
          dataUrl,
          filename,
          sendArquivo,
          os,
          token,
          nome_recurso_cod
        );

        setPrint(false);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  };

  function receivedMessage(message) {
    setG1(message.body.g1);
    setG2(message.body.g2);
    setY1(message.body.y1);
    setY2(message.body.y2);
    setR1(message.body.r1);
    setR2(message.body.r2);
  }

  function setEmitSocket() {
    const messageObject = {
      body: {
        g1: g1,
        g2: g2,
        y1: y1,
        y2: y2,
        r1: r1,
        r2: r2,
      },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);
    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, tokenSocketIo);
    }
  }, []);

  return (
    <Container id="divIdToPrint" className="containerr">
      <FormTitle
        onClick={() =>
          handleBackHome(
            modalClose,
            nome_recurso_cod,
            typeUser,
            props,
            os,
            token,
            ambiente
          )
        }
      >
        <span>Ciranda de Pessoas</span>
      </FormTitle>
      <CentralContainer>
        <Header>
          <span>Instruções para o(a) cliente:</span>
        </Header>
        <Header>
          Nos círculos <span style={{ color: '#a1dec5' }}>verdes</span>, coloque
          o nome daquelas pessoas mais próximas e com as quais você tem uma
          relação mais harmoniosa.
        </Header>
        <Header>
          Nos <span style={{ color: '#ebd88e' }}>amarelos</span>, coloque o nome
          das pessoas mais próximas e com as quais sua relação é razoavelmente
          boa.
        </Header>
        <Header>
          E nos <span style={{ color: '#ff8787' }}>vermelhos</span>, coloque o
          nome das pessoas próximas com as quais você tem relações conturbadas.
        </Header>
        {typeUser === 'profissional' && (
          <Row
            style={{
              marginTop: 40,
              marginBottom: 40,
              width: '100%',
            }}
          >
            {!print && (
              <Row>
                <PrimaryButton onClick={() => setIsOpenAlert(true)} mobile>
                  Instruções
                </PrimaryButton>
                <PrimaryButton onClick={() => setIsOpen(true)} mobile>
                  Salvar
                </PrimaryButton>
                <PrimaryButton onClick={() => setModalCloseIsOpen(true)} mobile>
                  Fechar
                </PrimaryButton>
              </Row>
            )}
          </Row>
        )}
        <Ciranda
          g1={g1}
          g2={g2}
          y1={y1}
          y2={y2}
          r1={r1}
          r2={r2}
          setG1={setG1}
          setG2={setG2}
          setR1={setR1}
          setR2={setR2}
          setY1={setY1}
          setY2={setY2}
          setEmitSocket={setEmitSocket}
        />

        {typeUser === 'profissional' && (
          <h2 style={{ marginTop: '50px' }}>{PacientName()}</h2>
        )}
      </CentralContainer>
      <ModalAddOps
        generatePdf={generatePdf}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        token={token}
      />
      <ModalAlert
        modalIsOpen={modalIsOpenAlert}
        setIsOpen={setIsOpenAlert}
        token={token}
        props={props}
        title={'Ciranda de Pessoas'}
        recurso={nome_recurso_cod}
        bt01Txt={'Fechar'}
      />
      <ModalConfirm
        title="Fechar o jogo"
        description="Deseja realmente fechar o jogo?"
        modalIsOpen={modalCloseIsOpen}
        setIsOpen={setModalCloseIsOpen}
        confirmButtonText="Fechar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCloseIsOpen(false)}
        onConfirm={() =>
          handleBackHome(
            modalClose,
            nome_recurso_cod,
            typeUser,
            props,
            os,
            token,
            ambiente
          )
        }
      />
    </Container>
  );
}
