//Main imports
import React, { useState, useEffect, useRef } from 'react';

//Socket & API imports
import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  DataURL,
} from '@utils';

//Styles import
import {
  Container,
  CentralContainer,
  AnswerTextArea,
  CardContainer,
  ButtonsContainer,
  Row,
  AnsweredCardsContainer,
} from './styles';

//Image imports
import { VaiEncararCards, VaiEncararBack, VaiEncararTitle } from '@assets';

//Components import
import {
  ModalAddOps,
  ModalAlert,
  TertiaryButton,
  PrimaryButton,
  ModalConfirm,
} from '@components';

import domtoimage from 'dom-to-image';

export default function VaiEncarar(props) {
  //URL variables
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  //Socket variables
  const [yourID, setYourID] = useState();
  const socketRef = useRef();

  //Modal variables
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);
  const [print, setPrint] = useState(false);

  //Control variables
  const [page, setPage] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [textAreaValue, setTextAreaValue] = useState('');
  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const [flipped, setFlipped] = useState(true);

  //**************************************************************************************************** */
  //Functions

  const handleNextCard = () => {
    if (activeCardIndex === 28) {
      return;
    } else {
      setFlipped(false);
      setFlippedOnSocket(false);
      setTimeout(() => {
        setActiveCardIndex(activeCardIndex + 1);
        setActiveIndexOnSocket(activeCardIndex + 1);
      }, 1000);
      setTimeout(() => {
        setFlipped(true);
        setFlippedOnSocket(true);
      }, 1000);
      addAnswer(true);
    }
  };

  const handlePreviousCard = () => {
    //na hora que ele clica pra voltar o index que entra na função é da carta que ele estava
    console.log(activeCardIndex);
    if (activeCardIndex > 0 && activeCardIndex < 29) {
      setFlipped(false);
      setFlippedOnSocket(false);
      setTimeout(() => {
        setActiveCardIndex(activeCardIndex - 1);
        setActiveIndexOnSocket(activeCardIndex - 1);
        setTextOnSocket(answers[activeCardIndex - 1]);
      }, 1000);
      setTimeout(() => {
        setFlipped(true);
        setFlippedOnSocket(true);
      }, 1000);
      addAnswer(false);
    }
  };

  const addAnswer = (movingForward) => {
    //O index ativo que entra aqui é o que ele estava
    console.log(activeCardIndex);
    if (activeCardIndex >= 0 && activeCardIndex <= 28) {
      let newAnswers = answers;
      newAnswers[activeCardIndex] = textAreaValue;
      setAnswers(newAnswers);

      setEmitSocket(newAnswers);

      if (activeCardIndex === newAnswers.length - 1 && movingForward) {
        setTextAreaValue('');
      } else if (movingForward) {
        setTextAreaValue(newAnswers[activeCardIndex + 1]);
      } else {
        setTextAreaValue(newAnswers[activeCardIndex - 1]);
      }
    } else {
      setPage(1);
      setPageOnSocket(1);
    }
  };

  const generatePdf = () => {
    setPrint(true);
    domtoimage
      .toPng(document.getElementById('divIdToPrint'))
      .then(function (dataUrl) {
        const nome_paciente = localStorage
          .getItem('@nome_paciente')
          .replaceAll(' ', '_');
        const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
        downloadURI(
          dataUrl,
          filename,
          sendArquivo,
          os,
          token,
          nome_recurso_cod
        );

        setPrint(false);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  };

  function receivedMessage(message) {
    if (message.body.textvalue) setTextAreaValue(message.body.textvalue);
    if (message.body.answers) {
      setAnswers(message.body.answers);
      setTextAreaValue('');
    }
    if (message.body.page) setPage(message.body.page);
    if (message.body.activeCardIndex !== undefined) {
      setActiveCardIndex(message.body.activeCardIndex);
    }
    if (message.body.flipped !== undefined) setFlipped(message.body.flipped);
  }

  function setPageOnSocket(pg) {
    const messageObject = {
      body: {
        page: pg,
      },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  function setTextOnSocket(txt) {
    const messageObject = {
      body: {
        textvalue: txt,
      },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  function setActiveIndexOnSocket(ai) {
    const messageObject = {
      body: {
        activeCardIndex: ai,
      },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  function setFlippedOnSocket(fp) {
    const messageObject = {
      body: {
        flipped: fp,
      },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  function setEmitSocket(ans) {
    const messageObject = {
      body: {
        answers: ans,
      },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);
    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, tokenSocketIo);
    }
  }, []);

  return (
    <Container id="divIdToPrint">
      <CentralContainer>
        <img
          src={VaiEncararTitle}
          alt={VaiEncararTitle}
          style={{ maxWidth: '90vw' }}
        />
        {page === 0 && (
          <>
            {typeUser === 'profissional' && !print && (
              <PrimaryButton
                onClick={() => setIsOpenAlert(true)}
                style={{ marginTop: 25, width: '120px' }}
              >
                Instruções
              </PrimaryButton>
            )}
            <h1>
              {activeCardIndex + 1}/{VaiEncararCards.length}
            </h1>
            <CardContainer flipped={flipped}>
              <img
                src={VaiEncararCards[activeCardIndex]}
                alt={VaiEncararCards[activeCardIndex]}
              />
              <img src={VaiEncararBack} alt={VaiEncararBack} />
            </CardContainer>
            <AnswerTextArea
              value={textAreaValue}
              onChange={(e) => setTextAreaValue(e.target.value)}
              maxLength={100}
              onKeyPress={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
              onKeyUp={() => setTextOnSocket(textAreaValue)}
              spellCheck={false}
            />
          </>
        )}
      </CentralContainer>
      <Row>
        {page === 1 &&
          answers.map((val, index) => (
            <AnsweredCardsContainer>
              <img src={VaiEncararCards[index]} alt={VaiEncararCards[index]} />
              <textarea
                value={val}
                contentEditable={false}
                spellCheck={false}
              />
            </AnsweredCardsContainer>
          ))}
      </Row>
      {typeUser === 'profissional' && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {page === 0 && (
              <TertiaryButton size="md" onClick={() => handlePreviousCard()}>
                Carta anterior
              </TertiaryButton>
            )}
            {page === 0 && (
              <PrimaryButton
                onClick={async () => {
                  await setFlippedOnSocket(!flipped);
                  setFlipped(!flipped);
                }}
              >
                Virar carta
              </PrimaryButton>
            )}
            {page === 0 && (
              <TertiaryButton size="md" onClick={() => handleNextCard()}>
                Próxima carta
              </TertiaryButton>
            )}
          </div>
          <ButtonsContainer>
            {page === 0 && (
              <PrimaryButton
                style={{ width: 230 }}
                onClick={() => {
                  addAnswer();
                  setPage(1);
                  setPageOnSocket(1);
                }}
              >
                Finalizar respostas
              </PrimaryButton>
            )}

            {page === 1 && (
              <PrimaryButton onClick={() => setIsOpen(true)}>
                Salvar
              </PrimaryButton>
            )}
            <PrimaryButton onClick={() => setModalCloseIsOpen(true)}>
              Fechar
            </PrimaryButton>
          </ButtonsContainer>
        </>
      )}
      <ModalAddOps
        generatePdf={generatePdf}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        token={token}
      />
      <ModalAlert
        modalIsOpen={modalIsOpenAlert}
        setIsOpen={setIsOpenAlert}
        token={token}
        props={props}
        title={'Vai encarar'}
        recurso={nome_recurso_cod}
        bt01Txt={'Fechar'}
      />
      <ModalConfirm
        title="Fechar o jogo"
        description="Deseja realmente fechar o jogo?"
        modalIsOpen={modalCloseIsOpen}
        setIsOpen={setModalCloseIsOpen}
        confirmButtonText="Fechar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCloseIsOpen(false)}
        onConfirm={() =>
          handleBackHome(
            modalClose,
            nome_recurso_cod,
            typeUser,
            props,
            os,
            token,
            ambiente
          )
        }
      />
    </Container>
  );
}
