import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { x_icon } from '../../assets';

import { modalOpen, startSocket } from '../../utils';

import {
  ModalReadMore,
  ModalStartResource,
  ModalAlert,
} from '../../components';

import { getListRecursos } from '../../services/apiService';

/** CSS */
import {
  Container,
  Box,
  SearchBox,
  InputContainer,
  InputField,
  ButtonSave,
  CardContainer,
} from './styles';

/** Imagens */
import { GoSearch } from 'react-icons/go';
import { LapisGrey } from '../../assets';

function Games(props) {
  const params = useParams();
  const [yourID, setYourID] = useState();
  const socketRef = useRef();

  // url related variables
  const url = props.location.pathname.split('/');
  const nome_recurso_cod = url[1];
  const typeUser = url[2];
  const urldebug =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjZlODM0ODQ4LWRlY2ItNDQ5MS1hNjRkLTllNDgyNjAxOTliZCIsImlhdCI6MTYxMjc5MTUwMiwiZXhwIjoxOTI4MzY3NTAyfQ.mcS6GKpGWw7YWjzEBxvEQYlPNq9gunRKe1Jswe__5vw';
  const os = params.os || 'terapiaplay';
  const token = params.token || urldebug;
  const urlSocketToken = typeUser === 'profissional' ? url[5] : url[4];
  const ambiente = url[6] || '';

  // when to turn card logic
  const [selectedId, setSelectedId] = useState(-1);

  const [patientGame, setPatientGame] = useState('');

  // modal related variables
  const [modalIsOpenAlert, setIsOpenAlert] = useState(
    ambiente === 'salavirtual' ? false : true
  );
  const [readMoreModal, setReadMoreModal] = useState(false);
  const [readMoreContent, setReadMoreContent] = useState({});
  const [startResourceContent, setStartResourceContent] = useState({});
  const [startResourceModal, setStartResourceModal] = useState(false);

  const [jogos, setJogos] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const listRecursos = async () => {
    await getListRecursos(os, token)
      .then((resp) => {
        setJogos(resp.data.records);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const searchData = () => {
    const results = jogos.filter((j) =>
      j.nome_recurso.toLowerCase().includes(searchTerm)
    );
    setSearchResults(results);
  };

  const modalClose = () => {
    if (window && window.parent) {
      window.parent.postMessage(
        {
          action: 'fechar',
        },
        '*'
      );
    }
  };

  const handleBackHome = () => {
    const r = window.confirm('Gostaria realmente de encerrar os jogos?');
    if (r === true) {
      modalClose();
    }
  };

  function handlePatientChooseGame(chosedGame) {
    chooseGame(chosedGame);
  }

  function chooseGame(patientChosedGame) {
    const messageObject = {
      body: { hasPatientChosedThisGame: patientChosedGame },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', urlSocketToken, messageObject);
  }

  function receivedMessage(message) {
    setPatientGame(message.body.hasPatientChosedThisGame);
  }

  useEffect(() => {
    startSocket(
      socketRef,
      setYourID,
      receivedMessage,
      urlSocketToken,
      typeUser
    );

    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, urlSocketToken);
    }
  }, [nome_recurso_cod, urlSocketToken, typeUser]);

  useEffect(() => {
    listRecursos();
    document.title = 'Jogos';
  }, []);

  useEffect(() => {
    searchData();
  }, [jogos, searchTerm]);

  return (
    <>
      <Container
        backgroundColor={ambiente === 'salavirtual' ? '#3D3D3D' : '#ffffff'}
      >
        <SearchBox>
          <InputContainer>
            <GoSearch size={26} color={'#AEB9BF'} />
            <InputField
              value={searchTerm}
              spellCheck={false}
              onChange={handleChange}
              placeholder="Buscar Recurso"
            />
          </InputContainer>
          <ButtonSave color={'#ff0000'} onClick={handleBackHome}>
            {ambiente === 'salavirtual' && (
              <>
                <button>Fechar</button>
                <img src={x_icon} alt="x icon" />
              </>
            )}
          </ButtonSave>
          <ButtonSave color={'#ff0000'}>
            {ambiente !== 'salavirtual' && typeUser === 'profissional' && (
              <>
                <a href={`/${os}/${token}`}>Voltar</a>
                <img src={x_icon} alt="x icon" />
              </>
            )}
          </ButtonSave>
        </SearchBox>

        <CardContainer>
          {searchResults.length > 0 &&
            searchResults.map((val, index) => {
              return (
                <React.Fragment>
                  {val.jogo_recurso === '2' && (
                    <Box status={val.status}>
                      <div
                        className="card"
                        onClick={() => setSelectedId(index)}
                      >
                        <div
                          className="content"
                          style={
                            selectedId === index
                              ? {
                                  transform: 'rotateY(180deg)',
                                  transition: ' transform 0.5s',
                                }
                              : {}
                          }
                        >
                          <div
                            status={val.status}
                            className={
                              patientGame === val.nome_recurso
                                ? 'front choosedGameCard'
                                : 'front'
                            }
                          >
                            {typeUser === 'profissional' &&
                              patientGame === val.nome_recurso && (
                                <div className={'choosedGame'}>
                                  Jogo escolhido pelo paciente
                                </div>
                              )}
                            <img
                              alt="Icon"
                              width="88px"
                              className="frontImage"
                              src={val.arquivo ? val.arquivo : LapisGrey}
                              style={{ filter: 'none', marginTop: 40 }}
                            />
                            <div status={val.status} className={'title'}>
                              {val.nome_recurso}
                            </div>
                          </div>
                          <div
                            status={val.status}
                            className={
                              patientGame === val.nome_recurso
                                ? 'back choosedGameCard'
                                : 'back'
                            }
                          >
                            <p className={'title'}>{val.nome_recurso}</p>
                            <p className={'description'}>
                              {val.desc_recurso_menor}
                            </p>
                            <div
                              className={
                                typeUser === 'profissional'
                                  ? 'button'
                                  : 'button patient'
                              }
                            >
                              {typeUser === 'profissional' && (
                                <div
                                  className={'white'}
                                  onClick={() => {
                                    setReadMoreContent(val);
                                    setReadMoreModal(true);
                                  }}
                                  style={{ cursor: 'pointer' }}
                                >
                                  Leia mais
                                </div>
                              )}
                              {val.status && typeUser === 'profissional' && (
                                <div
                                  className={'blue'}
                                  onClick={() => {
                                    setStartResourceContent(val);
                                    setStartResourceModal(true);
                                  }}
                                >
                                  Iniciar jogo
                                </div>
                              )}
                              {typeUser === 'paciente' && (
                                <div
                                  className={
                                    patientGame === val.nome_recurso
                                      ? 'darkBlue'
                                      : 'blue'
                                  }
                                  onClick={() =>
                                    handlePatientChooseGame(val.nome_recurso)
                                  }
                                >
                                  Selecionar jogo
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Box>
                  )}
                </React.Fragment>
              );
            })}
        </CardContainer>
        <ModalAlert
          modalIsOpen={modalIsOpenAlert}
          setIsOpen={setIsOpenAlert}
          token={token}
          props={props}
          title={'Saiba mais'}
          type={'os'}
          bt01Txt={'Continuar apenas nos jogos'}
        />
        <ModalReadMore
          modalIsOpen={readMoreModal}
          setIsOpen={setReadMoreModal}
          content={readMoreContent}
          os={os}
          token={token}
          ambiente={ambiente}
          setStartResourceContent={setStartResourceContent}
          setStartResourceModal={setStartResourceModal}
        />
      </Container>
      <ModalStartResource
        isOpen={startResourceModal}
        setIsOpen={setStartResourceModal}
        content={startResourceContent}
        os={os}
        token={token}
        ambiente={ambiente}
        tokenSocket={urlSocketToken}
      />
    </>
  );
}

export default Games;
