import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 90vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  background: white;
`;

export const CentralContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-family: 'Bungee Inline', cursive;
    font-weight: 700;
    font-size: 18px;
  }
`;

export const AnswerTextArea = styled.textarea`
  resize: none;
  width: 300px;
  height: 50px;
  margin-top: 10px;

  border: 2px solid;
  outline: none;
`;

export const CardContainer = styled.div`
  height: 400px;
  width: 312px;

  img {
    position: absolute;
  }

  img:nth-child(1) {
    opacity: ${(props) => (props.flipped ? 1 : 0)};
    animation: ${(props) =>
        props.flipped ? 'backToFrontFlip' : 'frontToBackFlip'}
      linear 1s;
  }

  img:nth-child(2) {
    opacity: ${(props) => (props.flipped ? 0 : 1)};
    animation: ${(props) =>
        props.flipped ? 'frontToBackFlip' : 'backToFrontFlip'}
      linear 1s;
  }

  @keyframes frontToBackFlip {
    0% {
      opacity: 1;
      transform: rotateY(0deg);
    }
    100% {
      opacity: 0;
      transform: rotateY(180deg);
    }
  }
  @keyframes backToFrontFlip {
    0% {
      opacity: 0;
      transform: rotateY(-180deg);
    }
    100% {
      opacity: 1;
      transform: rotateY(0deg);
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 350px;

  margin-top: 20px;

  max-width: 90vw;
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  max-width: 1700px;
`;

export const AnsweredCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;

  img {
    margin: 10px 20px 5px;
  }

  textarea {
    resize: none;
    width: 300px;
    outline: none;
    border: 1px solid;
  }

  h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 20px;
    margin: 0;
    max-width: 300px;
    height: 100px;
    text-align: center;
    border: 1px solid;
  }
`;
