import React, { useState } from 'react';

import { isMobile } from 'react-device-detect';

/** Imagens */
import { Bocas, Olhos } from '../../assets';

import {
  Container,
  Tab,
  Row,
  TabPanel,
  BackgroundCircle,
  MobileContainer,
  MobileTabsCol,
  MobileTab,
  MobileTabContainer,
} from './styles';

export default function MountTheEmotionTabs({ dragUrl, mobileTouchHandler }) {
  const [selectedTab, setSelectedTab] = useState(-1);

  return (
    <>
      {!isMobile && (
        <Container>
          <Row>
            <Tab
              isSelected={selectedTab <= 0}
              onClick={() => setSelectedTab(0)}
            >
              <BackgroundCircle isSelected={selectedTab <= 0}>
                <img src={Bocas[0]} alt={Bocas[0]} />
              </BackgroundCircle>
            </Tab>
            <Tab
              isSelected={selectedTab === 1}
              onClick={() => setSelectedTab(1)}
            >
              <BackgroundCircle isSelected={selectedTab === 1}>
                <img src={Olhos[0]} alt={Olhos[0]} />
              </BackgroundCircle>
            </Tab>
          </Row>
          <TabPanel selectedTab={selectedTab}>
            {Olhos &&
              selectedTab === 1 &&
              Olhos.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                />
              ))}

            {Bocas &&
              selectedTab <= 0 &&
              Bocas.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                />
              ))}
          </TabPanel>
        </Container>
      )}

      {isMobile && (
        <MobileContainer>
          <MobileTabContainer isOpen={selectedTab !== -1}>
            {Olhos &&
              (selectedTab === -1 || selectedTab === 1) &&
              Olhos.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchEnd={(e) => mobileTouchHandler(e)}
                />
              ))}

            {Bocas &&
              (selectedTab === -1 || selectedTab === 0) &&
              Bocas.map((item) => (
                <img
                  alt={item}
                  src={item}
                  draggable="true"
                  onDragStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchStart={(e) => {
                    dragUrl.current = e.target.src;
                  }}
                  onTouchEnd={(e) => mobileTouchHandler(e)}
                />
              ))}
          </MobileTabContainer>
          <MobileTabsCol>
            {(selectedTab === -1 || selectedTab === 0) && (
              <MobileTab
                isSelected={selectedTab === 0}
                onClick={() => setSelectedTab(selectedTab === 0 ? -1 : 0)}
              >
                <img src={Bocas[0]} alt={Bocas[0]} />
              </MobileTab>
            )}
            {(selectedTab === -1 || selectedTab === 1) && (
              <MobileTab
                isSelected={selectedTab === 1}
                onClick={() => setSelectedTab(selectedTab === 1 ? -1 : 1)}
              >
                <img src={Olhos[0]} alt={Olhos[0]} />
              </MobileTab>
            )}
          </MobileTabsCol>
        </MobileContainer>
      )}
    </>
  );
}
