import React, { useEffect, useState, useRef } from 'react';

import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  DataURL,
} from '@utils';

import { Container, CentralContainer, Row } from './styles';

import Board from '@components/SelfEvaluation/Board';
import { SelfEvaluationBgs } from '@assets';

import {
  ModalAddOps,
  ModalAlert,
  PrimaryButton,
  ModalConfirm,
} from '@components';

import domtoimage from 'dom-to-image';

export default function SelfEvaluation(props) {
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  const [board1, setBoard1] = useState(5);
  const [board2, setBoard2] = useState(5);
  const [board3, setBoard3] = useState(5);
  const [board4, setBoard4] = useState(5);
  const [board5, setBoard5] = useState(5);
  const [board6, setBoard6] = useState(5);
  const [board7, setBoard7] = useState(5);
  const [board8, setBoard8] = useState(5);

  const [print, setPrint] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);

  const [yourID, setYourID] = useState();

  const socketRef = useRef();

  function receivedMessage(message) {
    setBoard1(message.body.board1);
    setBoard2(message.body.board2);
    setBoard3(message.body.board3);
    setBoard4(message.body.board4);
    setBoard5(message.body.board5);
    setBoard6(message.body.board6);
    setBoard7(message.body.board7);
    setBoard8(message.body.board8);
  }

  function setEmitSocket(index, val) {
    const messageObject = {
      body: {
        board1: index === 1 ? val : board1,
        board2: index === 2 ? val : board2,
        board3: index === 3 ? val : board3,
        board4: index === 4 ? val : board4,
        board5: index === 5 ? val : board5,
        board6: index === 6 ? val : board6,
        board7: index === 7 ? val : board7,
        board8: index === 8 ? val : board8,
      },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  const generatePdf = () => {
    setPrint(true);
    domtoimage
      .toPng(document.getElementById('divIdToPrint'))
      .then(function (dataUrl) {
        const nome_paciente = localStorage
          .getItem('@nome_paciente')
          .replaceAll(' ', '_');
        const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
        downloadURI(
          dataUrl,
          filename,
          sendArquivo,
          os,
          token,
          nome_recurso_cod
        );

        setPrint(false);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  };

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);
    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, tokenSocketIo);
    }
  }, [nome_recurso_cod, tokenSocketIo, typeUser]);

  const setBoard = (index, val) => {
    switch (index) {
      case 1:
        setBoard1(val);
        break;
      case 2:
        setBoard2(val);
        break;
      case 3:
        setBoard3(val);
        break;
      case 4:
        setBoard4(val);
        break;
      case 5:
        setBoard5(val);
        break;
      case 6:
        setBoard6(val);
        break;
      case 7:
        setBoard7(val);
        break;
      case 8:
        setBoard8(val);
        break;

      default:
        return;
    }
    setEmitSocket(index, val);
  };

  return (
    <Container id="divIdToPrint">
      <CentralContainer>
        <strong>Autoavaliação</strong>
        <h1>O que estou fazendo bem?</h1>
        {typeUser === 'profissional' && !print && (
          <PrimaryButton
            style={{ marginTop: 40 }}
            onClick={() => setIsOpenAlert(true)}
          >
            Instruções
          </PrimaryButton>
        )}
        <Row>
          <Board
            index={1}
            question="Estou ajudando as pessoas?"
            bgimage={SelfEvaluationBgs[0]}
            semaphoreStatus={board1}
            setSemaphoreStatus={setBoard}
          />
          <Board
            index={2}
            question="Estou cuidando das minhas coisas?"
            bgimage={SelfEvaluationBgs[1]}
            semaphoreStatus={board2}
            setSemaphoreStatus={setBoard}
          />
        </Row>
        <Row>
          <Board
            index={3}
            question="Estou sendo um bom amigo?"
            bgimage={SelfEvaluationBgs[2]}
            semaphoreStatus={board3}
            setSemaphoreStatus={setBoard}
          />
          <Board
            index={4}
            question="Estou organizando meu quarto?"
            bgimage={SelfEvaluationBgs[3]}
            semaphoreStatus={board4}
            setSemaphoreStatus={setBoard}
          />
        </Row>
        <Row>
          <Board
            index={5}
            question="Peço ajuda quando necessário?"
            bgimage={SelfEvaluationBgs[4]}
            semaphoreStatus={board5}
            setSemaphoreStatus={setBoard}
          />
          <Board
            index={6}
            question="Estou pensando antes de agir?"
            bgimage={SelfEvaluationBgs[5]}
            semaphoreStatus={board6}
            setSemaphoreStatus={setBoard}
          />
        </Row>
        <Row>
          <Board
            index={7}
            question="Estou usando bem as palavras?"
            bgimage={SelfEvaluationBgs[6]}
            semaphoreStatus={board7}
            setSemaphoreStatus={setBoard}
          />
          <Board
            index={8}
            question="Estou tratando as pessoas bem?"
            bgimage={SelfEvaluationBgs[7]}
            semaphoreStatus={board8}
            setSemaphoreStatus={setBoard}
          />
        </Row>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '300px',
            justifyContent: 'space-between',
            marginTop: 50,
          }}
        >
          {typeUser === 'profissional' && !print && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <PrimaryButton onClick={() => setIsOpen(true)}>
                Salvar
              </PrimaryButton>
            </div>
          )}

          {typeUser === 'profissional' && !print && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <PrimaryButton onClick={() => setModalCloseIsOpen(true)}>
                Fechar
              </PrimaryButton>
            </div>
          )}
        </div>
      </CentralContainer>
      <ModalAddOps
        generatePdf={generatePdf}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        token={token}
      />
      <ModalAlert
        modalIsOpen={modalIsOpenAlert}
        setIsOpen={setIsOpenAlert}
        token={token}
        props={props}
        title={'Autoavaliação'}
        recurso={nome_recurso_cod}
        bt01Txt={'Fechar'}
      />
      <ModalConfirm
        title="Fechar o jogo"
        description="Deseja realmente fechar o jogo?"
        modalIsOpen={modalCloseIsOpen}
        setIsOpen={setModalCloseIsOpen}
        confirmButtonText="Fechar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCloseIsOpen(false)}
        onConfirm={() =>
          handleBackHome(
            modalClose,
            nome_recurso_cod,
            typeUser,
            props,
            os,
            token,
            ambiente
          )
        }
      />
    </Container>
  );
}
