import React, { useState, useEffect, useRef } from 'react';

//Utilities import
import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  PacientName,
  DataURL,
} from '@utils';

//Styles Import
import { Container, SaveArea, BoardBox } from './styles';

//Images import
import {
  ChessBoard,
  ChessGraveyard,
  BlueChessPawn,
  BlueChessRook,
  BlueChessKnight,
  BlueChessBishop,
  BlueChessQueen,
  BlueChessKing,
  YellowChessPawn,
  YellowChessRook,
  YellowChessKnight,
  YellowChessBishop,
  YellowChessQueen,
  YellowChessKing,
} from '@assets';

//Drag'n Drop imports
import { Stage, Layer, Text } from 'react-konva';
import MountUrlImage from '@components/MountUrlImage';

//Components import
import {
  ModalAddOps,
  ModalAlert,
  SecondaryButton,
  TertiaryButton,
  ModalConfirm,
} from '@components';

const Chess = (props) => {
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  //Socket variables
  const [yourID, setYourID] = useState();
  const socketRef = useRef();

  //Modal variables
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCleanIsOpen, setModalCleanIsOpen] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);

  //Drag'n Drop variables
  const innerWidthSize = window.innerWidth > 1000 ? 1000 : window.innerWidth;
  const proportionalStageHeight = (innerWidthSize * 753) / 1000;
  const scaleX = innerWidthSize / 1000;
  const scaleY = proportionalStageHeight / 753;
  const [lastKnownPosition, setLastKnownPosition] = useState({
    x: 0,
    y: 900,
    src: null,
    width: 70,
    height: 70,
    offsetX: 35,
    offsetY: 35,
    drag: false,
  });
  const stageRef = React.useRef();
  const [selectedId, selectShape] = useState(null);
  const standartBoardState = [
    {
      x: 235,
      y: 235,
      src: ChessBoard,
      width: 750,
      height: 750,
      drag: false,
      preventDefault: false,
    },
    {
      x: 835,
      y: 195,
      src: ChessGraveyard,
      drag: false,
      width: 230,
      height: 745,
      preventDefault: false,
    },
    {
      x: 84,
      y: 85,
      src: BlueChessRook,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },
    {
      x: 167,
      y: 85,
      src: BlueChessKnight,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },
    {
      x: 250,
      y: 85,
      src: BlueChessBishop,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },
    {
      x: 333,
      y: 85,
      src: BlueChessKing,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },

    {
      x: 416,
      y: 85,
      src: BlueChessQueen,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },
    {
      x: 499,
      y: 85,
      src: BlueChessBishop,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },
    {
      x: 582,
      y: 85,
      src: BlueChessKnight,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },
    {
      x: 665,
      y: 85,
      src: BlueChessRook,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },
    {
      x: 84,
      y: 167,
      src: BlueChessPawn,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },
    {
      x: 167,
      y: 167,
      src: BlueChessPawn,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },
    {
      x: 250,
      y: 167,
      src: BlueChessPawn,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },
    {
      x: 333,
      y: 167,
      src: BlueChessPawn,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },

    {
      x: 416,
      y: 167,
      src: BlueChessPawn,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },
    {
      x: 499,
      y: 167,
      src: BlueChessPawn,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },
    {
      x: 582,
      y: 167,
      src: BlueChessPawn,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },
    {
      x: 665,
      y: 167,
      src: BlueChessPawn,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },
    {
      x: 84,
      y: 583,
      src: YellowChessPawn,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },
    {
      x: 167,
      y: 583,
      src: YellowChessPawn,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },

    {
      x: 250,
      y: 583,
      src: YellowChessPawn,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },
    {
      x: 333,
      y: 583,
      src: YellowChessPawn,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },
    {
      x: 416,
      y: 583,
      src: YellowChessPawn,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },
    {
      x: 499,
      y: 583,
      src: YellowChessPawn,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },
    {
      x: 582,
      y: 583,
      src: YellowChessPawn,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },
    {
      x: 665,
      y: 583,
      src: YellowChessPawn,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },
    {
      x: 84,
      y: 667,
      src: YellowChessRook,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },
    {
      x: 167,
      y: 667,
      src: YellowChessKnight,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },

    {
      x: 250,
      y: 667,
      src: YellowChessBishop,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },
    {
      x: 333,
      y: 667,
      src: YellowChessQueen,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },
    {
      x: 416,
      y: 667,
      src: YellowChessKing,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },
    {
      x: 499,
      y: 667,
      src: YellowChessBishop,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },
    {
      x: 582,
      y: 667,
      src: YellowChessKnight,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },
    {
      x: 665,
      y: 667,
      src: YellowChessRook,
      drag: true,
      rotation: typeUser === 'profissional' ? 0 : 180,
    },
  ];
  const [images, setImages] = useState(standartBoardState);

  //*************************************************************************************************************** */
  //Functions

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };
  const salvarFim = () => {
    setIsOpen(true);
  };

  const handleExport = () => {
    selectShape(null);
    setTimeout(() => {
      const uri = stageRef.current.toDataURL();
      const nome_paciente = localStorage
        .getItem('@nome_paciente')
        .replaceAll(' ', '_');
      const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
      downloadURI(uri, filename, sendArquivo, os, token, nome_recurso_cod);
    }, 500);
  };

  const handleClean = () => {
    setImages(standartBoardState);
    selectShape(-1); //tst
    setLastKnownPosition({
      x: 0,
      y: 900,
      src: null,
      width: 70,
      height: 70,
      drag: false,
    });
    sendImage(standartBoardState, lastKnownPosition, selectedId);

    setModalCleanIsOpen(false);
  };

  function sendImage(imagesData, lastKnownP, selectedId) {
    const messageObject = {
      body: {
        imagesData: imagesData,
        lastKnownPosition: lastKnownP,
        selectedId: selectedId,
      },
      id: yourID,
      typeUser: typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  function receivedMessage(message) {
    selectShape(message.body.selectedId);
    setImages(
      message.body.imagesData.map((img, i) => {
        if (i > 1) {
          let aux = img;
          if (typeUser === 'profissional') {
            aux.x = img.x <= -30 ? 874 : img.x;
            aux.rotation = 0;
          } else {
            aux.x = img.x >= 790 ? -100 : img.x;
            aux.rotation = 180;
          }
          return aux;
        } else {
          return img;
        }
      })
    );
    let LNP = message.body.lastKnownPosition;
    setLastKnownPosition({
      x: LNP.x,
      y: LNP.y,
      width: LNP.width,
      height: LNP.height,
      offsetX: LNP.offsetX,
      offsetY: LNP.offsetY,
      drag: LNP.drag,
    });
  }

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);
    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, tokenSocketIo);
    }
  }, []);

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <SaveArea>
          <TertiaryButton onClick={() => setModalCleanIsOpen(true)}>
            Limpar
          </TertiaryButton>
          {typeUser === 'profissional' && (
            <SecondaryButton onClick={() => setIsOpenAlert(true)}>
              Instruções
            </SecondaryButton>
          )}
          {typeUser === 'profissional' && (
            <SecondaryButton onClick={salvarFim}>Salvar</SecondaryButton>
          )}
          {typeUser === 'profissional' && (
            <SecondaryButton onClick={() => setModalCloseIsOpen(true)}>
              Fechar
            </SecondaryButton>
          )}
        </SaveArea>

        <BoardBox
          onDragOver={(e) => {
            e.preventDefault();
          }}
        >
          <Stage
            width={innerWidthSize}
            height={proportionalStageHeight}
            scaleX={scaleX}
            scaleY={scaleY}
            onMouseDown={checkDeselect}
            onTouchStart={checkDeselect}
            ref={stageRef}
          >
            <Layer>
              {images.map((image, i) => {
                return (
                  i === 1 && (
                    <MountUrlImage
                      image={image}
                      key={i}
                      shapeProps={image}
                      onSelect={() => {
                        if (image.drag) selectShape(image.alt);
                      }}
                      onChange={(newAttrs) => {
                        const aux = images.slice();
                        aux[i] = newAttrs;
                        setImages(aux);

                        sendImage(
                          aux,
                          {
                            x: image.x,
                            y: image.y,
                            src: null,
                            width: 70,
                            offsetX: 35,
                            offsetY: 35,
                            height: 70,
                            drag: false,
                          },
                          i
                        );
                      }}
                      resizable={false}
                      rotatable={false}
                    />
                  )
                );
              })}
            </Layer>
            <Layer
              rotation={typeUser === 'profissional' ? 0 : 180}
              offsetX={typeUser === 'profissional' ? 0 : 750}
              offsetY={typeUser === 'profissional' ? 0 : 750}
            >
              {images.map((image, i) => {
                return (
                  i !== 1 && (
                    <MountUrlImage
                      image={image}
                      key={i}
                      shapeProps={image}
                      onSelect={() => {
                        if (image.drag) selectShape(image.alt);
                      }}
                      isSelected={i === selectedId}
                      onChange={(newAttrs) => {
                        setLastKnownPosition({
                          x: image.x,
                          y: image.y,
                          src: null,
                          width: 70,
                          offsetX: 35,
                          offsetY: 35,
                          height: 70,
                          drag: false,
                        });
                        if (image.drag) {
                          selectShape(i);
                        }
                        const aux = images.slice();
                        aux[i] = newAttrs;
                        console.log(newAttrs);
                        setImages(aux);
                        sendImage(
                          aux,
                          {
                            x: image.x,
                            y: image.y,
                            src: null,
                            width: 70,
                            offsetX: 35,
                            offsetY: 35,
                            height: 70,
                            drag: false,
                          },
                          i
                        );
                      }}
                      resizable={false}
                      rotatable={true}
                    />
                  )
                );
              })}
              <MountUrlImage
                image={lastKnownPosition}
                key={99}
                shapeProps={lastKnownPosition}
                isSelected={true}
                onSelect={() => {}}
                onChange={() => {}}
                resizable={false}
                rotatable={false}
              />
              {typeUser === 'profissional' && (
                <Text x={45} y={15} text={PacientName()} fontSize={24} />
              )}
            </Layer>
          </Stage>
        </BoardBox>
      </div>
      <ModalAddOps
        generatePdf={handleExport}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        token={token}
      />
      <ModalAlert
        modalIsOpen={modalIsOpenAlert}
        setIsOpen={setIsOpenAlert}
        token={token}
        props={props}
        title={'Xadrez'}
        recurso={nome_recurso_cod}
        bt01Txt={'Fechar'}
      />
      <ModalConfirm
        title="Limpar o jogo"
        description="Deseja realmente limpar o tabuleiro?"
        modalIsOpen={modalCleanIsOpen}
        setIsOpen={setModalCleanIsOpen}
        confirmButtonText="Limpar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCleanIsOpen(false)}
        onConfirm={() => handleClean()}
      />
      <ModalConfirm
        title="Fechar o jogo"
        description="Deseja realmente fechar o jogo?"
        modalIsOpen={modalCloseIsOpen}
        setIsOpen={setModalCloseIsOpen}
        confirmButtonText="Fechar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCloseIsOpen(false)}
        onConfirm={() =>
          handleBackHome(
            modalClose,
            nome_recurso_cod,
            typeUser,
            props,
            os,
            token,
            ambiente
          )
        }
      />
    </Container>
  );
};

export default Chess;
