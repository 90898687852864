/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

// Images import
import { ProblemSizeImages } from '@assets';

import {
  Container,
  BoxNumber,
  BoxContainer,
  BoxViewGrey,
  BoxViewColor,
  NumberButton,
  NumberWrapper,
  NumberContainer,
} from './styles';

import { Row, Col } from 'react-flexbox-grid';

import {
  ProblemSizeField,
  PrimaryButton,
  TertiaryButton,
} from '../../../components';

const Page03 = ({
  page,
  setPage,
  sizeReact,
  setSizeReact,
  emotionalSize,
  setEmotionalSize,
  justifyDescription,
  setJustifyDescription,
  alternativeProblemResolve,
  setAlternativeProblemResolve,
  setEmitSocket,
  modalClose,
  typeUser,
  bar,
  sizeProblem,
  setIsOpen,
}) => {
  const [print, setPrint] = useState(false);

  return (
    <>
      <Container className={'center-items'}>
        <Row
          bottom="xs"
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            margintop: '15px',
          }}
        >
          <Col xs={3} sm={3} md={3} lg={3}>
            <BoxContainer>
              <BoxViewGrey>
                <img alt={'6'} width={'100%'} src={ProblemSizeImages[6]} />
              </BoxViewGrey>

              <BoxViewColor clip={emotionalSize <= 4 ? 0 : 100}>
                <img alt={'3'} width={'100%'} src={ProblemSizeImages[3]} />
              </BoxViewColor>
            </BoxContainer>
          </Col>

          <Col xs={3} sm={3} md={3} lg={3} style={{ marginLeft: 30 }}>
            <BoxContainer>
              <BoxViewGrey>
                <img alt={'7'} width={'80%'} src={ProblemSizeImages[7]} />
              </BoxViewGrey>
              <BoxViewColor
                clip={emotionalSize > 4 && emotionalSize <= 7 ? 0 : 100}
                width={80}
              >
                <img alt={'4'} width={'80%'} src={ProblemSizeImages[4]} />
              </BoxViewColor>
            </BoxContainer>
          </Col>

          <Col xs={3} sm={3} md={3} lg={3} style={{ marginLeft: -25 }}>
            <BoxContainer>
              <BoxViewGrey>
                <img alt={'8'} width={'83%'} src={ProblemSizeImages[8]} />
              </BoxViewGrey>
              <BoxViewColor clip={emotionalSize > 7 ? 0 : 100} width={83}>
                <img alt={'5'} width={'83%'} src={ProblemSizeImages[5]} />
              </BoxViewColor>
            </BoxContainer>
          </Col>
        </Row>

        <NumberWrapper>
          <BoxNumber>
            <p style={{ fontWeight: '700' }}>Quão grande é o sua reação?</p>
          </BoxNumber>

          <NumberContainer>
            <NumberButton
              color="#85ecff"
              onClick={() => {
                setEmotionalSize(1);
              }}
              isSelected={emotionalSize === 1}
            >
              <h1>1</h1>
            </NumberButton>
            <NumberButton
              color="#01afd2"
              onClick={() => {
                setEmotionalSize(2);
              }}
              isSelected={emotionalSize === 2}
            >
              <h1>2</h1>
            </NumberButton>
            <NumberButton
              color="#83f5a8"
              onClick={() => {
                setEmotionalSize(3);
              }}
              isSelected={emotionalSize === 3}
            >
              <h1>3</h1>
            </NumberButton>
            <NumberButton
              color="#24d560"
              onClick={() => {
                setEmotionalSize(4);
              }}
              isSelected={emotionalSize === 4}
            >
              <h1>4</h1>
            </NumberButton>
            <NumberButton
              color="#ffe46f"
              onClick={() => {
                setEmotionalSize(5);
              }}
              isSelected={emotionalSize === 5}
            >
              <h1>5</h1>
            </NumberButton>
            <NumberButton
              color="#fec55a"
              onClick={() => {
                setEmotionalSize(6);
              }}
              isSelected={emotionalSize === 6}
            >
              <h1>6</h1>
            </NumberButton>
            <NumberButton
              color="#feab5b"
              onClick={() => {
                setEmotionalSize(7);
              }}
              isSelected={emotionalSize === 7}
            >
              <h1>7</h1>
            </NumberButton>
            <NumberButton
              color="#ff8371"
              onClick={() => {
                setEmotionalSize(8);
              }}
              isSelected={emotionalSize === 8}
            >
              <h1>8</h1>
            </NumberButton>
            <NumberButton
              color="#f22a0c"
              onClick={() => {
                setEmotionalSize(9);
              }}
              isSelected={emotionalSize === 9}
            >
              <h1>9</h1>
            </NumberButton>
            <NumberButton
              color="#a31800"
              onClick={() => {
                setEmotionalSize(10);
              }}
              isSelected={emotionalSize === 10}
            >
              <h1>10</h1>
            </NumberButton>
          </NumberContainer>
        </NumberWrapper>

        <Row className="page-break">
          <BoxNumber>
            <Row>
              <p style={{ fontWeight: '700' }}>
                O tamanho do problema era igual ao tamanho da emoção?
              </p>
            </Row>
            <Row>
              <Col>
                <p style={{ display: 'flex', alignItems: 'center' }}>
                  Sim
                  <input
                    style={{ maxWidth: 20, marginLeft: 10 }}
                    type="checkbox"
                    checked={sizeReact}
                    onChange={() => setSizeReact(!sizeReact)}
                  />
                </p>
              </Col>
              <Col>
                <p style={{ display: 'flex', alignItems: 'center' }}>
                  Não
                  <input
                    style={{ maxWidth: 20, marginLeft: 10 }}
                    type="checkbox"
                    checked={!sizeReact}
                    onChange={() => setSizeReact(!sizeReact)}
                  />
                </p>
              </Col>
            </Row>
          </BoxNumber>
        </Row>

        <ProblemSizeField
          title="Justifique:"
          rotation="none"
          titleBlock
          size="lg"
          data={justifyDescription}
          setData={setJustifyDescription}
          setEmitSocket={setEmitSocket}
          bar={bar}
          page={page}
          sizeProblem={sizeProblem}
          sizeReact={sizeReact}
          emotionalSize={emotionalSize}
        />

        <ProblemSizeField
          title="Haveria outra forma de contornar-me frente ao problema? Se sim, qual?"
          rotation="none"
          titleBlock
          size="lg"
          data={alternativeProblemResolve}
          setData={setAlternativeProblemResolve}
          setEmitSocket={setEmitSocket}
          bar={bar}
          page={page}
          sizeProblem={sizeProblem}
          sizeReact={sizeReact}
          emotionalSize={emotionalSize}
        />

        {typeUser === 'profissional' && (
          <div
            style={{
              width: '100%',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'row',
              marginTop: 25,
            }}
          >
            {page > 0 && (
              <TertiaryButton onClick={() => setPage(2)} problemSize>
                {' '}
                Voltar
              </TertiaryButton>
            )}

            {!print && typeUser === 'profissional' && (
              <TertiaryButton
                onClick={() => {
                  setPage(-1);
                  setPrint(!print);
                }}
                problemSize
              >
                Finalizar
              </TertiaryButton>
            )}
          </div>
        )}
        {print && typeUser === 'profissional' && (
          <TertiaryButton
            onClick={() => {
              setPage(-1);
              setIsOpen(true);
            }}
            style={{ alignSelf: 'center' }}
            problemSize
          >
            Salvar
          </TertiaryButton>
        )}
        <div
          style={{
            width: '100%',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'row',
            marginTop: 25,
          }}
        >
          {print && typeUser === 'profissional' && (
            <PrimaryButton onClick={() => modalClose()} problemSize>
              Fechar
            </PrimaryButton>
          )}
          {page > 0 && typeUser === 'profissional' && (
            <PrimaryButton onClick={() => modalClose()} problemSize>
              Fechar
            </PrimaryButton>
          )}
        </div>
      </Container>
    </>
  );
};

export default Page03;
