import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100px;
    cursor: pointer;
  }
`;
export const Light = styled.div``;
