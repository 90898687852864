import styled from 'styled-components';

import { LeftDialog, RightDialog, Magnifier } from '../../assets';

import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;

  padding-bottom: 150px;

  background-color: #fff;

  width: 100%;
  min-height: 100vh;

  background: white;

  overflow-x: hidden;

  h3 {
    font-size: 50px;
    font-family: 'Roboto', serif;
    margin-top: 80px;
  }
  h4 {
    text-align: center;
    font-family: 'Rochester', cursive;
    font-size: 30px;
    margin-top: -30px;
    margin-bottom: 80px;

    @media (max-width: 786px) {
      margin-bottom: 0;
    }
  }

  @media (max-width: 786px) {
    padding-bottom: 30px;
  }
`;

export const InstructionsContainer = styled.div`
  display: flex;

  width: 60%;

  padding: 20px 0;

  flex-direction: column;

  align-items: flex-start;

  strong {
    font-size: 24px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    margin: 2px;
    margin-top: 10px;
  }
  h1 {
    margin: 2px;
    margin-top: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 500;
  }
  margin-bottom: 10px;
`;

export const Logo = styled.img`
  height: 200px;
  margin-bottom: 30px;

  @media (max-width: 786px) {
    height: 80px;
    margin-top: 10px;
  }
`;

export const LeftDialogTextArea = styled.textarea`
  text-decoration: none;
  overflow: hidden;
  resize: none;
  border: none;
  box-sizing: border-box;
  outline: 0;

  height: 500px;
  width: 600px;

  padding: 40px 120px 100px 75px;

  font-size: 20px;

  background: url(${LeftDialog});
  background-size: 600px 500px;
  background-repeat: no-repeat;

  @media (max-width: 786px) {
    width: 310px;
    height: 260px;
    padding: 20px 80px 65px 45px;
    background-size: 300px 285px;
  }
`;

export const RightDialogTextArea = styled.textarea`
  text-decoration: none;
  overflow: hidden;
  resize: none;
  border: none;
  box-sizing: border-box;
  outline: 0;

  height: 500px;
  width: 600px;

  padding: 40px 75px 100px 150px;

  font-size: 20px;

  background: url(${RightDialog});
  background-size: 600px 500px;
  background-repeat: no-repeat;

  @media (max-width: 786px) {
    width: 310px;
    height: 280px;
    padding: 20px 55px 52px 70px;
    background-size: 300px 285px;
    max-height: 260px;
  }
`;

export const FlexRowCenter = styled.div`
  display: flex;

  flex-direction: row;

  justify-content: space-between;

  align-items: center;

  max-width: 1000px;

  h2 {
    text-align: center;
    font-family: 'Rochester', cursive;
    font-size: calc(2vw - 1px);
    width: 40%;
    margin-bottom: 120px;
  }

  @media (max-width: 1200px) {
    h2 {
      font-size: calc(3vw);
    }
  }

  @media (max-width: 786px) {
    flex-direction: column;

    h2 {
      font-size: 1.7rem;
      width: 80%;
      margin-bottom: 0;
    }
  }
`;

export const FlexRowStart = styled.div`
  display: flex;

  flex-direction: row;

  justify-content: flex-start;
  width: 1100px;

  @media (max-width: 786px) {
    justify-content: center;
  }
`;

export const ProsecutorImg = styled.img`
  height: 400px;
`;
export const DefenseLawyerImg = styled.img`
  height: 400px;
`;
export const JudgeImg = styled.img`
  height: 400px;
`;

export const MagnifierBackground = styled.div`
  height: 400px;
  width: 400px;

  background-image: url(${Magnifier});
  background-size: 400px 400px;
  background-repeat: no-repeat;

  margin-top: ${(props) =>
    props.rotation === 'left' || props.rotation === 'right' ? '90px' : '0px'};

  @media (max-width: 786px) {
    background-size: 300px 300px;
    margin-left: ${(props) => (props.rotation === 'left' ? '90px' : 0)};
    margin-right: ${(props) => (props.rotation === 'right' ? '90px' : 0)};
    margin-top: 0;
  }

  transform: ${(props) => {
    switch (props.rotation) {
      case 'left':
        return '';
      case 'none':
        return 'rotate(46deg)';
      case 'right':
        return 'rotate(90deg)';
      default:
        return '';
    }
  }};

  textarea {
    text-decoration: none;
    overflow: hidden;
    resize: none;
    border: none;
    box-sizing: border-box;
    outline: 0;
    background: transparent;
    position: relative;

    top: ${(props) => {
      switch (props.rotation) {
        case 'left':
          return '70px';
        case 'none':
          return '55px';
        case 'right':
          return '50px';
        default:
          return '65px';
      }
    }};
    left: ${(props) => {
      switch (props.rotation) {
        case 'left':
          return '60px';
        case 'none':
          return '65px';
        case 'right':
          return '70px';
        default:
          return '60px';
      }
    }};

    transform: ${(props) => {
      switch (props.rotation) {
        case 'left':
          return 'rotate(0deg)';
        case 'none':
          return 'rotate(-45deg)';
        case 'right':
          return 'rotate(-90deg)';
        default:
          return 'rotate(0deg)';
      }
    }};
    font-size: 16px;

    height: 180px;
    width: 180px;

    @media (max-width: 786px) {
      top: ${(props) => {
        switch (props.rotation) {
          case 'left':
            return '38px';
          case 'none':
            return '41px';
          case 'right':
            return '41px';
          default:
            return '65px';
        }
      }};
      left: ${(props) => {
        switch (props.rotation) {
          case 'left':
            return '42px';
          case 'none':
            return '44px';
          case 'right':
            return '41px';
          default:
            return '60px';
        }
      }};

      height: 130px;
      width: 130px;
    }
  }
`;

export const MagnifiersContainer = styled.div`
  display: flex;
  flex-direction: row;

  height: auto;
  width: 90%;

  justify-content: center;

  @media (max-width: 1363px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: auto;
  }
`;

export const AcusationWrapper = styled.div`
  display: flex;

  h2 {
    margin-top: 110px;
  }

  @media (max-width: 786px) {
    flex-direction: column-reverse;
    align-items: center;

    h2 {
      margin-top: 0;
      margin-bottom: 40px;
    }
  }
`;
