import styled from 'styled-components';

export const Container = styled.div`
  width: 180px;
  display: flex;
  flex-direction: column;

  margin-left: -100px;
  margin-right: 10px;
  margin-top: 60px;

  @media (max-width: 1200px) {
    margin-left: 0;
  }
`;

export const Tab = styled.div`
  height: 70px;
  width: 60px;
  background: white;
  border: 1px solid #2797ba;
  border-bottom: none;
  margin-bottom: -1px;

  display: flex;
  justify-content: center;

  border-radius: 50px 50px 0px 0px;

  img {
    width: 40px;
  }

  z-index: 999;
`;

export const TabPanel = styled.div`
  height: 500px;
  width: 180px;
  border: 1px solid #2797ba;
  border-radius: 0px 10px 10px 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: scroll;

  padding-top: 15px;

  img {
    width: 100px;
    cursor: pointer;
    user-select: none;
    margin-top: 10px;
  }
`;

export const BackgroundCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 60px;
  width: 60px;

  background: #ddf2f8;

  border-radius: 50%;
`;
