import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  *{
    outline:0;
  }

  body{
    margin:0;
    padding:0;
    box-sizing:border-box;
    -webkit-font-smoothing:antialiased;
  }
`;
