import React, { useState } from 'react';

import { isMobile } from 'react-device-detect';

import {
  Container,
  Tab,
  TabPanel,
  BackgroundCircle,
  MobileContainer,
  MobileTab,
  MobileTabContainer,
} from './styles';

export default function DominoTabs({
  dragUrl,
  dominos,
  usedIndexes,
  setUsedIndexes,
  mobileTouchHandler,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const deleteImage = (index) => {
    let indexArr = usedIndexes;
    indexArr.push(index);
    setUsedIndexes(indexArr);
  };
  return (
    <>
      {!isMobile && (
        <Container>
          <Tab>
            <BackgroundCircle>
              <img src={dominos[0]} alt={dominos[0]} />
            </BackgroundCircle>
          </Tab>
          <TabPanel>
            {dominos &&
              dominos.map(
                (item, index) =>
                  usedIndexes !== undefined &&
                  !usedIndexes.includes(index) && (
                    <img
                      alt={item}
                      src={item}
                      draggable="true"
                      onDragStart={(e) => {
                        dragUrl.current = e.target.src;
                        deleteImage(index);
                      }}
                      onTouchStart={(e) => {
                        dragUrl.current = e.target.src;
                        deleteImage(index);
                      }}
                      onTouchEnd={(e) => mobileTouchHandler(e)}
                    />
                  )
              )}
          </TabPanel>
        </Container>
      )}
      {isMobile && (
        <MobileContainer>
          <MobileTabContainer isOpen={isOpen}>
            {dominos &&
              dominos.map(
                (item, index) =>
                  usedIndexes !== undefined &&
                  !usedIndexes.includes(index) && (
                    <img
                      alt={item}
                      src={item}
                      draggable="true"
                      onDragStart={(e) => {
                        dragUrl.current = e.target.src;
                        deleteImage(index);
                      }}
                      onTouchStart={(e) => {
                        dragUrl.current = e.target.src;
                        deleteImage(index);
                      }}
                      onTouchEnd={(e) => mobileTouchHandler(e)}
                    />
                  )
              )}
          </MobileTabContainer>
          <MobileTab onClick={() => setIsOpen(!isOpen)}>
            <img src={dominos[0]} alt={dominos[0]} />
          </MobileTab>
        </MobileContainer>
      )}
    </>
  );
}
