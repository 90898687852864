import Areia from './areia/background/areia.jpg';
import Logo from './geral/logo.png';
import LogoFundo from './geral/logo_fundo.png';
import Cacto from './geral/cacto_icon.png';
import CactoGrey from './geral/cacto_grey_icon.png';
import Lapis from './geral/lapis_icon.png';
import LapisGrey from './geral/lapis_grey_icon.png';

import FirstCactus from './cactusflower/firstCactus.png';
import FirstLines from './cactusflower/firstlines.png';
import LogoCacto from './cactusflower/logoCacto.png';

import ProblemSizeLogo from './problemsize/problem_size_logo.png';
import Termometro from './problemsize/termometro.png';
import TermometroColor from './problemsize/termometro_color.png';
import Numbers1a4 from './problemsize/1a4.png';
import Numbers5a7 from './problemsize/5a7.png';
import Numbers8a10 from './problemsize/8a10.png';
import Numbers1a4Grey from './problemsize/1a4_grey.png';
import Numbers5a7Grey from './problemsize/5a7_grey.png';
import Numbers8a10Grey from './problemsize/8a10_grey.png';

import PawImage from './amaform/paw.png';
import MusicNotes from './amaform/notes.png';
import CloverImage from './amaform/clover.png';

import jigsaw from './main/jigsaw.png';
import cactus from './main/cactus.png';
import x_icon from './main/x_icon.png';

//TOC Judgement images
import DefenseLawyer from './tocJudgement/defenseLawyer.png';
import Judge from './tocJudgement/judge.png';
import JudgementLogo from './tocJudgement/judgementLogo.png';
import LeftDialog from './tocJudgement/leftDialog.png';
import RightDialog from './tocJudgement/rightDialog.png';
import Prosecutor from './tocJudgement/prosecutor.png';
import Magnifier from './tocJudgement/magnifier.png';

//Mount the emotion images
import OlhoAnsioso from './mounttheemotion/olho-ansioso.png';
import OlhoAssustado from './mounttheemotion/olho-assustado.png';
import OlhoFeliz from './mounttheemotion/olho-feliz.png';
import OlhoRaiva from './mounttheemotion/olho-raiva.png';
import OlhoTedio from './mounttheemotion/olho-tedio.png';
import OlhoTriste from './mounttheemotion/olho-triste.png';

import BocaAnsioso from './mounttheemotion/boca-ansioso.png';
import BocaFeliz from './mounttheemotion/boca-feliz.png';
import BocaRaiva from './mounttheemotion/boca-raiva.png';
import BocaTedio from './mounttheemotion/boca-tedio.png';
import BocaTriste from './mounttheemotion/boca-triste.png';

import Rosto from './mounttheemotion/rosto.png';
import RostoAnsioso from './mounttheemotion/rosto-ansioso.png';
import RostoFeliz from './mounttheemotion/rosto-feliz.png';
import RostoRaiva from './mounttheemotion/rosto-raiva.png';
import RostoTedio from './mounttheemotion/rosto-tedio.png';
import RostoTriste from './mounttheemotion/rosto-triste.png';

//Images Cause Effect
import CauseEffectTitle from './causeeffect/cause-effect-title.png';
import NegativeCause from './causeeffect/negative-cause.png';
import NegativeEffect from './causeeffect/negative-effect.png';
import PositiveCause from './causeeffect/positive-cause.png';
import PositiveEffect from './causeeffect/positive-effect.png';

//Self Evaluation images
import SemaforoTopo from './selfevaluation/semaforo-normal-topo.png';
import SemaforoMeio from './selfevaluation/semaforo-normal-meio.png';
import SemaforoBaixo from './selfevaluation/semaforo-normal-baixo.png';
import SemaforoTopoColorido from './selfevaluation/semaforo-colorido-topo.png';
import SemaforoMeioColorido from './selfevaluation/semaforo-colorido-meio.png';
import SemaforoBaixoColorido from './selfevaluation/semaforo-colorido-baixo.png';
import OpcoesSemaforo from './selfevaluation/options.png';
import SE1 from './selfevaluation/1.png';
import SE2 from './selfevaluation/2.png';
import SE3 from './selfevaluation/3.png';
import SE4 from './selfevaluation/4.png';
import SE5 from './selfevaluation/5.png';
import SE6 from './selfevaluation/6.png';
import SE7 from './selfevaluation/7.png';
import SE8 from './selfevaluation/8.png';

//Hexagonal Domino Images
import Domino1 from './hexagonaldomino/domino1.png';
import Domino2 from './hexagonaldomino/domino2.png';
import Domino3 from './hexagonaldomino/domino3.png';
import Domino4 from './hexagonaldomino/domino4.png';
import Domino5 from './hexagonaldomino/domino5.png';
import Domino6 from './hexagonaldomino/domino6.png';
import Domino7 from './hexagonaldomino/domino7.png';
import Domino8 from './hexagonaldomino/domino8.png';
import Domino9 from './hexagonaldomino/domino9.png';
import Domino10 from './hexagonaldomino/domino10.png';
import Domino11 from './hexagonaldomino/domino11.png';
import Domino12 from './hexagonaldomino/domino12.png';
import Domino13 from './hexagonaldomino/domino13.png';
import Domino14 from './hexagonaldomino/domino14.png';
import Domino15 from './hexagonaldomino/domino15.png';
import Domino16 from './hexagonaldomino/domino16.png';
import Domino17 from './hexagonaldomino/domino17.png';
import Domino18 from './hexagonaldomino/domino18.png';
import Domino19 from './hexagonaldomino/domino19.png';
import Domino20 from './hexagonaldomino/domino20.png';
import Domino21 from './hexagonaldomino/domino21.png';

//Show The Way images
import ArrowDown from './showtheway/arrowDown.png';
import ArrowDownLeft from './showtheway/arrowDownLeft.png';
import ArrowDownRight from './showtheway/arrowDownRight.png';
import ArrowRight from './showtheway/arrowRight.png';
import ArrowLeft from './showtheway/arrowLeft.png';
import ArrowUp from './showtheway/arrowUp.png';
import ArrowUpLeft from './showtheway/arrowUpLeft.png';
import ArrowUpRight from './showtheway/arrowUpRight.png';
import ArrowLeftDown from './showtheway/arrowLeftDown.png';
import ArrowLeftUp from './showtheway/arrowLeftUp.png';
import ArrowRightDown from './showtheway/arrowRightDown.png';
import ArrowRightUp from './showtheway/arrowRightUp.png';

import ShowTheWayBoard from './showtheway/board.png';
import ShowTheWayBall from './showtheway/bola.png';
import ShowTheWayDog from './showtheway/cachorro.png';
import ShowTheWayLeaf from './showtheway/folha.png';
import ShowTheWayCat from './showtheway/gato.png';
import ShowTheWayBeetle from './showtheway/joaninha.png';
import ShowTheWayBone from './showtheway/osso.png';

import People002 from './areia/people002.svg';
import People003 from './areia/people003.svg';
import People004 from './areia/people004.svg';
import People010 from './areia/people010.svg';
import People012 from './areia/people012.svg';
import People025 from './areia/people025.svg';
import People026 from './areia/people026.svg';
import People027 from './areia/people027.svg';
import People028 from './areia/people028.svg';
import People029 from './areia/people029.svg';
import People030 from './areia/people030.svg';
import People031 from './areia/people031.svg';
import People032 from './areia/people032.svg';
import People033 from './areia/people033.svg';
import People034 from './areia/people034.svg';

import Buildings001 from './areia/buildings001.svg';
import Buildings002 from './areia/buildings002.svg';
import Buildings003 from './areia/buildings003.svg';
import Buildings004 from './areia/buildings004.svg';
import Buildings005 from './areia/buildings005.svg';
import Buildings006 from './areia/buildings006.svg';
import Buildings007 from './areia/buildings007.svg';
import Buildings008 from './areia/buildings008.svg';
import Buildings009 from './areia/buildings009.svg';
import Buildings010 from './areia/buildings010.svg';
import Buildings011 from './areia/buildings011.svg';
import Buildings012 from './areia/buildings012.svg';
import Buildings013 from './areia/buildings013.svg';
import Buildings014 from './areia/buildings014.svg';
import Buildings015 from './areia/buildings015.svg';
import Buildings016 from './areia/buildings016.svg';
import Buildings114 from './areia/buildings114.svg';
import Buildings115 from './areia/buildings115.svg';
import Buildings116 from './areia/buildings116.svg';
import Buildings117 from './areia/buildings117.svg';
import Buildings118 from './areia/buildings118.svg';
import Buildings119 from './areia/buildings119.svg';
import Buildings120 from './areia/buildings120.svg';

import Creatures001 from './areia/creatures001.svg';
import Creatures002 from './areia/creatures002.svg';
import Creatures003 from './areia/creatures003.svg';
import Creatures004 from './areia/creatures004.svg';
import Creatures005 from './areia/creatures005.svg';
import Creatures006 from './areia/creatures006.svg';
import Creatures007 from './areia/creatures007.svg';
import Creatures008 from './areia/creatures008.svg';
import Creatures009 from './areia/creatures009.svg';
import Creatures010 from './areia/creatures010.svg';
import Creatures011 from './areia/creatures011.svg';
import Creatures012 from './areia/creatures012.svg';
import Creatures013 from './areia/creatures013.svg';
import Creatures014 from './areia/creatures014.svg';
import Creatures015 from './areia/creatures015.svg';
import Creatures016 from './areia/creatures016.svg';
import Creatures017 from './areia/creatures017.svg';
import Creatures018 from './areia/creatures018.svg';
import Creatures019 from './areia/creatures019.svg';
import Creatures020 from './areia/creatures020.svg';
import Creatures021 from './areia/creatures021.svg';
import Creatures022 from './areia/creatures022.svg';
import Creatures023 from './areia/creatures023.svg';
import Creatures024 from './areia/creatures024.svg';
import Creatures025 from './areia/creatures025.svg';
import Creatures026 from './areia/creatures026.svg';
import Creatures027 from './areia/creatures027.svg';
import Creatures028 from './areia/creatures028.svg';
import Creatures040 from './areia/creatures040.svg';
import Creatures041 from './areia/creatures041.svg';
import Creatures042 from './areia/creatures042.svg';
import Creatures043 from './areia/creatures043.svg';
import Creatures044 from './areia/creatures044.svg';
import Creatures045 from './areia/creatures045.svg';
import Creatures046 from './areia/creatures046.svg';
import Creatures047 from './areia/creatures047.svg';
import Creatures048 from './areia/creatures048.svg';
import Creatures049 from './areia/creatures049.svg';
import Creatures055 from './areia/creatures055.svg';
import Creatures056 from './areia/creatures056.svg';

import Food001 from './areia/food001.svg';
import Food002 from './areia/food002.svg';
import Food003 from './areia/food003.svg';
import Food004 from './areia/food004.svg';
import Food005 from './areia/food005.svg';
import Food006 from './areia/food006.svg';
import Food007 from './areia/food007.svg';
import Food008 from './areia/food008.svg';
import Food009 from './areia/food009.svg';
import Food010 from './areia/food010.svg';
import Food011 from './areia/food011.svg';
import Food012 from './areia/food012.svg';
import Food013 from './areia/food013.svg';
import Food014 from './areia/food014.svg';
import Food015 from './areia/food015.svg';
import Food016 from './areia/food016.svg';
import Food017 from './areia/food017.svg';
import Food018 from './areia/food018.svg';

import Health001 from './areia/health001.svg';
import Health002 from './areia/health002.svg';
import Health003 from './areia/health003.svg';
import Health004 from './areia/health004.svg';
import Health005 from './areia/health005.svg';
import Health006 from './areia/health006.svg';
import Health007 from './areia/health007.svg';
import Health008 from './areia/health008.svg';
import Health009 from './areia/health009.svg';
import Health010 from './areia/health010.svg';
import Health011 from './areia/health011.svg';
import Health012 from './areia/health012.svg';
import Health013 from './areia/health013.svg';
import Health014 from './areia/health014.svg';
import Health015 from './areia/health015.svg';
import Health016 from './areia/health016.svg';
import Health017 from './areia/health017.svg';
import Health018 from './areia/health018.svg';
import Health019 from './areia/health019.svg';
import Health020 from './areia/health020.svg';
import Health021 from './areia/health021.svg';
import Health022 from './areia/health022.svg';
import Health023 from './areia/health023.svg';
import Health024 from './areia/health024.svg';
import Health025 from './areia/health025.svg';
import Health026 from './areia/health026.svg';
import Health027 from './areia/health027.svg';

import Houses001 from './areia/houses001.svg';
import Houses002 from './areia/houses002.svg';
import Houses003 from './areia/houses003.svg';
import Houses004 from './areia/houses004.svg';

//Parking Images
import Parking1 from './parking/card1.png';
import Parking2 from './parking/card2.png';
import Parking3 from './parking/card3.png';
import Parking4 from './parking/card4.png';
import Parking5 from './parking/card5.png';
import Parking6 from './parking/card6.png';
import Parking7 from './parking/card7.png';
import Parking8 from './parking/card8.png';
import Parking9 from './parking/card9.png';
import Parking10 from './parking/card10.png';
import Parking11 from './parking/card11.png';

import EmptyParking from './parking/emptyParking.png';

import YellowCar from './parking/carroAmarelo.png';
import BlueCar from './parking/carroAzul.png';
import CBlueCar from './parking/carroConversivelAzul.png';
import BlackCar from './parking/carroPreto.png';
import PinkCar from './parking/carroRosa.png';
import TurquoiseCar from './parking/carroTurquesa.png';
import LightGreenCar from './parking/carroVerdeClaro.png';
import DarkGreenCar from './parking/carroVerdeEscuro.png';
import YellowCarR from './parking/carroAmareloR.png';
import BlueCarR from './parking/carroAzulR.png';
import CBlueCarR from './parking/carroConversivelAzulR.png';
import BlackCarR from './parking/carroPretoR.png';
import PinkCarR from './parking/carroRosaR.png';
import TurquoiseCarR from './parking/carroTurquesaR.png';
import LightGreenCarR from './parking/carroVerdeClaroR.png';
import DarkGreenCarR from './parking/carroVerdeEscuroR.png';

//LoveMyself images
import LoveMyselfTitle from './lovemyself/title.png';
import HeartBg from './lovemyself/heartBg.png';
import LoveMyselfTree from './lovemyself/tree.png';
import LoveMyselfMobileTree from './lovemyself/mobileTree.svg';

//Vai Encarar Images
import VaiEncararCard1 from './vaiencarar/card1.png';
import VaiEncararCard2 from './vaiencarar/card2.png';
import VaiEncararCard3 from './vaiencarar/card3.png';
import VaiEncararCard4 from './vaiencarar/card4.png';
import VaiEncararCard5 from './vaiencarar/card5.png';
import VaiEncararCard6 from './vaiencarar/card6.png';
import VaiEncararCard7 from './vaiencarar/card7.png';
import VaiEncararCard8 from './vaiencarar/card8.png';
import VaiEncararCard9 from './vaiencarar/card9.png';
import VaiEncararCard10 from './vaiencarar/card10.png';
import VaiEncararCard11 from './vaiencarar/card11.png';
import VaiEncararCard12 from './vaiencarar/card12.png';
import VaiEncararCard13 from './vaiencarar/card13.png';
import VaiEncararCard14 from './vaiencarar/card14.png';
import VaiEncararCard15 from './vaiencarar/card15.png';
// import VaiEncararCard16 from './vaiencarar/card16.png';
import VaiEncararCard17 from './vaiencarar/card17.png';
import VaiEncararCard18 from './vaiencarar/card18.png';
import VaiEncararCard19 from './vaiencarar/card19.png';
import VaiEncararCard20 from './vaiencarar/card20.png';
import VaiEncararCard21 from './vaiencarar/card21.png';
import VaiEncararCard22 from './vaiencarar/card22.png';
import VaiEncararCard23 from './vaiencarar/card23.png';
import VaiEncararCard24 from './vaiencarar/card24.png';
import VaiEncararCard25 from './vaiencarar/card25.png';
import VaiEncararCard26 from './vaiencarar/card26.png';
import VaiEncararCard27 from './vaiencarar/card27.png';
import VaiEncararCard28 from './vaiencarar/card28.png';
import VaiEncararCard29 from './vaiencarar/card29.png';
import VaiEncararCard30 from './vaiencarar/card30.png';
import VaiEncararBack from './vaiencarar/back.png';
import VaiEncararTitle from './vaiencarar/vai-encarar-title.png';

//Relate the colors images
import RelateCircle1 from './relatethecolors/circle1.png';
import RelateCircle2 from './relatethecolors/circle2.png';
import RelateCircle3 from './relatethecolors/circle3.png';
import RelateCircle4 from './relatethecolors/circle4.png';
import RelateCircle5 from './relatethecolors/circle5.png';
import RelateCircle6 from './relatethecolors/circle6.png';
import RelateCircle7 from './relatethecolors/circle7.png';
import RelateCircle8 from './relatethecolors/circle8.png';
import RelateCircle9 from './relatethecolors/circle9.png';
import RelateCircle10 from './relatethecolors/circle10.png';
import RelateCircle11 from './relatethecolors/circle11.png';
import RelateCircle12 from './relatethecolors/circle12.png';

import RelateColors1 from './relatethecolors/colors1.png';
import RelateColors2 from './relatethecolors/colors2.png';
import RelateColors3 from './relatethecolors/colors3.png';
import RelateColors4 from './relatethecolors/colors4.png';
import RelateColors5 from './relatethecolors/colors5.png';
import RelateColors6 from './relatethecolors/colors6.png';
import RelateColors7 from './relatethecolors/colors7.png';
import RelateColors8 from './relatethecolors/colors8.png';
import RelateColors9 from './relatethecolors/colors9.png';
import RelateColors10 from './relatethecolors/colors10.png';
import RelateColors11 from './relatethecolors/colors11.png';
import RelateColors12 from './relatethecolors/colors12.png';
import RelateColors13 from './relatethecolors/colors13.png';
import RelateColors14 from './relatethecolors/colors14.png';

import RelateColorEasyBoard from './relatethecolors/easyBoard.png';
import RelateColorMediumBoard from './relatethecolors/mediumBoard.png';
import RelateColorHardBoard from './relatethecolors/hardBoard.png';

//Good vs Bad Behavior images
import GoodVsBadBackground from './goodvsbadbehavior/background.png';
import Behavior1 from './goodvsbadbehavior/behavior1.png';
import Behavior2 from './goodvsbadbehavior/behavior2.png';
import Behavior3 from './goodvsbadbehavior/behavior3.png';
import Behavior4 from './goodvsbadbehavior/behavior4.png';
import Behavior5 from './goodvsbadbehavior/behavior5.png';
import Behavior6 from './goodvsbadbehavior/behavior6.png';
import Behavior7 from './goodvsbadbehavior/behavior7.png';
import Behavior8 from './goodvsbadbehavior/behavior8.png';
import Behavior9 from './goodvsbadbehavior/behavior9.png';
import Behavior10 from './goodvsbadbehavior/behavior10.png';
import Behavior11 from './goodvsbadbehavior/behavior11.png';
import Behavior12 from './goodvsbadbehavior/behavior12.png';
import Behavior13 from './goodvsbadbehavior/behavior13.png';
import Behavior14 from './goodvsbadbehavior/behavior14.png';
import Behavior15 from './goodvsbadbehavior/behavior15.png';
import Behavior16 from './goodvsbadbehavior/behavior16.png';
import Behavior17 from './goodvsbadbehavior/behavior17.png';

//Excessive screens
import XSBoy from './excessivescreens/boy.png';
import XSGirl from './excessivescreens/girl.png';
import XSGirl2 from './excessivescreens/girl2.png';
import XSDialog from './excessivescreens/dialog.png';
import XSGreenDialog from './excessivescreens/greenDialog.png';
import XSOrangeDialog from './excessivescreens/orangeDialog.png';
import XSBlueDialog from './excessivescreens/blueDialog.png';
import XSTitle from './excessivescreens/title.png';
import XSHandWithSmartphone from './excessivescreens/handwithsmartphone.png';
import XSLeftHandWithSmartphone from './excessivescreens/lefthandwithsmartphone.png';

//Arrows images
import BlueBall from './arrows/blueball.png';
import YellowBall from './arrows/yellowball.png';
import RedBall from './arrows/redball.png';

import ArrowInstructions from './arrows/instructions.png';

import ArrowAnswers from './arrows/gabarito.png';

import ArrowBoard from './arrows/board.png';

//Link The Planes images
import VeryEasyPlane1 from './linktheplanes/veryEasy1.png';
import VeryEasyPlane2 from './linktheplanes/veryEasy2.png';
import VeryEasyPlane3 from './linktheplanes/veryEasy3.png';
import VeryEasyPlane4 from './linktheplanes/veryEasy4.png';
import VeryEasyPlane5 from './linktheplanes/veryEasy5.png';
import VeryEasyPlane6 from './linktheplanes/veryEasy6.png';
import VeryEasyPlane7 from './linktheplanes/veryEasy7.png';
import VeryEasyPlane8 from './linktheplanes/veryEasy8.png';
import VeryEasyPlane9 from './linktheplanes/veryEasy9.png';
import VeryEasyPlane10 from './linktheplanes/veryEasy10.png';

import EasyPlane1 from './linktheplanes/easy1.png';
import EasyPlane2 from './linktheplanes/easy2.png';
import EasyPlane3 from './linktheplanes/easy3.png';
import EasyPlane4 from './linktheplanes/easy4.png';
import EasyPlane5 from './linktheplanes/easy5.png';
import EasyPlane6 from './linktheplanes/easy6.png';
import EasyPlane7 from './linktheplanes/easy7.png';
import EasyPlane8 from './linktheplanes/easy8.png';
import EasyPlane9 from './linktheplanes/easy9.png';
import EasyPlane10 from './linktheplanes/easy10.png';

import NormalPlane1 from './linktheplanes/normal1.png';
import NormalPlane2 from './linktheplanes/normal2.png';
import NormalPlane3 from './linktheplanes/normal3.png';
import NormalPlane4 from './linktheplanes/normal4.png';
import NormalPlane5 from './linktheplanes/normal5.png';
import NormalPlane6 from './linktheplanes/normal6.png';
import NormalPlane7 from './linktheplanes/normal7.png';
import NormalPlane8 from './linktheplanes/normal8.png';
import NormalPlane9 from './linktheplanes/normal9.png';
import NormalPlane10 from './linktheplanes/normal10.png';

import HardPlane1 from './linktheplanes/hard1.png';
import HardPlane2 from './linktheplanes/hard2.png';
import HardPlane3 from './linktheplanes/hard3.png';
import HardPlane4 from './linktheplanes/hard4.png';
import HardPlane5 from './linktheplanes/hard5.png';
import HardPlane6 from './linktheplanes/hard6.png';
import HardPlane7 from './linktheplanes/hard7.png';
import HardPlane8 from './linktheplanes/hard8.png';
import HardPlane9 from './linktheplanes/hard9.png';
import HardPlane10 from './linktheplanes/hard10.png';

import VeryHardPlane1 from './linktheplanes/veryHard1.png';
import VeryHardPlane2 from './linktheplanes/veryHard2.png';
import VeryHardPlane3 from './linktheplanes/veryHard3.png';
import VeryHardPlane4 from './linktheplanes/veryHard4.png';
import VeryHardPlane5 from './linktheplanes/veryHard5.png';
import VeryHardPlane6 from './linktheplanes/veryHard6.png';
import VeryHardPlane7 from './linktheplanes/veryHard7.png';
import VeryHardPlane8 from './linktheplanes/veryHard8.png';
import VeryHardPlane9 from './linktheplanes/veryHard9.png';
import VeryHardPlane10 from './linktheplanes/veryHard10.png';

import BlueStraight1 from './linktheplanes/blue/blue1.png';
import BlueStraight2 from './linktheplanes/blue/blue2.png';
import BlueStraight3 from './linktheplanes/blue/blue3.png';
import BlueStraight4 from './linktheplanes/blue/blue4.png';
import BlueStraight5 from './linktheplanes/blue/blue5.png';
import BlueStraight6 from './linktheplanes/blue/blue6.png';

import GreenStraight1 from './linktheplanes/green/green1.png';
import GreenStraight2 from './linktheplanes/green/green2.png';
import GreenStraight3 from './linktheplanes/green/green3.png';
import GreenStraight4 from './linktheplanes/green/green4.png';
import GreenStraight5 from './linktheplanes/green/green5.png';
import GreenStraight6 from './linktheplanes/green/green6.png';

import LightBlueStraight1 from './linktheplanes/lightBlue/lbue1.png';
import LightBlueStraight2 from './linktheplanes/lightBlue/lbue2.png';
import LightBlueStraight3 from './linktheplanes/lightBlue/lbue3.png';
import LightBlueStraight4 from './linktheplanes/lightBlue/lbue4.png';
import LightBlueStraight5 from './linktheplanes/lightBlue/lbue5.png';
import LightBlueStraight6 from './linktheplanes/lightBlue/lbue6.png';

import OrangeStraight1 from './linktheplanes/orange/orange1.png';
import OrangeStraight2 from './linktheplanes/orange/orange2.png';
import OrangeStraight3 from './linktheplanes/orange/orange3.png';
import OrangeStraight4 from './linktheplanes/orange/orange4.png';
import OrangeStraight5 from './linktheplanes/orange/orange5.png';
import OrangeStraight6 from './linktheplanes/orange/orange6.png';

import PinkStraight1 from './linktheplanes/pink/pink1.png';
import PinkStraight2 from './linktheplanes/pink/pink2.png';
import PinkStraight3 from './linktheplanes/pink/pink3.png';
import PinkStraight4 from './linktheplanes/pink/pink4.png';
import PinkStraight5 from './linktheplanes/pink/pink5.png';
import PinkStraight6 from './linktheplanes/pink/pink6.png';

import PurpleStraight1 from './linktheplanes/purple/purple1.png';
import PurpleStraight2 from './linktheplanes/purple/purple2.png';
import PurpleStraight3 from './linktheplanes/purple/purple3.png';
import PurpleStraight4 from './linktheplanes/purple/purple4.png';
import PurpleStraight5 from './linktheplanes/purple/purple5.png';
import PurpleStraight6 from './linktheplanes/purple/purple6.png';

import RedStraight1 from './linktheplanes/red/red1.png';
import RedStraight2 from './linktheplanes/red/red2.png';
import RedStraight3 from './linktheplanes/red/red3.png';
import RedStraight4 from './linktheplanes/red/red4.png';
import RedStraight5 from './linktheplanes/red/red5.png';
import RedStraight6 from './linktheplanes/red/red6.png';

import YellowStraight1 from './linktheplanes/yellow/yellow1.png';
import YellowStraight2 from './linktheplanes/yellow/yellow2.png';
import YellowStraight3 from './linktheplanes/yellow/yellow3.png';
import YellowStraight4 from './linktheplanes/yellow/yellow4.png';
import YellowStraight5 from './linktheplanes/yellow/yellow5.png';
import YellowStraight6 from './linktheplanes/yellow/yellow6.png';

//FoodGroup images
import Frutas from './foodgroup/frutas.png';
import Graos from './foodgroup/graos.png';
import Laticinios from './foodgroup/laticinios.png';
import Proteinas from './foodgroup/proteinas.png';
import Vegetais from './foodgroup/vegetais.png';

import FoodGroup1 from './foodgroup/foods/1.png';
import FoodGroup2 from './foodgroup/foods/2.png';
import FoodGroup3 from './foodgroup/foods/3.png';
import FoodGroup4 from './foodgroup/foods/4.png';
import FoodGroup5 from './foodgroup/foods/5.png';
import FoodGroup6 from './foodgroup/foods/6.png';
import FoodGroup7 from './foodgroup/foods/7.png';
import FoodGroup8 from './foodgroup/foods/8.png';
import FoodGroup9 from './foodgroup/foods/9.png';
import FoodGroup10 from './foodgroup/foods/10.png';
import FoodGroup11 from './foodgroup/foods/11.png';
import FoodGroup12 from './foodgroup/foods/12.png';
import FoodGroup13 from './foodgroup/foods/13.png';
import FoodGroup14 from './foodgroup/foods/14.png';
import FoodGroup15 from './foodgroup/foods/15.png';
import FoodGroup16 from './foodgroup/foods/16.png';
import FoodGroup17 from './foodgroup/foods/17.png';
import FoodGroup18 from './foodgroup/foods/18.png';

//Checkers images
import BluePawn from './checkers/pazul.png';
import BlueChecker from './checkers/dazul.png';
import YellowPawn from './checkers/pamarelo.png';
import YellowChecker from './checkers/damarela.png';
import CheckerBoard from './checkers/tabuleiro.svg';
import CheckerGraveyard from './checkers/cemiterio.png';
import CheckersBox from './checkers/caixa-damas.png';

//Chess Images
import ChessBoard from './chess/tabuleiro.svg';
import ChessGraveyard from './chess/cemiterio.png';
import BlueChessPawn from './chess/bluePawn.svg';
import BlueChessRook from './chess/blueRook.svg';
import BlueChessKnight from './chess/blueKnight.svg';
import BlueChessBishop from './chess/blueBishop.svg';
import BlueChessQueen from './chess/blueQueen.svg';
import BlueChessKing from './chess/blueKing.svg';
import YellowChessPawn from './chess/yellowPawn.svg';
import YellowChessRook from './chess/yellowRook.svg';
import YellowChessKnight from './chess/yellowKnight.svg';
import YellowChessBishop from './chess/yellowBishop.svg';
import YellowChessQueen from './chess/yellowQueen.svg';
import YellowChessKing from './chess/yellowKing.svg';

//Domino Images
import ZeroZeroDomino from './domino/zeroZero.svg';
import ZeroOneDomino from './domino/zeroOne.svg';
import ZeroTwoDomino from './domino/zeroTwo.svg';
import ZeroThreeDomino from './domino/zeroThree.svg';
import ZeroFourDomino from './domino/zeroFour.svg';
import ZeroFiveDomino from './domino/zeroFive.svg';
import ZeroSixDomino from './domino/zeroSix.svg';
import OneOneDomino from './domino/oneOne.svg';
import OneTwoDomino from './domino/oneTwo.svg';
import OneThreeDomino from './domino/oneThree.svg';
import OneFourDomino from './domino/oneFour.svg';
import OneFiveDomino from './domino/oneFive.svg';
import OneSixDomino from './domino/oneSix.svg';
import TwoTwoDomino from './domino/twoTwo.svg';
import TwoThreeDomino from './domino/twoThree.svg';
import TwoFourDomino from './domino/twoFour.svg';
import TwoFiveDomino from './domino/twoFive.svg';
import TwoSixDomino from './domino/twoSix.svg';
import ThreeThreeDomino from './domino/threeThree.svg';
import ThreeFourDomino from './domino/threeFour.svg';
import ThreeFiveDomino from './domino/threeFive.svg';
import ThreeSixDomino from './domino/threeSix.svg';
import FourFourDomino from './domino/fourFour.svg';
import FourFiveDomino from './domino/fourFive.svg';
import FourSixDomino from './domino/fourSix.svg';
import FiveFiveDomino from './domino/fiveFive.svg';
import FiveSixDomino from './domino/fiveSix.svg';
import SixSixDomino from './domino/sixSix.svg';

//Passive Agressive Assertive Card Game Images
import Card01 from './passiveagressive/NumberCards/1.png';
import Card02 from './passiveagressive/NumberCards/2.png';
import Card03 from './passiveagressive/NumberCards/3.png';
import Card04 from './passiveagressive/NumberCards/4.png';
import Card05 from './passiveagressive/NumberCards/5.png';
import Card06 from './passiveagressive/NumberCards/6.png';
import Card07 from './passiveagressive/NumberCards/7.png';
import Card08 from './passiveagressive/NumberCards/8.png';
import Card09 from './passiveagressive/NumberCards/9.png';
import Card10 from './passiveagressive/NumberCards/10.png';
import Card11 from './passiveagressive/NumberCards/11.png';
import Card12 from './passiveagressive/NumberCards/12.png';
import Card13 from './passiveagressive/NumberCards/13.png';
import Card14 from './passiveagressive/NumberCards/14.png';
import Card15 from './passiveagressive/NumberCards/15.png';
import Card16 from './passiveagressive/NumberCards/16.png';
import Card17 from './passiveagressive/NumberCards/17.png';
import Card18 from './passiveagressive/NumberCards/18.png';
import Card19 from './passiveagressive/NumberCards/19.png';
import Card20 from './passiveagressive/NumberCards/20.png';
import Card21 from './passiveagressive/NumberCards/21.png';
import Card22 from './passiveagressive/NumberCards/22.png';
import Card23 from './passiveagressive/NumberCards/23.png';
import Card24 from './passiveagressive/NumberCards/24.png';
import Card25 from './passiveagressive/NumberCards/25.png';
import Card26 from './passiveagressive/NumberCards/26.png';
import Card27 from './passiveagressive/NumberCards/27.png';
import CardBack from './passiveagressive/back.png';
import Gabarito from './passiveagressive/gabarito.png';
import AgressiveCard from './passiveagressive/BehaviorCards/agressive.png';
import PassiveCard from './passiveagressive/BehaviorCards/passive.png';
import AssertiveCard from './passiveagressive/BehaviorCards/assertive.png';
import ResultCard from './passiveagressive/result.png';

export const numberedCards = [
  Card01,
  Card02,
  Card03,
  Card04,
  Card05,
  Card06,
  Card07,
  Card08,
  Card09,
  Card10,
  Card11,
  Card12,
  Card13,
  Card14,
  Card15,
  Card16,
  Card17,
  Card18,
  Card19,
  Card20,
  Card21,
  Card22,
  Card23,
  Card24,
  Card25,
  Card26,
  Card27,
];

export const NormalDominos = [
  ZeroZeroDomino,
  ZeroOneDomino,
  ZeroTwoDomino,
  ZeroThreeDomino,
  ZeroFourDomino,
  ZeroFiveDomino,
  ZeroSixDomino,
  OneOneDomino,
  OneTwoDomino,
  OneThreeDomino,
  OneFourDomino,
  OneFiveDomino,
  OneSixDomino,
  TwoTwoDomino,
  TwoThreeDomino,
  TwoFourDomino,
  TwoFiveDomino,
  TwoSixDomino,
  ThreeThreeDomino,
  ThreeFourDomino,
  ThreeFiveDomino,
  ThreeSixDomino,
  FourFourDomino,
  FourFiveDomino,
  FourSixDomino,
  FiveFiveDomino,
  FiveSixDomino,
  SixSixDomino,
];

export const FoodGroupFoods = [
  FoodGroup1,
  FoodGroup2,
  FoodGroup3,
  FoodGroup4,
  FoodGroup5,
  FoodGroup6,
  FoodGroup7,
  FoodGroup8,
  FoodGroup9,
  FoodGroup10,
  FoodGroup11,
  FoodGroup12,
  FoodGroup13,
  FoodGroup14,
  FoodGroup15,
  FoodGroup16,
  FoodGroup17,
  FoodGroup18,
];

export const FoodsOptions = [Frutas, Graos, Laticinios, Proteinas, Vegetais];

export const YellowStraights = [
  YellowStraight5,
  YellowStraight6,
  YellowStraight1,
  YellowStraight2,
  YellowStraight3,
  YellowStraight4,
];

export const RedStraights = [
  RedStraight5,
  RedStraight6,
  RedStraight1,
  RedStraight2,
  RedStraight3,
  RedStraight4,
];

export const PurpleStraights = [
  PurpleStraight5,
  PurpleStraight6,
  PurpleStraight1,
  PurpleStraight2,
  PurpleStraight3,
  PurpleStraight4,
];

export const PinkStraights = [
  PinkStraight5,
  PinkStraight6,
  PinkStraight1,
  PinkStraight2,
  PinkStraight3,
  PinkStraight4,
];

export const OrangeStraights = [
  OrangeStraight5,
  OrangeStraight6,
  OrangeStraight1,
  OrangeStraight2,
  OrangeStraight3,
  OrangeStraight4,
];

export const LighBlueStraights = [
  LightBlueStraight5,
  LightBlueStraight6,
  LightBlueStraight1,
  LightBlueStraight2,
  LightBlueStraight3,
  LightBlueStraight4,
];

export const GreenStraights = [
  GreenStraight5,
  GreenStraight6,
  GreenStraight1,
  GreenStraight2,
  GreenStraight3,
  GreenStraight4,
];

export const BlueStraights = [
  BlueStraight5,
  BlueStraight6,
  BlueStraight1,
  BlueStraight2,
  BlueStraight3,
  BlueStraight4,
];

export const VeryEasyPlanes = [
  VeryEasyPlane1,
  VeryEasyPlane2,
  VeryEasyPlane3,
  VeryEasyPlane4,
  VeryEasyPlane5,
  VeryEasyPlane6,
  VeryEasyPlane7,
  VeryEasyPlane8,
  VeryEasyPlane9,
  VeryEasyPlane10,
];

export const EasyPlanes = [
  EasyPlane1,
  EasyPlane2,
  EasyPlane3,
  EasyPlane4,
  EasyPlane5,
  EasyPlane6,
  EasyPlane7,
  EasyPlane8,
  EasyPlane9,
  EasyPlane10,
];

export const NormalPlanes = [
  NormalPlane1,
  NormalPlane2,
  NormalPlane3,
  NormalPlane4,
  NormalPlane5,
  NormalPlane6,
  NormalPlane7,
  NormalPlane8,
  NormalPlane9,
  NormalPlane10,
];

export const HardPlanes = [
  HardPlane1,
  HardPlane2,
  HardPlane3,
  HardPlane4,
  HardPlane5,
  HardPlane6,
  HardPlane7,
  HardPlane8,
  HardPlane9,
  HardPlane10,
];

export const VeryHardPlanes = [
  VeryHardPlane1,
  VeryHardPlane2,
  VeryHardPlane3,
  VeryHardPlane4,
  VeryHardPlane5,
  VeryHardPlane6,
  VeryHardPlane7,
  VeryHardPlane8,
  VeryHardPlane9,
  VeryHardPlane10,
];

export const RelateTheColorsBoards = [
  RelateColorEasyBoard,
  RelateColorMediumBoard,
  RelateColorHardBoard,
];

export const ArrowsBalls = [BlueBall, YellowBall, RedBall];

export const Dominos = [
  Domino1,
  Domino2,
  Domino3,
  Domino4,
  Domino5,
  Domino6,
  Domino7,
  Domino8,
  Domino9,
  Domino10,
  Domino11,
  Domino12,
  Domino13,
  Domino14,
  Domino15,
  Domino16,
  Domino17,
  Domino18,
  Domino19,
  Domino20,
  Domino21,
];

export const RelateTheColorsCircles = [
  RelateCircle1,
  RelateCircle2,
  RelateCircle3,
  RelateCircle4,
  RelateCircle5,
  RelateCircle6,
  RelateCircle7,
  RelateCircle8,
  RelateCircle9,
  RelateCircle10,
  RelateCircle11,
  RelateCircle12,
];

export const RelateTheColorsMediumColors = [
  RelateColors1,
  RelateColors2,
  RelateColors3,
  RelateColors4,
  RelateColors5,
  RelateColors9,
  RelateColors7,
  RelateColors8,
];

export const RelateTheColorsEasyColors = [
  RelateColors9,
  RelateColors10,
  RelateColors11,
  RelateColors12,
  RelateColors13,
  RelateColors14,
];

export const Behaviors = [
  Behavior1,
  Behavior2,
  Behavior3,
  Behavior4,
  Behavior5,
  Behavior6,
  Behavior7,
  Behavior8,
  Behavior9,
  Behavior10,
  Behavior11,
  Behavior12,
  Behavior13,
  Behavior14,
  Behavior15,
  Behavior16,
  Behavior17,
];

export const VaiEncararCards = [
  VaiEncararCard1,
  VaiEncararCard2,
  VaiEncararCard3,
  VaiEncararCard4,
  VaiEncararCard5,
  VaiEncararCard6,
  VaiEncararCard7,
  VaiEncararCard8,
  VaiEncararCard9,
  VaiEncararCard10,
  VaiEncararCard11,
  VaiEncararCard12,
  VaiEncararCard13,
  VaiEncararCard14,
  VaiEncararCard15,
  // VaiEncararCard16, carta que exige desenhar
  VaiEncararCard17,
  VaiEncararCard18,
  VaiEncararCard19,
  VaiEncararCard20,
  VaiEncararCard21,
  VaiEncararCard22,
  VaiEncararCard23,
  VaiEncararCard24,
  VaiEncararCard25,
  VaiEncararCard26,
  VaiEncararCard27,
  VaiEncararCard28,
  VaiEncararCard29,
  VaiEncararCard30,
];

export const ParkingCars = [
  YellowCar,
  BlueCar,
  CBlueCar,
  BlackCar,
  PinkCar,
  TurquoiseCar,
  LightGreenCar,
  DarkGreenCar,
  YellowCarR,
  BlueCarR,
  CBlueCarR,
  BlackCarR,
  PinkCarR,
  TurquoiseCarR,
  LightGreenCarR,
  DarkGreenCarR,
];

const ParkingCards = [
  Parking1,
  Parking2,
  Parking3,
  Parking4,
  Parking5,
  Parking6,
  Parking7,
  Parking8,
  Parking9,
  Parking10,
  Parking11,
];

const ShowTheWayArrows = [
  ArrowDown,
  ArrowUp,
  ArrowLeft,
  ArrowRight,
  ArrowDownLeft,
  ArrowDownRight,
  ArrowUpLeft,
  ArrowUpRight,
  ArrowLeftUp,
  ArrowRightUp,
  ArrowLeftDown,
  ArrowRightDown,
];

const ShowTheWayObjects = [
  ShowTheWayCat,
  ShowTheWayBall,
  ShowTheWayBeetle,
  ShowTheWayLeaf,
  ShowTheWayDog,
  ShowTheWayBone,
];

const CauseEffectImages = [
  CauseEffectTitle,
  NegativeCause,
  NegativeEffect,
  PositiveCause,
  PositiveEffect,
];

const SelfEvaluationBgs = [SE1, SE2, SE3, SE4, SE5, SE6, SE7, SE8];

const SelfEvaluationImages = [
  OpcoesSemaforo,
  SemaforoTopo,
  SemaforoMeio,
  SemaforoBaixo,
  SemaforoTopoColorido,
  SemaforoMeioColorido,
  SemaforoBaixoColorido,
];

const Olhos = [
  OlhoAnsioso,
  OlhoAssustado,
  OlhoFeliz,
  OlhoRaiva,
  OlhoTedio,
  OlhoTriste,
];

const Bocas = [BocaAnsioso, BocaFeliz, BocaRaiva, BocaTedio, BocaTriste];

const Rostos = [RostoAnsioso, RostoFeliz, RostoRaiva, RostoTedio, RostoTriste];

const ProblemSizeImages = [
  ProblemSizeLogo,
  Termometro,
  TermometroColor,
  Numbers1a4,
  Numbers5a7,
  Numbers8a10,
  Numbers1a4Grey,
  Numbers5a7Grey,
  Numbers8a10Grey,
];

const People = [
  People002,
  People003,
  People004,
  People010,
  People012,
  People025,
  People026,
  People027,
  People028,
  People029,
  People030,
  People031,
  People032,
  People033,
  People034,
];

const Buildings = [
  Buildings001,
  Buildings002,
  Buildings003,
  Buildings004,
  Buildings005,
  Buildings006,
  Buildings007,
  Buildings008,
  Buildings009,
  Buildings010,
  Buildings011,
  Buildings012,
  Buildings013,
  Buildings014,
  Buildings015,
  Buildings016,
  Buildings114,
  Buildings115,
  Buildings116,
  Buildings117,
  Buildings118,
  Buildings119,
  Buildings120,
  Houses001,
  Houses002,
  Houses003,
  Houses004,
];

const Creatures = [
  Creatures001,
  Creatures002,
  Creatures003,
  Creatures004,
  Creatures005,
  Creatures006,
  Creatures007,
  Creatures008,
  Creatures009,
  Creatures010,
  Creatures011,
  Creatures012,
  Creatures013,
  Creatures014,
  Creatures015,
  Creatures016,
  Creatures017,
  Creatures018,
  Creatures019,
  Creatures020,
  Creatures021,
  Creatures022,
  Creatures023,
  Creatures024,
  Creatures025,
  Creatures026,
  Creatures027,
  Creatures028,
  Creatures040,
  Creatures041,
  Creatures042,
  Creatures043,
  Creatures044,
  Creatures045,
  Creatures046,
  Creatures047,
  Creatures048,
  Creatures049,
  Creatures055,
  Creatures056,
];

const Foods = [
  Food001,
  Food002,
  Food003,
  Food004,
  Food005,
  Food006,
  Food007,
  Food008,
  Food009,
  Food010,
  Food011,
  Food012,
  Food013,
  Food014,
  Food015,
  Food016,
  Food017,
  Food018,
];

const Healths = [
  Health001,
  Health002,
  Health003,
  Health004,
  Health005,
  Health006,
  Health007,
  Health008,
  Health009,
  Health010,
  Health011,
  Health012,
  Health013,
  Health014,
  Health015,
  Health016,
  Health017,
  Health018,
  Health019,
  Health020,
  Health021,
  Health022,
  Health023,
  Health024,
  Health025,
  Health026,
  Health027,
];

export {
  Logo,
  LogoFundo,
  Cacto,
  CactoGrey,
  Lapis,
  LapisGrey,
  Areia,
  ProblemSizeImages,
  Buildings,
  Creatures,
  Foods,
  Healths,
  People,
  PawImage,
  MusicNotes,
  CloverImage,
  FirstCactus,
  FirstLines,
  LogoCacto,
  DefenseLawyer,
  Judge,
  Prosecutor,
  JudgementLogo,
  LeftDialog,
  RightDialog,
  Magnifier,
  CardBack,
  AgressiveCard,
  PassiveCard,
  AssertiveCard,
  ResultCard,
  Rosto,
  Rostos,
  Bocas,
  Olhos,
  CauseEffectImages,
  SelfEvaluationImages,
  SelfEvaluationBgs,
  ShowTheWayBoard,
  ShowTheWayArrows,
  ShowTheWayObjects,
  ParkingCards,
  EmptyParking,
  LoveMyselfTitle,
  LoveMyselfTree,
  LoveMyselfMobileTree,
  HeartBg,
  VaiEncararBack,
  VaiEncararTitle,
  GoodVsBadBackground,
  XSBoy,
  XSGirl,
  XSGirl2,
  XSDialog,
  XSTitle,
  XSHandWithSmartphone,
  XSLeftHandWithSmartphone,
  XSGreenDialog,
  XSOrangeDialog,
  XSBlueDialog,
  ArrowInstructions,
  ArrowAnswers,
  ArrowBoard,
  BluePawn,
  BlueChecker,
  YellowPawn,
  YellowChecker,
  CheckerBoard,
  CheckerGraveyard,
  CheckersBox,
  ChessBoard,
  ChessGraveyard,
  BlueChessPawn,
  BlueChessRook,
  BlueChessKnight,
  BlueChessBishop,
  BlueChessQueen,
  BlueChessKing,
  YellowChessPawn,
  YellowChessRook,
  YellowChessKnight,
  YellowChessBishop,
  YellowChessQueen,
  YellowChessKing,
  jigsaw,
  cactus,
  x_icon,
};
